import { LocalizedStringsMethods } from "localized-strings";
import { UAParser } from "ua-parser-js";

let baseUrl: string = "localhost:8000";
let strings: (LocalizedStringsMethods & any) | null = null;

export function setUrl(url: string): void {
    baseUrl = url;
}

export function setStrings(newStrings: (LocalizedStringsMethods & {}) | null): void {
    strings = newStrings;
}
export interface Image {
    width: number;
    height: number;
    url: string;
}

export interface ImageAndThumb {
    thumbImage: Image;
    image: Image;
}

export interface UncertainImage {
    bytes: Buffer | null;
    image: Image | null;
}

export interface ProfileStats {
    numberOfItems: number;
    numberOfRents: number;
    numberOfMyRents: number;
    rating: number;
    numberOfSales: number;
    numberOfFollowing: number;
    numberOfFollowers: number;
    numberOfLockerLikes: number;
}

export interface UserInfo {
    id: string;
    avatar: Image | null;
    name: string;
    nick: string;
    rate: number;
    height: number | null;
    deleted: boolean;
    type: LocUserType;
    isLockerLiked: boolean;
    isFollowing: boolean;
    ownerName: string | null;
}

export interface ItemDetails {
    adjustment: boolean;
    category: Category;
    color: Color;
    details: string;
    gender: Gender;
    images: Image[];
    brand: string | null;
    ocasions: Ocasion[];
    size: Size;
    salePrice: number | null;
    rentPrice: number | null;
    originalPrice: number;
}

export interface Status {
    status: LocStatus;
    changeDate: Date;
    actionBy: string | null;
    pending: boolean;
}

export interface Notification {
    id: string;
    type: NotificationType;
    contentId: string[];
    image: Image | null;
    title: string | null;
    body: string;
    badge: number;
    icon: string;
    tag: string | null;
    createdAt: Date;
}

export interface ProviderToken {
    providor: Providers;
    idToken: string;
    acessToken: string | null;
}

export interface ProviderUserInfo {
    providor: Providers;
    rawId: string;
    displayName: string | null;
    email: string | null;
    photoUrl: string | null;
}

export interface Balance {
    waitingFunds: BalanceWaitingFunds;
    avaiable: BalanceAvaiable;
    transfered: BalanceTransfered;
}

export interface BalanceWaitingFunds {
    amount: number;
}

export interface BalanceAvaiable {
    amount: number;
}

export interface BalanceTransfered {
    amount: number;
}

export interface Recipient {
    transferInterval: string;
    transferDay: string;
    transferEnabled: boolean;
    bankAccountId: string | null;
    bankAccount: BankAccount | null;
}

export interface BankAccount {
    documentNumber: string;
    bankName: string;
    bankCode: string;
    agency: string;
    agencyDv: string | null;
    account: string;
    accountDv: string;
    type: BankAccountType;
    legalName: string;
}

export interface EditBankAccount {
    bankName: string;
    bankCode: string;
    agency: string;
    agencyDv: string | null;
    account: string;
    accountDv: string;
    type: BankAccountType;
    legalName: string;
}

export interface NFe {
    id: string | null;
    number: number | null;
    emitedAt: Date | null;
    canceledAt: Date | null;
    status: NFeStatus | null;
}

export interface Pagination {
    pageNumber: number;
}

export interface PaginationOptions {
    limit: number | null;
    skip: number | null;
}

export interface Phone {
    ddd: string;
    ddi: string;
    number: string;
}

export interface Period {
    start: Date;
    end: Date;
}

export interface Location {
    lat: number;
    lon: number;
}

export interface FillAddress {
    street: string;
    neighborhood: string;
    city: string;
    state: string;
    uf: string;
    country: string;
    zip: string;
    location: Location;
}

export interface Address {
    street: string;
    neighborhood: string;
    city: string;
    uf: string;
    country: string;
    number: string;
    zip: string;
    adicionalInfo: string;
    reference: string;
    location: Location;
}

export interface UserCard {
    id: string;
    lastDigits: string;
    brand: string;
    exp: string;
}

export interface TableStats {
    totalAmount: number;
    averageTicked: number;
    totalPrice: number;
}

export interface FilterPeriod {
    startDate: Date | null;
    endDate: Date | null;
}

export interface City {
    id: string;
    name: string;
    uf: StateUF;
}

export interface NewAdminUser {
    password: string;
    licensedInfo: CreateLicensedInfo | null;
    type: AdminUserType;
    name: string;
    email: string;
}

export interface EditAdminUser {
    password: string;
    licensedInfo: EditLicensedInfo | null;
    type: AdminUserType;
    name: string;
    email: string;
}

export interface AdminUser {
    id: string;
    licensedInfo: LicensedInfo | null;
    type: AdminUserType;
    name: string;
    email: string;
}

export interface AdminUserDetails {
    type: AdminUserType;
    name: string;
    email: string;
}

export interface CreateLicensedInfo {
    document: string;
    bankAccount: BankAccount;
    licenses: CreateLicense[];
}

export interface EditLicensedInfo {
    bankAccount: BankAccount;
    licenses: EditLicense[];
}

export interface LicensedInfo {
    document: string;
    bankAccount: BankAccount;
    licenses: License[];
}

export interface CreateLicense {
    cityId: string;
    percentage: number;
    expirationDate: Date;
}

export interface EditLicense {
    cityId: string;
    percentage: number;
    expirationDate: Date;
}

export interface License {
    city: City;
    percentage: number;
    expirationDate: Date;
}

export interface LicenseDetails {
    percentage: number;
    expirationDate: Date;
}

export interface PagarmeBalance {
    object: string;
    waitingFunds: BalanceFundsWaitingFunds;
    available: BalanceFundsAvailable;
    transferred: BalanceFundsTransferred;
}

export interface BalanceFunds {
    waitingFunds: BalanceFundsWaitingFunds;
    available: BalanceFundsAvailable;
    transferred: BalanceFundsTransferred;
}

export interface BalanceFundsWaitingFunds {
    amount: number;
}

export interface BalanceFundsAvailable {
    amount: number;
}

export interface BalanceFundsTransferred {
    amount: number;
}

export interface FilterCity {
    id: string;
    name: string;
}

export interface ItemAddress {
    neighborhood: string;
    city: string;
    uf: string;
    country: string;
    location: Location;
    street: string;
    number: string;
}

export interface OptionalItemDetails {
    adjustment: boolean;
    category: Category | null;
    color: Color | null;
    details: string;
    gender: Gender;
    thumbImage: Image;
    images: Image[];
    brand: string | null;
    ocasions: (Ocasion | null)[];
    size: Size | null;
    rentPrice: number | null;
    salePrice: number | null;
    originalPrice: number;
}

export interface Filter {
    distance: number;
    sizes: Size[];
    gender: Gender[];
    categories: Category[];
    ocasions: Ocasion[];
    colors: Color[];
    orderBy: OrderBy | null;
    itemOfferType: ItemOfferType | null;
}

export interface Query {
    search: string | null;
    filter: Filter;
}

export interface Banner {
    id: string;
    image: Image;
    mobileImage: Image | null;
    contentType: BannerContentType | null;
    type: BannerType;
    slug: string;
    title: string;
    url: string | null;
    content: BannerContent;
}

export interface BannerDetails {
    contentType: BannerContentType | null;
    type: BannerType;
    slug: string;
    title: string;
    url: string | null;
    content: BannerContent;
}

export interface BannerContent {
    query: Query | null;
    url: string | null;
    group: BannerGroup | null;
}

export interface BannerGroup {
    userIds: string[];
    sections: BannerSection[];
    innerBanner: InnerBanner | null;
}

export interface BannerSection {
    title: string;
    itemIds: string[];
}

export interface InnerBanner {
    image: Image;
    url: string;
}

export interface NewBanner {
    image: UncertainImage;
    mobileImage: UncertainImage | null;
    innerBanner: UncertainInnerBanner | null;
    contentType: BannerContentType | null;
    type: BannerType;
    slug: string;
    title: string;
    url: string | null;
    content: BannerContent;
}

export interface EditBanner {
    image: UncertainImage;
    mobileImage: UncertainImage | null;
    innerBanner: UncertainInnerBanner | null;
    contentType: BannerContentType | null;
    type: BannerType;
    slug: string;
    title: string;
    url: string | null;
    content: BannerContent;
}

export interface UncertainInnerBanner {
    innerImage: UncertainImage;
    innerUrl: string;
}

export interface Post {
    id: string;
    user: UserInfo | null;
    approved: boolean;
    image: Image;
    slug: string;
    title: string;
    body: string;
    bodyHtml: string;
}

export interface NewPost {
    image: UncertainImage;
    slug: string;
    title: string;
    body: string;
    bodyHtml: string;
}

export interface EditPost {
    image: UncertainImage;
    slug: string;
    title: string;
    body: string;
    bodyHtml: string;
}

export interface PostDetails {
    slug: string;
    title: string;
    body: string;
    bodyHtml: string;
}

export interface FranchiseContact {
    name: string;
    email: string;
    phone: string;
    instagram: string;
    city: string;
    state: string;
}

export interface FeedPost {
    id: string;
    text: string;
    image: Image | null;
    liked: boolean;
    likes: number;
    userId: string;
    createdAt: Date;
    userAddress: Address | null;
    userInfo: UserInfo;
    items: number;
    countComment: number;
}

export interface FeedPostDetails {
    id: string;
    text: string;
    image: Image | null;
    likes: number;
    userId: string;
    createdAt: Date;
}

export interface StatsReport {
    amount: number;
    ticket: number;
    totalPrice: number;
}

export interface AdminHomeData {
    sales: StatsReport;
    rents: StatsReport;
    rentsAndSales: StatsReport;
    createdUsers: number;
    createdItems: number;
}

export interface GraphicDateReport {
    value: number;
    date: Date;
}

export interface GraphicDateHourReport {
    value: number;
    dateHour: Date;
}

export interface GraphicHourReport {
    value: number;
    hour: number;
}

export interface GraphicDataPerDate {
    sales: GraphicDateReport[];
    rents: GraphicDateReport[];
}

export interface GraphicDataPerDateHour {
    sales: GraphicDateHourReport[];
    rents: GraphicDateHourReport[];
}

export interface GraphicDataPerHour {
    sales: GraphicHourReport[];
    rents: GraphicHourReport[];
}

export interface PreviousPeriodPercent {
    rentsAndSalesAmount: number;
    rentsAndSalesTicket: number;
    salesTicket: number;
    salesAmount: number;
    rentsTicket: number;
    rentsAmount: number;
    users: number;
    items: number;
}

export interface User {
    id: string;
    gender: Gender | null;
    birthday: Date | null;
    type: LocUserType;
    name: string;
    nick: string;
    cpf: string;
    email: string;
    phone: Phone;
    avatar: Image | null;
    address: Address | null;
    createdAt: Date;
    deletedAt: Date | null;
    blockedUntil: Date | null;
    basePopularity: number;
    documentsVerified: boolean;
}

export interface EditUser {
    nick: string;
    name: string;
    email: string;
    phone: Phone;
    gender: Gender | null;
    type: LocUserType;
    blockedUntil: Date | null;
    basePopularity: number;
    documentsVerified: boolean;
}

export interface UserTop {
    user: User;
    amount: number;
}

export interface TopTeenUsers {
    topRent: UserTop[];
    topRented: UserTop[];
    topSeller: UserTop[];
    topBuyer: UserTop[];
}

export interface UserStats {
    total: number;
}

export interface UsersFilter {
    createdAtUserDate: FilterPeriod | null;
    orderBy: UserTableOrderBy | null;
    search: string | null;
    topLoc: LocUserType | null;
}

export interface UserTableOrderBy {
    field: UserFieldsOrderBy;
    order: TableOrderBy;
}

export interface Item {
    id: string;
    owner: User;
    calendar: Period[];
    createdAt: Date;
    department: Department;
    adjustment: boolean;
    category: Category | null;
    color: Color | null;
    details: string;
    gender: Gender;
    thumbImage: Image;
    images: Image[];
    brand: string | null;
    ocasions: (Ocasion | null)[];
    size: Size | null;
    rentPrice: number | null;
    salePrice: number | null;
    originalPrice: number;
}

export interface ItemFilter {
    createdAtRentDate: FilterPeriod | null;
    orderBy: ItemTableOrderBy | null;
}

export interface ItemTableOrderBy {
    field: ItemFieldsOrderBy;
    order: TableOrderBy;
}

export interface ItemStats {
    totalAmount: number;
    averageRentPrice: number;
    averageSalePrice: number;
}

export interface PostComment {
    id: string;
    text: string;
    userInfo: UserInfo;
    postId: string;
    createdAt: Date;
}

export interface Request {
    id: string;
    numId: string;
    owner: User;
    requester: User;
    item: RequestItem;
    transport: TransportInfo | null;
    period: Period;
    rentPrice: number;
    originalPrice: number;
    statusList: Status[];
    currentStatus: Status;
    payment: Payment | null;
    promotion: RequestPromotion;
    nfe: NFe;
    createdAt: Date;
}

export interface RequestPromotion {
    card: Payment | null;
    type: PromotionType | null;
    total: number;
    id: string | null;
}

export interface RequestItem {
    thumbImage: Image;
    calendar: Period[];
    adjustment: boolean;
    category: Category;
    color: Color;
    details: string;
    gender: Gender;
    images: Image[];
    brand: string | null;
    ocasions: Ocasion[];
    size: Size;
    salePrice: number | null;
    rentPrice: number | null;
    originalPrice: number;
}

export interface TransportInfo {
    requester: Address;
    owner: Address;
    price: number;
}

export interface Payment {
    transactionId: number;
    lastDigits: string;
    brand: string;
    paidAmount: number;
    status: PaymentStatus;
    locSplit: PaymentLocSplit;
    ownerSplit: PaymentOwnerSplit;
}

export interface PaymentLocSplit {
    id: string;
    recipientId: string;
    amount: number;
}

export interface PaymentOwnerSplit {
    id: string;
    recipientId: string;
    amount: number;
}

export interface FineDetails {
    reason: string;
    amount: number;
}

export interface Fine {
    id: string;
    transactionId: number;
    payedBy: User | null;
    payedTo: User | null;
    request: Request | null;
    createdAt: Date;
    reason: string;
    amount: number;
}

export interface RequestFine {
    id: string;
    transactionId: number;
    payedBy: User | null;
    payedTo: User;
    createdAt: Date;
    reason: string;
    amount: number;
}

export interface CreateRequestFine {
    requestId: string | null;
    payedBy: OwnerOrRequester | null;
    payedTo: OwnerOrRequester | null;
    reason: string;
    amount: number;
}

export interface CreateFine {
    payedById: string | null;
    payedToId: string | null;
    reason: string;
    amount: number;
}

export interface RequestTableOrderBy {
    field: RequestFieldsOrderBy;
    order: TableOrderBy;
}

export interface RentsFilter {
    startRentDate: FilterPeriod | null;
    endRentDate: FilterPeriod | null;
    createdAtRentDate: FilterPeriod | null;
    status: LocStatus[];
    orderBy: RequestTableOrderBy | null;
}

export interface NewPostComment {
    text: string;
    postId: string;
}

export interface PostCommentDetails {
    id: string;
    text: string;
    userId: string;
    postId: string;
    createdAt: Date;
}

export interface PromotionFilter {
    id: string | null;
    expirationDate: FilterPeriod | null;
    createdAtDate: FilterPeriod | null;
    orderBy: PromotionTableOrderBy | null;
}

export interface PromotionTableOrderBy {
    field: PromotionFieldOrderBy;
    order: TableOrderBy;
}

export interface Promotion {
    uses: number;
    createdAt: Date;
    deletedAt: Date | null;
    cityIds: string[] | null;
    id: string;
    value: number | null;
    percentage: number | null;
    type: PromotionType;
    usageType: PromotionUsageType;
    maxUsesPerUser: number | null;
    maxUses: number;
    expirationDate: Date;
    userIds: string[] | null;
}

export interface PromotionDetails {
    id: string;
    value: number | null;
    percentage: number | null;
    type: PromotionType;
    usageType: PromotionUsageType;
    maxUsesPerUser: number | null;
    maxUses: number;
    expirationDate: Date;
    userIds: string[] | null;
}

export interface Report {
    numberOfUser: number;
    numberOfPieces: number;
    piecesTotalSum: number;
    averageTicket: number;
    averageSalesTicket: number;
    totalOfRents: number;
    totalOfSales: number;
}

export interface ReportPerItemField {
    fieldname: string;
    value: string;
    number: number;
    sum: number;
}

export interface NumberLabelReport {
    label: string;
    number: number;
}

export interface NumberDateReport {
    date: Date;
    number: number;
}

export interface NumberDateHourReport {
    dateHour: Date;
    number: number;
}

export interface NumberHourReport {
    hour: number;
    number: number;
}

export interface ItemLabelReport {
    label: string;
    number: number;
    originalPrice: number;
    rentPrice: number;
}

export interface ItemDateReport {
    date: Date;
    number: number;
    originalPrice: number;
    rentPrice: number;
}

export interface ItemDateHourReport {
    dateHour: Date;
    number: number;
    originalPrice: number;
    rentPrice: number;
}

export interface ItemHourReport {
    hour: number;
    number: number;
    originalPrice: number;
    rentPrice: number;
}

export interface ItemCategoryReport {
    category: Category;
    number: number;
    originalPrice: number;
    rentPrice: number;
}

export interface NumberColorReport {
    color: Color;
    number: number;
}

export interface NumberDepartmentReport {
    department: Department;
    number: number;
}

export interface NumberSizeReport {
    size: Size;
    number: number;
}

export interface SalePromotion {
    promotionId: string;
    type: PromotionType;
    total: number;
}

export interface SaleStatus {
    status: SaleStatusType;
    actionBy: string | null;
    createdAt: Date;
}

export interface Sale {
    id: string;
    simpleId: string;
    price: number;
    item: Item;
    seller: User;
    buyer: User;
    currentStatus: SaleStatus;
    statusList: SaleStatus[];
    promotion: SalePromotion | null;
    createdAt: Date;
    editedAt: Date;
}

export interface SaleTableOrderBy {
    field: SaleFieldsOrderBy;
    order: TableOrderBy;
}

export interface SalesFilter {
    createdAtSaleDate: FilterPeriod | null | null;
    orderBy: SaleTableOrderBy | null;
    status: SaleStatusType[];
}

export enum LocUserType {
    LOC = "LOC",
    LOCPRO = "LOCPRO",
    TOPLOC = "TOPLOC",
    LOCLOVER = "LOCLOVER",
    LOCSTORE = "LOCSTORE",
    LOCSELLER = "LOCSELLER",
}

export function translateLocUserType(enumLocUserType: LocUserType): string {
    switch (enumLocUserType) {
        case LocUserType.LOC: {
            return strings ? strings["enum"]["LocUserType"]["LOC"] || LocUserType.LOC : LocUserType.LOC;
        }
        case LocUserType.LOCPRO: {
            return strings ? strings["enum"]["LocUserType"]["LOCPRO"] || LocUserType.LOCPRO : LocUserType.LOCPRO;
        }
        case LocUserType.TOPLOC: {
            return strings ? strings["enum"]["LocUserType"]["TOPLOC"] || LocUserType.TOPLOC : LocUserType.TOPLOC;
        }
        case LocUserType.LOCLOVER: {
            return strings ? strings["enum"]["LocUserType"]["LOCLOVER"] || LocUserType.LOCLOVER : LocUserType.LOCLOVER;
        }
        case LocUserType.LOCSTORE: {
            return strings ? strings["enum"]["LocUserType"]["LOCSTORE"] || LocUserType.LOCSTORE : LocUserType.LOCSTORE;
        }
        case LocUserType.LOCSELLER: {
            return strings ? strings["enum"]["LocUserType"]["LOCSELLER"] || LocUserType.LOCSELLER : LocUserType.LOCSELLER;
        }
    }
    return "";
}

export function allValuesLocUserType(): LocUserType[] {
    return [
        LocUserType.LOC,
        LocUserType.LOCPRO,
        LocUserType.TOPLOC,
        LocUserType.LOCLOVER,
        LocUserType.LOCSTORE,
        LocUserType.LOCSELLER,
    ];
}

export function allTranslatedValuesLocUserType(): string[] {
    return [
        translateLocUserType(LocUserType.LOC),
        translateLocUserType(LocUserType.LOCPRO),
        translateLocUserType(LocUserType.TOPLOC),
        translateLocUserType(LocUserType.LOCLOVER),
        translateLocUserType(LocUserType.LOCSTORE),
        translateLocUserType(LocUserType.LOCSELLER),
    ];
}

export function allDisplayableValuesLocUserType(): string[] {
    return allTranslatedValuesLocUserType().sort();
}

export function valueFromTranslationLocUserType(translation: string): LocUserType {
    const index = allTranslatedValuesLocUserType().indexOf(translation);
    return allValuesLocUserType()[index] || LocUserType.LOC;
}

export enum Department {
    clothes = "clothes",
    accessory = "accessory",
}

export function translateDepartment(enumDepartment: Department): string {
    switch (enumDepartment) {
        case Department.clothes: {
            return strings ? strings["enum"]["Department"]["clothes"] || Department.clothes : Department.clothes;
        }
        case Department.accessory: {
            return strings ? strings["enum"]["Department"]["accessory"] || Department.accessory : Department.accessory;
        }
    }
    return "";
}

export function allValuesDepartment(): Department[] {
    return [
        Department.clothes,
        Department.accessory,
    ];
}

export function allTranslatedValuesDepartment(): string[] {
    return [
        translateDepartment(Department.clothes),
        translateDepartment(Department.accessory),
    ];
}

export function allDisplayableValuesDepartment(): string[] {
    return allTranslatedValuesDepartment().sort();
}

export function valueFromTranslationDepartment(translation: string): Department {
    const index = allTranslatedValuesDepartment().indexOf(translation);
    return allValuesDepartment()[index] || Department.clothes;
}

export enum Category {
    rompers = "rompers",
    conjunct = "conjunct",
    body = "body",
    top = "top",
    blouse = "blouse",
    pants = "pants",
    shorts = "shorts",
    longShorts = "longShorts",
    skirt = "skirt",
    dress = "dress",
    overlap = "overlap",
    overall = "overall",
    suit = "suit",
    purse = "purse",
    shoes = "shoes",
    winter = "winter",
    accessory = "accessory",
    other = "other",
}

export function translateCategory(enumCategory: Category): string {
    switch (enumCategory) {
        case Category.rompers: {
            return strings ? strings["enum"]["Category"]["rompers"] || Category.rompers : Category.rompers;
        }
        case Category.conjunct: {
            return strings ? strings["enum"]["Category"]["conjunct"] || Category.conjunct : Category.conjunct;
        }
        case Category.body: {
            return strings ? strings["enum"]["Category"]["body"] || Category.body : Category.body;
        }
        case Category.top: {
            return strings ? strings["enum"]["Category"]["top"] || Category.top : Category.top;
        }
        case Category.blouse: {
            return strings ? strings["enum"]["Category"]["blouse"] || Category.blouse : Category.blouse;
        }
        case Category.pants: {
            return strings ? strings["enum"]["Category"]["pants"] || Category.pants : Category.pants;
        }
        case Category.shorts: {
            return strings ? strings["enum"]["Category"]["shorts"] || Category.shorts : Category.shorts;
        }
        case Category.longShorts: {
            return strings ? strings["enum"]["Category"]["longShorts"] || Category.longShorts : Category.longShorts;
        }
        case Category.skirt: {
            return strings ? strings["enum"]["Category"]["skirt"] || Category.skirt : Category.skirt;
        }
        case Category.dress: {
            return strings ? strings["enum"]["Category"]["dress"] || Category.dress : Category.dress;
        }
        case Category.overlap: {
            return strings ? strings["enum"]["Category"]["overlap"] || Category.overlap : Category.overlap;
        }
        case Category.overall: {
            return strings ? strings["enum"]["Category"]["overall"] || Category.overall : Category.overall;
        }
        case Category.suit: {
            return strings ? strings["enum"]["Category"]["suit"] || Category.suit : Category.suit;
        }
        case Category.purse: {
            return strings ? strings["enum"]["Category"]["purse"] || Category.purse : Category.purse;
        }
        case Category.shoes: {
            return strings ? strings["enum"]["Category"]["shoes"] || Category.shoes : Category.shoes;
        }
        case Category.winter: {
            return strings ? strings["enum"]["Category"]["winter"] || Category.winter : Category.winter;
        }
        case Category.accessory: {
            return strings ? strings["enum"]["Category"]["accessory"] || Category.accessory : Category.accessory;
        }
        case Category.other: {
            return strings ? strings["enum"]["Category"]["other"] || Category.other : Category.other;
        }
    }
    return "";
}

export function allValuesCategory(): Category[] {
    return [
        Category.rompers,
        Category.conjunct,
        Category.body,
        Category.top,
        Category.blouse,
        Category.pants,
        Category.shorts,
        Category.longShorts,
        Category.skirt,
        Category.dress,
        Category.overlap,
        Category.overall,
        Category.suit,
        Category.purse,
        Category.shoes,
        Category.winter,
        Category.accessory,
        Category.other,
    ];
}

export function allTranslatedValuesCategory(): string[] {
    return [
        translateCategory(Category.rompers),
        translateCategory(Category.conjunct),
        translateCategory(Category.body),
        translateCategory(Category.top),
        translateCategory(Category.blouse),
        translateCategory(Category.pants),
        translateCategory(Category.shorts),
        translateCategory(Category.longShorts),
        translateCategory(Category.skirt),
        translateCategory(Category.dress),
        translateCategory(Category.overlap),
        translateCategory(Category.overall),
        translateCategory(Category.suit),
        translateCategory(Category.purse),
        translateCategory(Category.shoes),
        translateCategory(Category.winter),
        translateCategory(Category.accessory),
        translateCategory(Category.other),
    ];
}

export function allDisplayableValuesCategory(): string[] {
    return allTranslatedValuesCategory().sort();
}

export function valueFromTranslationCategory(translation: string): Category {
    const index = allTranslatedValuesCategory().indexOf(translation);
    return allValuesCategory()[index] || Category.rompers;
}

export enum Ocasion {
    formalParty = "formalParty",
    party = "party",
    casual = "casual",
    winter = "winter",
    costume = "costume",
    beach = "beach",
    work = "work",
    pregnant = "pregnant",
}

export function translateOcasion(enumOcasion: Ocasion): string {
    switch (enumOcasion) {
        case Ocasion.formalParty: {
            return strings ? strings["enum"]["Ocasion"]["formalParty"] || Ocasion.formalParty : Ocasion.formalParty;
        }
        case Ocasion.party: {
            return strings ? strings["enum"]["Ocasion"]["party"] || Ocasion.party : Ocasion.party;
        }
        case Ocasion.casual: {
            return strings ? strings["enum"]["Ocasion"]["casual"] || Ocasion.casual : Ocasion.casual;
        }
        case Ocasion.winter: {
            return strings ? strings["enum"]["Ocasion"]["winter"] || Ocasion.winter : Ocasion.winter;
        }
        case Ocasion.costume: {
            return strings ? strings["enum"]["Ocasion"]["costume"] || Ocasion.costume : Ocasion.costume;
        }
        case Ocasion.beach: {
            return strings ? strings["enum"]["Ocasion"]["beach"] || Ocasion.beach : Ocasion.beach;
        }
        case Ocasion.work: {
            return strings ? strings["enum"]["Ocasion"]["work"] || Ocasion.work : Ocasion.work;
        }
        case Ocasion.pregnant: {
            return strings ? strings["enum"]["Ocasion"]["pregnant"] || Ocasion.pregnant : Ocasion.pregnant;
        }
    }
    return "";
}

export function allValuesOcasion(): Ocasion[] {
    return [
        Ocasion.formalParty,
        Ocasion.party,
        Ocasion.casual,
        Ocasion.winter,
        Ocasion.costume,
        Ocasion.beach,
        Ocasion.work,
        Ocasion.pregnant,
    ];
}

export function allTranslatedValuesOcasion(): string[] {
    return [
        translateOcasion(Ocasion.formalParty),
        translateOcasion(Ocasion.party),
        translateOcasion(Ocasion.casual),
        translateOcasion(Ocasion.winter),
        translateOcasion(Ocasion.costume),
        translateOcasion(Ocasion.beach),
        translateOcasion(Ocasion.work),
        translateOcasion(Ocasion.pregnant),
    ];
}

export function allDisplayableValuesOcasion(): string[] {
    return allTranslatedValuesOcasion().sort();
}

export function valueFromTranslationOcasion(translation: string): Ocasion {
    const index = allTranslatedValuesOcasion().indexOf(translation);
    return allValuesOcasion()[index] || Ocasion.formalParty;
}

export enum Color {
    striped = "striped",
    grid = "grid",
    yellow = "yellow",
    blue = "blue",
    beige = "beige",
    white = "white",
    gray = "gray",
    gold = "gold",
    jeans = "jeans",
    orange = "orange",
    brown = "brown",
    nude = "nude",
    offWhite = "offWhite",
    silver = "silver",
    black = "black",
    pink = "pink",
    purple = "purple",
    green = "green",
    red = "red",
    wine = "wine",
    stamped = "stamped",
}

export function translateColor(enumColor: Color): string {
    switch (enumColor) {
        case Color.striped: {
            return strings ? strings["enum"]["Color"]["striped"] || Color.striped : Color.striped;
        }
        case Color.grid: {
            return strings ? strings["enum"]["Color"]["grid"] || Color.grid : Color.grid;
        }
        case Color.yellow: {
            return strings ? strings["enum"]["Color"]["yellow"] || Color.yellow : Color.yellow;
        }
        case Color.blue: {
            return strings ? strings["enum"]["Color"]["blue"] || Color.blue : Color.blue;
        }
        case Color.beige: {
            return strings ? strings["enum"]["Color"]["beige"] || Color.beige : Color.beige;
        }
        case Color.white: {
            return strings ? strings["enum"]["Color"]["white"] || Color.white : Color.white;
        }
        case Color.gray: {
            return strings ? strings["enum"]["Color"]["gray"] || Color.gray : Color.gray;
        }
        case Color.gold: {
            return strings ? strings["enum"]["Color"]["gold"] || Color.gold : Color.gold;
        }
        case Color.jeans: {
            return strings ? strings["enum"]["Color"]["jeans"] || Color.jeans : Color.jeans;
        }
        case Color.orange: {
            return strings ? strings["enum"]["Color"]["orange"] || Color.orange : Color.orange;
        }
        case Color.brown: {
            return strings ? strings["enum"]["Color"]["brown"] || Color.brown : Color.brown;
        }
        case Color.nude: {
            return strings ? strings["enum"]["Color"]["nude"] || Color.nude : Color.nude;
        }
        case Color.offWhite: {
            return strings ? strings["enum"]["Color"]["offWhite"] || Color.offWhite : Color.offWhite;
        }
        case Color.silver: {
            return strings ? strings["enum"]["Color"]["silver"] || Color.silver : Color.silver;
        }
        case Color.black: {
            return strings ? strings["enum"]["Color"]["black"] || Color.black : Color.black;
        }
        case Color.pink: {
            return strings ? strings["enum"]["Color"]["pink"] || Color.pink : Color.pink;
        }
        case Color.purple: {
            return strings ? strings["enum"]["Color"]["purple"] || Color.purple : Color.purple;
        }
        case Color.green: {
            return strings ? strings["enum"]["Color"]["green"] || Color.green : Color.green;
        }
        case Color.red: {
            return strings ? strings["enum"]["Color"]["red"] || Color.red : Color.red;
        }
        case Color.wine: {
            return strings ? strings["enum"]["Color"]["wine"] || Color.wine : Color.wine;
        }
        case Color.stamped: {
            return strings ? strings["enum"]["Color"]["stamped"] || Color.stamped : Color.stamped;
        }
    }
    return "";
}

export function allValuesColor(): Color[] {
    return [
        Color.striped,
        Color.grid,
        Color.yellow,
        Color.blue,
        Color.beige,
        Color.white,
        Color.gray,
        Color.gold,
        Color.jeans,
        Color.orange,
        Color.brown,
        Color.nude,
        Color.offWhite,
        Color.silver,
        Color.black,
        Color.pink,
        Color.purple,
        Color.green,
        Color.red,
        Color.wine,
        Color.stamped,
    ];
}

export function allTranslatedValuesColor(): string[] {
    return [
        translateColor(Color.striped),
        translateColor(Color.grid),
        translateColor(Color.yellow),
        translateColor(Color.blue),
        translateColor(Color.beige),
        translateColor(Color.white),
        translateColor(Color.gray),
        translateColor(Color.gold),
        translateColor(Color.jeans),
        translateColor(Color.orange),
        translateColor(Color.brown),
        translateColor(Color.nude),
        translateColor(Color.offWhite),
        translateColor(Color.silver),
        translateColor(Color.black),
        translateColor(Color.pink),
        translateColor(Color.purple),
        translateColor(Color.green),
        translateColor(Color.red),
        translateColor(Color.wine),
        translateColor(Color.stamped),
    ];
}

export function allDisplayableValuesColor(): string[] {
    return allTranslatedValuesColor().sort();
}

export function valueFromTranslationColor(translation: string): Color {
    const index = allTranslatedValuesColor().indexOf(translation);
    return allValuesColor()[index] || Color.striped;
}

export enum Size {
    sSingle = "sSingle",
    sXS = "sXS",
    sS = "sS",
    sM = "sM",
    sL = "sL",
    sXL = "sXL",
    sXXL = "sXXL",
    s33 = "s33",
    s34 = "s34",
    s35 = "s35",
    s36 = "s36",
    s37 = "s37",
    s38 = "s38",
    s39 = "s39",
    s40 = "s40",
    s41 = "s41",
    s42 = "s42",
    s43 = "s43",
    s44 = "s44",
    s45 = "s45",
    s46 = "s46",
    s48 = "s48",
    s50 = "s50",
    s50Plus = "s50Plus",
}

export function translateSize(enumSize: Size): string {
    switch (enumSize) {
        case Size.sSingle: {
            return strings ? strings["enum"]["Size"]["sSingle"] || Size.sSingle : Size.sSingle;
        }
        case Size.sXS: {
            return strings ? strings["enum"]["Size"]["sXS"] || Size.sXS : Size.sXS;
        }
        case Size.sS: {
            return strings ? strings["enum"]["Size"]["sS"] || Size.sS : Size.sS;
        }
        case Size.sM: {
            return strings ? strings["enum"]["Size"]["sM"] || Size.sM : Size.sM;
        }
        case Size.sL: {
            return strings ? strings["enum"]["Size"]["sL"] || Size.sL : Size.sL;
        }
        case Size.sXL: {
            return strings ? strings["enum"]["Size"]["sXL"] || Size.sXL : Size.sXL;
        }
        case Size.sXXL: {
            return strings ? strings["enum"]["Size"]["sXXL"] || Size.sXXL : Size.sXXL;
        }
        case Size.s33: {
            return strings ? strings["enum"]["Size"]["s33"] || Size.s33 : Size.s33;
        }
        case Size.s34: {
            return strings ? strings["enum"]["Size"]["s34"] || Size.s34 : Size.s34;
        }
        case Size.s35: {
            return strings ? strings["enum"]["Size"]["s35"] || Size.s35 : Size.s35;
        }
        case Size.s36: {
            return strings ? strings["enum"]["Size"]["s36"] || Size.s36 : Size.s36;
        }
        case Size.s37: {
            return strings ? strings["enum"]["Size"]["s37"] || Size.s37 : Size.s37;
        }
        case Size.s38: {
            return strings ? strings["enum"]["Size"]["s38"] || Size.s38 : Size.s38;
        }
        case Size.s39: {
            return strings ? strings["enum"]["Size"]["s39"] || Size.s39 : Size.s39;
        }
        case Size.s40: {
            return strings ? strings["enum"]["Size"]["s40"] || Size.s40 : Size.s40;
        }
        case Size.s41: {
            return strings ? strings["enum"]["Size"]["s41"] || Size.s41 : Size.s41;
        }
        case Size.s42: {
            return strings ? strings["enum"]["Size"]["s42"] || Size.s42 : Size.s42;
        }
        case Size.s43: {
            return strings ? strings["enum"]["Size"]["s43"] || Size.s43 : Size.s43;
        }
        case Size.s44: {
            return strings ? strings["enum"]["Size"]["s44"] || Size.s44 : Size.s44;
        }
        case Size.s45: {
            return strings ? strings["enum"]["Size"]["s45"] || Size.s45 : Size.s45;
        }
        case Size.s46: {
            return strings ? strings["enum"]["Size"]["s46"] || Size.s46 : Size.s46;
        }
        case Size.s48: {
            return strings ? strings["enum"]["Size"]["s48"] || Size.s48 : Size.s48;
        }
        case Size.s50: {
            return strings ? strings["enum"]["Size"]["s50"] || Size.s50 : Size.s50;
        }
        case Size.s50Plus: {
            return strings ? strings["enum"]["Size"]["s50Plus"] || Size.s50Plus : Size.s50Plus;
        }
    }
    return "";
}

export function allValuesSize(): Size[] {
    return [
        Size.sSingle,
        Size.sXS,
        Size.sS,
        Size.sM,
        Size.sL,
        Size.sXL,
        Size.sXXL,
        Size.s33,
        Size.s34,
        Size.s35,
        Size.s36,
        Size.s37,
        Size.s38,
        Size.s39,
        Size.s40,
        Size.s41,
        Size.s42,
        Size.s43,
        Size.s44,
        Size.s45,
        Size.s46,
        Size.s48,
        Size.s50,
        Size.s50Plus,
    ];
}

export function allTranslatedValuesSize(): string[] {
    return [
        translateSize(Size.sSingle),
        translateSize(Size.sXS),
        translateSize(Size.sS),
        translateSize(Size.sM),
        translateSize(Size.sL),
        translateSize(Size.sXL),
        translateSize(Size.sXXL),
        translateSize(Size.s33),
        translateSize(Size.s34),
        translateSize(Size.s35),
        translateSize(Size.s36),
        translateSize(Size.s37),
        translateSize(Size.s38),
        translateSize(Size.s39),
        translateSize(Size.s40),
        translateSize(Size.s41),
        translateSize(Size.s42),
        translateSize(Size.s43),
        translateSize(Size.s44),
        translateSize(Size.s45),
        translateSize(Size.s46),
        translateSize(Size.s48),
        translateSize(Size.s50),
        translateSize(Size.s50Plus),
    ];
}

export function allDisplayableValuesSize(): string[] {
    return allTranslatedValuesSize().sort();
}

export function valueFromTranslationSize(translation: string): Size {
    const index = allTranslatedValuesSize().indexOf(translation);
    return allValuesSize()[index] || Size.sSingle;
}

export enum LocStatus {
    pendingApproval = "pendingApproval",
    approved = "approved",
    notApproved = "notApproved",
    canceled = "canceled",
    deliveredToRequester = "deliveredToRequester",
    receivedByRequester = "receivedByRequester",
    deliveredToOwner = "deliveredToOwner",
    receivedByOwner = "receivedByOwner",
}

export function translateLocStatus(enumLocStatus: LocStatus): string {
    switch (enumLocStatus) {
        case LocStatus.pendingApproval: {
            return strings ? strings["enum"]["LocStatus"]["pendingApproval"] || LocStatus.pendingApproval : LocStatus.pendingApproval;
        }
        case LocStatus.approved: {
            return strings ? strings["enum"]["LocStatus"]["approved"] || LocStatus.approved : LocStatus.approved;
        }
        case LocStatus.notApproved: {
            return strings ? strings["enum"]["LocStatus"]["notApproved"] || LocStatus.notApproved : LocStatus.notApproved;
        }
        case LocStatus.canceled: {
            return strings ? strings["enum"]["LocStatus"]["canceled"] || LocStatus.canceled : LocStatus.canceled;
        }
        case LocStatus.deliveredToRequester: {
            return strings ? strings["enum"]["LocStatus"]["deliveredToRequester"] || LocStatus.deliveredToRequester : LocStatus.deliveredToRequester;
        }
        case LocStatus.receivedByRequester: {
            return strings ? strings["enum"]["LocStatus"]["receivedByRequester"] || LocStatus.receivedByRequester : LocStatus.receivedByRequester;
        }
        case LocStatus.deliveredToOwner: {
            return strings ? strings["enum"]["LocStatus"]["deliveredToOwner"] || LocStatus.deliveredToOwner : LocStatus.deliveredToOwner;
        }
        case LocStatus.receivedByOwner: {
            return strings ? strings["enum"]["LocStatus"]["receivedByOwner"] || LocStatus.receivedByOwner : LocStatus.receivedByOwner;
        }
    }
    return "";
}

export function allValuesLocStatus(): LocStatus[] {
    return [
        LocStatus.pendingApproval,
        LocStatus.approved,
        LocStatus.notApproved,
        LocStatus.canceled,
        LocStatus.deliveredToRequester,
        LocStatus.receivedByRequester,
        LocStatus.deliveredToOwner,
        LocStatus.receivedByOwner,
    ];
}

export function allTranslatedValuesLocStatus(): string[] {
    return [
        translateLocStatus(LocStatus.pendingApproval),
        translateLocStatus(LocStatus.approved),
        translateLocStatus(LocStatus.notApproved),
        translateLocStatus(LocStatus.canceled),
        translateLocStatus(LocStatus.deliveredToRequester),
        translateLocStatus(LocStatus.receivedByRequester),
        translateLocStatus(LocStatus.deliveredToOwner),
        translateLocStatus(LocStatus.receivedByOwner),
    ];
}

export function allDisplayableValuesLocStatus(): string[] {
    return allTranslatedValuesLocStatus().sort();
}

export function valueFromTranslationLocStatus(translation: string): LocStatus {
    const index = allTranslatedValuesLocStatus().indexOf(translation);
    return allValuesLocStatus()[index] || LocStatus.pendingApproval;
}

export enum LocRequestActions {
    approve = "approve",
    disapprove = "disapprove",
    deliverToRequester = "deliverToRequester",
    receiveFromRequester = "receiveFromRequester",
    deliverToOwner = "deliverToOwner",
    receiveFromOwner = "receiveFromOwner",
    cancel = "cancel",
    cancelWithPrejudice = "cancelWithPrejudice",
}

export function translateLocRequestActions(enumLocRequestActions: LocRequestActions): string {
    switch (enumLocRequestActions) {
        case LocRequestActions.approve: {
            return strings ? strings["enum"]["LocRequestActions"]["approve"] || LocRequestActions.approve : LocRequestActions.approve;
        }
        case LocRequestActions.disapprove: {
            return strings ? strings["enum"]["LocRequestActions"]["disapprove"] || LocRequestActions.disapprove : LocRequestActions.disapprove;
        }
        case LocRequestActions.deliverToRequester: {
            return strings ? strings["enum"]["LocRequestActions"]["deliverToRequester"] || LocRequestActions.deliverToRequester : LocRequestActions.deliverToRequester;
        }
        case LocRequestActions.receiveFromRequester: {
            return strings ? strings["enum"]["LocRequestActions"]["receiveFromRequester"] || LocRequestActions.receiveFromRequester : LocRequestActions.receiveFromRequester;
        }
        case LocRequestActions.deliverToOwner: {
            return strings ? strings["enum"]["LocRequestActions"]["deliverToOwner"] || LocRequestActions.deliverToOwner : LocRequestActions.deliverToOwner;
        }
        case LocRequestActions.receiveFromOwner: {
            return strings ? strings["enum"]["LocRequestActions"]["receiveFromOwner"] || LocRequestActions.receiveFromOwner : LocRequestActions.receiveFromOwner;
        }
        case LocRequestActions.cancel: {
            return strings ? strings["enum"]["LocRequestActions"]["cancel"] || LocRequestActions.cancel : LocRequestActions.cancel;
        }
        case LocRequestActions.cancelWithPrejudice: {
            return strings ? strings["enum"]["LocRequestActions"]["cancelWithPrejudice"] || LocRequestActions.cancelWithPrejudice : LocRequestActions.cancelWithPrejudice;
        }
    }
    return "";
}

export function allValuesLocRequestActions(): LocRequestActions[] {
    return [
        LocRequestActions.approve,
        LocRequestActions.disapprove,
        LocRequestActions.deliverToRequester,
        LocRequestActions.receiveFromRequester,
        LocRequestActions.deliverToOwner,
        LocRequestActions.receiveFromOwner,
        LocRequestActions.cancel,
        LocRequestActions.cancelWithPrejudice,
    ];
}

export function allTranslatedValuesLocRequestActions(): string[] {
    return [
        translateLocRequestActions(LocRequestActions.approve),
        translateLocRequestActions(LocRequestActions.disapprove),
        translateLocRequestActions(LocRequestActions.deliverToRequester),
        translateLocRequestActions(LocRequestActions.receiveFromRequester),
        translateLocRequestActions(LocRequestActions.deliverToOwner),
        translateLocRequestActions(LocRequestActions.receiveFromOwner),
        translateLocRequestActions(LocRequestActions.cancel),
        translateLocRequestActions(LocRequestActions.cancelWithPrejudice),
    ];
}

export function allDisplayableValuesLocRequestActions(): string[] {
    return allTranslatedValuesLocRequestActions().sort();
}

export function valueFromTranslationLocRequestActions(translation: string): LocRequestActions {
    const index = allTranslatedValuesLocRequestActions().indexOf(translation);
    return allValuesLocRequestActions()[index] || LocRequestActions.approve;
}

export enum PromotionType {
    percentage = "percentage",
    value = "value",
}

export function translatePromotionType(enumPromotionType: PromotionType): string {
    switch (enumPromotionType) {
        case PromotionType.percentage: {
            return strings ? strings["enum"]["PromotionType"]["percentage"] || PromotionType.percentage : PromotionType.percentage;
        }
        case PromotionType.value: {
            return strings ? strings["enum"]["PromotionType"]["value"] || PromotionType.value : PromotionType.value;
        }
    }
    return "";
}

export function allValuesPromotionType(): PromotionType[] {
    return [
        PromotionType.percentage,
        PromotionType.value,
    ];
}

export function allTranslatedValuesPromotionType(): string[] {
    return [
        translatePromotionType(PromotionType.percentage),
        translatePromotionType(PromotionType.value),
    ];
}

export function allDisplayableValuesPromotionType(): string[] {
    return allTranslatedValuesPromotionType().sort();
}

export function valueFromTranslationPromotionType(translation: string): PromotionType {
    const index = allTranslatedValuesPromotionType().indexOf(translation);
    return allValuesPromotionType()[index] || PromotionType.percentage;
}

export enum NotificationType {
    chat = "chat",
    locRequest = "locRequest",
    card = "card",
    bank = "bank",
    display = "display",
    item = "item",
    locker = "locker",
}

export function translateNotificationType(enumNotificationType: NotificationType): string {
    switch (enumNotificationType) {
        case NotificationType.chat: {
            return strings ? strings["enum"]["NotificationType"]["chat"] || NotificationType.chat : NotificationType.chat;
        }
        case NotificationType.locRequest: {
            return strings ? strings["enum"]["NotificationType"]["locRequest"] || NotificationType.locRequest : NotificationType.locRequest;
        }
        case NotificationType.card: {
            return strings ? strings["enum"]["NotificationType"]["card"] || NotificationType.card : NotificationType.card;
        }
        case NotificationType.bank: {
            return strings ? strings["enum"]["NotificationType"]["bank"] || NotificationType.bank : NotificationType.bank;
        }
        case NotificationType.display: {
            return strings ? strings["enum"]["NotificationType"]["display"] || NotificationType.display : NotificationType.display;
        }
        case NotificationType.item: {
            return strings ? strings["enum"]["NotificationType"]["item"] || NotificationType.item : NotificationType.item;
        }
        case NotificationType.locker: {
            return strings ? strings["enum"]["NotificationType"]["locker"] || NotificationType.locker : NotificationType.locker;
        }
    }
    return "";
}

export function allValuesNotificationType(): NotificationType[] {
    return [
        NotificationType.chat,
        NotificationType.locRequest,
        NotificationType.card,
        NotificationType.bank,
        NotificationType.display,
        NotificationType.item,
        NotificationType.locker,
    ];
}

export function allTranslatedValuesNotificationType(): string[] {
    return [
        translateNotificationType(NotificationType.chat),
        translateNotificationType(NotificationType.locRequest),
        translateNotificationType(NotificationType.card),
        translateNotificationType(NotificationType.bank),
        translateNotificationType(NotificationType.display),
        translateNotificationType(NotificationType.item),
        translateNotificationType(NotificationType.locker),
    ];
}

export function allDisplayableValuesNotificationType(): string[] {
    return allTranslatedValuesNotificationType().sort();
}

export function valueFromTranslationNotificationType(translation: string): NotificationType {
    const index = allTranslatedValuesNotificationType().indexOf(translation);
    return allValuesNotificationType()[index] || NotificationType.chat;
}

export enum Providers {
    apple = "apple",
    google = "google",
    facebook = "facebook",
}

export function translateProviders(enumProviders: Providers): string {
    switch (enumProviders) {
        case Providers.apple: {
            return strings ? strings["enum"]["Providers"]["apple"] || Providers.apple : Providers.apple;
        }
        case Providers.google: {
            return strings ? strings["enum"]["Providers"]["google"] || Providers.google : Providers.google;
        }
        case Providers.facebook: {
            return strings ? strings["enum"]["Providers"]["facebook"] || Providers.facebook : Providers.facebook;
        }
    }
    return "";
}

export function allValuesProviders(): Providers[] {
    return [
        Providers.apple,
        Providers.google,
        Providers.facebook,
    ];
}

export function allTranslatedValuesProviders(): string[] {
    return [
        translateProviders(Providers.apple),
        translateProviders(Providers.google),
        translateProviders(Providers.facebook),
    ];
}

export function allDisplayableValuesProviders(): string[] {
    return allTranslatedValuesProviders().sort();
}

export function valueFromTranslationProviders(translation: string): Providers {
    const index = allTranslatedValuesProviders().indexOf(translation);
    return allValuesProviders()[index] || Providers.apple;
}

export enum BankAccountType {
    contaCorrente = "contaCorrente",
    contaPoupanca = "contaPoupanca",
    contaCorrenteConjunta = "contaCorrenteConjunta",
    contaPoupancaConjunta = "contaPoupancaConjunta",
}

export function translateBankAccountType(enumBankAccountType: BankAccountType): string {
    switch (enumBankAccountType) {
        case BankAccountType.contaCorrente: {
            return strings ? strings["enum"]["BankAccountType"]["contaCorrente"] || BankAccountType.contaCorrente : BankAccountType.contaCorrente;
        }
        case BankAccountType.contaPoupanca: {
            return strings ? strings["enum"]["BankAccountType"]["contaPoupanca"] || BankAccountType.contaPoupanca : BankAccountType.contaPoupanca;
        }
        case BankAccountType.contaCorrenteConjunta: {
            return strings ? strings["enum"]["BankAccountType"]["contaCorrenteConjunta"] || BankAccountType.contaCorrenteConjunta : BankAccountType.contaCorrenteConjunta;
        }
        case BankAccountType.contaPoupancaConjunta: {
            return strings ? strings["enum"]["BankAccountType"]["contaPoupancaConjunta"] || BankAccountType.contaPoupancaConjunta : BankAccountType.contaPoupancaConjunta;
        }
    }
    return "";
}

export function allValuesBankAccountType(): BankAccountType[] {
    return [
        BankAccountType.contaCorrente,
        BankAccountType.contaPoupanca,
        BankAccountType.contaCorrenteConjunta,
        BankAccountType.contaPoupancaConjunta,
    ];
}

export function allTranslatedValuesBankAccountType(): string[] {
    return [
        translateBankAccountType(BankAccountType.contaCorrente),
        translateBankAccountType(BankAccountType.contaPoupanca),
        translateBankAccountType(BankAccountType.contaCorrenteConjunta),
        translateBankAccountType(BankAccountType.contaPoupancaConjunta),
    ];
}

export function allDisplayableValuesBankAccountType(): string[] {
    return allTranslatedValuesBankAccountType().sort();
}

export function valueFromTranslationBankAccountType(translation: string): BankAccountType {
    const index = allTranslatedValuesBankAccountType().indexOf(translation);
    return allValuesBankAccountType()[index] || BankAccountType.contaCorrente;
}

export enum NFeStatus {
    Issued = "Issued",
    Created = "Created",
    Error = "Error",
    None = "None",
}

export function translateNFeStatus(enumNFeStatus: NFeStatus): string {
    switch (enumNFeStatus) {
        case NFeStatus.Issued: {
            return strings ? strings["enum"]["NFeStatus"]["Issued"] || NFeStatus.Issued : NFeStatus.Issued;
        }
        case NFeStatus.Created: {
            return strings ? strings["enum"]["NFeStatus"]["Created"] || NFeStatus.Created : NFeStatus.Created;
        }
        case NFeStatus.Error: {
            return strings ? strings["enum"]["NFeStatus"]["Error"] || NFeStatus.Error : NFeStatus.Error;
        }
        case NFeStatus.None: {
            return strings ? strings["enum"]["NFeStatus"]["None"] || NFeStatus.None : NFeStatus.None;
        }
    }
    return "";
}

export function allValuesNFeStatus(): NFeStatus[] {
    return [
        NFeStatus.Issued,
        NFeStatus.Created,
        NFeStatus.Error,
        NFeStatus.None,
    ];
}

export function allTranslatedValuesNFeStatus(): string[] {
    return [
        translateNFeStatus(NFeStatus.Issued),
        translateNFeStatus(NFeStatus.Created),
        translateNFeStatus(NFeStatus.Error),
        translateNFeStatus(NFeStatus.None),
    ];
}

export function allDisplayableValuesNFeStatus(): string[] {
    return allTranslatedValuesNFeStatus().sort();
}

export function valueFromTranslationNFeStatus(translation: string): NFeStatus {
    const index = allTranslatedValuesNFeStatus().indexOf(translation);
    return allValuesNFeStatus()[index] || NFeStatus.Issued;
}

export enum Gender {
    female = "female",
    male = "male",
}

export function translateGender(enumGender: Gender): string {
    switch (enumGender) {
        case Gender.female: {
            return strings ? strings["enum"]["Gender"]["female"] || Gender.female : Gender.female;
        }
        case Gender.male: {
            return strings ? strings["enum"]["Gender"]["male"] || Gender.male : Gender.male;
        }
    }
    return "";
}

export function allValuesGender(): Gender[] {
    return [
        Gender.female,
        Gender.male,
    ];
}

export function allTranslatedValuesGender(): string[] {
    return [
        translateGender(Gender.female),
        translateGender(Gender.male),
    ];
}

export function allDisplayableValuesGender(): string[] {
    return allTranslatedValuesGender().sort();
}

export function valueFromTranslationGender(translation: string): Gender {
    const index = allTranslatedValuesGender().indexOf(translation);
    return allValuesGender()[index] || Gender.female;
}

export enum StateUF {
    AC = "AC",
    AL = "AL",
    AP = "AP",
    AM = "AM",
    BA = "BA",
    CE = "CE",
    DF = "DF",
    ES = "ES",
    GO = "GO",
    MA = "MA",
    MT = "MT",
    MS = "MS",
    MG = "MG",
    PA = "PA",
    PB = "PB",
    PR = "PR",
    PE = "PE",
    PI = "PI",
    RJ = "RJ",
    RN = "RN",
    RS = "RS",
    RO = "RO",
    RR = "RR",
    SC = "SC",
    SP = "SP",
    SE = "SE",
    TO = "TO",
}

export function translateStateUF(enumStateUF: StateUF): string {
    switch (enumStateUF) {
        case StateUF.AC: {
            return strings ? strings["enum"]["StateUF"]["AC"] || StateUF.AC : StateUF.AC;
        }
        case StateUF.AL: {
            return strings ? strings["enum"]["StateUF"]["AL"] || StateUF.AL : StateUF.AL;
        }
        case StateUF.AP: {
            return strings ? strings["enum"]["StateUF"]["AP"] || StateUF.AP : StateUF.AP;
        }
        case StateUF.AM: {
            return strings ? strings["enum"]["StateUF"]["AM"] || StateUF.AM : StateUF.AM;
        }
        case StateUF.BA: {
            return strings ? strings["enum"]["StateUF"]["BA"] || StateUF.BA : StateUF.BA;
        }
        case StateUF.CE: {
            return strings ? strings["enum"]["StateUF"]["CE"] || StateUF.CE : StateUF.CE;
        }
        case StateUF.DF: {
            return strings ? strings["enum"]["StateUF"]["DF"] || StateUF.DF : StateUF.DF;
        }
        case StateUF.ES: {
            return strings ? strings["enum"]["StateUF"]["ES"] || StateUF.ES : StateUF.ES;
        }
        case StateUF.GO: {
            return strings ? strings["enum"]["StateUF"]["GO"] || StateUF.GO : StateUF.GO;
        }
        case StateUF.MA: {
            return strings ? strings["enum"]["StateUF"]["MA"] || StateUF.MA : StateUF.MA;
        }
        case StateUF.MT: {
            return strings ? strings["enum"]["StateUF"]["MT"] || StateUF.MT : StateUF.MT;
        }
        case StateUF.MS: {
            return strings ? strings["enum"]["StateUF"]["MS"] || StateUF.MS : StateUF.MS;
        }
        case StateUF.MG: {
            return strings ? strings["enum"]["StateUF"]["MG"] || StateUF.MG : StateUF.MG;
        }
        case StateUF.PA: {
            return strings ? strings["enum"]["StateUF"]["PA"] || StateUF.PA : StateUF.PA;
        }
        case StateUF.PB: {
            return strings ? strings["enum"]["StateUF"]["PB"] || StateUF.PB : StateUF.PB;
        }
        case StateUF.PR: {
            return strings ? strings["enum"]["StateUF"]["PR"] || StateUF.PR : StateUF.PR;
        }
        case StateUF.PE: {
            return strings ? strings["enum"]["StateUF"]["PE"] || StateUF.PE : StateUF.PE;
        }
        case StateUF.PI: {
            return strings ? strings["enum"]["StateUF"]["PI"] || StateUF.PI : StateUF.PI;
        }
        case StateUF.RJ: {
            return strings ? strings["enum"]["StateUF"]["RJ"] || StateUF.RJ : StateUF.RJ;
        }
        case StateUF.RN: {
            return strings ? strings["enum"]["StateUF"]["RN"] || StateUF.RN : StateUF.RN;
        }
        case StateUF.RS: {
            return strings ? strings["enum"]["StateUF"]["RS"] || StateUF.RS : StateUF.RS;
        }
        case StateUF.RO: {
            return strings ? strings["enum"]["StateUF"]["RO"] || StateUF.RO : StateUF.RO;
        }
        case StateUF.RR: {
            return strings ? strings["enum"]["StateUF"]["RR"] || StateUF.RR : StateUF.RR;
        }
        case StateUF.SC: {
            return strings ? strings["enum"]["StateUF"]["SC"] || StateUF.SC : StateUF.SC;
        }
        case StateUF.SP: {
            return strings ? strings["enum"]["StateUF"]["SP"] || StateUF.SP : StateUF.SP;
        }
        case StateUF.SE: {
            return strings ? strings["enum"]["StateUF"]["SE"] || StateUF.SE : StateUF.SE;
        }
        case StateUF.TO: {
            return strings ? strings["enum"]["StateUF"]["TO"] || StateUF.TO : StateUF.TO;
        }
    }
    return "";
}

export function allValuesStateUF(): StateUF[] {
    return [
        StateUF.AC,
        StateUF.AL,
        StateUF.AP,
        StateUF.AM,
        StateUF.BA,
        StateUF.CE,
        StateUF.DF,
        StateUF.ES,
        StateUF.GO,
        StateUF.MA,
        StateUF.MT,
        StateUF.MS,
        StateUF.MG,
        StateUF.PA,
        StateUF.PB,
        StateUF.PR,
        StateUF.PE,
        StateUF.PI,
        StateUF.RJ,
        StateUF.RN,
        StateUF.RS,
        StateUF.RO,
        StateUF.RR,
        StateUF.SC,
        StateUF.SP,
        StateUF.SE,
        StateUF.TO,
    ];
}

export function allTranslatedValuesStateUF(): string[] {
    return [
        translateStateUF(StateUF.AC),
        translateStateUF(StateUF.AL),
        translateStateUF(StateUF.AP),
        translateStateUF(StateUF.AM),
        translateStateUF(StateUF.BA),
        translateStateUF(StateUF.CE),
        translateStateUF(StateUF.DF),
        translateStateUF(StateUF.ES),
        translateStateUF(StateUF.GO),
        translateStateUF(StateUF.MA),
        translateStateUF(StateUF.MT),
        translateStateUF(StateUF.MS),
        translateStateUF(StateUF.MG),
        translateStateUF(StateUF.PA),
        translateStateUF(StateUF.PB),
        translateStateUF(StateUF.PR),
        translateStateUF(StateUF.PE),
        translateStateUF(StateUF.PI),
        translateStateUF(StateUF.RJ),
        translateStateUF(StateUF.RN),
        translateStateUF(StateUF.RS),
        translateStateUF(StateUF.RO),
        translateStateUF(StateUF.RR),
        translateStateUF(StateUF.SC),
        translateStateUF(StateUF.SP),
        translateStateUF(StateUF.SE),
        translateStateUF(StateUF.TO),
    ];
}

export function allDisplayableValuesStateUF(): string[] {
    return allTranslatedValuesStateUF().sort();
}

export function valueFromTranslationStateUF(translation: string): StateUF {
    const index = allTranslatedValuesStateUF().indexOf(translation);
    return allValuesStateUF()[index] || StateUF.AC;
}

export enum TableOrderBy {
    desc = "desc",
    asc = "asc",
}

export function translateTableOrderBy(enumTableOrderBy: TableOrderBy): string {
    switch (enumTableOrderBy) {
        case TableOrderBy.desc: {
            return strings ? strings["enum"]["TableOrderBy"]["desc"] || TableOrderBy.desc : TableOrderBy.desc;
        }
        case TableOrderBy.asc: {
            return strings ? strings["enum"]["TableOrderBy"]["asc"] || TableOrderBy.asc : TableOrderBy.asc;
        }
    }
    return "";
}

export function allValuesTableOrderBy(): TableOrderBy[] {
    return [
        TableOrderBy.desc,
        TableOrderBy.asc,
    ];
}

export function allTranslatedValuesTableOrderBy(): string[] {
    return [
        translateTableOrderBy(TableOrderBy.desc),
        translateTableOrderBy(TableOrderBy.asc),
    ];
}

export function allDisplayableValuesTableOrderBy(): string[] {
    return allTranslatedValuesTableOrderBy().sort();
}

export function valueFromTranslationTableOrderBy(translation: string): TableOrderBy {
    const index = allTranslatedValuesTableOrderBy().indexOf(translation);
    return allValuesTableOrderBy()[index] || TableOrderBy.desc;
}

export enum UserType {
    LOC = "LOC",
    LOCPRO = "LOCPRO",
    TOPLOC = "TOPLOC",
}

export function translateUserType(enumUserType: UserType): string {
    switch (enumUserType) {
        case UserType.LOC: {
            return strings ? strings["enum"]["UserType"]["LOC"] || UserType.LOC : UserType.LOC;
        }
        case UserType.LOCPRO: {
            return strings ? strings["enum"]["UserType"]["LOCPRO"] || UserType.LOCPRO : UserType.LOCPRO;
        }
        case UserType.TOPLOC: {
            return strings ? strings["enum"]["UserType"]["TOPLOC"] || UserType.TOPLOC : UserType.TOPLOC;
        }
    }
    return "";
}

export function allValuesUserType(): UserType[] {
    return [
        UserType.LOC,
        UserType.LOCPRO,
        UserType.TOPLOC,
    ];
}

export function allTranslatedValuesUserType(): string[] {
    return [
        translateUserType(UserType.LOC),
        translateUserType(UserType.LOCPRO),
        translateUserType(UserType.TOPLOC),
    ];
}

export function allDisplayableValuesUserType(): string[] {
    return allTranslatedValuesUserType().sort();
}

export function valueFromTranslationUserType(translation: string): UserType {
    const index = allTranslatedValuesUserType().indexOf(translation);
    return allValuesUserType()[index] || UserType.LOC;
}

export enum AdminUserType {
    admin = "admin",
    licensed = "licensed",
}

export function translateAdminUserType(enumAdminUserType: AdminUserType): string {
    switch (enumAdminUserType) {
        case AdminUserType.admin: {
            return strings ? strings["enum"]["AdminUserType"]["admin"] || AdminUserType.admin : AdminUserType.admin;
        }
        case AdminUserType.licensed: {
            return strings ? strings["enum"]["AdminUserType"]["licensed"] || AdminUserType.licensed : AdminUserType.licensed;
        }
    }
    return "";
}

export function allValuesAdminUserType(): AdminUserType[] {
    return [
        AdminUserType.admin,
        AdminUserType.licensed,
    ];
}

export function allTranslatedValuesAdminUserType(): string[] {
    return [
        translateAdminUserType(AdminUserType.admin),
        translateAdminUserType(AdminUserType.licensed),
    ];
}

export function allDisplayableValuesAdminUserType(): string[] {
    return allTranslatedValuesAdminUserType().sort();
}

export function valueFromTranslationAdminUserType(translation: string): AdminUserType {
    const index = allTranslatedValuesAdminUserType().indexOf(translation);
    return allValuesAdminUserType()[index] || AdminUserType.admin;
}

export enum ItemOfferType {
    rent = "rent",
    sale = "sale",
    rentAndSale = "rentAndSale",
    all = "all",
}

export function translateItemOfferType(enumItemOfferType: ItemOfferType): string {
    switch (enumItemOfferType) {
        case ItemOfferType.rent: {
            return strings ? strings["enum"]["ItemOfferType"]["rent"] || ItemOfferType.rent : ItemOfferType.rent;
        }
        case ItemOfferType.sale: {
            return strings ? strings["enum"]["ItemOfferType"]["sale"] || ItemOfferType.sale : ItemOfferType.sale;
        }
        case ItemOfferType.rentAndSale: {
            return strings ? strings["enum"]["ItemOfferType"]["rentAndSale"] || ItemOfferType.rentAndSale : ItemOfferType.rentAndSale;
        }
        case ItemOfferType.all: {
            return strings ? strings["enum"]["ItemOfferType"]["all"] || ItemOfferType.all : ItemOfferType.all;
        }
    }
    return "";
}

export function allValuesItemOfferType(): ItemOfferType[] {
    return [
        ItemOfferType.rent,
        ItemOfferType.sale,
        ItemOfferType.rentAndSale,
        ItemOfferType.all,
    ];
}

export function allTranslatedValuesItemOfferType(): string[] {
    return [
        translateItemOfferType(ItemOfferType.rent),
        translateItemOfferType(ItemOfferType.sale),
        translateItemOfferType(ItemOfferType.rentAndSale),
        translateItemOfferType(ItemOfferType.all),
    ];
}

export function allDisplayableValuesItemOfferType(): string[] {
    return allTranslatedValuesItemOfferType().sort();
}

export function valueFromTranslationItemOfferType(translation: string): ItemOfferType {
    const index = allTranslatedValuesItemOfferType().indexOf(translation);
    return allValuesItemOfferType()[index] || ItemOfferType.rent;
}

export enum OrderBy {
    popular = "popular",
    relevant = "relevant",
    lowestPrice = "lowestPrice",
    highestPrice = "highestPrice",
}

export function translateOrderBy(enumOrderBy: OrderBy): string {
    switch (enumOrderBy) {
        case OrderBy.popular: {
            return strings ? strings["enum"]["OrderBy"]["popular"] || OrderBy.popular : OrderBy.popular;
        }
        case OrderBy.relevant: {
            return strings ? strings["enum"]["OrderBy"]["relevant"] || OrderBy.relevant : OrderBy.relevant;
        }
        case OrderBy.lowestPrice: {
            return strings ? strings["enum"]["OrderBy"]["lowestPrice"] || OrderBy.lowestPrice : OrderBy.lowestPrice;
        }
        case OrderBy.highestPrice: {
            return strings ? strings["enum"]["OrderBy"]["highestPrice"] || OrderBy.highestPrice : OrderBy.highestPrice;
        }
    }
    return "";
}

export function allValuesOrderBy(): OrderBy[] {
    return [
        OrderBy.popular,
        OrderBy.relevant,
        OrderBy.lowestPrice,
        OrderBy.highestPrice,
    ];
}

export function allTranslatedValuesOrderBy(): string[] {
    return [
        translateOrderBy(OrderBy.popular),
        translateOrderBy(OrderBy.relevant),
        translateOrderBy(OrderBy.lowestPrice),
        translateOrderBy(OrderBy.highestPrice),
    ];
}

export function allDisplayableValuesOrderBy(): string[] {
    return allTranslatedValuesOrderBy().sort();
}

export function valueFromTranslationOrderBy(translation: string): OrderBy {
    const index = allTranslatedValuesOrderBy().indexOf(translation);
    return allValuesOrderBy()[index] || OrderBy.popular;
}

export enum BannerType {
    banner = "banner",
    card = "card",
}

export function translateBannerType(enumBannerType: BannerType): string {
    switch (enumBannerType) {
        case BannerType.banner: {
            return strings ? strings["enum"]["BannerType"]["banner"] || BannerType.banner : BannerType.banner;
        }
        case BannerType.card: {
            return strings ? strings["enum"]["BannerType"]["card"] || BannerType.card : BannerType.card;
        }
    }
    return "";
}

export function allValuesBannerType(): BannerType[] {
    return [
        BannerType.banner,
        BannerType.card,
    ];
}

export function allTranslatedValuesBannerType(): string[] {
    return [
        translateBannerType(BannerType.banner),
        translateBannerType(BannerType.card),
    ];
}

export function allDisplayableValuesBannerType(): string[] {
    return allTranslatedValuesBannerType().sort();
}

export function valueFromTranslationBannerType(translation: string): BannerType {
    const index = allTranslatedValuesBannerType().indexOf(translation);
    return allValuesBannerType()[index] || BannerType.banner;
}

export enum BannerContentType {
    group = "group",
    query = "query",
    url = "url",
}

export function translateBannerContentType(enumBannerContentType: BannerContentType): string {
    switch (enumBannerContentType) {
        case BannerContentType.group: {
            return strings ? strings["enum"]["BannerContentType"]["group"] || BannerContentType.group : BannerContentType.group;
        }
        case BannerContentType.query: {
            return strings ? strings["enum"]["BannerContentType"]["query"] || BannerContentType.query : BannerContentType.query;
        }
        case BannerContentType.url: {
            return strings ? strings["enum"]["BannerContentType"]["url"] || BannerContentType.url : BannerContentType.url;
        }
    }
    return "";
}

export function allValuesBannerContentType(): BannerContentType[] {
    return [
        BannerContentType.group,
        BannerContentType.query,
        BannerContentType.url,
    ];
}

export function allTranslatedValuesBannerContentType(): string[] {
    return [
        translateBannerContentType(BannerContentType.group),
        translateBannerContentType(BannerContentType.query),
        translateBannerContentType(BannerContentType.url),
    ];
}

export function allDisplayableValuesBannerContentType(): string[] {
    return allTranslatedValuesBannerContentType().sort();
}

export function valueFromTranslationBannerContentType(translation: string): BannerContentType {
    const index = allTranslatedValuesBannerContentType().indexOf(translation);
    return allValuesBannerContentType()[index] || BannerContentType.group;
}

export enum HomeGraphicType {
    value = "value",
    amount = "amount",
}

export function translateHomeGraphicType(enumHomeGraphicType: HomeGraphicType): string {
    switch (enumHomeGraphicType) {
        case HomeGraphicType.value: {
            return strings ? strings["enum"]["HomeGraphicType"]["value"] || HomeGraphicType.value : HomeGraphicType.value;
        }
        case HomeGraphicType.amount: {
            return strings ? strings["enum"]["HomeGraphicType"]["amount"] || HomeGraphicType.amount : HomeGraphicType.amount;
        }
    }
    return "";
}

export function allValuesHomeGraphicType(): HomeGraphicType[] {
    return [
        HomeGraphicType.value,
        HomeGraphicType.amount,
    ];
}

export function allTranslatedValuesHomeGraphicType(): string[] {
    return [
        translateHomeGraphicType(HomeGraphicType.value),
        translateHomeGraphicType(HomeGraphicType.amount),
    ];
}

export function allDisplayableValuesHomeGraphicType(): string[] {
    return allTranslatedValuesHomeGraphicType().sort();
}

export function valueFromTranslationHomeGraphicType(translation: string): HomeGraphicType {
    const index = allTranslatedValuesHomeGraphicType().indexOf(translation);
    return allValuesHomeGraphicType()[index] || HomeGraphicType.value;
}

export enum UserFieldsOrderBy {
    name = "name",
    nick = "nick",
    email = "email",
    cpf = "cpf",
    createdAt = "createdAt",
}

export function translateUserFieldsOrderBy(enumUserFieldsOrderBy: UserFieldsOrderBy): string {
    switch (enumUserFieldsOrderBy) {
        case UserFieldsOrderBy.name: {
            return strings ? strings["enum"]["UserFieldsOrderBy"]["name"] || UserFieldsOrderBy.name : UserFieldsOrderBy.name;
        }
        case UserFieldsOrderBy.nick: {
            return strings ? strings["enum"]["UserFieldsOrderBy"]["nick"] || UserFieldsOrderBy.nick : UserFieldsOrderBy.nick;
        }
        case UserFieldsOrderBy.email: {
            return strings ? strings["enum"]["UserFieldsOrderBy"]["email"] || UserFieldsOrderBy.email : UserFieldsOrderBy.email;
        }
        case UserFieldsOrderBy.cpf: {
            return strings ? strings["enum"]["UserFieldsOrderBy"]["cpf"] || UserFieldsOrderBy.cpf : UserFieldsOrderBy.cpf;
        }
        case UserFieldsOrderBy.createdAt: {
            return strings ? strings["enum"]["UserFieldsOrderBy"]["createdAt"] || UserFieldsOrderBy.createdAt : UserFieldsOrderBy.createdAt;
        }
    }
    return "";
}

export function allValuesUserFieldsOrderBy(): UserFieldsOrderBy[] {
    return [
        UserFieldsOrderBy.name,
        UserFieldsOrderBy.nick,
        UserFieldsOrderBy.email,
        UserFieldsOrderBy.cpf,
        UserFieldsOrderBy.createdAt,
    ];
}

export function allTranslatedValuesUserFieldsOrderBy(): string[] {
    return [
        translateUserFieldsOrderBy(UserFieldsOrderBy.name),
        translateUserFieldsOrderBy(UserFieldsOrderBy.nick),
        translateUserFieldsOrderBy(UserFieldsOrderBy.email),
        translateUserFieldsOrderBy(UserFieldsOrderBy.cpf),
        translateUserFieldsOrderBy(UserFieldsOrderBy.createdAt),
    ];
}

export function allDisplayableValuesUserFieldsOrderBy(): string[] {
    return allTranslatedValuesUserFieldsOrderBy().sort();
}

export function valueFromTranslationUserFieldsOrderBy(translation: string): UserFieldsOrderBy {
    const index = allTranslatedValuesUserFieldsOrderBy().indexOf(translation);
    return allValuesUserFieldsOrderBy()[index] || UserFieldsOrderBy.name;
}

export enum ItemFieldsOrderBy {
    rentPrice = "rentPrice",
    originalPrice = "originalPrice",
    salePrice = "salePrice",
    createdAt = "createdAt",
}

export function translateItemFieldsOrderBy(enumItemFieldsOrderBy: ItemFieldsOrderBy): string {
    switch (enumItemFieldsOrderBy) {
        case ItemFieldsOrderBy.rentPrice: {
            return strings ? strings["enum"]["ItemFieldsOrderBy"]["rentPrice"] || ItemFieldsOrderBy.rentPrice : ItemFieldsOrderBy.rentPrice;
        }
        case ItemFieldsOrderBy.originalPrice: {
            return strings ? strings["enum"]["ItemFieldsOrderBy"]["originalPrice"] || ItemFieldsOrderBy.originalPrice : ItemFieldsOrderBy.originalPrice;
        }
        case ItemFieldsOrderBy.salePrice: {
            return strings ? strings["enum"]["ItemFieldsOrderBy"]["salePrice"] || ItemFieldsOrderBy.salePrice : ItemFieldsOrderBy.salePrice;
        }
        case ItemFieldsOrderBy.createdAt: {
            return strings ? strings["enum"]["ItemFieldsOrderBy"]["createdAt"] || ItemFieldsOrderBy.createdAt : ItemFieldsOrderBy.createdAt;
        }
    }
    return "";
}

export function allValuesItemFieldsOrderBy(): ItemFieldsOrderBy[] {
    return [
        ItemFieldsOrderBy.rentPrice,
        ItemFieldsOrderBy.originalPrice,
        ItemFieldsOrderBy.salePrice,
        ItemFieldsOrderBy.createdAt,
    ];
}

export function allTranslatedValuesItemFieldsOrderBy(): string[] {
    return [
        translateItemFieldsOrderBy(ItemFieldsOrderBy.rentPrice),
        translateItemFieldsOrderBy(ItemFieldsOrderBy.originalPrice),
        translateItemFieldsOrderBy(ItemFieldsOrderBy.salePrice),
        translateItemFieldsOrderBy(ItemFieldsOrderBy.createdAt),
    ];
}

export function allDisplayableValuesItemFieldsOrderBy(): string[] {
    return allTranslatedValuesItemFieldsOrderBy().sort();
}

export function valueFromTranslationItemFieldsOrderBy(translation: string): ItemFieldsOrderBy {
    const index = allTranslatedValuesItemFieldsOrderBy().indexOf(translation);
    return allValuesItemFieldsOrderBy()[index] || ItemFieldsOrderBy.rentPrice;
}

export enum DeviceType {
    ios = "ios",
    android = "android",
}

export function translateDeviceType(enumDeviceType: DeviceType): string {
    switch (enumDeviceType) {
        case DeviceType.ios: {
            return strings ? strings["enum"]["DeviceType"]["ios"] || DeviceType.ios : DeviceType.ios;
        }
        case DeviceType.android: {
            return strings ? strings["enum"]["DeviceType"]["android"] || DeviceType.android : DeviceType.android;
        }
    }
    return "";
}

export function allValuesDeviceType(): DeviceType[] {
    return [
        DeviceType.ios,
        DeviceType.android,
    ];
}

export function allTranslatedValuesDeviceType(): string[] {
    return [
        translateDeviceType(DeviceType.ios),
        translateDeviceType(DeviceType.android),
    ];
}

export function allDisplayableValuesDeviceType(): string[] {
    return allTranslatedValuesDeviceType().sort();
}

export function valueFromTranslationDeviceType(translation: string): DeviceType {
    const index = allTranslatedValuesDeviceType().indexOf(translation);
    return allValuesDeviceType()[index] || DeviceType.ios;
}

export enum PaymentStatus {
    processing = "processing",
    authorized = "authorized",
    paid = "paid",
    refunded = "refunded",
    waitingPayment = "waitingPayment",
    pendingRefund = "pendingRefund",
    refused = "refused",
}

export function translatePaymentStatus(enumPaymentStatus: PaymentStatus): string {
    switch (enumPaymentStatus) {
        case PaymentStatus.processing: {
            return strings ? strings["enum"]["PaymentStatus"]["processing"] || PaymentStatus.processing : PaymentStatus.processing;
        }
        case PaymentStatus.authorized: {
            return strings ? strings["enum"]["PaymentStatus"]["authorized"] || PaymentStatus.authorized : PaymentStatus.authorized;
        }
        case PaymentStatus.paid: {
            return strings ? strings["enum"]["PaymentStatus"]["paid"] || PaymentStatus.paid : PaymentStatus.paid;
        }
        case PaymentStatus.refunded: {
            return strings ? strings["enum"]["PaymentStatus"]["refunded"] || PaymentStatus.refunded : PaymentStatus.refunded;
        }
        case PaymentStatus.waitingPayment: {
            return strings ? strings["enum"]["PaymentStatus"]["waitingPayment"] || PaymentStatus.waitingPayment : PaymentStatus.waitingPayment;
        }
        case PaymentStatus.pendingRefund: {
            return strings ? strings["enum"]["PaymentStatus"]["pendingRefund"] || PaymentStatus.pendingRefund : PaymentStatus.pendingRefund;
        }
        case PaymentStatus.refused: {
            return strings ? strings["enum"]["PaymentStatus"]["refused"] || PaymentStatus.refused : PaymentStatus.refused;
        }
    }
    return "";
}

export function allValuesPaymentStatus(): PaymentStatus[] {
    return [
        PaymentStatus.processing,
        PaymentStatus.authorized,
        PaymentStatus.paid,
        PaymentStatus.refunded,
        PaymentStatus.waitingPayment,
        PaymentStatus.pendingRefund,
        PaymentStatus.refused,
    ];
}

export function allTranslatedValuesPaymentStatus(): string[] {
    return [
        translatePaymentStatus(PaymentStatus.processing),
        translatePaymentStatus(PaymentStatus.authorized),
        translatePaymentStatus(PaymentStatus.paid),
        translatePaymentStatus(PaymentStatus.refunded),
        translatePaymentStatus(PaymentStatus.waitingPayment),
        translatePaymentStatus(PaymentStatus.pendingRefund),
        translatePaymentStatus(PaymentStatus.refused),
    ];
}

export function allDisplayableValuesPaymentStatus(): string[] {
    return allTranslatedValuesPaymentStatus().sort();
}

export function valueFromTranslationPaymentStatus(translation: string): PaymentStatus {
    const index = allTranslatedValuesPaymentStatus().indexOf(translation);
    return allValuesPaymentStatus()[index] || PaymentStatus.processing;
}

export enum ReportType {
    all = "all",
    users = "users",
    requests = "requests",
    items = "items",
    likes = "likes",
}

export function translateReportType(enumReportType: ReportType): string {
    switch (enumReportType) {
        case ReportType.all: {
            return strings ? strings["enum"]["ReportType"]["all"] || ReportType.all : ReportType.all;
        }
        case ReportType.users: {
            return strings ? strings["enum"]["ReportType"]["users"] || ReportType.users : ReportType.users;
        }
        case ReportType.requests: {
            return strings ? strings["enum"]["ReportType"]["requests"] || ReportType.requests : ReportType.requests;
        }
        case ReportType.items: {
            return strings ? strings["enum"]["ReportType"]["items"] || ReportType.items : ReportType.items;
        }
        case ReportType.likes: {
            return strings ? strings["enum"]["ReportType"]["likes"] || ReportType.likes : ReportType.likes;
        }
    }
    return "";
}

export function allValuesReportType(): ReportType[] {
    return [
        ReportType.all,
        ReportType.users,
        ReportType.requests,
        ReportType.items,
        ReportType.likes,
    ];
}

export function allTranslatedValuesReportType(): string[] {
    return [
        translateReportType(ReportType.all),
        translateReportType(ReportType.users),
        translateReportType(ReportType.requests),
        translateReportType(ReportType.items),
        translateReportType(ReportType.likes),
    ];
}

export function allDisplayableValuesReportType(): string[] {
    return allTranslatedValuesReportType().sort();
}

export function valueFromTranslationReportType(translation: string): ReportType {
    const index = allTranslatedValuesReportType().indexOf(translation);
    return allValuesReportType()[index] || ReportType.all;
}

export enum OwnerOrRequester {
    owner = "owner",
    requester = "requester",
}

export function translateOwnerOrRequester(enumOwnerOrRequester: OwnerOrRequester): string {
    switch (enumOwnerOrRequester) {
        case OwnerOrRequester.owner: {
            return strings ? strings["enum"]["OwnerOrRequester"]["owner"] || OwnerOrRequester.owner : OwnerOrRequester.owner;
        }
        case OwnerOrRequester.requester: {
            return strings ? strings["enum"]["OwnerOrRequester"]["requester"] || OwnerOrRequester.requester : OwnerOrRequester.requester;
        }
    }
    return "";
}

export function allValuesOwnerOrRequester(): OwnerOrRequester[] {
    return [
        OwnerOrRequester.owner,
        OwnerOrRequester.requester,
    ];
}

export function allTranslatedValuesOwnerOrRequester(): string[] {
    return [
        translateOwnerOrRequester(OwnerOrRequester.owner),
        translateOwnerOrRequester(OwnerOrRequester.requester),
    ];
}

export function allDisplayableValuesOwnerOrRequester(): string[] {
    return allTranslatedValuesOwnerOrRequester().sort();
}

export function valueFromTranslationOwnerOrRequester(translation: string): OwnerOrRequester {
    const index = allTranslatedValuesOwnerOrRequester().indexOf(translation);
    return allValuesOwnerOrRequester()[index] || OwnerOrRequester.owner;
}

export enum RequestFieldsOrderBy {
    rentPrice = "rentPrice",
    createdAt = "createdAt",
}

export function translateRequestFieldsOrderBy(enumRequestFieldsOrderBy: RequestFieldsOrderBy): string {
    switch (enumRequestFieldsOrderBy) {
        case RequestFieldsOrderBy.rentPrice: {
            return strings ? strings["enum"]["RequestFieldsOrderBy"]["rentPrice"] || RequestFieldsOrderBy.rentPrice : RequestFieldsOrderBy.rentPrice;
        }
        case RequestFieldsOrderBy.createdAt: {
            return strings ? strings["enum"]["RequestFieldsOrderBy"]["createdAt"] || RequestFieldsOrderBy.createdAt : RequestFieldsOrderBy.createdAt;
        }
    }
    return "";
}

export function allValuesRequestFieldsOrderBy(): RequestFieldsOrderBy[] {
    return [
        RequestFieldsOrderBy.rentPrice,
        RequestFieldsOrderBy.createdAt,
    ];
}

export function allTranslatedValuesRequestFieldsOrderBy(): string[] {
    return [
        translateRequestFieldsOrderBy(RequestFieldsOrderBy.rentPrice),
        translateRequestFieldsOrderBy(RequestFieldsOrderBy.createdAt),
    ];
}

export function allDisplayableValuesRequestFieldsOrderBy(): string[] {
    return allTranslatedValuesRequestFieldsOrderBy().sort();
}

export function valueFromTranslationRequestFieldsOrderBy(translation: string): RequestFieldsOrderBy {
    const index = allTranslatedValuesRequestFieldsOrderBy().indexOf(translation);
    return allValuesRequestFieldsOrderBy()[index] || RequestFieldsOrderBy.rentPrice;
}

export enum PromotionUsageType {
    firstRentOnly = "firstRentOnly",
    infinite = "infinite",
    multiple = "multiple",
}

export function translatePromotionUsageType(enumPromotionUsageType: PromotionUsageType): string {
    switch (enumPromotionUsageType) {
        case PromotionUsageType.firstRentOnly: {
            return strings ? strings["enum"]["PromotionUsageType"]["firstRentOnly"] || PromotionUsageType.firstRentOnly : PromotionUsageType.firstRentOnly;
        }
        case PromotionUsageType.infinite: {
            return strings ? strings["enum"]["PromotionUsageType"]["infinite"] || PromotionUsageType.infinite : PromotionUsageType.infinite;
        }
        case PromotionUsageType.multiple: {
            return strings ? strings["enum"]["PromotionUsageType"]["multiple"] || PromotionUsageType.multiple : PromotionUsageType.multiple;
        }
    }
    return "";
}

export function allValuesPromotionUsageType(): PromotionUsageType[] {
    return [
        PromotionUsageType.firstRentOnly,
        PromotionUsageType.infinite,
        PromotionUsageType.multiple,
    ];
}

export function allTranslatedValuesPromotionUsageType(): string[] {
    return [
        translatePromotionUsageType(PromotionUsageType.firstRentOnly),
        translatePromotionUsageType(PromotionUsageType.infinite),
        translatePromotionUsageType(PromotionUsageType.multiple),
    ];
}

export function allDisplayableValuesPromotionUsageType(): string[] {
    return allTranslatedValuesPromotionUsageType().sort();
}

export function valueFromTranslationPromotionUsageType(translation: string): PromotionUsageType {
    const index = allTranslatedValuesPromotionUsageType().indexOf(translation);
    return allValuesPromotionUsageType()[index] || PromotionUsageType.firstRentOnly;
}

export enum PromotionFieldOrderBy {
    expirationDate = "expirationDate",
    createdAt = "createdAt",
    id = "id",
}

export function translatePromotionFieldOrderBy(enumPromotionFieldOrderBy: PromotionFieldOrderBy): string {
    switch (enumPromotionFieldOrderBy) {
        case PromotionFieldOrderBy.expirationDate: {
            return strings ? strings["enum"]["PromotionFieldOrderBy"]["expirationDate"] || PromotionFieldOrderBy.expirationDate : PromotionFieldOrderBy.expirationDate;
        }
        case PromotionFieldOrderBy.createdAt: {
            return strings ? strings["enum"]["PromotionFieldOrderBy"]["createdAt"] || PromotionFieldOrderBy.createdAt : PromotionFieldOrderBy.createdAt;
        }
        case PromotionFieldOrderBy.id: {
            return strings ? strings["enum"]["PromotionFieldOrderBy"]["id"] || PromotionFieldOrderBy.id : PromotionFieldOrderBy.id;
        }
    }
    return "";
}

export function allValuesPromotionFieldOrderBy(): PromotionFieldOrderBy[] {
    return [
        PromotionFieldOrderBy.expirationDate,
        PromotionFieldOrderBy.createdAt,
        PromotionFieldOrderBy.id,
    ];
}

export function allTranslatedValuesPromotionFieldOrderBy(): string[] {
    return [
        translatePromotionFieldOrderBy(PromotionFieldOrderBy.expirationDate),
        translatePromotionFieldOrderBy(PromotionFieldOrderBy.createdAt),
        translatePromotionFieldOrderBy(PromotionFieldOrderBy.id),
    ];
}

export function allDisplayableValuesPromotionFieldOrderBy(): string[] {
    return allTranslatedValuesPromotionFieldOrderBy().sort();
}

export function valueFromTranslationPromotionFieldOrderBy(translation: string): PromotionFieldOrderBy {
    const index = allTranslatedValuesPromotionFieldOrderBy().indexOf(translation);
    return allValuesPromotionFieldOrderBy()[index] || PromotionFieldOrderBy.expirationDate;
}

export enum ReportGroupType {
    date = "date",
    dateHour = "dateHour",
    hour = "hour",
}

export function translateReportGroupType(enumReportGroupType: ReportGroupType): string {
    switch (enumReportGroupType) {
        case ReportGroupType.date: {
            return strings ? strings["enum"]["ReportGroupType"]["date"] || ReportGroupType.date : ReportGroupType.date;
        }
        case ReportGroupType.dateHour: {
            return strings ? strings["enum"]["ReportGroupType"]["dateHour"] || ReportGroupType.dateHour : ReportGroupType.dateHour;
        }
        case ReportGroupType.hour: {
            return strings ? strings["enum"]["ReportGroupType"]["hour"] || ReportGroupType.hour : ReportGroupType.hour;
        }
    }
    return "";
}

export function allValuesReportGroupType(): ReportGroupType[] {
    return [
        ReportGroupType.date,
        ReportGroupType.dateHour,
        ReportGroupType.hour,
    ];
}

export function allTranslatedValuesReportGroupType(): string[] {
    return [
        translateReportGroupType(ReportGroupType.date),
        translateReportGroupType(ReportGroupType.dateHour),
        translateReportGroupType(ReportGroupType.hour),
    ];
}

export function allDisplayableValuesReportGroupType(): string[] {
    return allTranslatedValuesReportGroupType().sort();
}

export function valueFromTranslationReportGroupType(translation: string): ReportGroupType {
    const index = allTranslatedValuesReportGroupType().indexOf(translation);
    return allValuesReportGroupType()[index] || ReportGroupType.date;
}

export enum SaleStatusType {
    pendingApproval = "pendingApproval",
    approved = "approved",
    notApproved = "notApproved",
    deliveredToRequester = "deliveredToRequester",
    receivedByRequester = "receivedByRequester",
    canceled = "canceled",
}

export function translateSaleStatusType(enumSaleStatusType: SaleStatusType): string {
    switch (enumSaleStatusType) {
        case SaleStatusType.pendingApproval: {
            return strings ? strings["enum"]["SaleStatusType"]["pendingApproval"] || SaleStatusType.pendingApproval : SaleStatusType.pendingApproval;
        }
        case SaleStatusType.approved: {
            return strings ? strings["enum"]["SaleStatusType"]["approved"] || SaleStatusType.approved : SaleStatusType.approved;
        }
        case SaleStatusType.notApproved: {
            return strings ? strings["enum"]["SaleStatusType"]["notApproved"] || SaleStatusType.notApproved : SaleStatusType.notApproved;
        }
        case SaleStatusType.deliveredToRequester: {
            return strings ? strings["enum"]["SaleStatusType"]["deliveredToRequester"] || SaleStatusType.deliveredToRequester : SaleStatusType.deliveredToRequester;
        }
        case SaleStatusType.receivedByRequester: {
            return strings ? strings["enum"]["SaleStatusType"]["receivedByRequester"] || SaleStatusType.receivedByRequester : SaleStatusType.receivedByRequester;
        }
        case SaleStatusType.canceled: {
            return strings ? strings["enum"]["SaleStatusType"]["canceled"] || SaleStatusType.canceled : SaleStatusType.canceled;
        }
    }
    return "";
}

export function allValuesSaleStatusType(): SaleStatusType[] {
    return [
        SaleStatusType.pendingApproval,
        SaleStatusType.approved,
        SaleStatusType.notApproved,
        SaleStatusType.deliveredToRequester,
        SaleStatusType.receivedByRequester,
        SaleStatusType.canceled,
    ];
}

export function allTranslatedValuesSaleStatusType(): string[] {
    return [
        translateSaleStatusType(SaleStatusType.pendingApproval),
        translateSaleStatusType(SaleStatusType.approved),
        translateSaleStatusType(SaleStatusType.notApproved),
        translateSaleStatusType(SaleStatusType.deliveredToRequester),
        translateSaleStatusType(SaleStatusType.receivedByRequester),
        translateSaleStatusType(SaleStatusType.canceled),
    ];
}

export function allDisplayableValuesSaleStatusType(): string[] {
    return allTranslatedValuesSaleStatusType().sort();
}

export function valueFromTranslationSaleStatusType(translation: string): SaleStatusType {
    const index = allTranslatedValuesSaleStatusType().indexOf(translation);
    return allValuesSaleStatusType()[index] || SaleStatusType.pendingApproval;
}

export enum SaleFieldsOrderBy {
    price = "price",
    createdAt = "createdAt",
}

export function translateSaleFieldsOrderBy(enumSaleFieldsOrderBy: SaleFieldsOrderBy): string {
    switch (enumSaleFieldsOrderBy) {
        case SaleFieldsOrderBy.price: {
            return strings ? strings["enum"]["SaleFieldsOrderBy"]["price"] || SaleFieldsOrderBy.price : SaleFieldsOrderBy.price;
        }
        case SaleFieldsOrderBy.createdAt: {
            return strings ? strings["enum"]["SaleFieldsOrderBy"]["createdAt"] || SaleFieldsOrderBy.createdAt : SaleFieldsOrderBy.createdAt;
        }
    }
    return "";
}

export function allValuesSaleFieldsOrderBy(): SaleFieldsOrderBy[] {
    return [
        SaleFieldsOrderBy.price,
        SaleFieldsOrderBy.createdAt,
    ];
}

export function allTranslatedValuesSaleFieldsOrderBy(): string[] {
    return [
        translateSaleFieldsOrderBy(SaleFieldsOrderBy.price),
        translateSaleFieldsOrderBy(SaleFieldsOrderBy.createdAt),
    ];
}

export function allDisplayableValuesSaleFieldsOrderBy(): string[] {
    return allTranslatedValuesSaleFieldsOrderBy().sort();
}

export function valueFromTranslationSaleFieldsOrderBy(translation: string): SaleFieldsOrderBy {
    const index = allTranslatedValuesSaleFieldsOrderBy().indexOf(translation);
    return allValuesSaleFieldsOrderBy()[index] || SaleFieldsOrderBy.price;
}

export enum ErrorType {
    PaymentRefused = "PaymentRefused",
    InternalError = "InternalError",
    TransferValueTooLow = "TransferValueTooLow",
    FailedToRefund = "FailedToRefund",
    InvalidAccountDv = "InvalidAccountDv",
    InvalidAgencyDv = "InvalidAgencyDv",
    InvalidAccountNumber = "InvalidAccountNumber",
    InvalidAgencyNumber = "InvalidAgencyNumber",
    InvalidBankCode = "InvalidBankCode",
    InvalidBankAccount = "InvalidBankAccount",
    NotFound = "NotFound",
    NotLoggedIn = "NotLoggedIn",
    MissingParameter = "MissingParameter",
    AccessNotAllowed = "AccessNotAllowed",
    ValueISEmpty = "ValueISEmpty",
    ValueTooLarge = "ValueTooLarge",
    ValueTooSmall = "ValueTooSmall",
    InvalidEmail = "InvalidEmail",
    InvalidArgument = "InvalidArgument",
    InvalidUrl = "InvalidUrl",
    InvalidSlug = "InvalidSlug",
    SlugAlreadyInUse = "SlugAlreadyInUse",
    UserDocumentsNotVerified = "UserDocumentsNotVerified",
    DateUnavaiable = "DateUnavaiable",
    PeriodTooSmall = "PeriodTooSmall",
    NotForRent = "NotForRent",
    CardWalletEmpty = "CardWalletEmpty",
    DeletedCard = "DeletedCard",
    UserDoesntExist = "UserDoesntExist",
    ShouldUseThirdParty = "ShouldUseThirdParty",
    WrongPassword = "WrongPassword",
    LoginError = "LoginError",
    ThirdPartyAuthError = "ThirdPartyAuthError",
    WrongCredentials = "WrongCredentials",
    EmailDoesntExist = "EmailDoesntExist",
    PasswordToSmall = "PasswordToSmall",
    InvalidDocumentNumber = "InvalidDocumentNumber",
    InvalidZipCode = "InvalidZipCode",
    AddresNotSet = "AddresNotSet",
    InvalidLocation = "InvalidLocation",
    CityNotFound = "CityNotFound",
    ShippingDateNotAvaiable = "ShippingDateNotAvaiable",
    ShippingDistanceTooLarge = "ShippingDistanceTooLarge",
    ShippingCityNotAvaiable = "ShippingCityNotAvaiable",
    DeletedItem = "DeletedItem",
    BadFormattedResponse = "BadFormattedResponse",
    InvalidPercentege = "InvalidPercentege",
    CityAlreadyInUse = "CityAlreadyInUse",
    RecipientAlreadyExists = "RecipientAlreadyExists",
    SaleStatusChange = "SaleStatusChange",
    ItemAlreadySold = "ItemAlreadySold",
    InvalidInstallment = "InvalidInstallment",
    MissingBirthDate = "MissingBirthDate",
    MissingAddress = "MissingAddress",
    OutdatedRecipient = "OutdatedRecipient",
    NotForSale = "NotForSale",
    InvalidRentPrice = "InvalidRentPrice",
    InvalidOriginalPrice = "InvalidOriginalPrice",
    Fatal = "Fatal",
    Connection = "Connection",
}

export function translateErrorType(enumErrorType: ErrorType): string {
    switch (enumErrorType) {
        case ErrorType.PaymentRefused: {
            return strings ? strings["enum"]["ErrorType"]["PaymentRefused"] || ErrorType.PaymentRefused : ErrorType.PaymentRefused;
        }
        case ErrorType.InternalError: {
            return strings ? strings["enum"]["ErrorType"]["InternalError"] || ErrorType.InternalError : ErrorType.InternalError;
        }
        case ErrorType.TransferValueTooLow: {
            return strings ? strings["enum"]["ErrorType"]["TransferValueTooLow"] || ErrorType.TransferValueTooLow : ErrorType.TransferValueTooLow;
        }
        case ErrorType.FailedToRefund: {
            return strings ? strings["enum"]["ErrorType"]["FailedToRefund"] || ErrorType.FailedToRefund : ErrorType.FailedToRefund;
        }
        case ErrorType.InvalidAccountDv: {
            return strings ? strings["enum"]["ErrorType"]["InvalidAccountDv"] || ErrorType.InvalidAccountDv : ErrorType.InvalidAccountDv;
        }
        case ErrorType.InvalidAgencyDv: {
            return strings ? strings["enum"]["ErrorType"]["InvalidAgencyDv"] || ErrorType.InvalidAgencyDv : ErrorType.InvalidAgencyDv;
        }
        case ErrorType.InvalidAccountNumber: {
            return strings ? strings["enum"]["ErrorType"]["InvalidAccountNumber"] || ErrorType.InvalidAccountNumber : ErrorType.InvalidAccountNumber;
        }
        case ErrorType.InvalidAgencyNumber: {
            return strings ? strings["enum"]["ErrorType"]["InvalidAgencyNumber"] || ErrorType.InvalidAgencyNumber : ErrorType.InvalidAgencyNumber;
        }
        case ErrorType.InvalidBankCode: {
            return strings ? strings["enum"]["ErrorType"]["InvalidBankCode"] || ErrorType.InvalidBankCode : ErrorType.InvalidBankCode;
        }
        case ErrorType.InvalidBankAccount: {
            return strings ? strings["enum"]["ErrorType"]["InvalidBankAccount"] || ErrorType.InvalidBankAccount : ErrorType.InvalidBankAccount;
        }
        case ErrorType.NotFound: {
            return strings ? strings["enum"]["ErrorType"]["NotFound"] || ErrorType.NotFound : ErrorType.NotFound;
        }
        case ErrorType.NotLoggedIn: {
            return strings ? strings["enum"]["ErrorType"]["NotLoggedIn"] || ErrorType.NotLoggedIn : ErrorType.NotLoggedIn;
        }
        case ErrorType.MissingParameter: {
            return strings ? strings["enum"]["ErrorType"]["MissingParameter"] || ErrorType.MissingParameter : ErrorType.MissingParameter;
        }
        case ErrorType.AccessNotAllowed: {
            return strings ? strings["enum"]["ErrorType"]["AccessNotAllowed"] || ErrorType.AccessNotAllowed : ErrorType.AccessNotAllowed;
        }
        case ErrorType.ValueISEmpty: {
            return strings ? strings["enum"]["ErrorType"]["ValueISEmpty"] || ErrorType.ValueISEmpty : ErrorType.ValueISEmpty;
        }
        case ErrorType.ValueTooLarge: {
            return strings ? strings["enum"]["ErrorType"]["ValueTooLarge"] || ErrorType.ValueTooLarge : ErrorType.ValueTooLarge;
        }
        case ErrorType.ValueTooSmall: {
            return strings ? strings["enum"]["ErrorType"]["ValueTooSmall"] || ErrorType.ValueTooSmall : ErrorType.ValueTooSmall;
        }
        case ErrorType.InvalidEmail: {
            return strings ? strings["enum"]["ErrorType"]["InvalidEmail"] || ErrorType.InvalidEmail : ErrorType.InvalidEmail;
        }
        case ErrorType.InvalidArgument: {
            return strings ? strings["enum"]["ErrorType"]["InvalidArgument"] || ErrorType.InvalidArgument : ErrorType.InvalidArgument;
        }
        case ErrorType.InvalidUrl: {
            return strings ? strings["enum"]["ErrorType"]["InvalidUrl"] || ErrorType.InvalidUrl : ErrorType.InvalidUrl;
        }
        case ErrorType.InvalidSlug: {
            return strings ? strings["enum"]["ErrorType"]["InvalidSlug"] || ErrorType.InvalidSlug : ErrorType.InvalidSlug;
        }
        case ErrorType.SlugAlreadyInUse: {
            return strings ? strings["enum"]["ErrorType"]["SlugAlreadyInUse"] || ErrorType.SlugAlreadyInUse : ErrorType.SlugAlreadyInUse;
        }
        case ErrorType.UserDocumentsNotVerified: {
            return strings ? strings["enum"]["ErrorType"]["UserDocumentsNotVerified"] || ErrorType.UserDocumentsNotVerified : ErrorType.UserDocumentsNotVerified;
        }
        case ErrorType.DateUnavaiable: {
            return strings ? strings["enum"]["ErrorType"]["DateUnavaiable"] || ErrorType.DateUnavaiable : ErrorType.DateUnavaiable;
        }
        case ErrorType.PeriodTooSmall: {
            return strings ? strings["enum"]["ErrorType"]["PeriodTooSmall"] || ErrorType.PeriodTooSmall : ErrorType.PeriodTooSmall;
        }
        case ErrorType.NotForRent: {
            return strings ? strings["enum"]["ErrorType"]["NotForRent"] || ErrorType.NotForRent : ErrorType.NotForRent;
        }
        case ErrorType.CardWalletEmpty: {
            return strings ? strings["enum"]["ErrorType"]["CardWalletEmpty"] || ErrorType.CardWalletEmpty : ErrorType.CardWalletEmpty;
        }
        case ErrorType.DeletedCard: {
            return strings ? strings["enum"]["ErrorType"]["DeletedCard"] || ErrorType.DeletedCard : ErrorType.DeletedCard;
        }
        case ErrorType.UserDoesntExist: {
            return strings ? strings["enum"]["ErrorType"]["UserDoesntExist"] || ErrorType.UserDoesntExist : ErrorType.UserDoesntExist;
        }
        case ErrorType.ShouldUseThirdParty: {
            return strings ? strings["enum"]["ErrorType"]["ShouldUseThirdParty"] || ErrorType.ShouldUseThirdParty : ErrorType.ShouldUseThirdParty;
        }
        case ErrorType.WrongPassword: {
            return strings ? strings["enum"]["ErrorType"]["WrongPassword"] || ErrorType.WrongPassword : ErrorType.WrongPassword;
        }
        case ErrorType.LoginError: {
            return strings ? strings["enum"]["ErrorType"]["LoginError"] || ErrorType.LoginError : ErrorType.LoginError;
        }
        case ErrorType.ThirdPartyAuthError: {
            return strings ? strings["enum"]["ErrorType"]["ThirdPartyAuthError"] || ErrorType.ThirdPartyAuthError : ErrorType.ThirdPartyAuthError;
        }
        case ErrorType.WrongCredentials: {
            return strings ? strings["enum"]["ErrorType"]["WrongCredentials"] || ErrorType.WrongCredentials : ErrorType.WrongCredentials;
        }
        case ErrorType.EmailDoesntExist: {
            return strings ? strings["enum"]["ErrorType"]["EmailDoesntExist"] || ErrorType.EmailDoesntExist : ErrorType.EmailDoesntExist;
        }
        case ErrorType.PasswordToSmall: {
            return strings ? strings["enum"]["ErrorType"]["PasswordToSmall"] || ErrorType.PasswordToSmall : ErrorType.PasswordToSmall;
        }
        case ErrorType.InvalidDocumentNumber: {
            return strings ? strings["enum"]["ErrorType"]["InvalidDocumentNumber"] || ErrorType.InvalidDocumentNumber : ErrorType.InvalidDocumentNumber;
        }
        case ErrorType.InvalidZipCode: {
            return strings ? strings["enum"]["ErrorType"]["InvalidZipCode"] || ErrorType.InvalidZipCode : ErrorType.InvalidZipCode;
        }
        case ErrorType.AddresNotSet: {
            return strings ? strings["enum"]["ErrorType"]["AddresNotSet"] || ErrorType.AddresNotSet : ErrorType.AddresNotSet;
        }
        case ErrorType.InvalidLocation: {
            return strings ? strings["enum"]["ErrorType"]["InvalidLocation"] || ErrorType.InvalidLocation : ErrorType.InvalidLocation;
        }
        case ErrorType.CityNotFound: {
            return strings ? strings["enum"]["ErrorType"]["CityNotFound"] || ErrorType.CityNotFound : ErrorType.CityNotFound;
        }
        case ErrorType.ShippingDateNotAvaiable: {
            return strings ? strings["enum"]["ErrorType"]["ShippingDateNotAvaiable"] || ErrorType.ShippingDateNotAvaiable : ErrorType.ShippingDateNotAvaiable;
        }
        case ErrorType.ShippingDistanceTooLarge: {
            return strings ? strings["enum"]["ErrorType"]["ShippingDistanceTooLarge"] || ErrorType.ShippingDistanceTooLarge : ErrorType.ShippingDistanceTooLarge;
        }
        case ErrorType.ShippingCityNotAvaiable: {
            return strings ? strings["enum"]["ErrorType"]["ShippingCityNotAvaiable"] || ErrorType.ShippingCityNotAvaiable : ErrorType.ShippingCityNotAvaiable;
        }
        case ErrorType.DeletedItem: {
            return strings ? strings["enum"]["ErrorType"]["DeletedItem"] || ErrorType.DeletedItem : ErrorType.DeletedItem;
        }
        case ErrorType.BadFormattedResponse: {
            return strings ? strings["enum"]["ErrorType"]["BadFormattedResponse"] || ErrorType.BadFormattedResponse : ErrorType.BadFormattedResponse;
        }
        case ErrorType.InvalidPercentege: {
            return strings ? strings["enum"]["ErrorType"]["InvalidPercentege"] || ErrorType.InvalidPercentege : ErrorType.InvalidPercentege;
        }
        case ErrorType.CityAlreadyInUse: {
            return strings ? strings["enum"]["ErrorType"]["CityAlreadyInUse"] || ErrorType.CityAlreadyInUse : ErrorType.CityAlreadyInUse;
        }
        case ErrorType.RecipientAlreadyExists: {
            return strings ? strings["enum"]["ErrorType"]["RecipientAlreadyExists"] || ErrorType.RecipientAlreadyExists : ErrorType.RecipientAlreadyExists;
        }
        case ErrorType.SaleStatusChange: {
            return strings ? strings["enum"]["ErrorType"]["SaleStatusChange"] || ErrorType.SaleStatusChange : ErrorType.SaleStatusChange;
        }
        case ErrorType.ItemAlreadySold: {
            return strings ? strings["enum"]["ErrorType"]["ItemAlreadySold"] || ErrorType.ItemAlreadySold : ErrorType.ItemAlreadySold;
        }
        case ErrorType.InvalidInstallment: {
            return strings ? strings["enum"]["ErrorType"]["InvalidInstallment"] || ErrorType.InvalidInstallment : ErrorType.InvalidInstallment;
        }
        case ErrorType.MissingBirthDate: {
            return strings ? strings["enum"]["ErrorType"]["MissingBirthDate"] || ErrorType.MissingBirthDate : ErrorType.MissingBirthDate;
        }
        case ErrorType.MissingAddress: {
            return strings ? strings["enum"]["ErrorType"]["MissingAddress"] || ErrorType.MissingAddress : ErrorType.MissingAddress;
        }
        case ErrorType.OutdatedRecipient: {
            return strings ? strings["enum"]["ErrorType"]["OutdatedRecipient"] || ErrorType.OutdatedRecipient : ErrorType.OutdatedRecipient;
        }
        case ErrorType.NotForSale: {
            return strings ? strings["enum"]["ErrorType"]["NotForSale"] || ErrorType.NotForSale : ErrorType.NotForSale;
        }
        case ErrorType.InvalidRentPrice: {
            return strings ? strings["enum"]["ErrorType"]["InvalidRentPrice"] || ErrorType.InvalidRentPrice : ErrorType.InvalidRentPrice;
        }
        case ErrorType.InvalidOriginalPrice: {
            return strings ? strings["enum"]["ErrorType"]["InvalidOriginalPrice"] || ErrorType.InvalidOriginalPrice : ErrorType.InvalidOriginalPrice;
        }
        case ErrorType.Fatal: {
            return strings ? strings["enum"]["ErrorType"]["Fatal"] || ErrorType.Fatal : ErrorType.Fatal;
        }
        case ErrorType.Connection: {
            return strings ? strings["enum"]["ErrorType"]["Connection"] || ErrorType.Connection : ErrorType.Connection;
        }
    }
    return "";
}

export function allValuesErrorType(): ErrorType[] {
    return [
        ErrorType.PaymentRefused,
        ErrorType.InternalError,
        ErrorType.TransferValueTooLow,
        ErrorType.FailedToRefund,
        ErrorType.InvalidAccountDv,
        ErrorType.InvalidAgencyDv,
        ErrorType.InvalidAccountNumber,
        ErrorType.InvalidAgencyNumber,
        ErrorType.InvalidBankCode,
        ErrorType.InvalidBankAccount,
        ErrorType.NotFound,
        ErrorType.NotLoggedIn,
        ErrorType.MissingParameter,
        ErrorType.AccessNotAllowed,
        ErrorType.ValueISEmpty,
        ErrorType.ValueTooLarge,
        ErrorType.ValueTooSmall,
        ErrorType.InvalidEmail,
        ErrorType.InvalidArgument,
        ErrorType.InvalidUrl,
        ErrorType.InvalidSlug,
        ErrorType.SlugAlreadyInUse,
        ErrorType.UserDocumentsNotVerified,
        ErrorType.DateUnavaiable,
        ErrorType.PeriodTooSmall,
        ErrorType.NotForRent,
        ErrorType.CardWalletEmpty,
        ErrorType.DeletedCard,
        ErrorType.UserDoesntExist,
        ErrorType.ShouldUseThirdParty,
        ErrorType.WrongPassword,
        ErrorType.LoginError,
        ErrorType.ThirdPartyAuthError,
        ErrorType.WrongCredentials,
        ErrorType.EmailDoesntExist,
        ErrorType.PasswordToSmall,
        ErrorType.InvalidDocumentNumber,
        ErrorType.InvalidZipCode,
        ErrorType.AddresNotSet,
        ErrorType.InvalidLocation,
        ErrorType.CityNotFound,
        ErrorType.ShippingDateNotAvaiable,
        ErrorType.ShippingDistanceTooLarge,
        ErrorType.ShippingCityNotAvaiable,
        ErrorType.DeletedItem,
        ErrorType.BadFormattedResponse,
        ErrorType.InvalidPercentege,
        ErrorType.CityAlreadyInUse,
        ErrorType.RecipientAlreadyExists,
        ErrorType.SaleStatusChange,
        ErrorType.ItemAlreadySold,
        ErrorType.InvalidInstallment,
        ErrorType.MissingBirthDate,
        ErrorType.MissingAddress,
        ErrorType.OutdatedRecipient,
        ErrorType.NotForSale,
        ErrorType.InvalidRentPrice,
        ErrorType.InvalidOriginalPrice,
        ErrorType.Fatal,
        ErrorType.Connection,
    ];
}

export function allTranslatedValuesErrorType(): string[] {
    return [
        translateErrorType(ErrorType.PaymentRefused),
        translateErrorType(ErrorType.InternalError),
        translateErrorType(ErrorType.TransferValueTooLow),
        translateErrorType(ErrorType.FailedToRefund),
        translateErrorType(ErrorType.InvalidAccountDv),
        translateErrorType(ErrorType.InvalidAgencyDv),
        translateErrorType(ErrorType.InvalidAccountNumber),
        translateErrorType(ErrorType.InvalidAgencyNumber),
        translateErrorType(ErrorType.InvalidBankCode),
        translateErrorType(ErrorType.InvalidBankAccount),
        translateErrorType(ErrorType.NotFound),
        translateErrorType(ErrorType.NotLoggedIn),
        translateErrorType(ErrorType.MissingParameter),
        translateErrorType(ErrorType.AccessNotAllowed),
        translateErrorType(ErrorType.ValueISEmpty),
        translateErrorType(ErrorType.ValueTooLarge),
        translateErrorType(ErrorType.ValueTooSmall),
        translateErrorType(ErrorType.InvalidEmail),
        translateErrorType(ErrorType.InvalidArgument),
        translateErrorType(ErrorType.InvalidUrl),
        translateErrorType(ErrorType.InvalidSlug),
        translateErrorType(ErrorType.SlugAlreadyInUse),
        translateErrorType(ErrorType.UserDocumentsNotVerified),
        translateErrorType(ErrorType.DateUnavaiable),
        translateErrorType(ErrorType.PeriodTooSmall),
        translateErrorType(ErrorType.NotForRent),
        translateErrorType(ErrorType.CardWalletEmpty),
        translateErrorType(ErrorType.DeletedCard),
        translateErrorType(ErrorType.UserDoesntExist),
        translateErrorType(ErrorType.ShouldUseThirdParty),
        translateErrorType(ErrorType.WrongPassword),
        translateErrorType(ErrorType.LoginError),
        translateErrorType(ErrorType.ThirdPartyAuthError),
        translateErrorType(ErrorType.WrongCredentials),
        translateErrorType(ErrorType.EmailDoesntExist),
        translateErrorType(ErrorType.PasswordToSmall),
        translateErrorType(ErrorType.InvalidDocumentNumber),
        translateErrorType(ErrorType.InvalidZipCode),
        translateErrorType(ErrorType.AddresNotSet),
        translateErrorType(ErrorType.InvalidLocation),
        translateErrorType(ErrorType.CityNotFound),
        translateErrorType(ErrorType.ShippingDateNotAvaiable),
        translateErrorType(ErrorType.ShippingDistanceTooLarge),
        translateErrorType(ErrorType.ShippingCityNotAvaiable),
        translateErrorType(ErrorType.DeletedItem),
        translateErrorType(ErrorType.BadFormattedResponse),
        translateErrorType(ErrorType.InvalidPercentege),
        translateErrorType(ErrorType.CityAlreadyInUse),
        translateErrorType(ErrorType.RecipientAlreadyExists),
        translateErrorType(ErrorType.SaleStatusChange),
        translateErrorType(ErrorType.ItemAlreadySold),
        translateErrorType(ErrorType.InvalidInstallment),
        translateErrorType(ErrorType.MissingBirthDate),
        translateErrorType(ErrorType.MissingAddress),
        translateErrorType(ErrorType.OutdatedRecipient),
        translateErrorType(ErrorType.NotForSale),
        translateErrorType(ErrorType.InvalidRentPrice),
        translateErrorType(ErrorType.InvalidOriginalPrice),
        translateErrorType(ErrorType.Fatal),
        translateErrorType(ErrorType.Connection),
    ];
}

export function allDisplayableValuesErrorType(): string[] {
    return allTranslatedValuesErrorType().sort();
}

export function valueFromTranslationErrorType(translation: string): ErrorType {
    const index = allTranslatedValuesErrorType().indexOf(translation);
    return allValuesErrorType()[index] || ErrorType.PaymentRefused;
}

export async function uploadUncertainImageWith(size: number, data: Buffer, progress?: (progress: number) => void): Promise<Image> {
    const args = {
        size: size || 0,
        data: data.toString("base64"),
    };
    const ret = await makeRequest({name: "uploadUncertainImageWith", args, progress});
    return {
        width: ret.width || 0,
        height: ret.height || 0,
        url: ret.url,
    };
}

export async function getProfileStats(userId: string | null, progress?: (progress: number) => void): Promise<ProfileStats> {
    const args = {
        userId: userId === null || userId === undefined ? null : userId,
    };
    const ret = await makeRequest({name: "getProfileStats", args, progress});
    return {
        numberOfItems: ret.numberOfItems || 0,
        numberOfRents: ret.numberOfRents || 0,
        numberOfMyRents: ret.numberOfMyRents || 0,
        rating: ret.rating,
        numberOfSales: ret.numberOfSales || 0,
        numberOfFollowing: ret.numberOfFollowing || 0,
        numberOfFollowers: ret.numberOfFollowers || 0,
        numberOfLockerLikes: ret.numberOfLockerLikes || 0,
    };
}

export async function uploadImage(image: Buffer, progress?: (progress: number) => void): Promise<Image> {
    const args = {
        image: image.toString("base64"),
    };
    const ret = await makeRequest({name: "uploadImage", args, progress});
    return {
        width: ret.width || 0,
        height: ret.height || 0,
        url: ret.url,
    };
}

export async function uploadThumb(image: Buffer, progress?: (progress: number) => void): Promise<ImageAndThumb> {
    const args = {
        image: image.toString("base64"),
    };
    const ret = await makeRequest({name: "uploadThumb", args, progress});
    return {
        thumbImage: {
            width: ret.thumbImage.width || 0,
            height: ret.thumbImage.height || 0,
            url: ret.thumbImage.url,
        },
        image: {
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
    };
}

export async function getAddressFromCep(zip: string, progress?: (progress: number) => void): Promise<FillAddress | null> {
    const args = {
        zip: zip,
    };
    const ret = await makeRequest({name: "getAddressFromCep", args, progress});
    return ret === null || ret === undefined ? null : {
        street: ret.street,
        neighborhood: ret.neighborhood,
        city: ret.city,
        state: ret.state,
        uf: ret.uf,
        country: ret.country,
        zip: ret.zip,
        location: {
            lat: ret.location.lat,
            lon: ret.location.lon,
        },
    };
}

export async function getCpfValidate(cpf: string, progress?: (progress: number) => void): Promise<void> {
    const args = {
        cpf: cpf,
    };
    await makeRequest({name: "getCpfValidate", args, progress});
    return undefined;
}

export async function getMyLocation(progress?: (progress: number) => void): Promise<Location | null> {
    const ret = await makeRequest({name: "getMyLocation", args: {}, progress});
    return ret === null || ret === undefined ? null : {
        lat: ret.lat,
        lon: ret.lon,
    };
}

export async function getCity(cityId: string, progress?: (progress: number) => void): Promise<City> {
    const args = {
        cityId: cityId,
    };
    const ret = await makeRequest({name: "getCity", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        uf: ret.uf,
    };
}

export async function getStateCities(uf: string, progress?: (progress: number) => void): Promise<City[]> {
    const args = {
        uf: uf,
    };
    const ret = await makeRequest({name: "getStateCities", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        name: e.name,
        uf: e.uf,
    }));
}

export async function getCityFromSlug(citySlug: string, progress?: (progress: number) => void): Promise<City> {
    const args = {
        citySlug: citySlug,
    };
    const ret = await makeRequest({name: "getCityFromSlug", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        uf: ret.uf,
    };
}

export async function createAdminUser(newAdminUser: NewAdminUser, progress?: (progress: number) => void): Promise<AdminUser> {
    const args = {
        newAdminUser: {
            password: newAdminUser.password,
            licensedInfo: newAdminUser.licensedInfo === null || newAdminUser.licensedInfo === undefined ? null : {
                document: newAdminUser.licensedInfo.document,
                bankAccount: {
                    documentNumber: newAdminUser.licensedInfo.bankAccount.documentNumber,
                    bankName: newAdminUser.licensedInfo.bankAccount.bankName,
                    bankCode: newAdminUser.licensedInfo.bankAccount.bankCode,
                    agency: newAdminUser.licensedInfo.bankAccount.agency,
                    agencyDv: newAdminUser.licensedInfo.bankAccount.agencyDv === null || newAdminUser.licensedInfo.bankAccount.agencyDv === undefined ? null : newAdminUser.licensedInfo.bankAccount.agencyDv,
                    account: newAdminUser.licensedInfo.bankAccount.account,
                    accountDv: newAdminUser.licensedInfo.bankAccount.accountDv,
                    type: newAdminUser.licensedInfo.bankAccount.type,
                    legalName: newAdminUser.licensedInfo.bankAccount.legalName,
                },
                licenses: newAdminUser.licensedInfo.licenses.map(e => ({
                    cityId: e.cityId,
                    percentage: e.percentage || 0,
                    expirationDate: typeof(e.expirationDate) === "string" ? new Date(new Date(e.expirationDate).getTime() - new Date(e.expirationDate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(e.expirationDate.getTime() - e.expirationDate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
                })),
            },
            type: newAdminUser.type,
            name: newAdminUser.name,
            email: newAdminUser.email,
        },
    };
    const ret = await makeRequest({name: "createAdminUser", args, progress});
    return {
        id: ret.id,
        licensedInfo: ret.licensedInfo === null || ret.licensedInfo === undefined ? null : {
            document: ret.licensedInfo.document,
            bankAccount: {
                documentNumber: ret.licensedInfo.bankAccount.documentNumber,
                bankName: ret.licensedInfo.bankAccount.bankName,
                bankCode: ret.licensedInfo.bankAccount.bankCode,
                agency: ret.licensedInfo.bankAccount.agency,
                agencyDv: ret.licensedInfo.bankAccount.agencyDv === null || ret.licensedInfo.bankAccount.agencyDv === undefined ? null : ret.licensedInfo.bankAccount.agencyDv,
                account: ret.licensedInfo.bankAccount.account,
                accountDv: ret.licensedInfo.bankAccount.accountDv,
                type: ret.licensedInfo.bankAccount.type,
                legalName: ret.licensedInfo.bankAccount.legalName,
            },
            licenses: ret.licensedInfo.licenses.map((e: any) => ({
                city: {
                    id: e.city.id,
                    name: e.city.name,
                    uf: e.city.uf,
                },
                percentage: e.percentage || 0,
                expirationDate: new Date(parseInt(e.expirationDate.split("-")[0], 10), parseInt(e.expirationDate.split("-")[1], 10) - 1, parseInt(e.expirationDate.split("-")[2], 10)),
            })),
        },
        type: ret.type,
        name: ret.name,
        email: ret.email,
    };
}

export async function editAdminUser(adminUserId: string, editAdminUser: EditAdminUser, progress?: (progress: number) => void): Promise<AdminUser> {
    const args = {
        adminUserId: adminUserId,
        editAdminUser: {
            password: editAdminUser.password,
            licensedInfo: editAdminUser.licensedInfo === null || editAdminUser.licensedInfo === undefined ? null : {
                bankAccount: {
                    documentNumber: editAdminUser.licensedInfo.bankAccount.documentNumber,
                    bankName: editAdminUser.licensedInfo.bankAccount.bankName,
                    bankCode: editAdminUser.licensedInfo.bankAccount.bankCode,
                    agency: editAdminUser.licensedInfo.bankAccount.agency,
                    agencyDv: editAdminUser.licensedInfo.bankAccount.agencyDv === null || editAdminUser.licensedInfo.bankAccount.agencyDv === undefined ? null : editAdminUser.licensedInfo.bankAccount.agencyDv,
                    account: editAdminUser.licensedInfo.bankAccount.account,
                    accountDv: editAdminUser.licensedInfo.bankAccount.accountDv,
                    type: editAdminUser.licensedInfo.bankAccount.type,
                    legalName: editAdminUser.licensedInfo.bankAccount.legalName,
                },
                licenses: editAdminUser.licensedInfo.licenses.map(e => ({
                    cityId: e.cityId,
                    percentage: e.percentage || 0,
                    expirationDate: typeof(e.expirationDate) === "string" ? new Date(new Date(e.expirationDate).getTime() - new Date(e.expirationDate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(e.expirationDate.getTime() - e.expirationDate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
                })),
            },
            type: editAdminUser.type,
            name: editAdminUser.name,
            email: editAdminUser.email,
        },
    };
    const ret = await makeRequest({name: "editAdminUser", args, progress});
    return {
        id: ret.id,
        licensedInfo: ret.licensedInfo === null || ret.licensedInfo === undefined ? null : {
            document: ret.licensedInfo.document,
            bankAccount: {
                documentNumber: ret.licensedInfo.bankAccount.documentNumber,
                bankName: ret.licensedInfo.bankAccount.bankName,
                bankCode: ret.licensedInfo.bankAccount.bankCode,
                agency: ret.licensedInfo.bankAccount.agency,
                agencyDv: ret.licensedInfo.bankAccount.agencyDv === null || ret.licensedInfo.bankAccount.agencyDv === undefined ? null : ret.licensedInfo.bankAccount.agencyDv,
                account: ret.licensedInfo.bankAccount.account,
                accountDv: ret.licensedInfo.bankAccount.accountDv,
                type: ret.licensedInfo.bankAccount.type,
                legalName: ret.licensedInfo.bankAccount.legalName,
            },
            licenses: ret.licensedInfo.licenses.map((e: any) => ({
                city: {
                    id: e.city.id,
                    name: e.city.name,
                    uf: e.city.uf,
                },
                percentage: e.percentage || 0,
                expirationDate: new Date(parseInt(e.expirationDate.split("-")[0], 10), parseInt(e.expirationDate.split("-")[1], 10) - 1, parseInt(e.expirationDate.split("-")[2], 10)),
            })),
        },
        type: ret.type,
        name: ret.name,
        email: ret.email,
    };
}

export async function getAdminUser(adminUserId: string, progress?: (progress: number) => void): Promise<AdminUser> {
    const args = {
        adminUserId: adminUserId,
    };
    const ret = await makeRequest({name: "getAdminUser", args, progress});
    return {
        id: ret.id,
        licensedInfo: ret.licensedInfo === null || ret.licensedInfo === undefined ? null : {
            document: ret.licensedInfo.document,
            bankAccount: {
                documentNumber: ret.licensedInfo.bankAccount.documentNumber,
                bankName: ret.licensedInfo.bankAccount.bankName,
                bankCode: ret.licensedInfo.bankAccount.bankCode,
                agency: ret.licensedInfo.bankAccount.agency,
                agencyDv: ret.licensedInfo.bankAccount.agencyDv === null || ret.licensedInfo.bankAccount.agencyDv === undefined ? null : ret.licensedInfo.bankAccount.agencyDv,
                account: ret.licensedInfo.bankAccount.account,
                accountDv: ret.licensedInfo.bankAccount.accountDv,
                type: ret.licensedInfo.bankAccount.type,
                legalName: ret.licensedInfo.bankAccount.legalName,
            },
            licenses: ret.licensedInfo.licenses.map((e: any) => ({
                city: {
                    id: e.city.id,
                    name: e.city.name,
                    uf: e.city.uf,
                },
                percentage: e.percentage || 0,
                expirationDate: new Date(parseInt(e.expirationDate.split("-")[0], 10), parseInt(e.expirationDate.split("-")[1], 10) - 1, parseInt(e.expirationDate.split("-")[2], 10)),
            })),
        },
        type: ret.type,
        name: ret.name,
        email: ret.email,
    };
}

export async function getLicenseds(pageOffset: number, progress?: (progress: number) => void): Promise<AdminUser[]> {
    const args = {
        pageOffset: pageOffset || 0,
    };
    const ret = await makeRequest({name: "getLicenseds", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        licensedInfo: e.licensedInfo === null || e.licensedInfo === undefined ? null : {
            document: e.licensedInfo.document,
            bankAccount: {
                documentNumber: e.licensedInfo.bankAccount.documentNumber,
                bankName: e.licensedInfo.bankAccount.bankName,
                bankCode: e.licensedInfo.bankAccount.bankCode,
                agency: e.licensedInfo.bankAccount.agency,
                agencyDv: e.licensedInfo.bankAccount.agencyDv === null || e.licensedInfo.bankAccount.agencyDv === undefined ? null : e.licensedInfo.bankAccount.agencyDv,
                account: e.licensedInfo.bankAccount.account,
                accountDv: e.licensedInfo.bankAccount.accountDv,
                type: e.licensedInfo.bankAccount.type,
                legalName: e.licensedInfo.bankAccount.legalName,
            },
            licenses: e.licensedInfo.licenses.map((e: any) => ({
                city: {
                    id: e.city.id,
                    name: e.city.name,
                    uf: e.city.uf,
                },
                percentage: e.percentage || 0,
                expirationDate: new Date(parseInt(e.expirationDate.split("-")[0], 10), parseInt(e.expirationDate.split("-")[1], 10) - 1, parseInt(e.expirationDate.split("-")[2], 10)),
            })),
        },
        type: e.type,
        name: e.name,
        email: e.email,
    }));
}

export async function getAvailableAdminFilterCities(progress?: (progress: number) => void): Promise<FilterCity[]> {
    const ret = await makeRequest({name: "getAvailableAdminFilterCities", args: {}, progress});
    return ret.map((e: any) => ({
        id: e.id,
        name: e.name,
    }));
}

export async function createOrEditBankAccount(bankAccount: BankAccount, progress?: (progress: number) => void): Promise<AdminUser> {
    const args = {
        bankAccount: {
            documentNumber: bankAccount.documentNumber,
            bankName: bankAccount.bankName,
            bankCode: bankAccount.bankCode,
            agency: bankAccount.agency,
            agencyDv: bankAccount.agencyDv === null || bankAccount.agencyDv === undefined ? null : bankAccount.agencyDv,
            account: bankAccount.account,
            accountDv: bankAccount.accountDv,
            type: bankAccount.type,
            legalName: bankAccount.legalName,
        },
    };
    const ret = await makeRequest({name: "createOrEditBankAccount", args, progress});
    return {
        id: ret.id,
        licensedInfo: ret.licensedInfo === null || ret.licensedInfo === undefined ? null : {
            document: ret.licensedInfo.document,
            bankAccount: {
                documentNumber: ret.licensedInfo.bankAccount.documentNumber,
                bankName: ret.licensedInfo.bankAccount.bankName,
                bankCode: ret.licensedInfo.bankAccount.bankCode,
                agency: ret.licensedInfo.bankAccount.agency,
                agencyDv: ret.licensedInfo.bankAccount.agencyDv === null || ret.licensedInfo.bankAccount.agencyDv === undefined ? null : ret.licensedInfo.bankAccount.agencyDv,
                account: ret.licensedInfo.bankAccount.account,
                accountDv: ret.licensedInfo.bankAccount.accountDv,
                type: ret.licensedInfo.bankAccount.type,
                legalName: ret.licensedInfo.bankAccount.legalName,
            },
            licenses: ret.licensedInfo.licenses.map((e: any) => ({
                city: {
                    id: e.city.id,
                    name: e.city.name,
                    uf: e.city.uf,
                },
                percentage: e.percentage || 0,
                expirationDate: new Date(parseInt(e.expirationDate.split("-")[0], 10), parseInt(e.expirationDate.split("-")[1], 10) - 1, parseInt(e.expirationDate.split("-")[2], 10)),
            })),
        },
        type: ret.type,
        name: ret.name,
        email: ret.email,
    };
}

export async function getBalance(progress?: (progress: number) => void): Promise<PagarmeBalance> {
    const ret = await makeRequest({name: "getBalance", args: {}, progress});
    return {
        object: ret.object,
        waitingFunds: {
            amount: ret.waitingFunds.amount || 0,
        },
        available: {
            amount: ret.available.amount || 0,
        },
        transferred: {
            amount: ret.transferred.amount || 0,
        },
    };
}

export async function withDraw(amount: number, progress?: (progress: number) => void): Promise<BalanceFunds> {
    const args = {
        amount: amount || 0,
    };
    const ret = await makeRequest({name: "withDraw", args, progress});
    return {
        waitingFunds: {
            amount: ret.waitingFunds.amount || 0,
        },
        available: {
            amount: ret.available.amount || 0,
        },
        transferred: {
            amount: ret.transferred.amount || 0,
        },
    };
}

export async function resetPasswordAdminUser(token: string, newPassword: string, progress?: (progress: number) => void): Promise<void> {
    const args = {
        token: token,
        newPassword: newPassword,
    };
    await makeRequest({name: "resetPasswordAdminUser", args, progress});
    return undefined;
}

export async function getBanners(pageOffset: number, progress?: (progress: number) => void): Promise<Banner[]> {
    const args = {
        pageOffset: pageOffset || 0,
    };
    const ret = await makeRequest({name: "getBanners", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        image: {
            width: e.image.width || 0,
            height: e.image.height || 0,
            url: e.image.url,
        },
        mobileImage: e.mobileImage === null || e.mobileImage === undefined ? null : {
            width: e.mobileImage.width || 0,
            height: e.mobileImage.height || 0,
            url: e.mobileImage.url,
        },
        contentType: e.contentType === null || e.contentType === undefined ? null : e.contentType,
        type: e.type,
        slug: e.slug,
        title: e.title,
        url: e.url === null || e.url === undefined ? null : e.url,
        content: {
            query: e.content.query === null || e.content.query === undefined ? null : {
                search: e.content.query.search === null || e.content.query.search === undefined ? null : e.content.query.search,
                filter: {
                    distance: e.content.query.filter.distance,
                    sizes: e.content.query.filter.sizes.map((e: any) => e),
                    gender: e.content.query.filter.gender.map((e: any) => e),
                    categories: e.content.query.filter.categories.map((e: any) => e),
                    ocasions: e.content.query.filter.ocasions.map((e: any) => e),
                    colors: e.content.query.filter.colors.map((e: any) => e),
                    orderBy: e.content.query.filter.orderBy === null || e.content.query.filter.orderBy === undefined ? null : e.content.query.filter.orderBy,
                    itemOfferType: e.content.query.filter.itemOfferType === null || e.content.query.filter.itemOfferType === undefined ? null : e.content.query.filter.itemOfferType,
                },
            },
            url: e.content.url === null || e.content.url === undefined ? null : e.content.url,
            group: e.content.group === null || e.content.group === undefined ? null : {
                userIds: e.content.group.userIds.map((e: any) => e),
                sections: e.content.group.sections.map((e: any) => ({
                    title: e.title,
                    itemIds: e.itemIds.map((e: any) => e),
                })),
                innerBanner: e.content.group.innerBanner === null || e.content.group.innerBanner === undefined ? null : {
                    image: {
                        width: e.content.group.innerBanner.image.width || 0,
                        height: e.content.group.innerBanner.image.height || 0,
                        url: e.content.group.innerBanner.image.url,
                    },
                    url: e.content.group.innerBanner.url,
                },
            },
        },
    }));
}

export async function getBanner(bannerId: string, progress?: (progress: number) => void): Promise<Banner> {
    const args = {
        bannerId: bannerId,
    };
    const ret = await makeRequest({name: "getBanner", args, progress});
    return {
        id: ret.id,
        image: {
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        mobileImage: ret.mobileImage === null || ret.mobileImage === undefined ? null : {
            width: ret.mobileImage.width || 0,
            height: ret.mobileImage.height || 0,
            url: ret.mobileImage.url,
        },
        contentType: ret.contentType === null || ret.contentType === undefined ? null : ret.contentType,
        type: ret.type,
        slug: ret.slug,
        title: ret.title,
        url: ret.url === null || ret.url === undefined ? null : ret.url,
        content: {
            query: ret.content.query === null || ret.content.query === undefined ? null : {
                search: ret.content.query.search === null || ret.content.query.search === undefined ? null : ret.content.query.search,
                filter: {
                    distance: ret.content.query.filter.distance,
                    sizes: ret.content.query.filter.sizes.map((e: any) => e),
                    gender: ret.content.query.filter.gender.map((e: any) => e),
                    categories: ret.content.query.filter.categories.map((e: any) => e),
                    ocasions: ret.content.query.filter.ocasions.map((e: any) => e),
                    colors: ret.content.query.filter.colors.map((e: any) => e),
                    orderBy: ret.content.query.filter.orderBy === null || ret.content.query.filter.orderBy === undefined ? null : ret.content.query.filter.orderBy,
                    itemOfferType: ret.content.query.filter.itemOfferType === null || ret.content.query.filter.itemOfferType === undefined ? null : ret.content.query.filter.itemOfferType,
                },
            },
            url: ret.content.url === null || ret.content.url === undefined ? null : ret.content.url,
            group: ret.content.group === null || ret.content.group === undefined ? null : {
                userIds: ret.content.group.userIds.map((e: any) => e),
                sections: ret.content.group.sections.map((e: any) => ({
                    title: e.title,
                    itemIds: e.itemIds.map((e: any) => e),
                })),
                innerBanner: ret.content.group.innerBanner === null || ret.content.group.innerBanner === undefined ? null : {
                    image: {
                        width: ret.content.group.innerBanner.image.width || 0,
                        height: ret.content.group.innerBanner.image.height || 0,
                        url: ret.content.group.innerBanner.image.url,
                    },
                    url: ret.content.group.innerBanner.url,
                },
            },
        },
    };
}

export async function createBanner(newBanner: NewBanner, progress?: (progress: number) => void): Promise<Banner> {
    const args = {
        newBanner: {
            image: {
                bytes: newBanner.image.bytes === null || newBanner.image.bytes === undefined ? null : newBanner.image.bytes.toString("base64"),
                image: newBanner.image.image === null || newBanner.image.image === undefined ? null : {
                    width: newBanner.image.image.width || 0,
                    height: newBanner.image.image.height || 0,
                    url: newBanner.image.image.url,
                },
            },
            mobileImage: newBanner.mobileImage === null || newBanner.mobileImage === undefined ? null : {
                bytes: newBanner.mobileImage.bytes === null || newBanner.mobileImage.bytes === undefined ? null : newBanner.mobileImage.bytes.toString("base64"),
                image: newBanner.mobileImage.image === null || newBanner.mobileImage.image === undefined ? null : {
                    width: newBanner.mobileImage.image.width || 0,
                    height: newBanner.mobileImage.image.height || 0,
                    url: newBanner.mobileImage.image.url,
                },
            },
            innerBanner: newBanner.innerBanner === null || newBanner.innerBanner === undefined ? null : {
                innerImage: {
                    bytes: newBanner.innerBanner.innerImage.bytes === null || newBanner.innerBanner.innerImage.bytes === undefined ? null : newBanner.innerBanner.innerImage.bytes.toString("base64"),
                    image: newBanner.innerBanner.innerImage.image === null || newBanner.innerBanner.innerImage.image === undefined ? null : {
                        width: newBanner.innerBanner.innerImage.image.width || 0,
                        height: newBanner.innerBanner.innerImage.image.height || 0,
                        url: newBanner.innerBanner.innerImage.image.url,
                    },
                },
                innerUrl: newBanner.innerBanner.innerUrl,
            },
            contentType: newBanner.contentType === null || newBanner.contentType === undefined ? null : newBanner.contentType,
            type: newBanner.type,
            slug: newBanner.slug,
            title: newBanner.title,
            url: newBanner.url === null || newBanner.url === undefined ? null : newBanner.url,
            content: {
                query: newBanner.content.query === null || newBanner.content.query === undefined ? null : {
                    search: newBanner.content.query.search === null || newBanner.content.query.search === undefined ? null : newBanner.content.query.search,
                    filter: {
                        distance: newBanner.content.query.filter.distance,
                        sizes: newBanner.content.query.filter.sizes.map(e => e),
                        gender: newBanner.content.query.filter.gender.map(e => e),
                        categories: newBanner.content.query.filter.categories.map(e => e),
                        ocasions: newBanner.content.query.filter.ocasions.map(e => e),
                        colors: newBanner.content.query.filter.colors.map(e => e),
                        orderBy: newBanner.content.query.filter.orderBy === null || newBanner.content.query.filter.orderBy === undefined ? null : newBanner.content.query.filter.orderBy,
                        itemOfferType: newBanner.content.query.filter.itemOfferType === null || newBanner.content.query.filter.itemOfferType === undefined ? null : newBanner.content.query.filter.itemOfferType,
                    },
                },
                url: newBanner.content.url === null || newBanner.content.url === undefined ? null : newBanner.content.url,
                group: newBanner.content.group === null || newBanner.content.group === undefined ? null : {
                    userIds: newBanner.content.group.userIds.map(e => e),
                    sections: newBanner.content.group.sections.map(e => ({
                        title: e.title,
                        itemIds: e.itemIds.map(e => e),
                    })),
                    innerBanner: newBanner.content.group.innerBanner === null || newBanner.content.group.innerBanner === undefined ? null : {
                        image: {
                            width: newBanner.content.group.innerBanner.image.width || 0,
                            height: newBanner.content.group.innerBanner.image.height || 0,
                            url: newBanner.content.group.innerBanner.image.url,
                        },
                        url: newBanner.content.group.innerBanner.url,
                    },
                },
            },
        },
    };
    const ret = await makeRequest({name: "createBanner", args, progress});
    return {
        id: ret.id,
        image: {
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        mobileImage: ret.mobileImage === null || ret.mobileImage === undefined ? null : {
            width: ret.mobileImage.width || 0,
            height: ret.mobileImage.height || 0,
            url: ret.mobileImage.url,
        },
        contentType: ret.contentType === null || ret.contentType === undefined ? null : ret.contentType,
        type: ret.type,
        slug: ret.slug,
        title: ret.title,
        url: ret.url === null || ret.url === undefined ? null : ret.url,
        content: {
            query: ret.content.query === null || ret.content.query === undefined ? null : {
                search: ret.content.query.search === null || ret.content.query.search === undefined ? null : ret.content.query.search,
                filter: {
                    distance: ret.content.query.filter.distance,
                    sizes: ret.content.query.filter.sizes.map((e: any) => e),
                    gender: ret.content.query.filter.gender.map((e: any) => e),
                    categories: ret.content.query.filter.categories.map((e: any) => e),
                    ocasions: ret.content.query.filter.ocasions.map((e: any) => e),
                    colors: ret.content.query.filter.colors.map((e: any) => e),
                    orderBy: ret.content.query.filter.orderBy === null || ret.content.query.filter.orderBy === undefined ? null : ret.content.query.filter.orderBy,
                    itemOfferType: ret.content.query.filter.itemOfferType === null || ret.content.query.filter.itemOfferType === undefined ? null : ret.content.query.filter.itemOfferType,
                },
            },
            url: ret.content.url === null || ret.content.url === undefined ? null : ret.content.url,
            group: ret.content.group === null || ret.content.group === undefined ? null : {
                userIds: ret.content.group.userIds.map((e: any) => e),
                sections: ret.content.group.sections.map((e: any) => ({
                    title: e.title,
                    itemIds: e.itemIds.map((e: any) => e),
                })),
                innerBanner: ret.content.group.innerBanner === null || ret.content.group.innerBanner === undefined ? null : {
                    image: {
                        width: ret.content.group.innerBanner.image.width || 0,
                        height: ret.content.group.innerBanner.image.height || 0,
                        url: ret.content.group.innerBanner.image.url,
                    },
                    url: ret.content.group.innerBanner.url,
                },
            },
        },
    };
}

export async function editBanner(bannerId: string, editedBanner: EditBanner, progress?: (progress: number) => void): Promise<Banner> {
    const args = {
        bannerId: bannerId,
        editedBanner: {
            image: {
                bytes: editedBanner.image.bytes === null || editedBanner.image.bytes === undefined ? null : editedBanner.image.bytes.toString("base64"),
                image: editedBanner.image.image === null || editedBanner.image.image === undefined ? null : {
                    width: editedBanner.image.image.width || 0,
                    height: editedBanner.image.image.height || 0,
                    url: editedBanner.image.image.url,
                },
            },
            mobileImage: editedBanner.mobileImage === null || editedBanner.mobileImage === undefined ? null : {
                bytes: editedBanner.mobileImage.bytes === null || editedBanner.mobileImage.bytes === undefined ? null : editedBanner.mobileImage.bytes.toString("base64"),
                image: editedBanner.mobileImage.image === null || editedBanner.mobileImage.image === undefined ? null : {
                    width: editedBanner.mobileImage.image.width || 0,
                    height: editedBanner.mobileImage.image.height || 0,
                    url: editedBanner.mobileImage.image.url,
                },
            },
            innerBanner: editedBanner.innerBanner === null || editedBanner.innerBanner === undefined ? null : {
                innerImage: {
                    bytes: editedBanner.innerBanner.innerImage.bytes === null || editedBanner.innerBanner.innerImage.bytes === undefined ? null : editedBanner.innerBanner.innerImage.bytes.toString("base64"),
                    image: editedBanner.innerBanner.innerImage.image === null || editedBanner.innerBanner.innerImage.image === undefined ? null : {
                        width: editedBanner.innerBanner.innerImage.image.width || 0,
                        height: editedBanner.innerBanner.innerImage.image.height || 0,
                        url: editedBanner.innerBanner.innerImage.image.url,
                    },
                },
                innerUrl: editedBanner.innerBanner.innerUrl,
            },
            contentType: editedBanner.contentType === null || editedBanner.contentType === undefined ? null : editedBanner.contentType,
            type: editedBanner.type,
            slug: editedBanner.slug,
            title: editedBanner.title,
            url: editedBanner.url === null || editedBanner.url === undefined ? null : editedBanner.url,
            content: {
                query: editedBanner.content.query === null || editedBanner.content.query === undefined ? null : {
                    search: editedBanner.content.query.search === null || editedBanner.content.query.search === undefined ? null : editedBanner.content.query.search,
                    filter: {
                        distance: editedBanner.content.query.filter.distance,
                        sizes: editedBanner.content.query.filter.sizes.map(e => e),
                        gender: editedBanner.content.query.filter.gender.map(e => e),
                        categories: editedBanner.content.query.filter.categories.map(e => e),
                        ocasions: editedBanner.content.query.filter.ocasions.map(e => e),
                        colors: editedBanner.content.query.filter.colors.map(e => e),
                        orderBy: editedBanner.content.query.filter.orderBy === null || editedBanner.content.query.filter.orderBy === undefined ? null : editedBanner.content.query.filter.orderBy,
                        itemOfferType: editedBanner.content.query.filter.itemOfferType === null || editedBanner.content.query.filter.itemOfferType === undefined ? null : editedBanner.content.query.filter.itemOfferType,
                    },
                },
                url: editedBanner.content.url === null || editedBanner.content.url === undefined ? null : editedBanner.content.url,
                group: editedBanner.content.group === null || editedBanner.content.group === undefined ? null : {
                    userIds: editedBanner.content.group.userIds.map(e => e),
                    sections: editedBanner.content.group.sections.map(e => ({
                        title: e.title,
                        itemIds: e.itemIds.map(e => e),
                    })),
                    innerBanner: editedBanner.content.group.innerBanner === null || editedBanner.content.group.innerBanner === undefined ? null : {
                        image: {
                            width: editedBanner.content.group.innerBanner.image.width || 0,
                            height: editedBanner.content.group.innerBanner.image.height || 0,
                            url: editedBanner.content.group.innerBanner.image.url,
                        },
                        url: editedBanner.content.group.innerBanner.url,
                    },
                },
            },
        },
    };
    const ret = await makeRequest({name: "editBanner", args, progress});
    return {
        id: ret.id,
        image: {
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        mobileImage: ret.mobileImage === null || ret.mobileImage === undefined ? null : {
            width: ret.mobileImage.width || 0,
            height: ret.mobileImage.height || 0,
            url: ret.mobileImage.url,
        },
        contentType: ret.contentType === null || ret.contentType === undefined ? null : ret.contentType,
        type: ret.type,
        slug: ret.slug,
        title: ret.title,
        url: ret.url === null || ret.url === undefined ? null : ret.url,
        content: {
            query: ret.content.query === null || ret.content.query === undefined ? null : {
                search: ret.content.query.search === null || ret.content.query.search === undefined ? null : ret.content.query.search,
                filter: {
                    distance: ret.content.query.filter.distance,
                    sizes: ret.content.query.filter.sizes.map((e: any) => e),
                    gender: ret.content.query.filter.gender.map((e: any) => e),
                    categories: ret.content.query.filter.categories.map((e: any) => e),
                    ocasions: ret.content.query.filter.ocasions.map((e: any) => e),
                    colors: ret.content.query.filter.colors.map((e: any) => e),
                    orderBy: ret.content.query.filter.orderBy === null || ret.content.query.filter.orderBy === undefined ? null : ret.content.query.filter.orderBy,
                    itemOfferType: ret.content.query.filter.itemOfferType === null || ret.content.query.filter.itemOfferType === undefined ? null : ret.content.query.filter.itemOfferType,
                },
            },
            url: ret.content.url === null || ret.content.url === undefined ? null : ret.content.url,
            group: ret.content.group === null || ret.content.group === undefined ? null : {
                userIds: ret.content.group.userIds.map((e: any) => e),
                sections: ret.content.group.sections.map((e: any) => ({
                    title: e.title,
                    itemIds: e.itemIds.map((e: any) => e),
                })),
                innerBanner: ret.content.group.innerBanner === null || ret.content.group.innerBanner === undefined ? null : {
                    image: {
                        width: ret.content.group.innerBanner.image.width || 0,
                        height: ret.content.group.innerBanner.image.height || 0,
                        url: ret.content.group.innerBanner.image.url,
                    },
                    url: ret.content.group.innerBanner.url,
                },
            },
        },
    };
}

export async function deleteBanner(bannerId: string, progress?: (progress: number) => void): Promise<void> {
    const args = {
        bannerId: bannerId,
    };
    await makeRequest({name: "deleteBanner", args, progress});
    return undefined;
}

export async function getSlugOption(title: string, progress?: (progress: number) => void): Promise<string> {
    const args = {
        title: title,
    };
    const ret = await makeRequest({name: "getSlugOption", args, progress});
    return ret;
}

export async function createPost(newPost: NewPost, progress?: (progress: number) => void): Promise<Post> {
    const args = {
        newPost: {
            image: {
                bytes: newPost.image.bytes === null || newPost.image.bytes === undefined ? null : newPost.image.bytes.toString("base64"),
                image: newPost.image.image === null || newPost.image.image === undefined ? null : {
                    width: newPost.image.image.width || 0,
                    height: newPost.image.image.height || 0,
                    url: newPost.image.image.url,
                },
            },
            slug: newPost.slug,
            title: newPost.title,
            body: newPost.body,
            bodyHtml: newPost.bodyHtml,
        },
    };
    const ret = await makeRequest({name: "createPost", args, progress});
    return {
        id: ret.id,
        user: ret.user === null || ret.user === undefined ? null : {
            id: ret.user.id,
            avatar: ret.user.avatar === null || ret.user.avatar === undefined ? null : {
                width: ret.user.avatar.width || 0,
                height: ret.user.avatar.height || 0,
                url: ret.user.avatar.url,
            },
            name: ret.user.name,
            nick: ret.user.nick,
            rate: ret.user.rate,
            height: ret.user.height === null || ret.user.height === undefined ? null : ret.user.height || 0,
            deleted: !!ret.user.deleted,
            type: ret.user.type,
            isLockerLiked: !!ret.user.isLockerLiked,
            isFollowing: !!ret.user.isFollowing,
            ownerName: ret.user.ownerName === null || ret.user.ownerName === undefined ? null : ret.user.ownerName,
        },
        approved: !!ret.approved,
        image: {
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        slug: ret.slug,
        title: ret.title,
        body: ret.body,
        bodyHtml: ret.bodyHtml,
    };
}

export async function editPost(postId: string, editedPost: EditPost, progress?: (progress: number) => void): Promise<Post> {
    const args = {
        postId: postId,
        editedPost: {
            image: {
                bytes: editedPost.image.bytes === null || editedPost.image.bytes === undefined ? null : editedPost.image.bytes.toString("base64"),
                image: editedPost.image.image === null || editedPost.image.image === undefined ? null : {
                    width: editedPost.image.image.width || 0,
                    height: editedPost.image.image.height || 0,
                    url: editedPost.image.image.url,
                },
            },
            slug: editedPost.slug,
            title: editedPost.title,
            body: editedPost.body,
            bodyHtml: editedPost.bodyHtml,
        },
    };
    const ret = await makeRequest({name: "editPost", args, progress});
    return {
        id: ret.id,
        user: ret.user === null || ret.user === undefined ? null : {
            id: ret.user.id,
            avatar: ret.user.avatar === null || ret.user.avatar === undefined ? null : {
                width: ret.user.avatar.width || 0,
                height: ret.user.avatar.height || 0,
                url: ret.user.avatar.url,
            },
            name: ret.user.name,
            nick: ret.user.nick,
            rate: ret.user.rate,
            height: ret.user.height === null || ret.user.height === undefined ? null : ret.user.height || 0,
            deleted: !!ret.user.deleted,
            type: ret.user.type,
            isLockerLiked: !!ret.user.isLockerLiked,
            isFollowing: !!ret.user.isFollowing,
            ownerName: ret.user.ownerName === null || ret.user.ownerName === undefined ? null : ret.user.ownerName,
        },
        approved: !!ret.approved,
        image: {
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        slug: ret.slug,
        title: ret.title,
        body: ret.body,
        bodyHtml: ret.bodyHtml,
    };
}

export async function deletePost(postId: string, progress?: (progress: number) => void): Promise<void> {
    const args = {
        postId: postId,
    };
    await makeRequest({name: "deletePost", args, progress});
    return undefined;
}

export async function getPost(postId: string, progress?: (progress: number) => void): Promise<Post> {
    const args = {
        postId: postId,
    };
    const ret = await makeRequest({name: "getPost", args, progress});
    return {
        id: ret.id,
        user: ret.user === null || ret.user === undefined ? null : {
            id: ret.user.id,
            avatar: ret.user.avatar === null || ret.user.avatar === undefined ? null : {
                width: ret.user.avatar.width || 0,
                height: ret.user.avatar.height || 0,
                url: ret.user.avatar.url,
            },
            name: ret.user.name,
            nick: ret.user.nick,
            rate: ret.user.rate,
            height: ret.user.height === null || ret.user.height === undefined ? null : ret.user.height || 0,
            deleted: !!ret.user.deleted,
            type: ret.user.type,
            isLockerLiked: !!ret.user.isLockerLiked,
            isFollowing: !!ret.user.isFollowing,
            ownerName: ret.user.ownerName === null || ret.user.ownerName === undefined ? null : ret.user.ownerName,
        },
        approved: !!ret.approved,
        image: {
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        slug: ret.slug,
        title: ret.title,
        body: ret.body,
        bodyHtml: ret.bodyHtml,
    };
}

export async function getPosts(pageOffset: number, approved: boolean, progress?: (progress: number) => void): Promise<Post[]> {
    const args = {
        pageOffset: pageOffset || 0,
        approved: !!approved,
    };
    const ret = await makeRequest({name: "getPosts", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        user: e.user === null || e.user === undefined ? null : {
            id: e.user.id,
            avatar: e.user.avatar === null || e.user.avatar === undefined ? null : {
                width: e.user.avatar.width || 0,
                height: e.user.avatar.height || 0,
                url: e.user.avatar.url,
            },
            name: e.user.name,
            nick: e.user.nick,
            rate: e.user.rate,
            height: e.user.height === null || e.user.height === undefined ? null : e.user.height || 0,
            deleted: !!e.user.deleted,
            type: e.user.type,
            isLockerLiked: !!e.user.isLockerLiked,
            isFollowing: !!e.user.isFollowing,
            ownerName: e.user.ownerName === null || e.user.ownerName === undefined ? null : e.user.ownerName,
        },
        approved: !!e.approved,
        image: {
            width: e.image.width || 0,
            height: e.image.height || 0,
            url: e.image.url,
        },
        slug: e.slug,
        title: e.title,
        body: e.body,
        bodyHtml: e.bodyHtml,
    }));
}

export async function setPostApproved(postId: string, approved: boolean, progress?: (progress: number) => void): Promise<Post> {
    const args = {
        postId: postId,
        approved: !!approved,
    };
    const ret = await makeRequest({name: "setPostApproved", args, progress});
    return {
        id: ret.id,
        user: ret.user === null || ret.user === undefined ? null : {
            id: ret.user.id,
            avatar: ret.user.avatar === null || ret.user.avatar === undefined ? null : {
                width: ret.user.avatar.width || 0,
                height: ret.user.avatar.height || 0,
                url: ret.user.avatar.url,
            },
            name: ret.user.name,
            nick: ret.user.nick,
            rate: ret.user.rate,
            height: ret.user.height === null || ret.user.height === undefined ? null : ret.user.height || 0,
            deleted: !!ret.user.deleted,
            type: ret.user.type,
            isLockerLiked: !!ret.user.isLockerLiked,
            isFollowing: !!ret.user.isFollowing,
            ownerName: ret.user.ownerName === null || ret.user.ownerName === undefined ? null : ret.user.ownerName,
        },
        approved: !!ret.approved,
        image: {
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        slug: ret.slug,
        title: ret.title,
        body: ret.body,
        bodyHtml: ret.bodyHtml,
    };
}

export async function sendEmailForFranchise(dataUser: FranchiseContact, progress?: (progress: number) => void): Promise<void> {
    const args = {
        dataUser: {
            name: dataUser.name,
            email: dataUser.email,
            phone: dataUser.phone,
            instagram: dataUser.instagram,
            city: dataUser.city,
            state: dataUser.state,
        },
    };
    await makeRequest({name: "sendEmailForFranchise", args, progress});
    return undefined;
}

export async function getAllFeedPost(pageOffset: number, progress?: (progress: number) => void): Promise<FeedPost[]> {
    const args = {
        pageOffset: pageOffset || 0,
    };
    const ret = await makeRequest({name: "getAllFeedPost", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        text: e.text,
        image: e.image === null || e.image === undefined ? null : {
            width: e.image.width || 0,
            height: e.image.height || 0,
            url: e.image.url,
        },
        liked: !!e.liked,
        likes: e.likes || 0,
        userId: e.userId,
        createdAt: new Date(e.createdAt + "Z"),
        userAddress: e.userAddress === null || e.userAddress === undefined ? null : {
            street: e.userAddress.street,
            neighborhood: e.userAddress.neighborhood,
            city: e.userAddress.city,
            uf: e.userAddress.uf,
            country: e.userAddress.country,
            number: e.userAddress.number,
            zip: e.userAddress.zip,
            adicionalInfo: e.userAddress.adicionalInfo,
            reference: e.userAddress.reference,
            location: {
                lat: e.userAddress.location.lat,
                lon: e.userAddress.location.lon,
            },
        },
        userInfo: {
            id: e.userInfo.id,
            avatar: e.userInfo.avatar === null || e.userInfo.avatar === undefined ? null : {
                width: e.userInfo.avatar.width || 0,
                height: e.userInfo.avatar.height || 0,
                url: e.userInfo.avatar.url,
            },
            name: e.userInfo.name,
            nick: e.userInfo.nick,
            rate: e.userInfo.rate,
            height: e.userInfo.height === null || e.userInfo.height === undefined ? null : e.userInfo.height || 0,
            deleted: !!e.userInfo.deleted,
            type: e.userInfo.type,
            isLockerLiked: !!e.userInfo.isLockerLiked,
            isFollowing: !!e.userInfo.isFollowing,
            ownerName: e.userInfo.ownerName === null || e.userInfo.ownerName === undefined ? null : e.userInfo.ownerName,
        },
        items: e.items || 0,
        countComment: e.countComment || 0,
    }));
}

export async function deleteFeedPost(feedPostId: string, progress?: (progress: number) => void): Promise<void> {
    const args = {
        feedPostId: feedPostId,
    };
    await makeRequest({name: "deleteFeedPost", args, progress});
    return undefined;
}

export async function getFeedPostForId(bannerId: string, progress?: (progress: number) => void): Promise<FeedPostDetails> {
    const args = {
        bannerId: bannerId,
    };
    const ret = await makeRequest({name: "getFeedPostForId", args, progress});
    return {
        id: ret.id,
        text: ret.text,
        image: ret.image === null || ret.image === undefined ? null : {
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        likes: ret.likes || 0,
        userId: ret.userId,
        createdAt: new Date(ret.createdAt + "Z"),
    };
}

export async function getAmountPosts(progress?: (progress: number) => void): Promise<number> {
    const ret = await makeRequest({name: "getAmountPosts", args: {}, progress});
    return ret || 0;
}

export async function getHomeData(startDate: Date, endDate: Date, cityIds: string[], progress?: (progress: number) => void): Promise<AdminHomeData> {
    const args = {
        startDate: (typeof startDate === "string" && (startDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (startDate as any).replace("Z", "") : startDate.toISOString().replace("Z", "")),
        endDate: (typeof endDate === "string" && (endDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (endDate as any).replace("Z", "") : endDate.toISOString().replace("Z", "")),
        cityIds: cityIds.map(e => e),
    };
    const ret = await makeRequest({name: "getHomeData", args, progress});
    return {
        sales: {
            amount: ret.sales.amount || 0,
            ticket: ret.sales.ticket || 0,
            totalPrice: ret.sales.totalPrice || 0,
        },
        rents: {
            amount: ret.rents.amount || 0,
            ticket: ret.rents.ticket || 0,
            totalPrice: ret.rents.totalPrice || 0,
        },
        rentsAndSales: {
            amount: ret.rentsAndSales.amount || 0,
            ticket: ret.rentsAndSales.ticket || 0,
            totalPrice: ret.rentsAndSales.totalPrice || 0,
        },
        createdUsers: ret.createdUsers || 0,
        createdItems: ret.createdItems || 0,
    };
}

export async function getGraphicDataPerDate(startDate: Date, endDate: Date, cityIds: string[], type: HomeGraphicType, progress?: (progress: number) => void): Promise<GraphicDataPerDate> {
    const args = {
        startDate: (typeof startDate === "string" && (startDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (startDate as any).replace("Z", "") : startDate.toISOString().replace("Z", "")),
        endDate: (typeof endDate === "string" && (endDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (endDate as any).replace("Z", "") : endDate.toISOString().replace("Z", "")),
        cityIds: cityIds.map(e => e),
        type: type,
    };
    const ret = await makeRequest({name: "getGraphicDataPerDate", args, progress});
    return {
        sales: ret.sales.map((e: any) => ({
            value: e.value || 0,
            date: new Date(parseInt(e.date.split("-")[0], 10), parseInt(e.date.split("-")[1], 10) - 1, parseInt(e.date.split("-")[2], 10)),
        })),
        rents: ret.rents.map((e: any) => ({
            value: e.value || 0,
            date: new Date(parseInt(e.date.split("-")[0], 10), parseInt(e.date.split("-")[1], 10) - 1, parseInt(e.date.split("-")[2], 10)),
        })),
    };
}

export async function getGraphicDataPerDateHour(startDate: Date, endDate: Date, cityIds: string[], type: HomeGraphicType, progress?: (progress: number) => void): Promise<GraphicDataPerDateHour> {
    const args = {
        startDate: (typeof startDate === "string" && (startDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (startDate as any).replace("Z", "") : startDate.toISOString().replace("Z", "")),
        endDate: (typeof endDate === "string" && (endDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (endDate as any).replace("Z", "") : endDate.toISOString().replace("Z", "")),
        cityIds: cityIds.map(e => e),
        type: type,
    };
    const ret = await makeRequest({name: "getGraphicDataPerDateHour", args, progress});
    return {
        sales: ret.sales.map((e: any) => ({
            value: e.value || 0,
            dateHour: new Date(e.dateHour + "Z"),
        })),
        rents: ret.rents.map((e: any) => ({
            value: e.value || 0,
            dateHour: new Date(e.dateHour + "Z"),
        })),
    };
}

export async function getGraphicDataPerHour(startDate: Date, endDate: Date, cityIds: string[], type: HomeGraphicType, progress?: (progress: number) => void): Promise<GraphicDataPerHour> {
    const args = {
        startDate: (typeof startDate === "string" && (startDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (startDate as any).replace("Z", "") : startDate.toISOString().replace("Z", "")),
        endDate: (typeof endDate === "string" && (endDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (endDate as any).replace("Z", "") : endDate.toISOString().replace("Z", "")),
        cityIds: cityIds.map(e => e),
        type: type,
    };
    const ret = await makeRequest({name: "getGraphicDataPerHour", args, progress});
    return {
        sales: ret.sales.map((e: any) => ({
            value: e.value || 0,
            hour: e.hour || 0,
        })),
        rents: ret.rents.map((e: any) => ({
            value: e.value || 0,
            hour: e.hour || 0,
        })),
    };
}

export async function getItem(itemId: string, progress?: (progress: number) => void): Promise<Item | null> {
    const args = {
        itemId: itemId,
    };
    const ret = await makeRequest({name: "getItem", args, progress});
    return ret === null || ret === undefined ? null : {
        id: ret.id,
        owner: {
            id: ret.owner.id,
            gender: ret.owner.gender === null || ret.owner.gender === undefined ? null : ret.owner.gender,
            birthday: ret.owner.birthday === null || ret.owner.birthday === undefined ? null : new Date(parseInt(ret.owner.birthday.split("-")[0], 10), parseInt(ret.owner.birthday.split("-")[1], 10) - 1, parseInt(ret.owner.birthday.split("-")[2], 10)),
            type: ret.owner.type,
            name: ret.owner.name,
            nick: ret.owner.nick,
            cpf: ret.owner.cpf,
            email: ret.owner.email,
            phone: {
                ddd: ret.owner.phone.ddd,
                ddi: ret.owner.phone.ddi,
                number: ret.owner.phone.number,
            },
            avatar: ret.owner.avatar === null || ret.owner.avatar === undefined ? null : {
                width: ret.owner.avatar.width || 0,
                height: ret.owner.avatar.height || 0,
                url: ret.owner.avatar.url,
            },
            address: ret.owner.address === null || ret.owner.address === undefined ? null : {
                street: ret.owner.address.street,
                neighborhood: ret.owner.address.neighborhood,
                city: ret.owner.address.city,
                uf: ret.owner.address.uf,
                country: ret.owner.address.country,
                number: ret.owner.address.number,
                zip: ret.owner.address.zip,
                adicionalInfo: ret.owner.address.adicionalInfo,
                reference: ret.owner.address.reference,
                location: {
                    lat: ret.owner.address.location.lat,
                    lon: ret.owner.address.location.lon,
                },
            },
            createdAt: new Date(ret.owner.createdAt + "Z"),
            deletedAt: ret.owner.deletedAt === null || ret.owner.deletedAt === undefined ? null : new Date(ret.owner.deletedAt + "Z"),
            blockedUntil: ret.owner.blockedUntil === null || ret.owner.blockedUntil === undefined ? null : new Date(ret.owner.blockedUntil + "Z"),
            basePopularity: ret.owner.basePopularity || 0,
            documentsVerified: !!ret.owner.documentsVerified,
        },
        calendar: ret.calendar.map((e: any) => ({
            start: new Date(parseInt(e.start.split("-")[0], 10), parseInt(e.start.split("-")[1], 10) - 1, parseInt(e.start.split("-")[2], 10)),
            end: new Date(parseInt(e.end.split("-")[0], 10), parseInt(e.end.split("-")[1], 10) - 1, parseInt(e.end.split("-")[2], 10)),
        })),
        createdAt: new Date(ret.createdAt + "Z"),
        department: ret.department,
        adjustment: !!ret.adjustment,
        category: ret.category === null || ret.category === undefined ? null : ret.category,
        color: ret.color === null || ret.color === undefined ? null : ret.color,
        details: ret.details,
        gender: ret.gender,
        thumbImage: {
            width: ret.thumbImage.width || 0,
            height: ret.thumbImage.height || 0,
            url: ret.thumbImage.url,
        },
        images: ret.images.map((e: any) => ({
            width: e.width || 0,
            height: e.height || 0,
            url: e.url,
        })),
        brand: ret.brand === null || ret.brand === undefined ? null : ret.brand,
        ocasions: ret.ocasions.map((e: any) => e === null || e === undefined ? null : e),
        size: ret.size === null || ret.size === undefined ? null : ret.size,
        rentPrice: ret.rentPrice === null || ret.rentPrice === undefined ? null : ret.rentPrice || 0,
        salePrice: ret.salePrice === null || ret.salePrice === undefined ? null : ret.salePrice || 0,
        originalPrice: ret.originalPrice || 0,
    };
}

export async function getUser(userId: string, progress?: (progress: number) => void): Promise<User> {
    const args = {
        userId: userId,
    };
    const ret = await makeRequest({name: "getUser", args, progress});
    return {
        id: ret.id,
        gender: ret.gender === null || ret.gender === undefined ? null : ret.gender,
        birthday: ret.birthday === null || ret.birthday === undefined ? null : new Date(parseInt(ret.birthday.split("-")[0], 10), parseInt(ret.birthday.split("-")[1], 10) - 1, parseInt(ret.birthday.split("-")[2], 10)),
        type: ret.type,
        name: ret.name,
        nick: ret.nick,
        cpf: ret.cpf,
        email: ret.email,
        phone: {
            ddd: ret.phone.ddd,
            ddi: ret.phone.ddi,
            number: ret.phone.number,
        },
        avatar: ret.avatar === null || ret.avatar === undefined ? null : {
            width: ret.avatar.width || 0,
            height: ret.avatar.height || 0,
            url: ret.avatar.url,
        },
        address: ret.address === null || ret.address === undefined ? null : {
            street: ret.address.street,
            neighborhood: ret.address.neighborhood,
            city: ret.address.city,
            uf: ret.address.uf,
            country: ret.address.country,
            number: ret.address.number,
            zip: ret.address.zip,
            adicionalInfo: ret.address.adicionalInfo,
            reference: ret.address.reference,
            location: {
                lat: ret.address.location.lat,
                lon: ret.address.location.lon,
            },
        },
        createdAt: new Date(ret.createdAt + "Z"),
        deletedAt: ret.deletedAt === null || ret.deletedAt === undefined ? null : new Date(ret.deletedAt + "Z"),
        blockedUntil: ret.blockedUntil === null || ret.blockedUntil === undefined ? null : new Date(ret.blockedUntil + "Z"),
        basePopularity: ret.basePopularity || 0,
        documentsVerified: !!ret.documentsVerified,
    };
}

export async function getUsersReportForAdmin(filter: UsersFilter | null, progress?: (progress: number) => void): Promise<string> {
    const args = {
        filter: filter === null || filter === undefined ? null : {
            createdAtUserDate: filter.createdAtUserDate === null || filter.createdAtUserDate === undefined ? null : {
                startDate: filter.createdAtUserDate.startDate === null || filter.createdAtUserDate.startDate === undefined ? null : typeof(filter.createdAtUserDate.startDate) === "string" ? new Date(new Date(filter.createdAtUserDate.startDate).getTime() - new Date(filter.createdAtUserDate.startDate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(filter.createdAtUserDate.startDate.getTime() - filter.createdAtUserDate.startDate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
                endDate: filter.createdAtUserDate.endDate === null || filter.createdAtUserDate.endDate === undefined ? null : typeof(filter.createdAtUserDate.endDate) === "string" ? new Date(new Date(filter.createdAtUserDate.endDate).getTime() - new Date(filter.createdAtUserDate.endDate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(filter.createdAtUserDate.endDate.getTime() - filter.createdAtUserDate.endDate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
            },
            orderBy: filter.orderBy === null || filter.orderBy === undefined ? null : {
                field: filter.orderBy.field,
                order: filter.orderBy.order,
            },
            search: filter.search === null || filter.search === undefined ? null : filter.search,
            topLoc: filter.topLoc === null || filter.topLoc === undefined ? null : filter.topLoc,
        },
    };
    const ret = await makeRequest({name: "getUsersReportForAdmin", args, progress});
    return ret;
}

export async function getUsersForAdmin(page: number | null, filter: UsersFilter | null, progress?: (progress: number) => void): Promise<User[]> {
    const args = {
        page: page === null || page === undefined ? null : page || 0,
        filter: filter === null || filter === undefined ? null : {
            createdAtUserDate: filter.createdAtUserDate === null || filter.createdAtUserDate === undefined ? null : {
                startDate: filter.createdAtUserDate.startDate === null || filter.createdAtUserDate.startDate === undefined ? null : typeof(filter.createdAtUserDate.startDate) === "string" ? new Date(new Date(filter.createdAtUserDate.startDate).getTime() - new Date(filter.createdAtUserDate.startDate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(filter.createdAtUserDate.startDate.getTime() - filter.createdAtUserDate.startDate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
                endDate: filter.createdAtUserDate.endDate === null || filter.createdAtUserDate.endDate === undefined ? null : typeof(filter.createdAtUserDate.endDate) === "string" ? new Date(new Date(filter.createdAtUserDate.endDate).getTime() - new Date(filter.createdAtUserDate.endDate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(filter.createdAtUserDate.endDate.getTime() - filter.createdAtUserDate.endDate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
            },
            orderBy: filter.orderBy === null || filter.orderBy === undefined ? null : {
                field: filter.orderBy.field,
                order: filter.orderBy.order,
            },
            search: filter.search === null || filter.search === undefined ? null : filter.search,
            topLoc: filter.topLoc === null || filter.topLoc === undefined ? null : filter.topLoc,
        },
    };
    const ret = await makeRequest({name: "getUsersForAdmin", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        gender: e.gender === null || e.gender === undefined ? null : e.gender,
        birthday: e.birthday === null || e.birthday === undefined ? null : new Date(parseInt(e.birthday.split("-")[0], 10), parseInt(e.birthday.split("-")[1], 10) - 1, parseInt(e.birthday.split("-")[2], 10)),
        type: e.type,
        name: e.name,
        nick: e.nick,
        cpf: e.cpf,
        email: e.email,
        phone: {
            ddd: e.phone.ddd,
            ddi: e.phone.ddi,
            number: e.phone.number,
        },
        avatar: e.avatar === null || e.avatar === undefined ? null : {
            width: e.avatar.width || 0,
            height: e.avatar.height || 0,
            url: e.avatar.url,
        },
        address: e.address === null || e.address === undefined ? null : {
            street: e.address.street,
            neighborhood: e.address.neighborhood,
            city: e.address.city,
            uf: e.address.uf,
            country: e.address.country,
            number: e.address.number,
            zip: e.address.zip,
            adicionalInfo: e.address.adicionalInfo,
            reference: e.address.reference,
            location: {
                lat: e.address.location.lat,
                lon: e.address.location.lon,
            },
        },
        createdAt: new Date(e.createdAt + "Z"),
        deletedAt: e.deletedAt === null || e.deletedAt === undefined ? null : new Date(e.deletedAt + "Z"),
        blockedUntil: e.blockedUntil === null || e.blockedUntil === undefined ? null : new Date(e.blockedUntil + "Z"),
        basePopularity: e.basePopularity || 0,
        documentsVerified: !!e.documentsVerified,
    }));
}

export async function getUsersStats(filter: UsersFilter | null, progress?: (progress: number) => void): Promise<UserStats> {
    const args = {
        filter: filter === null || filter === undefined ? null : {
            createdAtUserDate: filter.createdAtUserDate === null || filter.createdAtUserDate === undefined ? null : {
                startDate: filter.createdAtUserDate.startDate === null || filter.createdAtUserDate.startDate === undefined ? null : typeof(filter.createdAtUserDate.startDate) === "string" ? new Date(new Date(filter.createdAtUserDate.startDate).getTime() - new Date(filter.createdAtUserDate.startDate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(filter.createdAtUserDate.startDate.getTime() - filter.createdAtUserDate.startDate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
                endDate: filter.createdAtUserDate.endDate === null || filter.createdAtUserDate.endDate === undefined ? null : typeof(filter.createdAtUserDate.endDate) === "string" ? new Date(new Date(filter.createdAtUserDate.endDate).getTime() - new Date(filter.createdAtUserDate.endDate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(filter.createdAtUserDate.endDate.getTime() - filter.createdAtUserDate.endDate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
            },
            orderBy: filter.orderBy === null || filter.orderBy === undefined ? null : {
                field: filter.orderBy.field,
                order: filter.orderBy.order,
            },
            search: filter.search === null || filter.search === undefined ? null : filter.search,
            topLoc: filter.topLoc === null || filter.topLoc === undefined ? null : filter.topLoc,
        },
    };
    const ret = await makeRequest({name: "getUsersStats", args, progress});
    return {
        total: ret.total || 0,
    };
}

export async function getTopTeenUsers(progress?: (progress: number) => void): Promise<TopTeenUsers> {
    const ret = await makeRequest({name: "getTopTeenUsers", args: {}, progress});
    return {
        topRent: ret.topRent.map((e: any) => ({
            user: {
                id: e.user.id,
                gender: e.user.gender === null || e.user.gender === undefined ? null : e.user.gender,
                birthday: e.user.birthday === null || e.user.birthday === undefined ? null : new Date(parseInt(e.user.birthday.split("-")[0], 10), parseInt(e.user.birthday.split("-")[1], 10) - 1, parseInt(e.user.birthday.split("-")[2], 10)),
                type: e.user.type,
                name: e.user.name,
                nick: e.user.nick,
                cpf: e.user.cpf,
                email: e.user.email,
                phone: {
                    ddd: e.user.phone.ddd,
                    ddi: e.user.phone.ddi,
                    number: e.user.phone.number,
                },
                avatar: e.user.avatar === null || e.user.avatar === undefined ? null : {
                    width: e.user.avatar.width || 0,
                    height: e.user.avatar.height || 0,
                    url: e.user.avatar.url,
                },
                address: e.user.address === null || e.user.address === undefined ? null : {
                    street: e.user.address.street,
                    neighborhood: e.user.address.neighborhood,
                    city: e.user.address.city,
                    uf: e.user.address.uf,
                    country: e.user.address.country,
                    number: e.user.address.number,
                    zip: e.user.address.zip,
                    adicionalInfo: e.user.address.adicionalInfo,
                    reference: e.user.address.reference,
                    location: {
                        lat: e.user.address.location.lat,
                        lon: e.user.address.location.lon,
                    },
                },
                createdAt: new Date(e.user.createdAt + "Z"),
                deletedAt: e.user.deletedAt === null || e.user.deletedAt === undefined ? null : new Date(e.user.deletedAt + "Z"),
                blockedUntil: e.user.blockedUntil === null || e.user.blockedUntil === undefined ? null : new Date(e.user.blockedUntil + "Z"),
                basePopularity: e.user.basePopularity || 0,
                documentsVerified: !!e.user.documentsVerified,
            },
            amount: e.amount || 0,
        })),
        topRented: ret.topRented.map((e: any) => ({
            user: {
                id: e.user.id,
                gender: e.user.gender === null || e.user.gender === undefined ? null : e.user.gender,
                birthday: e.user.birthday === null || e.user.birthday === undefined ? null : new Date(parseInt(e.user.birthday.split("-")[0], 10), parseInt(e.user.birthday.split("-")[1], 10) - 1, parseInt(e.user.birthday.split("-")[2], 10)),
                type: e.user.type,
                name: e.user.name,
                nick: e.user.nick,
                cpf: e.user.cpf,
                email: e.user.email,
                phone: {
                    ddd: e.user.phone.ddd,
                    ddi: e.user.phone.ddi,
                    number: e.user.phone.number,
                },
                avatar: e.user.avatar === null || e.user.avatar === undefined ? null : {
                    width: e.user.avatar.width || 0,
                    height: e.user.avatar.height || 0,
                    url: e.user.avatar.url,
                },
                address: e.user.address === null || e.user.address === undefined ? null : {
                    street: e.user.address.street,
                    neighborhood: e.user.address.neighborhood,
                    city: e.user.address.city,
                    uf: e.user.address.uf,
                    country: e.user.address.country,
                    number: e.user.address.number,
                    zip: e.user.address.zip,
                    adicionalInfo: e.user.address.adicionalInfo,
                    reference: e.user.address.reference,
                    location: {
                        lat: e.user.address.location.lat,
                        lon: e.user.address.location.lon,
                    },
                },
                createdAt: new Date(e.user.createdAt + "Z"),
                deletedAt: e.user.deletedAt === null || e.user.deletedAt === undefined ? null : new Date(e.user.deletedAt + "Z"),
                blockedUntil: e.user.blockedUntil === null || e.user.blockedUntil === undefined ? null : new Date(e.user.blockedUntil + "Z"),
                basePopularity: e.user.basePopularity || 0,
                documentsVerified: !!e.user.documentsVerified,
            },
            amount: e.amount || 0,
        })),
        topSeller: ret.topSeller.map((e: any) => ({
            user: {
                id: e.user.id,
                gender: e.user.gender === null || e.user.gender === undefined ? null : e.user.gender,
                birthday: e.user.birthday === null || e.user.birthday === undefined ? null : new Date(parseInt(e.user.birthday.split("-")[0], 10), parseInt(e.user.birthday.split("-")[1], 10) - 1, parseInt(e.user.birthday.split("-")[2], 10)),
                type: e.user.type,
                name: e.user.name,
                nick: e.user.nick,
                cpf: e.user.cpf,
                email: e.user.email,
                phone: {
                    ddd: e.user.phone.ddd,
                    ddi: e.user.phone.ddi,
                    number: e.user.phone.number,
                },
                avatar: e.user.avatar === null || e.user.avatar === undefined ? null : {
                    width: e.user.avatar.width || 0,
                    height: e.user.avatar.height || 0,
                    url: e.user.avatar.url,
                },
                address: e.user.address === null || e.user.address === undefined ? null : {
                    street: e.user.address.street,
                    neighborhood: e.user.address.neighborhood,
                    city: e.user.address.city,
                    uf: e.user.address.uf,
                    country: e.user.address.country,
                    number: e.user.address.number,
                    zip: e.user.address.zip,
                    adicionalInfo: e.user.address.adicionalInfo,
                    reference: e.user.address.reference,
                    location: {
                        lat: e.user.address.location.lat,
                        lon: e.user.address.location.lon,
                    },
                },
                createdAt: new Date(e.user.createdAt + "Z"),
                deletedAt: e.user.deletedAt === null || e.user.deletedAt === undefined ? null : new Date(e.user.deletedAt + "Z"),
                blockedUntil: e.user.blockedUntil === null || e.user.blockedUntil === undefined ? null : new Date(e.user.blockedUntil + "Z"),
                basePopularity: e.user.basePopularity || 0,
                documentsVerified: !!e.user.documentsVerified,
            },
            amount: e.amount || 0,
        })),
        topBuyer: ret.topBuyer.map((e: any) => ({
            user: {
                id: e.user.id,
                gender: e.user.gender === null || e.user.gender === undefined ? null : e.user.gender,
                birthday: e.user.birthday === null || e.user.birthday === undefined ? null : new Date(parseInt(e.user.birthday.split("-")[0], 10), parseInt(e.user.birthday.split("-")[1], 10) - 1, parseInt(e.user.birthday.split("-")[2], 10)),
                type: e.user.type,
                name: e.user.name,
                nick: e.user.nick,
                cpf: e.user.cpf,
                email: e.user.email,
                phone: {
                    ddd: e.user.phone.ddd,
                    ddi: e.user.phone.ddi,
                    number: e.user.phone.number,
                },
                avatar: e.user.avatar === null || e.user.avatar === undefined ? null : {
                    width: e.user.avatar.width || 0,
                    height: e.user.avatar.height || 0,
                    url: e.user.avatar.url,
                },
                address: e.user.address === null || e.user.address === undefined ? null : {
                    street: e.user.address.street,
                    neighborhood: e.user.address.neighborhood,
                    city: e.user.address.city,
                    uf: e.user.address.uf,
                    country: e.user.address.country,
                    number: e.user.address.number,
                    zip: e.user.address.zip,
                    adicionalInfo: e.user.address.adicionalInfo,
                    reference: e.user.address.reference,
                    location: {
                        lat: e.user.address.location.lat,
                        lon: e.user.address.location.lon,
                    },
                },
                createdAt: new Date(e.user.createdAt + "Z"),
                deletedAt: e.user.deletedAt === null || e.user.deletedAt === undefined ? null : new Date(e.user.deletedAt + "Z"),
                blockedUntil: e.user.blockedUntil === null || e.user.blockedUntil === undefined ? null : new Date(e.user.blockedUntil + "Z"),
                basePopularity: e.user.basePopularity || 0,
                documentsVerified: !!e.user.documentsVerified,
            },
            amount: e.amount || 0,
        })),
    };
}

export async function editUser(userId: string, editedUser: EditUser, progress?: (progress: number) => void): Promise<User> {
    const args = {
        userId: userId,
        editedUser: {
            nick: editedUser.nick,
            name: editedUser.name,
            email: editedUser.email,
            phone: {
                ddd: editedUser.phone.ddd,
                ddi: editedUser.phone.ddi,
                number: editedUser.phone.number,
            },
            gender: editedUser.gender === null || editedUser.gender === undefined ? null : editedUser.gender,
            type: editedUser.type,
            blockedUntil: editedUser.blockedUntil === null || editedUser.blockedUntil === undefined ? null : (typeof editedUser.blockedUntil === "string" && (editedUser.blockedUntil as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (editedUser.blockedUntil as any).replace("Z", "") : editedUser.blockedUntil.toISOString().replace("Z", "")),
            basePopularity: editedUser.basePopularity || 0,
            documentsVerified: !!editedUser.documentsVerified,
        },
    };
    const ret = await makeRequest({name: "editUser", args, progress});
    return {
        id: ret.id,
        gender: ret.gender === null || ret.gender === undefined ? null : ret.gender,
        birthday: ret.birthday === null || ret.birthday === undefined ? null : new Date(parseInt(ret.birthday.split("-")[0], 10), parseInt(ret.birthday.split("-")[1], 10) - 1, parseInt(ret.birthday.split("-")[2], 10)),
        type: ret.type,
        name: ret.name,
        nick: ret.nick,
        cpf: ret.cpf,
        email: ret.email,
        phone: {
            ddd: ret.phone.ddd,
            ddi: ret.phone.ddi,
            number: ret.phone.number,
        },
        avatar: ret.avatar === null || ret.avatar === undefined ? null : {
            width: ret.avatar.width || 0,
            height: ret.avatar.height || 0,
            url: ret.avatar.url,
        },
        address: ret.address === null || ret.address === undefined ? null : {
            street: ret.address.street,
            neighborhood: ret.address.neighborhood,
            city: ret.address.city,
            uf: ret.address.uf,
            country: ret.address.country,
            number: ret.address.number,
            zip: ret.address.zip,
            adicionalInfo: ret.address.adicionalInfo,
            reference: ret.address.reference,
            location: {
                lat: ret.address.location.lat,
                lon: ret.address.location.lon,
            },
        },
        createdAt: new Date(ret.createdAt + "Z"),
        deletedAt: ret.deletedAt === null || ret.deletedAt === undefined ? null : new Date(ret.deletedAt + "Z"),
        blockedUntil: ret.blockedUntil === null || ret.blockedUntil === undefined ? null : new Date(ret.blockedUntil + "Z"),
        basePopularity: ret.basePopularity || 0,
        documentsVerified: !!ret.documentsVerified,
    };
}

export async function getItems(page: number | null, filter: ItemFilter | null, progress?: (progress: number) => void): Promise<Item[]> {
    const args = {
        page: page === null || page === undefined ? null : page || 0,
        filter: filter === null || filter === undefined ? null : {
            createdAtRentDate: filter.createdAtRentDate === null || filter.createdAtRentDate === undefined ? null : {
                startDate: filter.createdAtRentDate.startDate === null || filter.createdAtRentDate.startDate === undefined ? null : typeof(filter.createdAtRentDate.startDate) === "string" ? new Date(new Date(filter.createdAtRentDate.startDate).getTime() - new Date(filter.createdAtRentDate.startDate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(filter.createdAtRentDate.startDate.getTime() - filter.createdAtRentDate.startDate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
                endDate: filter.createdAtRentDate.endDate === null || filter.createdAtRentDate.endDate === undefined ? null : typeof(filter.createdAtRentDate.endDate) === "string" ? new Date(new Date(filter.createdAtRentDate.endDate).getTime() - new Date(filter.createdAtRentDate.endDate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(filter.createdAtRentDate.endDate.getTime() - filter.createdAtRentDate.endDate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
            },
            orderBy: filter.orderBy === null || filter.orderBy === undefined ? null : {
                field: filter.orderBy.field,
                order: filter.orderBy.order,
            },
        },
    };
    const ret = await makeRequest({name: "getItems", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        owner: {
            id: e.owner.id,
            gender: e.owner.gender === null || e.owner.gender === undefined ? null : e.owner.gender,
            birthday: e.owner.birthday === null || e.owner.birthday === undefined ? null : new Date(parseInt(e.owner.birthday.split("-")[0], 10), parseInt(e.owner.birthday.split("-")[1], 10) - 1, parseInt(e.owner.birthday.split("-")[2], 10)),
            type: e.owner.type,
            name: e.owner.name,
            nick: e.owner.nick,
            cpf: e.owner.cpf,
            email: e.owner.email,
            phone: {
                ddd: e.owner.phone.ddd,
                ddi: e.owner.phone.ddi,
                number: e.owner.phone.number,
            },
            avatar: e.owner.avatar === null || e.owner.avatar === undefined ? null : {
                width: e.owner.avatar.width || 0,
                height: e.owner.avatar.height || 0,
                url: e.owner.avatar.url,
            },
            address: e.owner.address === null || e.owner.address === undefined ? null : {
                street: e.owner.address.street,
                neighborhood: e.owner.address.neighborhood,
                city: e.owner.address.city,
                uf: e.owner.address.uf,
                country: e.owner.address.country,
                number: e.owner.address.number,
                zip: e.owner.address.zip,
                adicionalInfo: e.owner.address.adicionalInfo,
                reference: e.owner.address.reference,
                location: {
                    lat: e.owner.address.location.lat,
                    lon: e.owner.address.location.lon,
                },
            },
            createdAt: new Date(e.owner.createdAt + "Z"),
            deletedAt: e.owner.deletedAt === null || e.owner.deletedAt === undefined ? null : new Date(e.owner.deletedAt + "Z"),
            blockedUntil: e.owner.blockedUntil === null || e.owner.blockedUntil === undefined ? null : new Date(e.owner.blockedUntil + "Z"),
            basePopularity: e.owner.basePopularity || 0,
            documentsVerified: !!e.owner.documentsVerified,
        },
        calendar: e.calendar.map((e: any) => ({
            start: new Date(parseInt(e.start.split("-")[0], 10), parseInt(e.start.split("-")[1], 10) - 1, parseInt(e.start.split("-")[2], 10)),
            end: new Date(parseInt(e.end.split("-")[0], 10), parseInt(e.end.split("-")[1], 10) - 1, parseInt(e.end.split("-")[2], 10)),
        })),
        createdAt: new Date(e.createdAt + "Z"),
        department: e.department,
        adjustment: !!e.adjustment,
        category: e.category === null || e.category === undefined ? null : e.category,
        color: e.color === null || e.color === undefined ? null : e.color,
        details: e.details,
        gender: e.gender,
        thumbImage: {
            width: e.thumbImage.width || 0,
            height: e.thumbImage.height || 0,
            url: e.thumbImage.url,
        },
        images: e.images.map((e: any) => ({
            width: e.width || 0,
            height: e.height || 0,
            url: e.url,
        })),
        brand: e.brand === null || e.brand === undefined ? null : e.brand,
        ocasions: e.ocasions.map((e: any) => e === null || e === undefined ? null : e),
        size: e.size === null || e.size === undefined ? null : e.size,
        rentPrice: e.rentPrice === null || e.rentPrice === undefined ? null : e.rentPrice || 0,
        salePrice: e.salePrice === null || e.salePrice === undefined ? null : e.salePrice || 0,
        originalPrice: e.originalPrice || 0,
    }));
}

export async function getItemsStats(filter: ItemFilter | null, progress?: (progress: number) => void): Promise<ItemStats> {
    const args = {
        filter: filter === null || filter === undefined ? null : {
            createdAtRentDate: filter.createdAtRentDate === null || filter.createdAtRentDate === undefined ? null : {
                startDate: filter.createdAtRentDate.startDate === null || filter.createdAtRentDate.startDate === undefined ? null : typeof(filter.createdAtRentDate.startDate) === "string" ? new Date(new Date(filter.createdAtRentDate.startDate).getTime() - new Date(filter.createdAtRentDate.startDate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(filter.createdAtRentDate.startDate.getTime() - filter.createdAtRentDate.startDate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
                endDate: filter.createdAtRentDate.endDate === null || filter.createdAtRentDate.endDate === undefined ? null : typeof(filter.createdAtRentDate.endDate) === "string" ? new Date(new Date(filter.createdAtRentDate.endDate).getTime() - new Date(filter.createdAtRentDate.endDate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(filter.createdAtRentDate.endDate.getTime() - filter.createdAtRentDate.endDate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
            },
            orderBy: filter.orderBy === null || filter.orderBy === undefined ? null : {
                field: filter.orderBy.field,
                order: filter.orderBy.order,
            },
        },
    };
    const ret = await makeRequest({name: "getItemsStats", args, progress});
    return {
        totalAmount: ret.totalAmount || 0,
        averageRentPrice: ret.averageRentPrice || 0,
        averageSalePrice: ret.averageSalePrice || 0,
    };
}

export async function getItemsReportForAdmin(filter: ItemFilter, progress?: (progress: number) => void): Promise<string> {
    const args = {
        filter: {
            createdAtRentDate: filter.createdAtRentDate === null || filter.createdAtRentDate === undefined ? null : {
                startDate: filter.createdAtRentDate.startDate === null || filter.createdAtRentDate.startDate === undefined ? null : typeof(filter.createdAtRentDate.startDate) === "string" ? new Date(new Date(filter.createdAtRentDate.startDate).getTime() - new Date(filter.createdAtRentDate.startDate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(filter.createdAtRentDate.startDate.getTime() - filter.createdAtRentDate.startDate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
                endDate: filter.createdAtRentDate.endDate === null || filter.createdAtRentDate.endDate === undefined ? null : typeof(filter.createdAtRentDate.endDate) === "string" ? new Date(new Date(filter.createdAtRentDate.endDate).getTime() - new Date(filter.createdAtRentDate.endDate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(filter.createdAtRentDate.endDate.getTime() - filter.createdAtRentDate.endDate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
            },
            orderBy: filter.orderBy === null || filter.orderBy === undefined ? null : {
                field: filter.orderBy.field,
                order: filter.orderBy.order,
            },
        },
    };
    const ret = await makeRequest({name: "getItemsReportForAdmin", args, progress});
    return ret;
}

export async function getAllComment(pageOffset: number, progress?: (progress: number) => void): Promise<PostComment[]> {
    const args = {
        pageOffset: pageOffset || 0,
    };
    const ret = await makeRequest({name: "getAllComment", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        text: e.text,
        userInfo: {
            id: e.userInfo.id,
            avatar: e.userInfo.avatar === null || e.userInfo.avatar === undefined ? null : {
                width: e.userInfo.avatar.width || 0,
                height: e.userInfo.avatar.height || 0,
                url: e.userInfo.avatar.url,
            },
            name: e.userInfo.name,
            nick: e.userInfo.nick,
            rate: e.userInfo.rate,
            height: e.userInfo.height === null || e.userInfo.height === undefined ? null : e.userInfo.height || 0,
            deleted: !!e.userInfo.deleted,
            type: e.userInfo.type,
            isLockerLiked: !!e.userInfo.isLockerLiked,
            isFollowing: !!e.userInfo.isFollowing,
            ownerName: e.userInfo.ownerName === null || e.userInfo.ownerName === undefined ? null : e.userInfo.ownerName,
        },
        postId: e.postId,
        createdAt: new Date(e.createdAt + "Z"),
    }));
}

export async function getCurrentAdminUser(progress?: (progress: number) => void): Promise<AdminUser> {
    const ret = await makeRequest({name: "getCurrentAdminUser", args: {}, progress});
    return {
        id: ret.id,
        licensedInfo: ret.licensedInfo === null || ret.licensedInfo === undefined ? null : {
            document: ret.licensedInfo.document,
            bankAccount: {
                documentNumber: ret.licensedInfo.bankAccount.documentNumber,
                bankName: ret.licensedInfo.bankAccount.bankName,
                bankCode: ret.licensedInfo.bankAccount.bankCode,
                agency: ret.licensedInfo.bankAccount.agency,
                agencyDv: ret.licensedInfo.bankAccount.agencyDv === null || ret.licensedInfo.bankAccount.agencyDv === undefined ? null : ret.licensedInfo.bankAccount.agencyDv,
                account: ret.licensedInfo.bankAccount.account,
                accountDv: ret.licensedInfo.bankAccount.accountDv,
                type: ret.licensedInfo.bankAccount.type,
                legalName: ret.licensedInfo.bankAccount.legalName,
            },
            licenses: ret.licensedInfo.licenses.map((e: any) => ({
                city: {
                    id: e.city.id,
                    name: e.city.name,
                    uf: e.city.uf,
                },
                percentage: e.percentage || 0,
                expirationDate: new Date(parseInt(e.expirationDate.split("-")[0], 10), parseInt(e.expirationDate.split("-")[1], 10) - 1, parseInt(e.expirationDate.split("-")[2], 10)),
            })),
        },
        type: ret.type,
        name: ret.name,
        email: ret.email,
    };
}

export async function login(email: string, password: string, progress?: (progress: number) => void): Promise<AdminUser> {
    const args = {
        email: email,
        password: password,
    };
    const ret = await makeRequest({name: "login", args, progress});
    return {
        id: ret.id,
        licensedInfo: ret.licensedInfo === null || ret.licensedInfo === undefined ? null : {
            document: ret.licensedInfo.document,
            bankAccount: {
                documentNumber: ret.licensedInfo.bankAccount.documentNumber,
                bankName: ret.licensedInfo.bankAccount.bankName,
                bankCode: ret.licensedInfo.bankAccount.bankCode,
                agency: ret.licensedInfo.bankAccount.agency,
                agencyDv: ret.licensedInfo.bankAccount.agencyDv === null || ret.licensedInfo.bankAccount.agencyDv === undefined ? null : ret.licensedInfo.bankAccount.agencyDv,
                account: ret.licensedInfo.bankAccount.account,
                accountDv: ret.licensedInfo.bankAccount.accountDv,
                type: ret.licensedInfo.bankAccount.type,
                legalName: ret.licensedInfo.bankAccount.legalName,
            },
            licenses: ret.licensedInfo.licenses.map((e: any) => ({
                city: {
                    id: e.city.id,
                    name: e.city.name,
                    uf: e.city.uf,
                },
                percentage: e.percentage || 0,
                expirationDate: new Date(parseInt(e.expirationDate.split("-")[0], 10), parseInt(e.expirationDate.split("-")[1], 10) - 1, parseInt(e.expirationDate.split("-")[2], 10)),
            })),
        },
        type: ret.type,
        name: ret.name,
        email: ret.email,
    };
}

export async function logout(progress?: (progress: number) => void): Promise<void> {
    await makeRequest({name: "logout", args: {}, progress});
    return undefined;
}

export async function getUsers(search: string | null, deviceType: DeviceType | null, page: number | null, progress?: (progress: number) => void): Promise<User[]> {
    const args = {
        search: search === null || search === undefined ? null : search,
        deviceType: deviceType === null || deviceType === undefined ? null : deviceType,
        page: page === null || page === undefined ? null : page || 0,
    };
    const ret = await makeRequest({name: "getUsers", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        gender: e.gender === null || e.gender === undefined ? null : e.gender,
        birthday: e.birthday === null || e.birthday === undefined ? null : new Date(parseInt(e.birthday.split("-")[0], 10), parseInt(e.birthday.split("-")[1], 10) - 1, parseInt(e.birthday.split("-")[2], 10)),
        type: e.type,
        name: e.name,
        nick: e.nick,
        cpf: e.cpf,
        email: e.email,
        phone: {
            ddd: e.phone.ddd,
            ddi: e.phone.ddi,
            number: e.phone.number,
        },
        avatar: e.avatar === null || e.avatar === undefined ? null : {
            width: e.avatar.width || 0,
            height: e.avatar.height || 0,
            url: e.avatar.url,
        },
        address: e.address === null || e.address === undefined ? null : {
            street: e.address.street,
            neighborhood: e.address.neighborhood,
            city: e.address.city,
            uf: e.address.uf,
            country: e.address.country,
            number: e.address.number,
            zip: e.address.zip,
            adicionalInfo: e.address.adicionalInfo,
            reference: e.address.reference,
            location: {
                lat: e.address.location.lat,
                lon: e.address.location.lon,
            },
        },
        createdAt: new Date(e.createdAt + "Z"),
        deletedAt: e.deletedAt === null || e.deletedAt === undefined ? null : new Date(e.deletedAt + "Z"),
        blockedUntil: e.blockedUntil === null || e.blockedUntil === undefined ? null : new Date(e.blockedUntil + "Z"),
        basePopularity: e.basePopularity || 0,
        documentsVerified: !!e.documentsVerified,
    }));
}

export async function getUsersForAdminUser(search: string | null, topLoc: LocUserType | null, page: number | null, progress?: (progress: number) => void): Promise<User[]> {
    const args = {
        search: search === null || search === undefined ? null : search,
        topLoc: topLoc === null || topLoc === undefined ? null : topLoc,
        page: page === null || page === undefined ? null : page || 0,
    };
    const ret = await makeRequest({name: "getUsersForAdminUser", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        gender: e.gender === null || e.gender === undefined ? null : e.gender,
        birthday: e.birthday === null || e.birthday === undefined ? null : new Date(parseInt(e.birthday.split("-")[0], 10), parseInt(e.birthday.split("-")[1], 10) - 1, parseInt(e.birthday.split("-")[2], 10)),
        type: e.type,
        name: e.name,
        nick: e.nick,
        cpf: e.cpf,
        email: e.email,
        phone: {
            ddd: e.phone.ddd,
            ddi: e.phone.ddi,
            number: e.phone.number,
        },
        avatar: e.avatar === null || e.avatar === undefined ? null : {
            width: e.avatar.width || 0,
            height: e.avatar.height || 0,
            url: e.avatar.url,
        },
        address: e.address === null || e.address === undefined ? null : {
            street: e.address.street,
            neighborhood: e.address.neighborhood,
            city: e.address.city,
            uf: e.address.uf,
            country: e.address.country,
            number: e.address.number,
            zip: e.address.zip,
            adicionalInfo: e.address.adicionalInfo,
            reference: e.address.reference,
            location: {
                lat: e.address.location.lat,
                lon: e.address.location.lon,
            },
        },
        createdAt: new Date(e.createdAt + "Z"),
        deletedAt: e.deletedAt === null || e.deletedAt === undefined ? null : new Date(e.deletedAt + "Z"),
        blockedUntil: e.blockedUntil === null || e.blockedUntil === undefined ? null : new Date(e.blockedUntil + "Z"),
        basePopularity: e.basePopularity || 0,
        documentsVerified: !!e.documentsVerified,
    }));
}

export async function getCurrentUser(search: string | null, deviceType: DeviceType | null, page: number | null, progress?: (progress: number) => void): Promise<User> {
    const args = {
        search: search === null || search === undefined ? null : search,
        deviceType: deviceType === null || deviceType === undefined ? null : deviceType,
        page: page === null || page === undefined ? null : page || 0,
    };
    const ret = await makeRequest({name: "getCurrentUser", args, progress});
    return {
        id: ret.id,
        gender: ret.gender === null || ret.gender === undefined ? null : ret.gender,
        birthday: ret.birthday === null || ret.birthday === undefined ? null : new Date(parseInt(ret.birthday.split("-")[0], 10), parseInt(ret.birthday.split("-")[1], 10) - 1, parseInt(ret.birthday.split("-")[2], 10)),
        type: ret.type,
        name: ret.name,
        nick: ret.nick,
        cpf: ret.cpf,
        email: ret.email,
        phone: {
            ddd: ret.phone.ddd,
            ddi: ret.phone.ddi,
            number: ret.phone.number,
        },
        avatar: ret.avatar === null || ret.avatar === undefined ? null : {
            width: ret.avatar.width || 0,
            height: ret.avatar.height || 0,
            url: ret.avatar.url,
        },
        address: ret.address === null || ret.address === undefined ? null : {
            street: ret.address.street,
            neighborhood: ret.address.neighborhood,
            city: ret.address.city,
            uf: ret.address.uf,
            country: ret.address.country,
            number: ret.address.number,
            zip: ret.address.zip,
            adicionalInfo: ret.address.adicionalInfo,
            reference: ret.address.reference,
            location: {
                lat: ret.address.location.lat,
                lon: ret.address.location.lon,
            },
        },
        createdAt: new Date(ret.createdAt + "Z"),
        deletedAt: ret.deletedAt === null || ret.deletedAt === undefined ? null : new Date(ret.deletedAt + "Z"),
        blockedUntil: ret.blockedUntil === null || ret.blockedUntil === undefined ? null : new Date(ret.blockedUntil + "Z"),
        basePopularity: ret.basePopularity || 0,
        documentsVerified: !!ret.documentsVerified,
    };
}

export async function deleteUser(userId: string, progress?: (progress: number) => void): Promise<void> {
    const args = {
        userId: userId,
    };
    await makeRequest({name: "deleteUser", args, progress});
    return undefined;
}

export async function verifyUser(userId: string, progress?: (progress: number) => void): Promise<void> {
    const args = {
        userId: userId,
    };
    await makeRequest({name: "verifyUser", args, progress});
    return undefined;
}

export async function getRequest(requestId: string, progress?: (progress: number) => void): Promise<Request> {
    const args = {
        requestId: requestId,
    };
    const ret = await makeRequest({name: "getRequest", args, progress});
    return {
        id: ret.id,
        numId: ret.numId,
        owner: {
            id: ret.owner.id,
            gender: ret.owner.gender === null || ret.owner.gender === undefined ? null : ret.owner.gender,
            birthday: ret.owner.birthday === null || ret.owner.birthday === undefined ? null : new Date(parseInt(ret.owner.birthday.split("-")[0], 10), parseInt(ret.owner.birthday.split("-")[1], 10) - 1, parseInt(ret.owner.birthday.split("-")[2], 10)),
            type: ret.owner.type,
            name: ret.owner.name,
            nick: ret.owner.nick,
            cpf: ret.owner.cpf,
            email: ret.owner.email,
            phone: {
                ddd: ret.owner.phone.ddd,
                ddi: ret.owner.phone.ddi,
                number: ret.owner.phone.number,
            },
            avatar: ret.owner.avatar === null || ret.owner.avatar === undefined ? null : {
                width: ret.owner.avatar.width || 0,
                height: ret.owner.avatar.height || 0,
                url: ret.owner.avatar.url,
            },
            address: ret.owner.address === null || ret.owner.address === undefined ? null : {
                street: ret.owner.address.street,
                neighborhood: ret.owner.address.neighborhood,
                city: ret.owner.address.city,
                uf: ret.owner.address.uf,
                country: ret.owner.address.country,
                number: ret.owner.address.number,
                zip: ret.owner.address.zip,
                adicionalInfo: ret.owner.address.adicionalInfo,
                reference: ret.owner.address.reference,
                location: {
                    lat: ret.owner.address.location.lat,
                    lon: ret.owner.address.location.lon,
                },
            },
            createdAt: new Date(ret.owner.createdAt + "Z"),
            deletedAt: ret.owner.deletedAt === null || ret.owner.deletedAt === undefined ? null : new Date(ret.owner.deletedAt + "Z"),
            blockedUntil: ret.owner.blockedUntil === null || ret.owner.blockedUntil === undefined ? null : new Date(ret.owner.blockedUntil + "Z"),
            basePopularity: ret.owner.basePopularity || 0,
            documentsVerified: !!ret.owner.documentsVerified,
        },
        requester: {
            id: ret.requester.id,
            gender: ret.requester.gender === null || ret.requester.gender === undefined ? null : ret.requester.gender,
            birthday: ret.requester.birthday === null || ret.requester.birthday === undefined ? null : new Date(parseInt(ret.requester.birthday.split("-")[0], 10), parseInt(ret.requester.birthday.split("-")[1], 10) - 1, parseInt(ret.requester.birthday.split("-")[2], 10)),
            type: ret.requester.type,
            name: ret.requester.name,
            nick: ret.requester.nick,
            cpf: ret.requester.cpf,
            email: ret.requester.email,
            phone: {
                ddd: ret.requester.phone.ddd,
                ddi: ret.requester.phone.ddi,
                number: ret.requester.phone.number,
            },
            avatar: ret.requester.avatar === null || ret.requester.avatar === undefined ? null : {
                width: ret.requester.avatar.width || 0,
                height: ret.requester.avatar.height || 0,
                url: ret.requester.avatar.url,
            },
            address: ret.requester.address === null || ret.requester.address === undefined ? null : {
                street: ret.requester.address.street,
                neighborhood: ret.requester.address.neighborhood,
                city: ret.requester.address.city,
                uf: ret.requester.address.uf,
                country: ret.requester.address.country,
                number: ret.requester.address.number,
                zip: ret.requester.address.zip,
                adicionalInfo: ret.requester.address.adicionalInfo,
                reference: ret.requester.address.reference,
                location: {
                    lat: ret.requester.address.location.lat,
                    lon: ret.requester.address.location.lon,
                },
            },
            createdAt: new Date(ret.requester.createdAt + "Z"),
            deletedAt: ret.requester.deletedAt === null || ret.requester.deletedAt === undefined ? null : new Date(ret.requester.deletedAt + "Z"),
            blockedUntil: ret.requester.blockedUntil === null || ret.requester.blockedUntil === undefined ? null : new Date(ret.requester.blockedUntil + "Z"),
            basePopularity: ret.requester.basePopularity || 0,
            documentsVerified: !!ret.requester.documentsVerified,
        },
        item: {
            thumbImage: {
                width: ret.item.thumbImage.width || 0,
                height: ret.item.thumbImage.height || 0,
                url: ret.item.thumbImage.url,
            },
            calendar: ret.item.calendar.map((e: any) => ({
                start: new Date(parseInt(e.start.split("-")[0], 10), parseInt(e.start.split("-")[1], 10) - 1, parseInt(e.start.split("-")[2], 10)),
                end: new Date(parseInt(e.end.split("-")[0], 10), parseInt(e.end.split("-")[1], 10) - 1, parseInt(e.end.split("-")[2], 10)),
            })),
            adjustment: !!ret.item.adjustment,
            category: ret.item.category,
            color: ret.item.color,
            details: ret.item.details,
            gender: ret.item.gender,
            images: ret.item.images.map((e: any) => ({
                width: e.width || 0,
                height: e.height || 0,
                url: e.url,
            })),
            brand: ret.item.brand === null || ret.item.brand === undefined ? null : ret.item.brand,
            ocasions: ret.item.ocasions.map((e: any) => e),
            size: ret.item.size,
            salePrice: ret.item.salePrice === null || ret.item.salePrice === undefined ? null : ret.item.salePrice || 0,
            rentPrice: ret.item.rentPrice === null || ret.item.rentPrice === undefined ? null : ret.item.rentPrice || 0,
            originalPrice: ret.item.originalPrice || 0,
        },
        transport: ret.transport === null || ret.transport === undefined ? null : {
            requester: {
                street: ret.transport.requester.street,
                neighborhood: ret.transport.requester.neighborhood,
                city: ret.transport.requester.city,
                uf: ret.transport.requester.uf,
                country: ret.transport.requester.country,
                number: ret.transport.requester.number,
                zip: ret.transport.requester.zip,
                adicionalInfo: ret.transport.requester.adicionalInfo,
                reference: ret.transport.requester.reference,
                location: {
                    lat: ret.transport.requester.location.lat,
                    lon: ret.transport.requester.location.lon,
                },
            },
            owner: {
                street: ret.transport.owner.street,
                neighborhood: ret.transport.owner.neighborhood,
                city: ret.transport.owner.city,
                uf: ret.transport.owner.uf,
                country: ret.transport.owner.country,
                number: ret.transport.owner.number,
                zip: ret.transport.owner.zip,
                adicionalInfo: ret.transport.owner.adicionalInfo,
                reference: ret.transport.owner.reference,
                location: {
                    lat: ret.transport.owner.location.lat,
                    lon: ret.transport.owner.location.lon,
                },
            },
            price: ret.transport.price || 0,
        },
        period: {
            start: new Date(parseInt(ret.period.start.split("-")[0], 10), parseInt(ret.period.start.split("-")[1], 10) - 1, parseInt(ret.period.start.split("-")[2], 10)),
            end: new Date(parseInt(ret.period.end.split("-")[0], 10), parseInt(ret.period.end.split("-")[1], 10) - 1, parseInt(ret.period.end.split("-")[2], 10)),
        },
        rentPrice: ret.rentPrice || 0,
        originalPrice: ret.originalPrice || 0,
        statusList: ret.statusList.map((e: any) => ({
            status: e.status,
            changeDate: new Date(parseInt(e.changeDate.split("-")[0], 10), parseInt(e.changeDate.split("-")[1], 10) - 1, parseInt(e.changeDate.split("-")[2], 10)),
            actionBy: e.actionBy === null || e.actionBy === undefined ? null : e.actionBy,
            pending: !!e.pending,
        })),
        currentStatus: {
            status: ret.currentStatus.status,
            changeDate: new Date(parseInt(ret.currentStatus.changeDate.split("-")[0], 10), parseInt(ret.currentStatus.changeDate.split("-")[1], 10) - 1, parseInt(ret.currentStatus.changeDate.split("-")[2], 10)),
            actionBy: ret.currentStatus.actionBy === null || ret.currentStatus.actionBy === undefined ? null : ret.currentStatus.actionBy,
            pending: !!ret.currentStatus.pending,
        },
        payment: ret.payment === null || ret.payment === undefined ? null : {
            transactionId: ret.payment.transactionId || 0,
            lastDigits: ret.payment.lastDigits,
            brand: ret.payment.brand,
            paidAmount: ret.payment.paidAmount || 0,
            status: ret.payment.status,
            locSplit: {
                id: ret.payment.locSplit.id,
                recipientId: ret.payment.locSplit.recipientId,
                amount: ret.payment.locSplit.amount || 0,
            },
            ownerSplit: {
                id: ret.payment.ownerSplit.id,
                recipientId: ret.payment.ownerSplit.recipientId,
                amount: ret.payment.ownerSplit.amount || 0,
            },
        },
        promotion: {
            card: ret.promotion.card === null || ret.promotion.card === undefined ? null : {
                transactionId: ret.promotion.card.transactionId || 0,
                lastDigits: ret.promotion.card.lastDigits,
                brand: ret.promotion.card.brand,
                paidAmount: ret.promotion.card.paidAmount || 0,
                status: ret.promotion.card.status,
                locSplit: {
                    id: ret.promotion.card.locSplit.id,
                    recipientId: ret.promotion.card.locSplit.recipientId,
                    amount: ret.promotion.card.locSplit.amount || 0,
                },
                ownerSplit: {
                    id: ret.promotion.card.ownerSplit.id,
                    recipientId: ret.promotion.card.ownerSplit.recipientId,
                    amount: ret.promotion.card.ownerSplit.amount || 0,
                },
            },
            type: ret.promotion.type === null || ret.promotion.type === undefined ? null : ret.promotion.type,
            total: ret.promotion.total || 0,
            id: ret.promotion.id === null || ret.promotion.id === undefined ? null : ret.promotion.id,
        },
        nfe: {
            id: ret.nfe.id === null || ret.nfe.id === undefined ? null : ret.nfe.id,
            number: ret.nfe.number === null || ret.nfe.number === undefined ? null : ret.nfe.number || 0,
            emitedAt: ret.nfe.emitedAt === null || ret.nfe.emitedAt === undefined ? null : new Date(ret.nfe.emitedAt + "Z"),
            canceledAt: ret.nfe.canceledAt === null || ret.nfe.canceledAt === undefined ? null : new Date(ret.nfe.canceledAt + "Z"),
            status: ret.nfe.status === null || ret.nfe.status === undefined ? null : ret.nfe.status,
        },
        createdAt: new Date(ret.createdAt + "Z"),
    };
}

export async function getRequests(page: number | null, filter: RentsFilter | null, progress?: (progress: number) => void): Promise<Request[]> {
    const args = {
        page: page === null || page === undefined ? null : page || 0,
        filter: filter === null || filter === undefined ? null : {
            startRentDate: filter.startRentDate === null || filter.startRentDate === undefined ? null : {
                startDate: filter.startRentDate.startDate === null || filter.startRentDate.startDate === undefined ? null : typeof(filter.startRentDate.startDate) === "string" ? new Date(new Date(filter.startRentDate.startDate).getTime() - new Date(filter.startRentDate.startDate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(filter.startRentDate.startDate.getTime() - filter.startRentDate.startDate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
                endDate: filter.startRentDate.endDate === null || filter.startRentDate.endDate === undefined ? null : typeof(filter.startRentDate.endDate) === "string" ? new Date(new Date(filter.startRentDate.endDate).getTime() - new Date(filter.startRentDate.endDate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(filter.startRentDate.endDate.getTime() - filter.startRentDate.endDate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
            },
            endRentDate: filter.endRentDate === null || filter.endRentDate === undefined ? null : {
                startDate: filter.endRentDate.startDate === null || filter.endRentDate.startDate === undefined ? null : typeof(filter.endRentDate.startDate) === "string" ? new Date(new Date(filter.endRentDate.startDate).getTime() - new Date(filter.endRentDate.startDate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(filter.endRentDate.startDate.getTime() - filter.endRentDate.startDate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
                endDate: filter.endRentDate.endDate === null || filter.endRentDate.endDate === undefined ? null : typeof(filter.endRentDate.endDate) === "string" ? new Date(new Date(filter.endRentDate.endDate).getTime() - new Date(filter.endRentDate.endDate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(filter.endRentDate.endDate.getTime() - filter.endRentDate.endDate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
            },
            createdAtRentDate: filter.createdAtRentDate === null || filter.createdAtRentDate === undefined ? null : {
                startDate: filter.createdAtRentDate.startDate === null || filter.createdAtRentDate.startDate === undefined ? null : typeof(filter.createdAtRentDate.startDate) === "string" ? new Date(new Date(filter.createdAtRentDate.startDate).getTime() - new Date(filter.createdAtRentDate.startDate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(filter.createdAtRentDate.startDate.getTime() - filter.createdAtRentDate.startDate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
                endDate: filter.createdAtRentDate.endDate === null || filter.createdAtRentDate.endDate === undefined ? null : typeof(filter.createdAtRentDate.endDate) === "string" ? new Date(new Date(filter.createdAtRentDate.endDate).getTime() - new Date(filter.createdAtRentDate.endDate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(filter.createdAtRentDate.endDate.getTime() - filter.createdAtRentDate.endDate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
            },
            status: filter.status.map(e => e),
            orderBy: filter.orderBy === null || filter.orderBy === undefined ? null : {
                field: filter.orderBy.field,
                order: filter.orderBy.order,
            },
        },
    };
    const ret = await makeRequest({name: "getRequests", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        numId: e.numId,
        owner: {
            id: e.owner.id,
            gender: e.owner.gender === null || e.owner.gender === undefined ? null : e.owner.gender,
            birthday: e.owner.birthday === null || e.owner.birthday === undefined ? null : new Date(parseInt(e.owner.birthday.split("-")[0], 10), parseInt(e.owner.birthday.split("-")[1], 10) - 1, parseInt(e.owner.birthday.split("-")[2], 10)),
            type: e.owner.type,
            name: e.owner.name,
            nick: e.owner.nick,
            cpf: e.owner.cpf,
            email: e.owner.email,
            phone: {
                ddd: e.owner.phone.ddd,
                ddi: e.owner.phone.ddi,
                number: e.owner.phone.number,
            },
            avatar: e.owner.avatar === null || e.owner.avatar === undefined ? null : {
                width: e.owner.avatar.width || 0,
                height: e.owner.avatar.height || 0,
                url: e.owner.avatar.url,
            },
            address: e.owner.address === null || e.owner.address === undefined ? null : {
                street: e.owner.address.street,
                neighborhood: e.owner.address.neighborhood,
                city: e.owner.address.city,
                uf: e.owner.address.uf,
                country: e.owner.address.country,
                number: e.owner.address.number,
                zip: e.owner.address.zip,
                adicionalInfo: e.owner.address.adicionalInfo,
                reference: e.owner.address.reference,
                location: {
                    lat: e.owner.address.location.lat,
                    lon: e.owner.address.location.lon,
                },
            },
            createdAt: new Date(e.owner.createdAt + "Z"),
            deletedAt: e.owner.deletedAt === null || e.owner.deletedAt === undefined ? null : new Date(e.owner.deletedAt + "Z"),
            blockedUntil: e.owner.blockedUntil === null || e.owner.blockedUntil === undefined ? null : new Date(e.owner.blockedUntil + "Z"),
            basePopularity: e.owner.basePopularity || 0,
            documentsVerified: !!e.owner.documentsVerified,
        },
        requester: {
            id: e.requester.id,
            gender: e.requester.gender === null || e.requester.gender === undefined ? null : e.requester.gender,
            birthday: e.requester.birthday === null || e.requester.birthday === undefined ? null : new Date(parseInt(e.requester.birthday.split("-")[0], 10), parseInt(e.requester.birthday.split("-")[1], 10) - 1, parseInt(e.requester.birthday.split("-")[2], 10)),
            type: e.requester.type,
            name: e.requester.name,
            nick: e.requester.nick,
            cpf: e.requester.cpf,
            email: e.requester.email,
            phone: {
                ddd: e.requester.phone.ddd,
                ddi: e.requester.phone.ddi,
                number: e.requester.phone.number,
            },
            avatar: e.requester.avatar === null || e.requester.avatar === undefined ? null : {
                width: e.requester.avatar.width || 0,
                height: e.requester.avatar.height || 0,
                url: e.requester.avatar.url,
            },
            address: e.requester.address === null || e.requester.address === undefined ? null : {
                street: e.requester.address.street,
                neighborhood: e.requester.address.neighborhood,
                city: e.requester.address.city,
                uf: e.requester.address.uf,
                country: e.requester.address.country,
                number: e.requester.address.number,
                zip: e.requester.address.zip,
                adicionalInfo: e.requester.address.adicionalInfo,
                reference: e.requester.address.reference,
                location: {
                    lat: e.requester.address.location.lat,
                    lon: e.requester.address.location.lon,
                },
            },
            createdAt: new Date(e.requester.createdAt + "Z"),
            deletedAt: e.requester.deletedAt === null || e.requester.deletedAt === undefined ? null : new Date(e.requester.deletedAt + "Z"),
            blockedUntil: e.requester.blockedUntil === null || e.requester.blockedUntil === undefined ? null : new Date(e.requester.blockedUntil + "Z"),
            basePopularity: e.requester.basePopularity || 0,
            documentsVerified: !!e.requester.documentsVerified,
        },
        item: {
            thumbImage: {
                width: e.item.thumbImage.width || 0,
                height: e.item.thumbImage.height || 0,
                url: e.item.thumbImage.url,
            },
            calendar: e.item.calendar.map((e: any) => ({
                start: new Date(parseInt(e.start.split("-")[0], 10), parseInt(e.start.split("-")[1], 10) - 1, parseInt(e.start.split("-")[2], 10)),
                end: new Date(parseInt(e.end.split("-")[0], 10), parseInt(e.end.split("-")[1], 10) - 1, parseInt(e.end.split("-")[2], 10)),
            })),
            adjustment: !!e.item.adjustment,
            category: e.item.category,
            color: e.item.color,
            details: e.item.details,
            gender: e.item.gender,
            images: e.item.images.map((e: any) => ({
                width: e.width || 0,
                height: e.height || 0,
                url: e.url,
            })),
            brand: e.item.brand === null || e.item.brand === undefined ? null : e.item.brand,
            ocasions: e.item.ocasions.map((e: any) => e),
            size: e.item.size,
            salePrice: e.item.salePrice === null || e.item.salePrice === undefined ? null : e.item.salePrice || 0,
            rentPrice: e.item.rentPrice === null || e.item.rentPrice === undefined ? null : e.item.rentPrice || 0,
            originalPrice: e.item.originalPrice || 0,
        },
        transport: e.transport === null || e.transport === undefined ? null : {
            requester: {
                street: e.transport.requester.street,
                neighborhood: e.transport.requester.neighborhood,
                city: e.transport.requester.city,
                uf: e.transport.requester.uf,
                country: e.transport.requester.country,
                number: e.transport.requester.number,
                zip: e.transport.requester.zip,
                adicionalInfo: e.transport.requester.adicionalInfo,
                reference: e.transport.requester.reference,
                location: {
                    lat: e.transport.requester.location.lat,
                    lon: e.transport.requester.location.lon,
                },
            },
            owner: {
                street: e.transport.owner.street,
                neighborhood: e.transport.owner.neighborhood,
                city: e.transport.owner.city,
                uf: e.transport.owner.uf,
                country: e.transport.owner.country,
                number: e.transport.owner.number,
                zip: e.transport.owner.zip,
                adicionalInfo: e.transport.owner.adicionalInfo,
                reference: e.transport.owner.reference,
                location: {
                    lat: e.transport.owner.location.lat,
                    lon: e.transport.owner.location.lon,
                },
            },
            price: e.transport.price || 0,
        },
        period: {
            start: new Date(parseInt(e.period.start.split("-")[0], 10), parseInt(e.period.start.split("-")[1], 10) - 1, parseInt(e.period.start.split("-")[2], 10)),
            end: new Date(parseInt(e.period.end.split("-")[0], 10), parseInt(e.period.end.split("-")[1], 10) - 1, parseInt(e.period.end.split("-")[2], 10)),
        },
        rentPrice: e.rentPrice || 0,
        originalPrice: e.originalPrice || 0,
        statusList: e.statusList.map((e: any) => ({
            status: e.status,
            changeDate: new Date(parseInt(e.changeDate.split("-")[0], 10), parseInt(e.changeDate.split("-")[1], 10) - 1, parseInt(e.changeDate.split("-")[2], 10)),
            actionBy: e.actionBy === null || e.actionBy === undefined ? null : e.actionBy,
            pending: !!e.pending,
        })),
        currentStatus: {
            status: e.currentStatus.status,
            changeDate: new Date(parseInt(e.currentStatus.changeDate.split("-")[0], 10), parseInt(e.currentStatus.changeDate.split("-")[1], 10) - 1, parseInt(e.currentStatus.changeDate.split("-")[2], 10)),
            actionBy: e.currentStatus.actionBy === null || e.currentStatus.actionBy === undefined ? null : e.currentStatus.actionBy,
            pending: !!e.currentStatus.pending,
        },
        payment: e.payment === null || e.payment === undefined ? null : {
            transactionId: e.payment.transactionId || 0,
            lastDigits: e.payment.lastDigits,
            brand: e.payment.brand,
            paidAmount: e.payment.paidAmount || 0,
            status: e.payment.status,
            locSplit: {
                id: e.payment.locSplit.id,
                recipientId: e.payment.locSplit.recipientId,
                amount: e.payment.locSplit.amount || 0,
            },
            ownerSplit: {
                id: e.payment.ownerSplit.id,
                recipientId: e.payment.ownerSplit.recipientId,
                amount: e.payment.ownerSplit.amount || 0,
            },
        },
        promotion: {
            card: e.promotion.card === null || e.promotion.card === undefined ? null : {
                transactionId: e.promotion.card.transactionId || 0,
                lastDigits: e.promotion.card.lastDigits,
                brand: e.promotion.card.brand,
                paidAmount: e.promotion.card.paidAmount || 0,
                status: e.promotion.card.status,
                locSplit: {
                    id: e.promotion.card.locSplit.id,
                    recipientId: e.promotion.card.locSplit.recipientId,
                    amount: e.promotion.card.locSplit.amount || 0,
                },
                ownerSplit: {
                    id: e.promotion.card.ownerSplit.id,
                    recipientId: e.promotion.card.ownerSplit.recipientId,
                    amount: e.promotion.card.ownerSplit.amount || 0,
                },
            },
            type: e.promotion.type === null || e.promotion.type === undefined ? null : e.promotion.type,
            total: e.promotion.total || 0,
            id: e.promotion.id === null || e.promotion.id === undefined ? null : e.promotion.id,
        },
        nfe: {
            id: e.nfe.id === null || e.nfe.id === undefined ? null : e.nfe.id,
            number: e.nfe.number === null || e.nfe.number === undefined ? null : e.nfe.number || 0,
            emitedAt: e.nfe.emitedAt === null || e.nfe.emitedAt === undefined ? null : new Date(e.nfe.emitedAt + "Z"),
            canceledAt: e.nfe.canceledAt === null || e.nfe.canceledAt === undefined ? null : new Date(e.nfe.canceledAt + "Z"),
            status: e.nfe.status === null || e.nfe.status === undefined ? null : e.nfe.status,
        },
        createdAt: new Date(e.createdAt + "Z"),
    }));
}

export async function changeDatesRequest(requestId: string, period: Period, progress?: (progress: number) => void): Promise<Request> {
    const args = {
        requestId: requestId,
        period: {
            start: typeof(period.start) === "string" ? new Date(new Date(period.start).getTime() - new Date(period.start).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(period.start.getTime() - period.start.getTimezoneOffset() * 60000).toISOString().split("T")[0],
            end: typeof(period.end) === "string" ? new Date(new Date(period.end).getTime() - new Date(period.end).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(period.end.getTime() - period.end.getTimezoneOffset() * 60000).toISOString().split("T")[0],
        },
    };
    const ret = await makeRequest({name: "changeDatesRequest", args, progress});
    return {
        id: ret.id,
        numId: ret.numId,
        owner: {
            id: ret.owner.id,
            gender: ret.owner.gender === null || ret.owner.gender === undefined ? null : ret.owner.gender,
            birthday: ret.owner.birthday === null || ret.owner.birthday === undefined ? null : new Date(parseInt(ret.owner.birthday.split("-")[0], 10), parseInt(ret.owner.birthday.split("-")[1], 10) - 1, parseInt(ret.owner.birthday.split("-")[2], 10)),
            type: ret.owner.type,
            name: ret.owner.name,
            nick: ret.owner.nick,
            cpf: ret.owner.cpf,
            email: ret.owner.email,
            phone: {
                ddd: ret.owner.phone.ddd,
                ddi: ret.owner.phone.ddi,
                number: ret.owner.phone.number,
            },
            avatar: ret.owner.avatar === null || ret.owner.avatar === undefined ? null : {
                width: ret.owner.avatar.width || 0,
                height: ret.owner.avatar.height || 0,
                url: ret.owner.avatar.url,
            },
            address: ret.owner.address === null || ret.owner.address === undefined ? null : {
                street: ret.owner.address.street,
                neighborhood: ret.owner.address.neighborhood,
                city: ret.owner.address.city,
                uf: ret.owner.address.uf,
                country: ret.owner.address.country,
                number: ret.owner.address.number,
                zip: ret.owner.address.zip,
                adicionalInfo: ret.owner.address.adicionalInfo,
                reference: ret.owner.address.reference,
                location: {
                    lat: ret.owner.address.location.lat,
                    lon: ret.owner.address.location.lon,
                },
            },
            createdAt: new Date(ret.owner.createdAt + "Z"),
            deletedAt: ret.owner.deletedAt === null || ret.owner.deletedAt === undefined ? null : new Date(ret.owner.deletedAt + "Z"),
            blockedUntil: ret.owner.blockedUntil === null || ret.owner.blockedUntil === undefined ? null : new Date(ret.owner.blockedUntil + "Z"),
            basePopularity: ret.owner.basePopularity || 0,
            documentsVerified: !!ret.owner.documentsVerified,
        },
        requester: {
            id: ret.requester.id,
            gender: ret.requester.gender === null || ret.requester.gender === undefined ? null : ret.requester.gender,
            birthday: ret.requester.birthday === null || ret.requester.birthday === undefined ? null : new Date(parseInt(ret.requester.birthday.split("-")[0], 10), parseInt(ret.requester.birthday.split("-")[1], 10) - 1, parseInt(ret.requester.birthday.split("-")[2], 10)),
            type: ret.requester.type,
            name: ret.requester.name,
            nick: ret.requester.nick,
            cpf: ret.requester.cpf,
            email: ret.requester.email,
            phone: {
                ddd: ret.requester.phone.ddd,
                ddi: ret.requester.phone.ddi,
                number: ret.requester.phone.number,
            },
            avatar: ret.requester.avatar === null || ret.requester.avatar === undefined ? null : {
                width: ret.requester.avatar.width || 0,
                height: ret.requester.avatar.height || 0,
                url: ret.requester.avatar.url,
            },
            address: ret.requester.address === null || ret.requester.address === undefined ? null : {
                street: ret.requester.address.street,
                neighborhood: ret.requester.address.neighborhood,
                city: ret.requester.address.city,
                uf: ret.requester.address.uf,
                country: ret.requester.address.country,
                number: ret.requester.address.number,
                zip: ret.requester.address.zip,
                adicionalInfo: ret.requester.address.adicionalInfo,
                reference: ret.requester.address.reference,
                location: {
                    lat: ret.requester.address.location.lat,
                    lon: ret.requester.address.location.lon,
                },
            },
            createdAt: new Date(ret.requester.createdAt + "Z"),
            deletedAt: ret.requester.deletedAt === null || ret.requester.deletedAt === undefined ? null : new Date(ret.requester.deletedAt + "Z"),
            blockedUntil: ret.requester.blockedUntil === null || ret.requester.blockedUntil === undefined ? null : new Date(ret.requester.blockedUntil + "Z"),
            basePopularity: ret.requester.basePopularity || 0,
            documentsVerified: !!ret.requester.documentsVerified,
        },
        item: {
            thumbImage: {
                width: ret.item.thumbImage.width || 0,
                height: ret.item.thumbImage.height || 0,
                url: ret.item.thumbImage.url,
            },
            calendar: ret.item.calendar.map((e: any) => ({
                start: new Date(parseInt(e.start.split("-")[0], 10), parseInt(e.start.split("-")[1], 10) - 1, parseInt(e.start.split("-")[2], 10)),
                end: new Date(parseInt(e.end.split("-")[0], 10), parseInt(e.end.split("-")[1], 10) - 1, parseInt(e.end.split("-")[2], 10)),
            })),
            adjustment: !!ret.item.adjustment,
            category: ret.item.category,
            color: ret.item.color,
            details: ret.item.details,
            gender: ret.item.gender,
            images: ret.item.images.map((e: any) => ({
                width: e.width || 0,
                height: e.height || 0,
                url: e.url,
            })),
            brand: ret.item.brand === null || ret.item.brand === undefined ? null : ret.item.brand,
            ocasions: ret.item.ocasions.map((e: any) => e),
            size: ret.item.size,
            salePrice: ret.item.salePrice === null || ret.item.salePrice === undefined ? null : ret.item.salePrice || 0,
            rentPrice: ret.item.rentPrice === null || ret.item.rentPrice === undefined ? null : ret.item.rentPrice || 0,
            originalPrice: ret.item.originalPrice || 0,
        },
        transport: ret.transport === null || ret.transport === undefined ? null : {
            requester: {
                street: ret.transport.requester.street,
                neighborhood: ret.transport.requester.neighborhood,
                city: ret.transport.requester.city,
                uf: ret.transport.requester.uf,
                country: ret.transport.requester.country,
                number: ret.transport.requester.number,
                zip: ret.transport.requester.zip,
                adicionalInfo: ret.transport.requester.adicionalInfo,
                reference: ret.transport.requester.reference,
                location: {
                    lat: ret.transport.requester.location.lat,
                    lon: ret.transport.requester.location.lon,
                },
            },
            owner: {
                street: ret.transport.owner.street,
                neighborhood: ret.transport.owner.neighborhood,
                city: ret.transport.owner.city,
                uf: ret.transport.owner.uf,
                country: ret.transport.owner.country,
                number: ret.transport.owner.number,
                zip: ret.transport.owner.zip,
                adicionalInfo: ret.transport.owner.adicionalInfo,
                reference: ret.transport.owner.reference,
                location: {
                    lat: ret.transport.owner.location.lat,
                    lon: ret.transport.owner.location.lon,
                },
            },
            price: ret.transport.price || 0,
        },
        period: {
            start: new Date(parseInt(ret.period.start.split("-")[0], 10), parseInt(ret.period.start.split("-")[1], 10) - 1, parseInt(ret.period.start.split("-")[2], 10)),
            end: new Date(parseInt(ret.period.end.split("-")[0], 10), parseInt(ret.period.end.split("-")[1], 10) - 1, parseInt(ret.period.end.split("-")[2], 10)),
        },
        rentPrice: ret.rentPrice || 0,
        originalPrice: ret.originalPrice || 0,
        statusList: ret.statusList.map((e: any) => ({
            status: e.status,
            changeDate: new Date(parseInt(e.changeDate.split("-")[0], 10), parseInt(e.changeDate.split("-")[1], 10) - 1, parseInt(e.changeDate.split("-")[2], 10)),
            actionBy: e.actionBy === null || e.actionBy === undefined ? null : e.actionBy,
            pending: !!e.pending,
        })),
        currentStatus: {
            status: ret.currentStatus.status,
            changeDate: new Date(parseInt(ret.currentStatus.changeDate.split("-")[0], 10), parseInt(ret.currentStatus.changeDate.split("-")[1], 10) - 1, parseInt(ret.currentStatus.changeDate.split("-")[2], 10)),
            actionBy: ret.currentStatus.actionBy === null || ret.currentStatus.actionBy === undefined ? null : ret.currentStatus.actionBy,
            pending: !!ret.currentStatus.pending,
        },
        payment: ret.payment === null || ret.payment === undefined ? null : {
            transactionId: ret.payment.transactionId || 0,
            lastDigits: ret.payment.lastDigits,
            brand: ret.payment.brand,
            paidAmount: ret.payment.paidAmount || 0,
            status: ret.payment.status,
            locSplit: {
                id: ret.payment.locSplit.id,
                recipientId: ret.payment.locSplit.recipientId,
                amount: ret.payment.locSplit.amount || 0,
            },
            ownerSplit: {
                id: ret.payment.ownerSplit.id,
                recipientId: ret.payment.ownerSplit.recipientId,
                amount: ret.payment.ownerSplit.amount || 0,
            },
        },
        promotion: {
            card: ret.promotion.card === null || ret.promotion.card === undefined ? null : {
                transactionId: ret.promotion.card.transactionId || 0,
                lastDigits: ret.promotion.card.lastDigits,
                brand: ret.promotion.card.brand,
                paidAmount: ret.promotion.card.paidAmount || 0,
                status: ret.promotion.card.status,
                locSplit: {
                    id: ret.promotion.card.locSplit.id,
                    recipientId: ret.promotion.card.locSplit.recipientId,
                    amount: ret.promotion.card.locSplit.amount || 0,
                },
                ownerSplit: {
                    id: ret.promotion.card.ownerSplit.id,
                    recipientId: ret.promotion.card.ownerSplit.recipientId,
                    amount: ret.promotion.card.ownerSplit.amount || 0,
                },
            },
            type: ret.promotion.type === null || ret.promotion.type === undefined ? null : ret.promotion.type,
            total: ret.promotion.total || 0,
            id: ret.promotion.id === null || ret.promotion.id === undefined ? null : ret.promotion.id,
        },
        nfe: {
            id: ret.nfe.id === null || ret.nfe.id === undefined ? null : ret.nfe.id,
            number: ret.nfe.number === null || ret.nfe.number === undefined ? null : ret.nfe.number || 0,
            emitedAt: ret.nfe.emitedAt === null || ret.nfe.emitedAt === undefined ? null : new Date(ret.nfe.emitedAt + "Z"),
            canceledAt: ret.nfe.canceledAt === null || ret.nfe.canceledAt === undefined ? null : new Date(ret.nfe.canceledAt + "Z"),
            status: ret.nfe.status === null || ret.nfe.status === undefined ? null : ret.nfe.status,
        },
        createdAt: new Date(ret.createdAt + "Z"),
    };
}

export async function cancelRequest(requestId: string, progress?: (progress: number) => void): Promise<Request> {
    const args = {
        requestId: requestId,
    };
    const ret = await makeRequest({name: "cancelRequest", args, progress});
    return {
        id: ret.id,
        numId: ret.numId,
        owner: {
            id: ret.owner.id,
            gender: ret.owner.gender === null || ret.owner.gender === undefined ? null : ret.owner.gender,
            birthday: ret.owner.birthday === null || ret.owner.birthday === undefined ? null : new Date(parseInt(ret.owner.birthday.split("-")[0], 10), parseInt(ret.owner.birthday.split("-")[1], 10) - 1, parseInt(ret.owner.birthday.split("-")[2], 10)),
            type: ret.owner.type,
            name: ret.owner.name,
            nick: ret.owner.nick,
            cpf: ret.owner.cpf,
            email: ret.owner.email,
            phone: {
                ddd: ret.owner.phone.ddd,
                ddi: ret.owner.phone.ddi,
                number: ret.owner.phone.number,
            },
            avatar: ret.owner.avatar === null || ret.owner.avatar === undefined ? null : {
                width: ret.owner.avatar.width || 0,
                height: ret.owner.avatar.height || 0,
                url: ret.owner.avatar.url,
            },
            address: ret.owner.address === null || ret.owner.address === undefined ? null : {
                street: ret.owner.address.street,
                neighborhood: ret.owner.address.neighborhood,
                city: ret.owner.address.city,
                uf: ret.owner.address.uf,
                country: ret.owner.address.country,
                number: ret.owner.address.number,
                zip: ret.owner.address.zip,
                adicionalInfo: ret.owner.address.adicionalInfo,
                reference: ret.owner.address.reference,
                location: {
                    lat: ret.owner.address.location.lat,
                    lon: ret.owner.address.location.lon,
                },
            },
            createdAt: new Date(ret.owner.createdAt + "Z"),
            deletedAt: ret.owner.deletedAt === null || ret.owner.deletedAt === undefined ? null : new Date(ret.owner.deletedAt + "Z"),
            blockedUntil: ret.owner.blockedUntil === null || ret.owner.blockedUntil === undefined ? null : new Date(ret.owner.blockedUntil + "Z"),
            basePopularity: ret.owner.basePopularity || 0,
            documentsVerified: !!ret.owner.documentsVerified,
        },
        requester: {
            id: ret.requester.id,
            gender: ret.requester.gender === null || ret.requester.gender === undefined ? null : ret.requester.gender,
            birthday: ret.requester.birthday === null || ret.requester.birthday === undefined ? null : new Date(parseInt(ret.requester.birthday.split("-")[0], 10), parseInt(ret.requester.birthday.split("-")[1], 10) - 1, parseInt(ret.requester.birthday.split("-")[2], 10)),
            type: ret.requester.type,
            name: ret.requester.name,
            nick: ret.requester.nick,
            cpf: ret.requester.cpf,
            email: ret.requester.email,
            phone: {
                ddd: ret.requester.phone.ddd,
                ddi: ret.requester.phone.ddi,
                number: ret.requester.phone.number,
            },
            avatar: ret.requester.avatar === null || ret.requester.avatar === undefined ? null : {
                width: ret.requester.avatar.width || 0,
                height: ret.requester.avatar.height || 0,
                url: ret.requester.avatar.url,
            },
            address: ret.requester.address === null || ret.requester.address === undefined ? null : {
                street: ret.requester.address.street,
                neighborhood: ret.requester.address.neighborhood,
                city: ret.requester.address.city,
                uf: ret.requester.address.uf,
                country: ret.requester.address.country,
                number: ret.requester.address.number,
                zip: ret.requester.address.zip,
                adicionalInfo: ret.requester.address.adicionalInfo,
                reference: ret.requester.address.reference,
                location: {
                    lat: ret.requester.address.location.lat,
                    lon: ret.requester.address.location.lon,
                },
            },
            createdAt: new Date(ret.requester.createdAt + "Z"),
            deletedAt: ret.requester.deletedAt === null || ret.requester.deletedAt === undefined ? null : new Date(ret.requester.deletedAt + "Z"),
            blockedUntil: ret.requester.blockedUntil === null || ret.requester.blockedUntil === undefined ? null : new Date(ret.requester.blockedUntil + "Z"),
            basePopularity: ret.requester.basePopularity || 0,
            documentsVerified: !!ret.requester.documentsVerified,
        },
        item: {
            thumbImage: {
                width: ret.item.thumbImage.width || 0,
                height: ret.item.thumbImage.height || 0,
                url: ret.item.thumbImage.url,
            },
            calendar: ret.item.calendar.map((e: any) => ({
                start: new Date(parseInt(e.start.split("-")[0], 10), parseInt(e.start.split("-")[1], 10) - 1, parseInt(e.start.split("-")[2], 10)),
                end: new Date(parseInt(e.end.split("-")[0], 10), parseInt(e.end.split("-")[1], 10) - 1, parseInt(e.end.split("-")[2], 10)),
            })),
            adjustment: !!ret.item.adjustment,
            category: ret.item.category,
            color: ret.item.color,
            details: ret.item.details,
            gender: ret.item.gender,
            images: ret.item.images.map((e: any) => ({
                width: e.width || 0,
                height: e.height || 0,
                url: e.url,
            })),
            brand: ret.item.brand === null || ret.item.brand === undefined ? null : ret.item.brand,
            ocasions: ret.item.ocasions.map((e: any) => e),
            size: ret.item.size,
            salePrice: ret.item.salePrice === null || ret.item.salePrice === undefined ? null : ret.item.salePrice || 0,
            rentPrice: ret.item.rentPrice === null || ret.item.rentPrice === undefined ? null : ret.item.rentPrice || 0,
            originalPrice: ret.item.originalPrice || 0,
        },
        transport: ret.transport === null || ret.transport === undefined ? null : {
            requester: {
                street: ret.transport.requester.street,
                neighborhood: ret.transport.requester.neighborhood,
                city: ret.transport.requester.city,
                uf: ret.transport.requester.uf,
                country: ret.transport.requester.country,
                number: ret.transport.requester.number,
                zip: ret.transport.requester.zip,
                adicionalInfo: ret.transport.requester.adicionalInfo,
                reference: ret.transport.requester.reference,
                location: {
                    lat: ret.transport.requester.location.lat,
                    lon: ret.transport.requester.location.lon,
                },
            },
            owner: {
                street: ret.transport.owner.street,
                neighborhood: ret.transport.owner.neighborhood,
                city: ret.transport.owner.city,
                uf: ret.transport.owner.uf,
                country: ret.transport.owner.country,
                number: ret.transport.owner.number,
                zip: ret.transport.owner.zip,
                adicionalInfo: ret.transport.owner.adicionalInfo,
                reference: ret.transport.owner.reference,
                location: {
                    lat: ret.transport.owner.location.lat,
                    lon: ret.transport.owner.location.lon,
                },
            },
            price: ret.transport.price || 0,
        },
        period: {
            start: new Date(parseInt(ret.period.start.split("-")[0], 10), parseInt(ret.period.start.split("-")[1], 10) - 1, parseInt(ret.period.start.split("-")[2], 10)),
            end: new Date(parseInt(ret.period.end.split("-")[0], 10), parseInt(ret.period.end.split("-")[1], 10) - 1, parseInt(ret.period.end.split("-")[2], 10)),
        },
        rentPrice: ret.rentPrice || 0,
        originalPrice: ret.originalPrice || 0,
        statusList: ret.statusList.map((e: any) => ({
            status: e.status,
            changeDate: new Date(parseInt(e.changeDate.split("-")[0], 10), parseInt(e.changeDate.split("-")[1], 10) - 1, parseInt(e.changeDate.split("-")[2], 10)),
            actionBy: e.actionBy === null || e.actionBy === undefined ? null : e.actionBy,
            pending: !!e.pending,
        })),
        currentStatus: {
            status: ret.currentStatus.status,
            changeDate: new Date(parseInt(ret.currentStatus.changeDate.split("-")[0], 10), parseInt(ret.currentStatus.changeDate.split("-")[1], 10) - 1, parseInt(ret.currentStatus.changeDate.split("-")[2], 10)),
            actionBy: ret.currentStatus.actionBy === null || ret.currentStatus.actionBy === undefined ? null : ret.currentStatus.actionBy,
            pending: !!ret.currentStatus.pending,
        },
        payment: ret.payment === null || ret.payment === undefined ? null : {
            transactionId: ret.payment.transactionId || 0,
            lastDigits: ret.payment.lastDigits,
            brand: ret.payment.brand,
            paidAmount: ret.payment.paidAmount || 0,
            status: ret.payment.status,
            locSplit: {
                id: ret.payment.locSplit.id,
                recipientId: ret.payment.locSplit.recipientId,
                amount: ret.payment.locSplit.amount || 0,
            },
            ownerSplit: {
                id: ret.payment.ownerSplit.id,
                recipientId: ret.payment.ownerSplit.recipientId,
                amount: ret.payment.ownerSplit.amount || 0,
            },
        },
        promotion: {
            card: ret.promotion.card === null || ret.promotion.card === undefined ? null : {
                transactionId: ret.promotion.card.transactionId || 0,
                lastDigits: ret.promotion.card.lastDigits,
                brand: ret.promotion.card.brand,
                paidAmount: ret.promotion.card.paidAmount || 0,
                status: ret.promotion.card.status,
                locSplit: {
                    id: ret.promotion.card.locSplit.id,
                    recipientId: ret.promotion.card.locSplit.recipientId,
                    amount: ret.promotion.card.locSplit.amount || 0,
                },
                ownerSplit: {
                    id: ret.promotion.card.ownerSplit.id,
                    recipientId: ret.promotion.card.ownerSplit.recipientId,
                    amount: ret.promotion.card.ownerSplit.amount || 0,
                },
            },
            type: ret.promotion.type === null || ret.promotion.type === undefined ? null : ret.promotion.type,
            total: ret.promotion.total || 0,
            id: ret.promotion.id === null || ret.promotion.id === undefined ? null : ret.promotion.id,
        },
        nfe: {
            id: ret.nfe.id === null || ret.nfe.id === undefined ? null : ret.nfe.id,
            number: ret.nfe.number === null || ret.nfe.number === undefined ? null : ret.nfe.number || 0,
            emitedAt: ret.nfe.emitedAt === null || ret.nfe.emitedAt === undefined ? null : new Date(ret.nfe.emitedAt + "Z"),
            canceledAt: ret.nfe.canceledAt === null || ret.nfe.canceledAt === undefined ? null : new Date(ret.nfe.canceledAt + "Z"),
            status: ret.nfe.status === null || ret.nfe.status === undefined ? null : ret.nfe.status,
        },
        createdAt: new Date(ret.createdAt + "Z"),
    };
}

export async function getLocker(ownerId: string | null, pagination: Pagination | null, progress?: (progress: number) => void): Promise<Item[]> {
    const args = {
        ownerId: ownerId === null || ownerId === undefined ? null : ownerId,
        pagination: pagination === null || pagination === undefined ? null : {
            pageNumber: pagination.pageNumber || 0,
        },
    };
    const ret = await makeRequest({name: "getLocker", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        owner: {
            id: e.owner.id,
            gender: e.owner.gender === null || e.owner.gender === undefined ? null : e.owner.gender,
            birthday: e.owner.birthday === null || e.owner.birthday === undefined ? null : new Date(parseInt(e.owner.birthday.split("-")[0], 10), parseInt(e.owner.birthday.split("-")[1], 10) - 1, parseInt(e.owner.birthday.split("-")[2], 10)),
            type: e.owner.type,
            name: e.owner.name,
            nick: e.owner.nick,
            cpf: e.owner.cpf,
            email: e.owner.email,
            phone: {
                ddd: e.owner.phone.ddd,
                ddi: e.owner.phone.ddi,
                number: e.owner.phone.number,
            },
            avatar: e.owner.avatar === null || e.owner.avatar === undefined ? null : {
                width: e.owner.avatar.width || 0,
                height: e.owner.avatar.height || 0,
                url: e.owner.avatar.url,
            },
            address: e.owner.address === null || e.owner.address === undefined ? null : {
                street: e.owner.address.street,
                neighborhood: e.owner.address.neighborhood,
                city: e.owner.address.city,
                uf: e.owner.address.uf,
                country: e.owner.address.country,
                number: e.owner.address.number,
                zip: e.owner.address.zip,
                adicionalInfo: e.owner.address.adicionalInfo,
                reference: e.owner.address.reference,
                location: {
                    lat: e.owner.address.location.lat,
                    lon: e.owner.address.location.lon,
                },
            },
            createdAt: new Date(e.owner.createdAt + "Z"),
            deletedAt: e.owner.deletedAt === null || e.owner.deletedAt === undefined ? null : new Date(e.owner.deletedAt + "Z"),
            blockedUntil: e.owner.blockedUntil === null || e.owner.blockedUntil === undefined ? null : new Date(e.owner.blockedUntil + "Z"),
            basePopularity: e.owner.basePopularity || 0,
            documentsVerified: !!e.owner.documentsVerified,
        },
        calendar: e.calendar.map((e: any) => ({
            start: new Date(parseInt(e.start.split("-")[0], 10), parseInt(e.start.split("-")[1], 10) - 1, parseInt(e.start.split("-")[2], 10)),
            end: new Date(parseInt(e.end.split("-")[0], 10), parseInt(e.end.split("-")[1], 10) - 1, parseInt(e.end.split("-")[2], 10)),
        })),
        createdAt: new Date(e.createdAt + "Z"),
        department: e.department,
        adjustment: !!e.adjustment,
        category: e.category === null || e.category === undefined ? null : e.category,
        color: e.color === null || e.color === undefined ? null : e.color,
        details: e.details,
        gender: e.gender,
        thumbImage: {
            width: e.thumbImage.width || 0,
            height: e.thumbImage.height || 0,
            url: e.thumbImage.url,
        },
        images: e.images.map((e: any) => ({
            width: e.width || 0,
            height: e.height || 0,
            url: e.url,
        })),
        brand: e.brand === null || e.brand === undefined ? null : e.brand,
        ocasions: e.ocasions.map((e: any) => e === null || e === undefined ? null : e),
        size: e.size === null || e.size === undefined ? null : e.size,
        rentPrice: e.rentPrice === null || e.rentPrice === undefined ? null : e.rentPrice || 0,
        salePrice: e.salePrice === null || e.salePrice === undefined ? null : e.salePrice || 0,
        originalPrice: e.originalPrice || 0,
    }));
}

export async function editItem(itemId: string, itemDetails: ItemDetails, progress?: (progress: number) => void): Promise<Item> {
    const args = {
        itemId: itemId,
        itemDetails: {
            adjustment: !!itemDetails.adjustment,
            category: itemDetails.category,
            color: itemDetails.color,
            details: itemDetails.details,
            gender: itemDetails.gender,
            images: itemDetails.images.map(e => ({
                width: e.width || 0,
                height: e.height || 0,
                url: e.url,
            })),
            brand: itemDetails.brand === null || itemDetails.brand === undefined ? null : itemDetails.brand,
            ocasions: itemDetails.ocasions.map(e => e),
            size: itemDetails.size,
            salePrice: itemDetails.salePrice === null || itemDetails.salePrice === undefined ? null : itemDetails.salePrice || 0,
            rentPrice: itemDetails.rentPrice === null || itemDetails.rentPrice === undefined ? null : itemDetails.rentPrice || 0,
            originalPrice: itemDetails.originalPrice || 0,
        },
    };
    const ret = await makeRequest({name: "editItem", args, progress});
    return {
        id: ret.id,
        owner: {
            id: ret.owner.id,
            gender: ret.owner.gender === null || ret.owner.gender === undefined ? null : ret.owner.gender,
            birthday: ret.owner.birthday === null || ret.owner.birthday === undefined ? null : new Date(parseInt(ret.owner.birthday.split("-")[0], 10), parseInt(ret.owner.birthday.split("-")[1], 10) - 1, parseInt(ret.owner.birthday.split("-")[2], 10)),
            type: ret.owner.type,
            name: ret.owner.name,
            nick: ret.owner.nick,
            cpf: ret.owner.cpf,
            email: ret.owner.email,
            phone: {
                ddd: ret.owner.phone.ddd,
                ddi: ret.owner.phone.ddi,
                number: ret.owner.phone.number,
            },
            avatar: ret.owner.avatar === null || ret.owner.avatar === undefined ? null : {
                width: ret.owner.avatar.width || 0,
                height: ret.owner.avatar.height || 0,
                url: ret.owner.avatar.url,
            },
            address: ret.owner.address === null || ret.owner.address === undefined ? null : {
                street: ret.owner.address.street,
                neighborhood: ret.owner.address.neighborhood,
                city: ret.owner.address.city,
                uf: ret.owner.address.uf,
                country: ret.owner.address.country,
                number: ret.owner.address.number,
                zip: ret.owner.address.zip,
                adicionalInfo: ret.owner.address.adicionalInfo,
                reference: ret.owner.address.reference,
                location: {
                    lat: ret.owner.address.location.lat,
                    lon: ret.owner.address.location.lon,
                },
            },
            createdAt: new Date(ret.owner.createdAt + "Z"),
            deletedAt: ret.owner.deletedAt === null || ret.owner.deletedAt === undefined ? null : new Date(ret.owner.deletedAt + "Z"),
            blockedUntil: ret.owner.blockedUntil === null || ret.owner.blockedUntil === undefined ? null : new Date(ret.owner.blockedUntil + "Z"),
            basePopularity: ret.owner.basePopularity || 0,
            documentsVerified: !!ret.owner.documentsVerified,
        },
        calendar: ret.calendar.map((e: any) => ({
            start: new Date(parseInt(e.start.split("-")[0], 10), parseInt(e.start.split("-")[1], 10) - 1, parseInt(e.start.split("-")[2], 10)),
            end: new Date(parseInt(e.end.split("-")[0], 10), parseInt(e.end.split("-")[1], 10) - 1, parseInt(e.end.split("-")[2], 10)),
        })),
        createdAt: new Date(ret.createdAt + "Z"),
        department: ret.department,
        adjustment: !!ret.adjustment,
        category: ret.category === null || ret.category === undefined ? null : ret.category,
        color: ret.color === null || ret.color === undefined ? null : ret.color,
        details: ret.details,
        gender: ret.gender,
        thumbImage: {
            width: ret.thumbImage.width || 0,
            height: ret.thumbImage.height || 0,
            url: ret.thumbImage.url,
        },
        images: ret.images.map((e: any) => ({
            width: e.width || 0,
            height: e.height || 0,
            url: e.url,
        })),
        brand: ret.brand === null || ret.brand === undefined ? null : ret.brand,
        ocasions: ret.ocasions.map((e: any) => e === null || e === undefined ? null : e),
        size: ret.size === null || ret.size === undefined ? null : ret.size,
        rentPrice: ret.rentPrice === null || ret.rentPrice === undefined ? null : ret.rentPrice || 0,
        salePrice: ret.salePrice === null || ret.salePrice === undefined ? null : ret.salePrice || 0,
        originalPrice: ret.originalPrice || 0,
    };
}

export async function deleteItem(itemId: string, progress?: (progress: number) => void): Promise<void> {
    const args = {
        itemId: itemId,
    };
    await makeRequest({name: "deleteItem", args, progress});
    return undefined;
}

export async function sendNotification(contentId: string | null, notificationType: NotificationType, message: string, userIds: string[], progress?: (progress: number) => void): Promise<number> {
    const args = {
        contentId: contentId === null || contentId === undefined ? null : contentId,
        notificationType: notificationType,
        message: message,
        userIds: userIds.map(e => e),
    };
    const ret = await makeRequest({name: "sendNotification", args, progress});
    return ret || 0;
}

export async function sendNotificationToDeviceType(contentId: string | null, notificationType: NotificationType, message: string, deviceType: DeviceType, progress?: (progress: number) => void): Promise<number> {
    const args = {
        contentId: contentId === null || contentId === undefined ? null : contentId,
        notificationType: notificationType,
        message: message,
        deviceType: deviceType,
    };
    const ret = await makeRequest({name: "sendNotificationToDeviceType", args, progress});
    return ret || 0;
}

export async function sendReportEmail(reportType: ReportType, progress?: (progress: number) => void): Promise<void> {
    const args = {
        reportType: reportType,
    };
    await makeRequest({name: "sendReportEmail", args, progress});
    return undefined;
}

export async function getFine(fineId: string, progress?: (progress: number) => void): Promise<Fine> {
    const args = {
        fineId: fineId,
    };
    const ret = await makeRequest({name: "getFine", args, progress});
    return {
        id: ret.id,
        transactionId: ret.transactionId || 0,
        payedBy: ret.payedBy === null || ret.payedBy === undefined ? null : {
            id: ret.payedBy.id,
            gender: ret.payedBy.gender === null || ret.payedBy.gender === undefined ? null : ret.payedBy.gender,
            birthday: ret.payedBy.birthday === null || ret.payedBy.birthday === undefined ? null : new Date(parseInt(ret.payedBy.birthday.split("-")[0], 10), parseInt(ret.payedBy.birthday.split("-")[1], 10) - 1, parseInt(ret.payedBy.birthday.split("-")[2], 10)),
            type: ret.payedBy.type,
            name: ret.payedBy.name,
            nick: ret.payedBy.nick,
            cpf: ret.payedBy.cpf,
            email: ret.payedBy.email,
            phone: {
                ddd: ret.payedBy.phone.ddd,
                ddi: ret.payedBy.phone.ddi,
                number: ret.payedBy.phone.number,
            },
            avatar: ret.payedBy.avatar === null || ret.payedBy.avatar === undefined ? null : {
                width: ret.payedBy.avatar.width || 0,
                height: ret.payedBy.avatar.height || 0,
                url: ret.payedBy.avatar.url,
            },
            address: ret.payedBy.address === null || ret.payedBy.address === undefined ? null : {
                street: ret.payedBy.address.street,
                neighborhood: ret.payedBy.address.neighborhood,
                city: ret.payedBy.address.city,
                uf: ret.payedBy.address.uf,
                country: ret.payedBy.address.country,
                number: ret.payedBy.address.number,
                zip: ret.payedBy.address.zip,
                adicionalInfo: ret.payedBy.address.adicionalInfo,
                reference: ret.payedBy.address.reference,
                location: {
                    lat: ret.payedBy.address.location.lat,
                    lon: ret.payedBy.address.location.lon,
                },
            },
            createdAt: new Date(ret.payedBy.createdAt + "Z"),
            deletedAt: ret.payedBy.deletedAt === null || ret.payedBy.deletedAt === undefined ? null : new Date(ret.payedBy.deletedAt + "Z"),
            blockedUntil: ret.payedBy.blockedUntil === null || ret.payedBy.blockedUntil === undefined ? null : new Date(ret.payedBy.blockedUntil + "Z"),
            basePopularity: ret.payedBy.basePopularity || 0,
            documentsVerified: !!ret.payedBy.documentsVerified,
        },
        payedTo: ret.payedTo === null || ret.payedTo === undefined ? null : {
            id: ret.payedTo.id,
            gender: ret.payedTo.gender === null || ret.payedTo.gender === undefined ? null : ret.payedTo.gender,
            birthday: ret.payedTo.birthday === null || ret.payedTo.birthday === undefined ? null : new Date(parseInt(ret.payedTo.birthday.split("-")[0], 10), parseInt(ret.payedTo.birthday.split("-")[1], 10) - 1, parseInt(ret.payedTo.birthday.split("-")[2], 10)),
            type: ret.payedTo.type,
            name: ret.payedTo.name,
            nick: ret.payedTo.nick,
            cpf: ret.payedTo.cpf,
            email: ret.payedTo.email,
            phone: {
                ddd: ret.payedTo.phone.ddd,
                ddi: ret.payedTo.phone.ddi,
                number: ret.payedTo.phone.number,
            },
            avatar: ret.payedTo.avatar === null || ret.payedTo.avatar === undefined ? null : {
                width: ret.payedTo.avatar.width || 0,
                height: ret.payedTo.avatar.height || 0,
                url: ret.payedTo.avatar.url,
            },
            address: ret.payedTo.address === null || ret.payedTo.address === undefined ? null : {
                street: ret.payedTo.address.street,
                neighborhood: ret.payedTo.address.neighborhood,
                city: ret.payedTo.address.city,
                uf: ret.payedTo.address.uf,
                country: ret.payedTo.address.country,
                number: ret.payedTo.address.number,
                zip: ret.payedTo.address.zip,
                adicionalInfo: ret.payedTo.address.adicionalInfo,
                reference: ret.payedTo.address.reference,
                location: {
                    lat: ret.payedTo.address.location.lat,
                    lon: ret.payedTo.address.location.lon,
                },
            },
            createdAt: new Date(ret.payedTo.createdAt + "Z"),
            deletedAt: ret.payedTo.deletedAt === null || ret.payedTo.deletedAt === undefined ? null : new Date(ret.payedTo.deletedAt + "Z"),
            blockedUntil: ret.payedTo.blockedUntil === null || ret.payedTo.blockedUntil === undefined ? null : new Date(ret.payedTo.blockedUntil + "Z"),
            basePopularity: ret.payedTo.basePopularity || 0,
            documentsVerified: !!ret.payedTo.documentsVerified,
        },
        request: ret.request === null || ret.request === undefined ? null : {
            id: ret.request.id,
            numId: ret.request.numId,
            owner: {
                id: ret.request.owner.id,
                gender: ret.request.owner.gender === null || ret.request.owner.gender === undefined ? null : ret.request.owner.gender,
                birthday: ret.request.owner.birthday === null || ret.request.owner.birthday === undefined ? null : new Date(parseInt(ret.request.owner.birthday.split("-")[0], 10), parseInt(ret.request.owner.birthday.split("-")[1], 10) - 1, parseInt(ret.request.owner.birthday.split("-")[2], 10)),
                type: ret.request.owner.type,
                name: ret.request.owner.name,
                nick: ret.request.owner.nick,
                cpf: ret.request.owner.cpf,
                email: ret.request.owner.email,
                phone: {
                    ddd: ret.request.owner.phone.ddd,
                    ddi: ret.request.owner.phone.ddi,
                    number: ret.request.owner.phone.number,
                },
                avatar: ret.request.owner.avatar === null || ret.request.owner.avatar === undefined ? null : {
                    width: ret.request.owner.avatar.width || 0,
                    height: ret.request.owner.avatar.height || 0,
                    url: ret.request.owner.avatar.url,
                },
                address: ret.request.owner.address === null || ret.request.owner.address === undefined ? null : {
                    street: ret.request.owner.address.street,
                    neighborhood: ret.request.owner.address.neighborhood,
                    city: ret.request.owner.address.city,
                    uf: ret.request.owner.address.uf,
                    country: ret.request.owner.address.country,
                    number: ret.request.owner.address.number,
                    zip: ret.request.owner.address.zip,
                    adicionalInfo: ret.request.owner.address.adicionalInfo,
                    reference: ret.request.owner.address.reference,
                    location: {
                        lat: ret.request.owner.address.location.lat,
                        lon: ret.request.owner.address.location.lon,
                    },
                },
                createdAt: new Date(ret.request.owner.createdAt + "Z"),
                deletedAt: ret.request.owner.deletedAt === null || ret.request.owner.deletedAt === undefined ? null : new Date(ret.request.owner.deletedAt + "Z"),
                blockedUntil: ret.request.owner.blockedUntil === null || ret.request.owner.blockedUntil === undefined ? null : new Date(ret.request.owner.blockedUntil + "Z"),
                basePopularity: ret.request.owner.basePopularity || 0,
                documentsVerified: !!ret.request.owner.documentsVerified,
            },
            requester: {
                id: ret.request.requester.id,
                gender: ret.request.requester.gender === null || ret.request.requester.gender === undefined ? null : ret.request.requester.gender,
                birthday: ret.request.requester.birthday === null || ret.request.requester.birthday === undefined ? null : new Date(parseInt(ret.request.requester.birthday.split("-")[0], 10), parseInt(ret.request.requester.birthday.split("-")[1], 10) - 1, parseInt(ret.request.requester.birthday.split("-")[2], 10)),
                type: ret.request.requester.type,
                name: ret.request.requester.name,
                nick: ret.request.requester.nick,
                cpf: ret.request.requester.cpf,
                email: ret.request.requester.email,
                phone: {
                    ddd: ret.request.requester.phone.ddd,
                    ddi: ret.request.requester.phone.ddi,
                    number: ret.request.requester.phone.number,
                },
                avatar: ret.request.requester.avatar === null || ret.request.requester.avatar === undefined ? null : {
                    width: ret.request.requester.avatar.width || 0,
                    height: ret.request.requester.avatar.height || 0,
                    url: ret.request.requester.avatar.url,
                },
                address: ret.request.requester.address === null || ret.request.requester.address === undefined ? null : {
                    street: ret.request.requester.address.street,
                    neighborhood: ret.request.requester.address.neighborhood,
                    city: ret.request.requester.address.city,
                    uf: ret.request.requester.address.uf,
                    country: ret.request.requester.address.country,
                    number: ret.request.requester.address.number,
                    zip: ret.request.requester.address.zip,
                    adicionalInfo: ret.request.requester.address.adicionalInfo,
                    reference: ret.request.requester.address.reference,
                    location: {
                        lat: ret.request.requester.address.location.lat,
                        lon: ret.request.requester.address.location.lon,
                    },
                },
                createdAt: new Date(ret.request.requester.createdAt + "Z"),
                deletedAt: ret.request.requester.deletedAt === null || ret.request.requester.deletedAt === undefined ? null : new Date(ret.request.requester.deletedAt + "Z"),
                blockedUntil: ret.request.requester.blockedUntil === null || ret.request.requester.blockedUntil === undefined ? null : new Date(ret.request.requester.blockedUntil + "Z"),
                basePopularity: ret.request.requester.basePopularity || 0,
                documentsVerified: !!ret.request.requester.documentsVerified,
            },
            item: {
                thumbImage: {
                    width: ret.request.item.thumbImage.width || 0,
                    height: ret.request.item.thumbImage.height || 0,
                    url: ret.request.item.thumbImage.url,
                },
                calendar: ret.request.item.calendar.map((e: any) => ({
                    start: new Date(parseInt(e.start.split("-")[0], 10), parseInt(e.start.split("-")[1], 10) - 1, parseInt(e.start.split("-")[2], 10)),
                    end: new Date(parseInt(e.end.split("-")[0], 10), parseInt(e.end.split("-")[1], 10) - 1, parseInt(e.end.split("-")[2], 10)),
                })),
                adjustment: !!ret.request.item.adjustment,
                category: ret.request.item.category,
                color: ret.request.item.color,
                details: ret.request.item.details,
                gender: ret.request.item.gender,
                images: ret.request.item.images.map((e: any) => ({
                    width: e.width || 0,
                    height: e.height || 0,
                    url: e.url,
                })),
                brand: ret.request.item.brand === null || ret.request.item.brand === undefined ? null : ret.request.item.brand,
                ocasions: ret.request.item.ocasions.map((e: any) => e),
                size: ret.request.item.size,
                salePrice: ret.request.item.salePrice === null || ret.request.item.salePrice === undefined ? null : ret.request.item.salePrice || 0,
                rentPrice: ret.request.item.rentPrice === null || ret.request.item.rentPrice === undefined ? null : ret.request.item.rentPrice || 0,
                originalPrice: ret.request.item.originalPrice || 0,
            },
            transport: ret.request.transport === null || ret.request.transport === undefined ? null : {
                requester: {
                    street: ret.request.transport.requester.street,
                    neighborhood: ret.request.transport.requester.neighborhood,
                    city: ret.request.transport.requester.city,
                    uf: ret.request.transport.requester.uf,
                    country: ret.request.transport.requester.country,
                    number: ret.request.transport.requester.number,
                    zip: ret.request.transport.requester.zip,
                    adicionalInfo: ret.request.transport.requester.adicionalInfo,
                    reference: ret.request.transport.requester.reference,
                    location: {
                        lat: ret.request.transport.requester.location.lat,
                        lon: ret.request.transport.requester.location.lon,
                    },
                },
                owner: {
                    street: ret.request.transport.owner.street,
                    neighborhood: ret.request.transport.owner.neighborhood,
                    city: ret.request.transport.owner.city,
                    uf: ret.request.transport.owner.uf,
                    country: ret.request.transport.owner.country,
                    number: ret.request.transport.owner.number,
                    zip: ret.request.transport.owner.zip,
                    adicionalInfo: ret.request.transport.owner.adicionalInfo,
                    reference: ret.request.transport.owner.reference,
                    location: {
                        lat: ret.request.transport.owner.location.lat,
                        lon: ret.request.transport.owner.location.lon,
                    },
                },
                price: ret.request.transport.price || 0,
            },
            period: {
                start: new Date(parseInt(ret.request.period.start.split("-")[0], 10), parseInt(ret.request.period.start.split("-")[1], 10) - 1, parseInt(ret.request.period.start.split("-")[2], 10)),
                end: new Date(parseInt(ret.request.period.end.split("-")[0], 10), parseInt(ret.request.period.end.split("-")[1], 10) - 1, parseInt(ret.request.period.end.split("-")[2], 10)),
            },
            rentPrice: ret.request.rentPrice || 0,
            originalPrice: ret.request.originalPrice || 0,
            statusList: ret.request.statusList.map((e: any) => ({
                status: e.status,
                changeDate: new Date(parseInt(e.changeDate.split("-")[0], 10), parseInt(e.changeDate.split("-")[1], 10) - 1, parseInt(e.changeDate.split("-")[2], 10)),
                actionBy: e.actionBy === null || e.actionBy === undefined ? null : e.actionBy,
                pending: !!e.pending,
            })),
            currentStatus: {
                status: ret.request.currentStatus.status,
                changeDate: new Date(parseInt(ret.request.currentStatus.changeDate.split("-")[0], 10), parseInt(ret.request.currentStatus.changeDate.split("-")[1], 10) - 1, parseInt(ret.request.currentStatus.changeDate.split("-")[2], 10)),
                actionBy: ret.request.currentStatus.actionBy === null || ret.request.currentStatus.actionBy === undefined ? null : ret.request.currentStatus.actionBy,
                pending: !!ret.request.currentStatus.pending,
            },
            payment: ret.request.payment === null || ret.request.payment === undefined ? null : {
                transactionId: ret.request.payment.transactionId || 0,
                lastDigits: ret.request.payment.lastDigits,
                brand: ret.request.payment.brand,
                paidAmount: ret.request.payment.paidAmount || 0,
                status: ret.request.payment.status,
                locSplit: {
                    id: ret.request.payment.locSplit.id,
                    recipientId: ret.request.payment.locSplit.recipientId,
                    amount: ret.request.payment.locSplit.amount || 0,
                },
                ownerSplit: {
                    id: ret.request.payment.ownerSplit.id,
                    recipientId: ret.request.payment.ownerSplit.recipientId,
                    amount: ret.request.payment.ownerSplit.amount || 0,
                },
            },
            promotion: {
                card: ret.request.promotion.card === null || ret.request.promotion.card === undefined ? null : {
                    transactionId: ret.request.promotion.card.transactionId || 0,
                    lastDigits: ret.request.promotion.card.lastDigits,
                    brand: ret.request.promotion.card.brand,
                    paidAmount: ret.request.promotion.card.paidAmount || 0,
                    status: ret.request.promotion.card.status,
                    locSplit: {
                        id: ret.request.promotion.card.locSplit.id,
                        recipientId: ret.request.promotion.card.locSplit.recipientId,
                        amount: ret.request.promotion.card.locSplit.amount || 0,
                    },
                    ownerSplit: {
                        id: ret.request.promotion.card.ownerSplit.id,
                        recipientId: ret.request.promotion.card.ownerSplit.recipientId,
                        amount: ret.request.promotion.card.ownerSplit.amount || 0,
                    },
                },
                type: ret.request.promotion.type === null || ret.request.promotion.type === undefined ? null : ret.request.promotion.type,
                total: ret.request.promotion.total || 0,
                id: ret.request.promotion.id === null || ret.request.promotion.id === undefined ? null : ret.request.promotion.id,
            },
            nfe: {
                id: ret.request.nfe.id === null || ret.request.nfe.id === undefined ? null : ret.request.nfe.id,
                number: ret.request.nfe.number === null || ret.request.nfe.number === undefined ? null : ret.request.nfe.number || 0,
                emitedAt: ret.request.nfe.emitedAt === null || ret.request.nfe.emitedAt === undefined ? null : new Date(ret.request.nfe.emitedAt + "Z"),
                canceledAt: ret.request.nfe.canceledAt === null || ret.request.nfe.canceledAt === undefined ? null : new Date(ret.request.nfe.canceledAt + "Z"),
                status: ret.request.nfe.status === null || ret.request.nfe.status === undefined ? null : ret.request.nfe.status,
            },
            createdAt: new Date(ret.request.createdAt + "Z"),
        },
        createdAt: new Date(ret.createdAt + "Z"),
        reason: ret.reason,
        amount: ret.amount || 0,
    };
}

export async function getFines(page: number | null, progress?: (progress: number) => void): Promise<Fine[]> {
    const args = {
        page: page === null || page === undefined ? null : page || 0,
    };
    const ret = await makeRequest({name: "getFines", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        transactionId: e.transactionId || 0,
        payedBy: e.payedBy === null || e.payedBy === undefined ? null : {
            id: e.payedBy.id,
            gender: e.payedBy.gender === null || e.payedBy.gender === undefined ? null : e.payedBy.gender,
            birthday: e.payedBy.birthday === null || e.payedBy.birthday === undefined ? null : new Date(parseInt(e.payedBy.birthday.split("-")[0], 10), parseInt(e.payedBy.birthday.split("-")[1], 10) - 1, parseInt(e.payedBy.birthday.split("-")[2], 10)),
            type: e.payedBy.type,
            name: e.payedBy.name,
            nick: e.payedBy.nick,
            cpf: e.payedBy.cpf,
            email: e.payedBy.email,
            phone: {
                ddd: e.payedBy.phone.ddd,
                ddi: e.payedBy.phone.ddi,
                number: e.payedBy.phone.number,
            },
            avatar: e.payedBy.avatar === null || e.payedBy.avatar === undefined ? null : {
                width: e.payedBy.avatar.width || 0,
                height: e.payedBy.avatar.height || 0,
                url: e.payedBy.avatar.url,
            },
            address: e.payedBy.address === null || e.payedBy.address === undefined ? null : {
                street: e.payedBy.address.street,
                neighborhood: e.payedBy.address.neighborhood,
                city: e.payedBy.address.city,
                uf: e.payedBy.address.uf,
                country: e.payedBy.address.country,
                number: e.payedBy.address.number,
                zip: e.payedBy.address.zip,
                adicionalInfo: e.payedBy.address.adicionalInfo,
                reference: e.payedBy.address.reference,
                location: {
                    lat: e.payedBy.address.location.lat,
                    lon: e.payedBy.address.location.lon,
                },
            },
            createdAt: new Date(e.payedBy.createdAt + "Z"),
            deletedAt: e.payedBy.deletedAt === null || e.payedBy.deletedAt === undefined ? null : new Date(e.payedBy.deletedAt + "Z"),
            blockedUntil: e.payedBy.blockedUntil === null || e.payedBy.blockedUntil === undefined ? null : new Date(e.payedBy.blockedUntil + "Z"),
            basePopularity: e.payedBy.basePopularity || 0,
            documentsVerified: !!e.payedBy.documentsVerified,
        },
        payedTo: e.payedTo === null || e.payedTo === undefined ? null : {
            id: e.payedTo.id,
            gender: e.payedTo.gender === null || e.payedTo.gender === undefined ? null : e.payedTo.gender,
            birthday: e.payedTo.birthday === null || e.payedTo.birthday === undefined ? null : new Date(parseInt(e.payedTo.birthday.split("-")[0], 10), parseInt(e.payedTo.birthday.split("-")[1], 10) - 1, parseInt(e.payedTo.birthday.split("-")[2], 10)),
            type: e.payedTo.type,
            name: e.payedTo.name,
            nick: e.payedTo.nick,
            cpf: e.payedTo.cpf,
            email: e.payedTo.email,
            phone: {
                ddd: e.payedTo.phone.ddd,
                ddi: e.payedTo.phone.ddi,
                number: e.payedTo.phone.number,
            },
            avatar: e.payedTo.avatar === null || e.payedTo.avatar === undefined ? null : {
                width: e.payedTo.avatar.width || 0,
                height: e.payedTo.avatar.height || 0,
                url: e.payedTo.avatar.url,
            },
            address: e.payedTo.address === null || e.payedTo.address === undefined ? null : {
                street: e.payedTo.address.street,
                neighborhood: e.payedTo.address.neighborhood,
                city: e.payedTo.address.city,
                uf: e.payedTo.address.uf,
                country: e.payedTo.address.country,
                number: e.payedTo.address.number,
                zip: e.payedTo.address.zip,
                adicionalInfo: e.payedTo.address.adicionalInfo,
                reference: e.payedTo.address.reference,
                location: {
                    lat: e.payedTo.address.location.lat,
                    lon: e.payedTo.address.location.lon,
                },
            },
            createdAt: new Date(e.payedTo.createdAt + "Z"),
            deletedAt: e.payedTo.deletedAt === null || e.payedTo.deletedAt === undefined ? null : new Date(e.payedTo.deletedAt + "Z"),
            blockedUntil: e.payedTo.blockedUntil === null || e.payedTo.blockedUntil === undefined ? null : new Date(e.payedTo.blockedUntil + "Z"),
            basePopularity: e.payedTo.basePopularity || 0,
            documentsVerified: !!e.payedTo.documentsVerified,
        },
        request: e.request === null || e.request === undefined ? null : {
            id: e.request.id,
            numId: e.request.numId,
            owner: {
                id: e.request.owner.id,
                gender: e.request.owner.gender === null || e.request.owner.gender === undefined ? null : e.request.owner.gender,
                birthday: e.request.owner.birthday === null || e.request.owner.birthday === undefined ? null : new Date(parseInt(e.request.owner.birthday.split("-")[0], 10), parseInt(e.request.owner.birthday.split("-")[1], 10) - 1, parseInt(e.request.owner.birthday.split("-")[2], 10)),
                type: e.request.owner.type,
                name: e.request.owner.name,
                nick: e.request.owner.nick,
                cpf: e.request.owner.cpf,
                email: e.request.owner.email,
                phone: {
                    ddd: e.request.owner.phone.ddd,
                    ddi: e.request.owner.phone.ddi,
                    number: e.request.owner.phone.number,
                },
                avatar: e.request.owner.avatar === null || e.request.owner.avatar === undefined ? null : {
                    width: e.request.owner.avatar.width || 0,
                    height: e.request.owner.avatar.height || 0,
                    url: e.request.owner.avatar.url,
                },
                address: e.request.owner.address === null || e.request.owner.address === undefined ? null : {
                    street: e.request.owner.address.street,
                    neighborhood: e.request.owner.address.neighborhood,
                    city: e.request.owner.address.city,
                    uf: e.request.owner.address.uf,
                    country: e.request.owner.address.country,
                    number: e.request.owner.address.number,
                    zip: e.request.owner.address.zip,
                    adicionalInfo: e.request.owner.address.adicionalInfo,
                    reference: e.request.owner.address.reference,
                    location: {
                        lat: e.request.owner.address.location.lat,
                        lon: e.request.owner.address.location.lon,
                    },
                },
                createdAt: new Date(e.request.owner.createdAt + "Z"),
                deletedAt: e.request.owner.deletedAt === null || e.request.owner.deletedAt === undefined ? null : new Date(e.request.owner.deletedAt + "Z"),
                blockedUntil: e.request.owner.blockedUntil === null || e.request.owner.blockedUntil === undefined ? null : new Date(e.request.owner.blockedUntil + "Z"),
                basePopularity: e.request.owner.basePopularity || 0,
                documentsVerified: !!e.request.owner.documentsVerified,
            },
            requester: {
                id: e.request.requester.id,
                gender: e.request.requester.gender === null || e.request.requester.gender === undefined ? null : e.request.requester.gender,
                birthday: e.request.requester.birthday === null || e.request.requester.birthday === undefined ? null : new Date(parseInt(e.request.requester.birthday.split("-")[0], 10), parseInt(e.request.requester.birthday.split("-")[1], 10) - 1, parseInt(e.request.requester.birthday.split("-")[2], 10)),
                type: e.request.requester.type,
                name: e.request.requester.name,
                nick: e.request.requester.nick,
                cpf: e.request.requester.cpf,
                email: e.request.requester.email,
                phone: {
                    ddd: e.request.requester.phone.ddd,
                    ddi: e.request.requester.phone.ddi,
                    number: e.request.requester.phone.number,
                },
                avatar: e.request.requester.avatar === null || e.request.requester.avatar === undefined ? null : {
                    width: e.request.requester.avatar.width || 0,
                    height: e.request.requester.avatar.height || 0,
                    url: e.request.requester.avatar.url,
                },
                address: e.request.requester.address === null || e.request.requester.address === undefined ? null : {
                    street: e.request.requester.address.street,
                    neighborhood: e.request.requester.address.neighborhood,
                    city: e.request.requester.address.city,
                    uf: e.request.requester.address.uf,
                    country: e.request.requester.address.country,
                    number: e.request.requester.address.number,
                    zip: e.request.requester.address.zip,
                    adicionalInfo: e.request.requester.address.adicionalInfo,
                    reference: e.request.requester.address.reference,
                    location: {
                        lat: e.request.requester.address.location.lat,
                        lon: e.request.requester.address.location.lon,
                    },
                },
                createdAt: new Date(e.request.requester.createdAt + "Z"),
                deletedAt: e.request.requester.deletedAt === null || e.request.requester.deletedAt === undefined ? null : new Date(e.request.requester.deletedAt + "Z"),
                blockedUntil: e.request.requester.blockedUntil === null || e.request.requester.blockedUntil === undefined ? null : new Date(e.request.requester.blockedUntil + "Z"),
                basePopularity: e.request.requester.basePopularity || 0,
                documentsVerified: !!e.request.requester.documentsVerified,
            },
            item: {
                thumbImage: {
                    width: e.request.item.thumbImage.width || 0,
                    height: e.request.item.thumbImage.height || 0,
                    url: e.request.item.thumbImage.url,
                },
                calendar: e.request.item.calendar.map((e: any) => ({
                    start: new Date(parseInt(e.start.split("-")[0], 10), parseInt(e.start.split("-")[1], 10) - 1, parseInt(e.start.split("-")[2], 10)),
                    end: new Date(parseInt(e.end.split("-")[0], 10), parseInt(e.end.split("-")[1], 10) - 1, parseInt(e.end.split("-")[2], 10)),
                })),
                adjustment: !!e.request.item.adjustment,
                category: e.request.item.category,
                color: e.request.item.color,
                details: e.request.item.details,
                gender: e.request.item.gender,
                images: e.request.item.images.map((e: any) => ({
                    width: e.width || 0,
                    height: e.height || 0,
                    url: e.url,
                })),
                brand: e.request.item.brand === null || e.request.item.brand === undefined ? null : e.request.item.brand,
                ocasions: e.request.item.ocasions.map((e: any) => e),
                size: e.request.item.size,
                salePrice: e.request.item.salePrice === null || e.request.item.salePrice === undefined ? null : e.request.item.salePrice || 0,
                rentPrice: e.request.item.rentPrice === null || e.request.item.rentPrice === undefined ? null : e.request.item.rentPrice || 0,
                originalPrice: e.request.item.originalPrice || 0,
            },
            transport: e.request.transport === null || e.request.transport === undefined ? null : {
                requester: {
                    street: e.request.transport.requester.street,
                    neighborhood: e.request.transport.requester.neighborhood,
                    city: e.request.transport.requester.city,
                    uf: e.request.transport.requester.uf,
                    country: e.request.transport.requester.country,
                    number: e.request.transport.requester.number,
                    zip: e.request.transport.requester.zip,
                    adicionalInfo: e.request.transport.requester.adicionalInfo,
                    reference: e.request.transport.requester.reference,
                    location: {
                        lat: e.request.transport.requester.location.lat,
                        lon: e.request.transport.requester.location.lon,
                    },
                },
                owner: {
                    street: e.request.transport.owner.street,
                    neighborhood: e.request.transport.owner.neighborhood,
                    city: e.request.transport.owner.city,
                    uf: e.request.transport.owner.uf,
                    country: e.request.transport.owner.country,
                    number: e.request.transport.owner.number,
                    zip: e.request.transport.owner.zip,
                    adicionalInfo: e.request.transport.owner.adicionalInfo,
                    reference: e.request.transport.owner.reference,
                    location: {
                        lat: e.request.transport.owner.location.lat,
                        lon: e.request.transport.owner.location.lon,
                    },
                },
                price: e.request.transport.price || 0,
            },
            period: {
                start: new Date(parseInt(e.request.period.start.split("-")[0], 10), parseInt(e.request.period.start.split("-")[1], 10) - 1, parseInt(e.request.period.start.split("-")[2], 10)),
                end: new Date(parseInt(e.request.period.end.split("-")[0], 10), parseInt(e.request.period.end.split("-")[1], 10) - 1, parseInt(e.request.period.end.split("-")[2], 10)),
            },
            rentPrice: e.request.rentPrice || 0,
            originalPrice: e.request.originalPrice || 0,
            statusList: e.request.statusList.map((e: any) => ({
                status: e.status,
                changeDate: new Date(parseInt(e.changeDate.split("-")[0], 10), parseInt(e.changeDate.split("-")[1], 10) - 1, parseInt(e.changeDate.split("-")[2], 10)),
                actionBy: e.actionBy === null || e.actionBy === undefined ? null : e.actionBy,
                pending: !!e.pending,
            })),
            currentStatus: {
                status: e.request.currentStatus.status,
                changeDate: new Date(parseInt(e.request.currentStatus.changeDate.split("-")[0], 10), parseInt(e.request.currentStatus.changeDate.split("-")[1], 10) - 1, parseInt(e.request.currentStatus.changeDate.split("-")[2], 10)),
                actionBy: e.request.currentStatus.actionBy === null || e.request.currentStatus.actionBy === undefined ? null : e.request.currentStatus.actionBy,
                pending: !!e.request.currentStatus.pending,
            },
            payment: e.request.payment === null || e.request.payment === undefined ? null : {
                transactionId: e.request.payment.transactionId || 0,
                lastDigits: e.request.payment.lastDigits,
                brand: e.request.payment.brand,
                paidAmount: e.request.payment.paidAmount || 0,
                status: e.request.payment.status,
                locSplit: {
                    id: e.request.payment.locSplit.id,
                    recipientId: e.request.payment.locSplit.recipientId,
                    amount: e.request.payment.locSplit.amount || 0,
                },
                ownerSplit: {
                    id: e.request.payment.ownerSplit.id,
                    recipientId: e.request.payment.ownerSplit.recipientId,
                    amount: e.request.payment.ownerSplit.amount || 0,
                },
            },
            promotion: {
                card: e.request.promotion.card === null || e.request.promotion.card === undefined ? null : {
                    transactionId: e.request.promotion.card.transactionId || 0,
                    lastDigits: e.request.promotion.card.lastDigits,
                    brand: e.request.promotion.card.brand,
                    paidAmount: e.request.promotion.card.paidAmount || 0,
                    status: e.request.promotion.card.status,
                    locSplit: {
                        id: e.request.promotion.card.locSplit.id,
                        recipientId: e.request.promotion.card.locSplit.recipientId,
                        amount: e.request.promotion.card.locSplit.amount || 0,
                    },
                    ownerSplit: {
                        id: e.request.promotion.card.ownerSplit.id,
                        recipientId: e.request.promotion.card.ownerSplit.recipientId,
                        amount: e.request.promotion.card.ownerSplit.amount || 0,
                    },
                },
                type: e.request.promotion.type === null || e.request.promotion.type === undefined ? null : e.request.promotion.type,
                total: e.request.promotion.total || 0,
                id: e.request.promotion.id === null || e.request.promotion.id === undefined ? null : e.request.promotion.id,
            },
            nfe: {
                id: e.request.nfe.id === null || e.request.nfe.id === undefined ? null : e.request.nfe.id,
                number: e.request.nfe.number === null || e.request.nfe.number === undefined ? null : e.request.nfe.number || 0,
                emitedAt: e.request.nfe.emitedAt === null || e.request.nfe.emitedAt === undefined ? null : new Date(e.request.nfe.emitedAt + "Z"),
                canceledAt: e.request.nfe.canceledAt === null || e.request.nfe.canceledAt === undefined ? null : new Date(e.request.nfe.canceledAt + "Z"),
                status: e.request.nfe.status === null || e.request.nfe.status === undefined ? null : e.request.nfe.status,
            },
            createdAt: new Date(e.request.createdAt + "Z"),
        },
        createdAt: new Date(e.createdAt + "Z"),
        reason: e.reason,
        amount: e.amount || 0,
    }));
}

export async function getFinesForRequest(requestId: string, progress?: (progress: number) => void): Promise<RequestFine[]> {
    const args = {
        requestId: requestId,
    };
    const ret = await makeRequest({name: "getFinesForRequest", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        transactionId: e.transactionId || 0,
        payedBy: e.payedBy === null || e.payedBy === undefined ? null : {
            id: e.payedBy.id,
            gender: e.payedBy.gender === null || e.payedBy.gender === undefined ? null : e.payedBy.gender,
            birthday: e.payedBy.birthday === null || e.payedBy.birthday === undefined ? null : new Date(parseInt(e.payedBy.birthday.split("-")[0], 10), parseInt(e.payedBy.birthday.split("-")[1], 10) - 1, parseInt(e.payedBy.birthday.split("-")[2], 10)),
            type: e.payedBy.type,
            name: e.payedBy.name,
            nick: e.payedBy.nick,
            cpf: e.payedBy.cpf,
            email: e.payedBy.email,
            phone: {
                ddd: e.payedBy.phone.ddd,
                ddi: e.payedBy.phone.ddi,
                number: e.payedBy.phone.number,
            },
            avatar: e.payedBy.avatar === null || e.payedBy.avatar === undefined ? null : {
                width: e.payedBy.avatar.width || 0,
                height: e.payedBy.avatar.height || 0,
                url: e.payedBy.avatar.url,
            },
            address: e.payedBy.address === null || e.payedBy.address === undefined ? null : {
                street: e.payedBy.address.street,
                neighborhood: e.payedBy.address.neighborhood,
                city: e.payedBy.address.city,
                uf: e.payedBy.address.uf,
                country: e.payedBy.address.country,
                number: e.payedBy.address.number,
                zip: e.payedBy.address.zip,
                adicionalInfo: e.payedBy.address.adicionalInfo,
                reference: e.payedBy.address.reference,
                location: {
                    lat: e.payedBy.address.location.lat,
                    lon: e.payedBy.address.location.lon,
                },
            },
            createdAt: new Date(e.payedBy.createdAt + "Z"),
            deletedAt: e.payedBy.deletedAt === null || e.payedBy.deletedAt === undefined ? null : new Date(e.payedBy.deletedAt + "Z"),
            blockedUntil: e.payedBy.blockedUntil === null || e.payedBy.blockedUntil === undefined ? null : new Date(e.payedBy.blockedUntil + "Z"),
            basePopularity: e.payedBy.basePopularity || 0,
            documentsVerified: !!e.payedBy.documentsVerified,
        },
        payedTo: {
            id: e.payedTo.id,
            gender: e.payedTo.gender === null || e.payedTo.gender === undefined ? null : e.payedTo.gender,
            birthday: e.payedTo.birthday === null || e.payedTo.birthday === undefined ? null : new Date(parseInt(e.payedTo.birthday.split("-")[0], 10), parseInt(e.payedTo.birthday.split("-")[1], 10) - 1, parseInt(e.payedTo.birthday.split("-")[2], 10)),
            type: e.payedTo.type,
            name: e.payedTo.name,
            nick: e.payedTo.nick,
            cpf: e.payedTo.cpf,
            email: e.payedTo.email,
            phone: {
                ddd: e.payedTo.phone.ddd,
                ddi: e.payedTo.phone.ddi,
                number: e.payedTo.phone.number,
            },
            avatar: e.payedTo.avatar === null || e.payedTo.avatar === undefined ? null : {
                width: e.payedTo.avatar.width || 0,
                height: e.payedTo.avatar.height || 0,
                url: e.payedTo.avatar.url,
            },
            address: e.payedTo.address === null || e.payedTo.address === undefined ? null : {
                street: e.payedTo.address.street,
                neighborhood: e.payedTo.address.neighborhood,
                city: e.payedTo.address.city,
                uf: e.payedTo.address.uf,
                country: e.payedTo.address.country,
                number: e.payedTo.address.number,
                zip: e.payedTo.address.zip,
                adicionalInfo: e.payedTo.address.adicionalInfo,
                reference: e.payedTo.address.reference,
                location: {
                    lat: e.payedTo.address.location.lat,
                    lon: e.payedTo.address.location.lon,
                },
            },
            createdAt: new Date(e.payedTo.createdAt + "Z"),
            deletedAt: e.payedTo.deletedAt === null || e.payedTo.deletedAt === undefined ? null : new Date(e.payedTo.deletedAt + "Z"),
            blockedUntil: e.payedTo.blockedUntil === null || e.payedTo.blockedUntil === undefined ? null : new Date(e.payedTo.blockedUntil + "Z"),
            basePopularity: e.payedTo.basePopularity || 0,
            documentsVerified: !!e.payedTo.documentsVerified,
        },
        createdAt: new Date(e.createdAt + "Z"),
        reason: e.reason,
        amount: e.amount || 0,
    }));
}

export async function getFinesPayedByUser(userId: string, progress?: (progress: number) => void): Promise<Fine[]> {
    const args = {
        userId: userId,
    };
    const ret = await makeRequest({name: "getFinesPayedByUser", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        transactionId: e.transactionId || 0,
        payedBy: e.payedBy === null || e.payedBy === undefined ? null : {
            id: e.payedBy.id,
            gender: e.payedBy.gender === null || e.payedBy.gender === undefined ? null : e.payedBy.gender,
            birthday: e.payedBy.birthday === null || e.payedBy.birthday === undefined ? null : new Date(parseInt(e.payedBy.birthday.split("-")[0], 10), parseInt(e.payedBy.birthday.split("-")[1], 10) - 1, parseInt(e.payedBy.birthday.split("-")[2], 10)),
            type: e.payedBy.type,
            name: e.payedBy.name,
            nick: e.payedBy.nick,
            cpf: e.payedBy.cpf,
            email: e.payedBy.email,
            phone: {
                ddd: e.payedBy.phone.ddd,
                ddi: e.payedBy.phone.ddi,
                number: e.payedBy.phone.number,
            },
            avatar: e.payedBy.avatar === null || e.payedBy.avatar === undefined ? null : {
                width: e.payedBy.avatar.width || 0,
                height: e.payedBy.avatar.height || 0,
                url: e.payedBy.avatar.url,
            },
            address: e.payedBy.address === null || e.payedBy.address === undefined ? null : {
                street: e.payedBy.address.street,
                neighborhood: e.payedBy.address.neighborhood,
                city: e.payedBy.address.city,
                uf: e.payedBy.address.uf,
                country: e.payedBy.address.country,
                number: e.payedBy.address.number,
                zip: e.payedBy.address.zip,
                adicionalInfo: e.payedBy.address.adicionalInfo,
                reference: e.payedBy.address.reference,
                location: {
                    lat: e.payedBy.address.location.lat,
                    lon: e.payedBy.address.location.lon,
                },
            },
            createdAt: new Date(e.payedBy.createdAt + "Z"),
            deletedAt: e.payedBy.deletedAt === null || e.payedBy.deletedAt === undefined ? null : new Date(e.payedBy.deletedAt + "Z"),
            blockedUntil: e.payedBy.blockedUntil === null || e.payedBy.blockedUntil === undefined ? null : new Date(e.payedBy.blockedUntil + "Z"),
            basePopularity: e.payedBy.basePopularity || 0,
            documentsVerified: !!e.payedBy.documentsVerified,
        },
        payedTo: e.payedTo === null || e.payedTo === undefined ? null : {
            id: e.payedTo.id,
            gender: e.payedTo.gender === null || e.payedTo.gender === undefined ? null : e.payedTo.gender,
            birthday: e.payedTo.birthday === null || e.payedTo.birthday === undefined ? null : new Date(parseInt(e.payedTo.birthday.split("-")[0], 10), parseInt(e.payedTo.birthday.split("-")[1], 10) - 1, parseInt(e.payedTo.birthday.split("-")[2], 10)),
            type: e.payedTo.type,
            name: e.payedTo.name,
            nick: e.payedTo.nick,
            cpf: e.payedTo.cpf,
            email: e.payedTo.email,
            phone: {
                ddd: e.payedTo.phone.ddd,
                ddi: e.payedTo.phone.ddi,
                number: e.payedTo.phone.number,
            },
            avatar: e.payedTo.avatar === null || e.payedTo.avatar === undefined ? null : {
                width: e.payedTo.avatar.width || 0,
                height: e.payedTo.avatar.height || 0,
                url: e.payedTo.avatar.url,
            },
            address: e.payedTo.address === null || e.payedTo.address === undefined ? null : {
                street: e.payedTo.address.street,
                neighborhood: e.payedTo.address.neighborhood,
                city: e.payedTo.address.city,
                uf: e.payedTo.address.uf,
                country: e.payedTo.address.country,
                number: e.payedTo.address.number,
                zip: e.payedTo.address.zip,
                adicionalInfo: e.payedTo.address.adicionalInfo,
                reference: e.payedTo.address.reference,
                location: {
                    lat: e.payedTo.address.location.lat,
                    lon: e.payedTo.address.location.lon,
                },
            },
            createdAt: new Date(e.payedTo.createdAt + "Z"),
            deletedAt: e.payedTo.deletedAt === null || e.payedTo.deletedAt === undefined ? null : new Date(e.payedTo.deletedAt + "Z"),
            blockedUntil: e.payedTo.blockedUntil === null || e.payedTo.blockedUntil === undefined ? null : new Date(e.payedTo.blockedUntil + "Z"),
            basePopularity: e.payedTo.basePopularity || 0,
            documentsVerified: !!e.payedTo.documentsVerified,
        },
        request: e.request === null || e.request === undefined ? null : {
            id: e.request.id,
            numId: e.request.numId,
            owner: {
                id: e.request.owner.id,
                gender: e.request.owner.gender === null || e.request.owner.gender === undefined ? null : e.request.owner.gender,
                birthday: e.request.owner.birthday === null || e.request.owner.birthday === undefined ? null : new Date(parseInt(e.request.owner.birthday.split("-")[0], 10), parseInt(e.request.owner.birthday.split("-")[1], 10) - 1, parseInt(e.request.owner.birthday.split("-")[2], 10)),
                type: e.request.owner.type,
                name: e.request.owner.name,
                nick: e.request.owner.nick,
                cpf: e.request.owner.cpf,
                email: e.request.owner.email,
                phone: {
                    ddd: e.request.owner.phone.ddd,
                    ddi: e.request.owner.phone.ddi,
                    number: e.request.owner.phone.number,
                },
                avatar: e.request.owner.avatar === null || e.request.owner.avatar === undefined ? null : {
                    width: e.request.owner.avatar.width || 0,
                    height: e.request.owner.avatar.height || 0,
                    url: e.request.owner.avatar.url,
                },
                address: e.request.owner.address === null || e.request.owner.address === undefined ? null : {
                    street: e.request.owner.address.street,
                    neighborhood: e.request.owner.address.neighborhood,
                    city: e.request.owner.address.city,
                    uf: e.request.owner.address.uf,
                    country: e.request.owner.address.country,
                    number: e.request.owner.address.number,
                    zip: e.request.owner.address.zip,
                    adicionalInfo: e.request.owner.address.adicionalInfo,
                    reference: e.request.owner.address.reference,
                    location: {
                        lat: e.request.owner.address.location.lat,
                        lon: e.request.owner.address.location.lon,
                    },
                },
                createdAt: new Date(e.request.owner.createdAt + "Z"),
                deletedAt: e.request.owner.deletedAt === null || e.request.owner.deletedAt === undefined ? null : new Date(e.request.owner.deletedAt + "Z"),
                blockedUntil: e.request.owner.blockedUntil === null || e.request.owner.blockedUntil === undefined ? null : new Date(e.request.owner.blockedUntil + "Z"),
                basePopularity: e.request.owner.basePopularity || 0,
                documentsVerified: !!e.request.owner.documentsVerified,
            },
            requester: {
                id: e.request.requester.id,
                gender: e.request.requester.gender === null || e.request.requester.gender === undefined ? null : e.request.requester.gender,
                birthday: e.request.requester.birthday === null || e.request.requester.birthday === undefined ? null : new Date(parseInt(e.request.requester.birthday.split("-")[0], 10), parseInt(e.request.requester.birthday.split("-")[1], 10) - 1, parseInt(e.request.requester.birthday.split("-")[2], 10)),
                type: e.request.requester.type,
                name: e.request.requester.name,
                nick: e.request.requester.nick,
                cpf: e.request.requester.cpf,
                email: e.request.requester.email,
                phone: {
                    ddd: e.request.requester.phone.ddd,
                    ddi: e.request.requester.phone.ddi,
                    number: e.request.requester.phone.number,
                },
                avatar: e.request.requester.avatar === null || e.request.requester.avatar === undefined ? null : {
                    width: e.request.requester.avatar.width || 0,
                    height: e.request.requester.avatar.height || 0,
                    url: e.request.requester.avatar.url,
                },
                address: e.request.requester.address === null || e.request.requester.address === undefined ? null : {
                    street: e.request.requester.address.street,
                    neighborhood: e.request.requester.address.neighborhood,
                    city: e.request.requester.address.city,
                    uf: e.request.requester.address.uf,
                    country: e.request.requester.address.country,
                    number: e.request.requester.address.number,
                    zip: e.request.requester.address.zip,
                    adicionalInfo: e.request.requester.address.adicionalInfo,
                    reference: e.request.requester.address.reference,
                    location: {
                        lat: e.request.requester.address.location.lat,
                        lon: e.request.requester.address.location.lon,
                    },
                },
                createdAt: new Date(e.request.requester.createdAt + "Z"),
                deletedAt: e.request.requester.deletedAt === null || e.request.requester.deletedAt === undefined ? null : new Date(e.request.requester.deletedAt + "Z"),
                blockedUntil: e.request.requester.blockedUntil === null || e.request.requester.blockedUntil === undefined ? null : new Date(e.request.requester.blockedUntil + "Z"),
                basePopularity: e.request.requester.basePopularity || 0,
                documentsVerified: !!e.request.requester.documentsVerified,
            },
            item: {
                thumbImage: {
                    width: e.request.item.thumbImage.width || 0,
                    height: e.request.item.thumbImage.height || 0,
                    url: e.request.item.thumbImage.url,
                },
                calendar: e.request.item.calendar.map((e: any) => ({
                    start: new Date(parseInt(e.start.split("-")[0], 10), parseInt(e.start.split("-")[1], 10) - 1, parseInt(e.start.split("-")[2], 10)),
                    end: new Date(parseInt(e.end.split("-")[0], 10), parseInt(e.end.split("-")[1], 10) - 1, parseInt(e.end.split("-")[2], 10)),
                })),
                adjustment: !!e.request.item.adjustment,
                category: e.request.item.category,
                color: e.request.item.color,
                details: e.request.item.details,
                gender: e.request.item.gender,
                images: e.request.item.images.map((e: any) => ({
                    width: e.width || 0,
                    height: e.height || 0,
                    url: e.url,
                })),
                brand: e.request.item.brand === null || e.request.item.brand === undefined ? null : e.request.item.brand,
                ocasions: e.request.item.ocasions.map((e: any) => e),
                size: e.request.item.size,
                salePrice: e.request.item.salePrice === null || e.request.item.salePrice === undefined ? null : e.request.item.salePrice || 0,
                rentPrice: e.request.item.rentPrice === null || e.request.item.rentPrice === undefined ? null : e.request.item.rentPrice || 0,
                originalPrice: e.request.item.originalPrice || 0,
            },
            transport: e.request.transport === null || e.request.transport === undefined ? null : {
                requester: {
                    street: e.request.transport.requester.street,
                    neighborhood: e.request.transport.requester.neighborhood,
                    city: e.request.transport.requester.city,
                    uf: e.request.transport.requester.uf,
                    country: e.request.transport.requester.country,
                    number: e.request.transport.requester.number,
                    zip: e.request.transport.requester.zip,
                    adicionalInfo: e.request.transport.requester.adicionalInfo,
                    reference: e.request.transport.requester.reference,
                    location: {
                        lat: e.request.transport.requester.location.lat,
                        lon: e.request.transport.requester.location.lon,
                    },
                },
                owner: {
                    street: e.request.transport.owner.street,
                    neighborhood: e.request.transport.owner.neighborhood,
                    city: e.request.transport.owner.city,
                    uf: e.request.transport.owner.uf,
                    country: e.request.transport.owner.country,
                    number: e.request.transport.owner.number,
                    zip: e.request.transport.owner.zip,
                    adicionalInfo: e.request.transport.owner.adicionalInfo,
                    reference: e.request.transport.owner.reference,
                    location: {
                        lat: e.request.transport.owner.location.lat,
                        lon: e.request.transport.owner.location.lon,
                    },
                },
                price: e.request.transport.price || 0,
            },
            period: {
                start: new Date(parseInt(e.request.period.start.split("-")[0], 10), parseInt(e.request.period.start.split("-")[1], 10) - 1, parseInt(e.request.period.start.split("-")[2], 10)),
                end: new Date(parseInt(e.request.period.end.split("-")[0], 10), parseInt(e.request.period.end.split("-")[1], 10) - 1, parseInt(e.request.period.end.split("-")[2], 10)),
            },
            rentPrice: e.request.rentPrice || 0,
            originalPrice: e.request.originalPrice || 0,
            statusList: e.request.statusList.map((e: any) => ({
                status: e.status,
                changeDate: new Date(parseInt(e.changeDate.split("-")[0], 10), parseInt(e.changeDate.split("-")[1], 10) - 1, parseInt(e.changeDate.split("-")[2], 10)),
                actionBy: e.actionBy === null || e.actionBy === undefined ? null : e.actionBy,
                pending: !!e.pending,
            })),
            currentStatus: {
                status: e.request.currentStatus.status,
                changeDate: new Date(parseInt(e.request.currentStatus.changeDate.split("-")[0], 10), parseInt(e.request.currentStatus.changeDate.split("-")[1], 10) - 1, parseInt(e.request.currentStatus.changeDate.split("-")[2], 10)),
                actionBy: e.request.currentStatus.actionBy === null || e.request.currentStatus.actionBy === undefined ? null : e.request.currentStatus.actionBy,
                pending: !!e.request.currentStatus.pending,
            },
            payment: e.request.payment === null || e.request.payment === undefined ? null : {
                transactionId: e.request.payment.transactionId || 0,
                lastDigits: e.request.payment.lastDigits,
                brand: e.request.payment.brand,
                paidAmount: e.request.payment.paidAmount || 0,
                status: e.request.payment.status,
                locSplit: {
                    id: e.request.payment.locSplit.id,
                    recipientId: e.request.payment.locSplit.recipientId,
                    amount: e.request.payment.locSplit.amount || 0,
                },
                ownerSplit: {
                    id: e.request.payment.ownerSplit.id,
                    recipientId: e.request.payment.ownerSplit.recipientId,
                    amount: e.request.payment.ownerSplit.amount || 0,
                },
            },
            promotion: {
                card: e.request.promotion.card === null || e.request.promotion.card === undefined ? null : {
                    transactionId: e.request.promotion.card.transactionId || 0,
                    lastDigits: e.request.promotion.card.lastDigits,
                    brand: e.request.promotion.card.brand,
                    paidAmount: e.request.promotion.card.paidAmount || 0,
                    status: e.request.promotion.card.status,
                    locSplit: {
                        id: e.request.promotion.card.locSplit.id,
                        recipientId: e.request.promotion.card.locSplit.recipientId,
                        amount: e.request.promotion.card.locSplit.amount || 0,
                    },
                    ownerSplit: {
                        id: e.request.promotion.card.ownerSplit.id,
                        recipientId: e.request.promotion.card.ownerSplit.recipientId,
                        amount: e.request.promotion.card.ownerSplit.amount || 0,
                    },
                },
                type: e.request.promotion.type === null || e.request.promotion.type === undefined ? null : e.request.promotion.type,
                total: e.request.promotion.total || 0,
                id: e.request.promotion.id === null || e.request.promotion.id === undefined ? null : e.request.promotion.id,
            },
            nfe: {
                id: e.request.nfe.id === null || e.request.nfe.id === undefined ? null : e.request.nfe.id,
                number: e.request.nfe.number === null || e.request.nfe.number === undefined ? null : e.request.nfe.number || 0,
                emitedAt: e.request.nfe.emitedAt === null || e.request.nfe.emitedAt === undefined ? null : new Date(e.request.nfe.emitedAt + "Z"),
                canceledAt: e.request.nfe.canceledAt === null || e.request.nfe.canceledAt === undefined ? null : new Date(e.request.nfe.canceledAt + "Z"),
                status: e.request.nfe.status === null || e.request.nfe.status === undefined ? null : e.request.nfe.status,
            },
            createdAt: new Date(e.request.createdAt + "Z"),
        },
        createdAt: new Date(e.createdAt + "Z"),
        reason: e.reason,
        amount: e.amount || 0,
    }));
}

export async function getFinesPayedToUser(userId: string, progress?: (progress: number) => void): Promise<Fine[]> {
    const args = {
        userId: userId,
    };
    const ret = await makeRequest({name: "getFinesPayedToUser", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        transactionId: e.transactionId || 0,
        payedBy: e.payedBy === null || e.payedBy === undefined ? null : {
            id: e.payedBy.id,
            gender: e.payedBy.gender === null || e.payedBy.gender === undefined ? null : e.payedBy.gender,
            birthday: e.payedBy.birthday === null || e.payedBy.birthday === undefined ? null : new Date(parseInt(e.payedBy.birthday.split("-")[0], 10), parseInt(e.payedBy.birthday.split("-")[1], 10) - 1, parseInt(e.payedBy.birthday.split("-")[2], 10)),
            type: e.payedBy.type,
            name: e.payedBy.name,
            nick: e.payedBy.nick,
            cpf: e.payedBy.cpf,
            email: e.payedBy.email,
            phone: {
                ddd: e.payedBy.phone.ddd,
                ddi: e.payedBy.phone.ddi,
                number: e.payedBy.phone.number,
            },
            avatar: e.payedBy.avatar === null || e.payedBy.avatar === undefined ? null : {
                width: e.payedBy.avatar.width || 0,
                height: e.payedBy.avatar.height || 0,
                url: e.payedBy.avatar.url,
            },
            address: e.payedBy.address === null || e.payedBy.address === undefined ? null : {
                street: e.payedBy.address.street,
                neighborhood: e.payedBy.address.neighborhood,
                city: e.payedBy.address.city,
                uf: e.payedBy.address.uf,
                country: e.payedBy.address.country,
                number: e.payedBy.address.number,
                zip: e.payedBy.address.zip,
                adicionalInfo: e.payedBy.address.adicionalInfo,
                reference: e.payedBy.address.reference,
                location: {
                    lat: e.payedBy.address.location.lat,
                    lon: e.payedBy.address.location.lon,
                },
            },
            createdAt: new Date(e.payedBy.createdAt + "Z"),
            deletedAt: e.payedBy.deletedAt === null || e.payedBy.deletedAt === undefined ? null : new Date(e.payedBy.deletedAt + "Z"),
            blockedUntil: e.payedBy.blockedUntil === null || e.payedBy.blockedUntil === undefined ? null : new Date(e.payedBy.blockedUntil + "Z"),
            basePopularity: e.payedBy.basePopularity || 0,
            documentsVerified: !!e.payedBy.documentsVerified,
        },
        payedTo: e.payedTo === null || e.payedTo === undefined ? null : {
            id: e.payedTo.id,
            gender: e.payedTo.gender === null || e.payedTo.gender === undefined ? null : e.payedTo.gender,
            birthday: e.payedTo.birthday === null || e.payedTo.birthday === undefined ? null : new Date(parseInt(e.payedTo.birthday.split("-")[0], 10), parseInt(e.payedTo.birthday.split("-")[1], 10) - 1, parseInt(e.payedTo.birthday.split("-")[2], 10)),
            type: e.payedTo.type,
            name: e.payedTo.name,
            nick: e.payedTo.nick,
            cpf: e.payedTo.cpf,
            email: e.payedTo.email,
            phone: {
                ddd: e.payedTo.phone.ddd,
                ddi: e.payedTo.phone.ddi,
                number: e.payedTo.phone.number,
            },
            avatar: e.payedTo.avatar === null || e.payedTo.avatar === undefined ? null : {
                width: e.payedTo.avatar.width || 0,
                height: e.payedTo.avatar.height || 0,
                url: e.payedTo.avatar.url,
            },
            address: e.payedTo.address === null || e.payedTo.address === undefined ? null : {
                street: e.payedTo.address.street,
                neighborhood: e.payedTo.address.neighborhood,
                city: e.payedTo.address.city,
                uf: e.payedTo.address.uf,
                country: e.payedTo.address.country,
                number: e.payedTo.address.number,
                zip: e.payedTo.address.zip,
                adicionalInfo: e.payedTo.address.adicionalInfo,
                reference: e.payedTo.address.reference,
                location: {
                    lat: e.payedTo.address.location.lat,
                    lon: e.payedTo.address.location.lon,
                },
            },
            createdAt: new Date(e.payedTo.createdAt + "Z"),
            deletedAt: e.payedTo.deletedAt === null || e.payedTo.deletedAt === undefined ? null : new Date(e.payedTo.deletedAt + "Z"),
            blockedUntil: e.payedTo.blockedUntil === null || e.payedTo.blockedUntil === undefined ? null : new Date(e.payedTo.blockedUntil + "Z"),
            basePopularity: e.payedTo.basePopularity || 0,
            documentsVerified: !!e.payedTo.documentsVerified,
        },
        request: e.request === null || e.request === undefined ? null : {
            id: e.request.id,
            numId: e.request.numId,
            owner: {
                id: e.request.owner.id,
                gender: e.request.owner.gender === null || e.request.owner.gender === undefined ? null : e.request.owner.gender,
                birthday: e.request.owner.birthday === null || e.request.owner.birthday === undefined ? null : new Date(parseInt(e.request.owner.birthday.split("-")[0], 10), parseInt(e.request.owner.birthday.split("-")[1], 10) - 1, parseInt(e.request.owner.birthday.split("-")[2], 10)),
                type: e.request.owner.type,
                name: e.request.owner.name,
                nick: e.request.owner.nick,
                cpf: e.request.owner.cpf,
                email: e.request.owner.email,
                phone: {
                    ddd: e.request.owner.phone.ddd,
                    ddi: e.request.owner.phone.ddi,
                    number: e.request.owner.phone.number,
                },
                avatar: e.request.owner.avatar === null || e.request.owner.avatar === undefined ? null : {
                    width: e.request.owner.avatar.width || 0,
                    height: e.request.owner.avatar.height || 0,
                    url: e.request.owner.avatar.url,
                },
                address: e.request.owner.address === null || e.request.owner.address === undefined ? null : {
                    street: e.request.owner.address.street,
                    neighborhood: e.request.owner.address.neighborhood,
                    city: e.request.owner.address.city,
                    uf: e.request.owner.address.uf,
                    country: e.request.owner.address.country,
                    number: e.request.owner.address.number,
                    zip: e.request.owner.address.zip,
                    adicionalInfo: e.request.owner.address.adicionalInfo,
                    reference: e.request.owner.address.reference,
                    location: {
                        lat: e.request.owner.address.location.lat,
                        lon: e.request.owner.address.location.lon,
                    },
                },
                createdAt: new Date(e.request.owner.createdAt + "Z"),
                deletedAt: e.request.owner.deletedAt === null || e.request.owner.deletedAt === undefined ? null : new Date(e.request.owner.deletedAt + "Z"),
                blockedUntil: e.request.owner.blockedUntil === null || e.request.owner.blockedUntil === undefined ? null : new Date(e.request.owner.blockedUntil + "Z"),
                basePopularity: e.request.owner.basePopularity || 0,
                documentsVerified: !!e.request.owner.documentsVerified,
            },
            requester: {
                id: e.request.requester.id,
                gender: e.request.requester.gender === null || e.request.requester.gender === undefined ? null : e.request.requester.gender,
                birthday: e.request.requester.birthday === null || e.request.requester.birthday === undefined ? null : new Date(parseInt(e.request.requester.birthday.split("-")[0], 10), parseInt(e.request.requester.birthday.split("-")[1], 10) - 1, parseInt(e.request.requester.birthday.split("-")[2], 10)),
                type: e.request.requester.type,
                name: e.request.requester.name,
                nick: e.request.requester.nick,
                cpf: e.request.requester.cpf,
                email: e.request.requester.email,
                phone: {
                    ddd: e.request.requester.phone.ddd,
                    ddi: e.request.requester.phone.ddi,
                    number: e.request.requester.phone.number,
                },
                avatar: e.request.requester.avatar === null || e.request.requester.avatar === undefined ? null : {
                    width: e.request.requester.avatar.width || 0,
                    height: e.request.requester.avatar.height || 0,
                    url: e.request.requester.avatar.url,
                },
                address: e.request.requester.address === null || e.request.requester.address === undefined ? null : {
                    street: e.request.requester.address.street,
                    neighborhood: e.request.requester.address.neighborhood,
                    city: e.request.requester.address.city,
                    uf: e.request.requester.address.uf,
                    country: e.request.requester.address.country,
                    number: e.request.requester.address.number,
                    zip: e.request.requester.address.zip,
                    adicionalInfo: e.request.requester.address.adicionalInfo,
                    reference: e.request.requester.address.reference,
                    location: {
                        lat: e.request.requester.address.location.lat,
                        lon: e.request.requester.address.location.lon,
                    },
                },
                createdAt: new Date(e.request.requester.createdAt + "Z"),
                deletedAt: e.request.requester.deletedAt === null || e.request.requester.deletedAt === undefined ? null : new Date(e.request.requester.deletedAt + "Z"),
                blockedUntil: e.request.requester.blockedUntil === null || e.request.requester.blockedUntil === undefined ? null : new Date(e.request.requester.blockedUntil + "Z"),
                basePopularity: e.request.requester.basePopularity || 0,
                documentsVerified: !!e.request.requester.documentsVerified,
            },
            item: {
                thumbImage: {
                    width: e.request.item.thumbImage.width || 0,
                    height: e.request.item.thumbImage.height || 0,
                    url: e.request.item.thumbImage.url,
                },
                calendar: e.request.item.calendar.map((e: any) => ({
                    start: new Date(parseInt(e.start.split("-")[0], 10), parseInt(e.start.split("-")[1], 10) - 1, parseInt(e.start.split("-")[2], 10)),
                    end: new Date(parseInt(e.end.split("-")[0], 10), parseInt(e.end.split("-")[1], 10) - 1, parseInt(e.end.split("-")[2], 10)),
                })),
                adjustment: !!e.request.item.adjustment,
                category: e.request.item.category,
                color: e.request.item.color,
                details: e.request.item.details,
                gender: e.request.item.gender,
                images: e.request.item.images.map((e: any) => ({
                    width: e.width || 0,
                    height: e.height || 0,
                    url: e.url,
                })),
                brand: e.request.item.brand === null || e.request.item.brand === undefined ? null : e.request.item.brand,
                ocasions: e.request.item.ocasions.map((e: any) => e),
                size: e.request.item.size,
                salePrice: e.request.item.salePrice === null || e.request.item.salePrice === undefined ? null : e.request.item.salePrice || 0,
                rentPrice: e.request.item.rentPrice === null || e.request.item.rentPrice === undefined ? null : e.request.item.rentPrice || 0,
                originalPrice: e.request.item.originalPrice || 0,
            },
            transport: e.request.transport === null || e.request.transport === undefined ? null : {
                requester: {
                    street: e.request.transport.requester.street,
                    neighborhood: e.request.transport.requester.neighborhood,
                    city: e.request.transport.requester.city,
                    uf: e.request.transport.requester.uf,
                    country: e.request.transport.requester.country,
                    number: e.request.transport.requester.number,
                    zip: e.request.transport.requester.zip,
                    adicionalInfo: e.request.transport.requester.adicionalInfo,
                    reference: e.request.transport.requester.reference,
                    location: {
                        lat: e.request.transport.requester.location.lat,
                        lon: e.request.transport.requester.location.lon,
                    },
                },
                owner: {
                    street: e.request.transport.owner.street,
                    neighborhood: e.request.transport.owner.neighborhood,
                    city: e.request.transport.owner.city,
                    uf: e.request.transport.owner.uf,
                    country: e.request.transport.owner.country,
                    number: e.request.transport.owner.number,
                    zip: e.request.transport.owner.zip,
                    adicionalInfo: e.request.transport.owner.adicionalInfo,
                    reference: e.request.transport.owner.reference,
                    location: {
                        lat: e.request.transport.owner.location.lat,
                        lon: e.request.transport.owner.location.lon,
                    },
                },
                price: e.request.transport.price || 0,
            },
            period: {
                start: new Date(parseInt(e.request.period.start.split("-")[0], 10), parseInt(e.request.period.start.split("-")[1], 10) - 1, parseInt(e.request.period.start.split("-")[2], 10)),
                end: new Date(parseInt(e.request.period.end.split("-")[0], 10), parseInt(e.request.period.end.split("-")[1], 10) - 1, parseInt(e.request.period.end.split("-")[2], 10)),
            },
            rentPrice: e.request.rentPrice || 0,
            originalPrice: e.request.originalPrice || 0,
            statusList: e.request.statusList.map((e: any) => ({
                status: e.status,
                changeDate: new Date(parseInt(e.changeDate.split("-")[0], 10), parseInt(e.changeDate.split("-")[1], 10) - 1, parseInt(e.changeDate.split("-")[2], 10)),
                actionBy: e.actionBy === null || e.actionBy === undefined ? null : e.actionBy,
                pending: !!e.pending,
            })),
            currentStatus: {
                status: e.request.currentStatus.status,
                changeDate: new Date(parseInt(e.request.currentStatus.changeDate.split("-")[0], 10), parseInt(e.request.currentStatus.changeDate.split("-")[1], 10) - 1, parseInt(e.request.currentStatus.changeDate.split("-")[2], 10)),
                actionBy: e.request.currentStatus.actionBy === null || e.request.currentStatus.actionBy === undefined ? null : e.request.currentStatus.actionBy,
                pending: !!e.request.currentStatus.pending,
            },
            payment: e.request.payment === null || e.request.payment === undefined ? null : {
                transactionId: e.request.payment.transactionId || 0,
                lastDigits: e.request.payment.lastDigits,
                brand: e.request.payment.brand,
                paidAmount: e.request.payment.paidAmount || 0,
                status: e.request.payment.status,
                locSplit: {
                    id: e.request.payment.locSplit.id,
                    recipientId: e.request.payment.locSplit.recipientId,
                    amount: e.request.payment.locSplit.amount || 0,
                },
                ownerSplit: {
                    id: e.request.payment.ownerSplit.id,
                    recipientId: e.request.payment.ownerSplit.recipientId,
                    amount: e.request.payment.ownerSplit.amount || 0,
                },
            },
            promotion: {
                card: e.request.promotion.card === null || e.request.promotion.card === undefined ? null : {
                    transactionId: e.request.promotion.card.transactionId || 0,
                    lastDigits: e.request.promotion.card.lastDigits,
                    brand: e.request.promotion.card.brand,
                    paidAmount: e.request.promotion.card.paidAmount || 0,
                    status: e.request.promotion.card.status,
                    locSplit: {
                        id: e.request.promotion.card.locSplit.id,
                        recipientId: e.request.promotion.card.locSplit.recipientId,
                        amount: e.request.promotion.card.locSplit.amount || 0,
                    },
                    ownerSplit: {
                        id: e.request.promotion.card.ownerSplit.id,
                        recipientId: e.request.promotion.card.ownerSplit.recipientId,
                        amount: e.request.promotion.card.ownerSplit.amount || 0,
                    },
                },
                type: e.request.promotion.type === null || e.request.promotion.type === undefined ? null : e.request.promotion.type,
                total: e.request.promotion.total || 0,
                id: e.request.promotion.id === null || e.request.promotion.id === undefined ? null : e.request.promotion.id,
            },
            nfe: {
                id: e.request.nfe.id === null || e.request.nfe.id === undefined ? null : e.request.nfe.id,
                number: e.request.nfe.number === null || e.request.nfe.number === undefined ? null : e.request.nfe.number || 0,
                emitedAt: e.request.nfe.emitedAt === null || e.request.nfe.emitedAt === undefined ? null : new Date(e.request.nfe.emitedAt + "Z"),
                canceledAt: e.request.nfe.canceledAt === null || e.request.nfe.canceledAt === undefined ? null : new Date(e.request.nfe.canceledAt + "Z"),
                status: e.request.nfe.status === null || e.request.nfe.status === undefined ? null : e.request.nfe.status,
            },
            createdAt: new Date(e.request.createdAt + "Z"),
        },
        createdAt: new Date(e.createdAt + "Z"),
        reason: e.reason,
        amount: e.amount || 0,
    }));
}

export async function createRequestFine(fine: CreateRequestFine, progress?: (progress: number) => void): Promise<Fine> {
    const args = {
        fine: {
            requestId: fine.requestId === null || fine.requestId === undefined ? null : fine.requestId,
            payedBy: fine.payedBy === null || fine.payedBy === undefined ? null : fine.payedBy,
            payedTo: fine.payedTo === null || fine.payedTo === undefined ? null : fine.payedTo,
            reason: fine.reason,
            amount: fine.amount || 0,
        },
    };
    const ret = await makeRequest({name: "createRequestFine", args, progress});
    return {
        id: ret.id,
        transactionId: ret.transactionId || 0,
        payedBy: ret.payedBy === null || ret.payedBy === undefined ? null : {
            id: ret.payedBy.id,
            gender: ret.payedBy.gender === null || ret.payedBy.gender === undefined ? null : ret.payedBy.gender,
            birthday: ret.payedBy.birthday === null || ret.payedBy.birthday === undefined ? null : new Date(parseInt(ret.payedBy.birthday.split("-")[0], 10), parseInt(ret.payedBy.birthday.split("-")[1], 10) - 1, parseInt(ret.payedBy.birthday.split("-")[2], 10)),
            type: ret.payedBy.type,
            name: ret.payedBy.name,
            nick: ret.payedBy.nick,
            cpf: ret.payedBy.cpf,
            email: ret.payedBy.email,
            phone: {
                ddd: ret.payedBy.phone.ddd,
                ddi: ret.payedBy.phone.ddi,
                number: ret.payedBy.phone.number,
            },
            avatar: ret.payedBy.avatar === null || ret.payedBy.avatar === undefined ? null : {
                width: ret.payedBy.avatar.width || 0,
                height: ret.payedBy.avatar.height || 0,
                url: ret.payedBy.avatar.url,
            },
            address: ret.payedBy.address === null || ret.payedBy.address === undefined ? null : {
                street: ret.payedBy.address.street,
                neighborhood: ret.payedBy.address.neighborhood,
                city: ret.payedBy.address.city,
                uf: ret.payedBy.address.uf,
                country: ret.payedBy.address.country,
                number: ret.payedBy.address.number,
                zip: ret.payedBy.address.zip,
                adicionalInfo: ret.payedBy.address.adicionalInfo,
                reference: ret.payedBy.address.reference,
                location: {
                    lat: ret.payedBy.address.location.lat,
                    lon: ret.payedBy.address.location.lon,
                },
            },
            createdAt: new Date(ret.payedBy.createdAt + "Z"),
            deletedAt: ret.payedBy.deletedAt === null || ret.payedBy.deletedAt === undefined ? null : new Date(ret.payedBy.deletedAt + "Z"),
            blockedUntil: ret.payedBy.blockedUntil === null || ret.payedBy.blockedUntil === undefined ? null : new Date(ret.payedBy.blockedUntil + "Z"),
            basePopularity: ret.payedBy.basePopularity || 0,
            documentsVerified: !!ret.payedBy.documentsVerified,
        },
        payedTo: ret.payedTo === null || ret.payedTo === undefined ? null : {
            id: ret.payedTo.id,
            gender: ret.payedTo.gender === null || ret.payedTo.gender === undefined ? null : ret.payedTo.gender,
            birthday: ret.payedTo.birthday === null || ret.payedTo.birthday === undefined ? null : new Date(parseInt(ret.payedTo.birthday.split("-")[0], 10), parseInt(ret.payedTo.birthday.split("-")[1], 10) - 1, parseInt(ret.payedTo.birthday.split("-")[2], 10)),
            type: ret.payedTo.type,
            name: ret.payedTo.name,
            nick: ret.payedTo.nick,
            cpf: ret.payedTo.cpf,
            email: ret.payedTo.email,
            phone: {
                ddd: ret.payedTo.phone.ddd,
                ddi: ret.payedTo.phone.ddi,
                number: ret.payedTo.phone.number,
            },
            avatar: ret.payedTo.avatar === null || ret.payedTo.avatar === undefined ? null : {
                width: ret.payedTo.avatar.width || 0,
                height: ret.payedTo.avatar.height || 0,
                url: ret.payedTo.avatar.url,
            },
            address: ret.payedTo.address === null || ret.payedTo.address === undefined ? null : {
                street: ret.payedTo.address.street,
                neighborhood: ret.payedTo.address.neighborhood,
                city: ret.payedTo.address.city,
                uf: ret.payedTo.address.uf,
                country: ret.payedTo.address.country,
                number: ret.payedTo.address.number,
                zip: ret.payedTo.address.zip,
                adicionalInfo: ret.payedTo.address.adicionalInfo,
                reference: ret.payedTo.address.reference,
                location: {
                    lat: ret.payedTo.address.location.lat,
                    lon: ret.payedTo.address.location.lon,
                },
            },
            createdAt: new Date(ret.payedTo.createdAt + "Z"),
            deletedAt: ret.payedTo.deletedAt === null || ret.payedTo.deletedAt === undefined ? null : new Date(ret.payedTo.deletedAt + "Z"),
            blockedUntil: ret.payedTo.blockedUntil === null || ret.payedTo.blockedUntil === undefined ? null : new Date(ret.payedTo.blockedUntil + "Z"),
            basePopularity: ret.payedTo.basePopularity || 0,
            documentsVerified: !!ret.payedTo.documentsVerified,
        },
        request: ret.request === null || ret.request === undefined ? null : {
            id: ret.request.id,
            numId: ret.request.numId,
            owner: {
                id: ret.request.owner.id,
                gender: ret.request.owner.gender === null || ret.request.owner.gender === undefined ? null : ret.request.owner.gender,
                birthday: ret.request.owner.birthday === null || ret.request.owner.birthday === undefined ? null : new Date(parseInt(ret.request.owner.birthday.split("-")[0], 10), parseInt(ret.request.owner.birthday.split("-")[1], 10) - 1, parseInt(ret.request.owner.birthday.split("-")[2], 10)),
                type: ret.request.owner.type,
                name: ret.request.owner.name,
                nick: ret.request.owner.nick,
                cpf: ret.request.owner.cpf,
                email: ret.request.owner.email,
                phone: {
                    ddd: ret.request.owner.phone.ddd,
                    ddi: ret.request.owner.phone.ddi,
                    number: ret.request.owner.phone.number,
                },
                avatar: ret.request.owner.avatar === null || ret.request.owner.avatar === undefined ? null : {
                    width: ret.request.owner.avatar.width || 0,
                    height: ret.request.owner.avatar.height || 0,
                    url: ret.request.owner.avatar.url,
                },
                address: ret.request.owner.address === null || ret.request.owner.address === undefined ? null : {
                    street: ret.request.owner.address.street,
                    neighborhood: ret.request.owner.address.neighborhood,
                    city: ret.request.owner.address.city,
                    uf: ret.request.owner.address.uf,
                    country: ret.request.owner.address.country,
                    number: ret.request.owner.address.number,
                    zip: ret.request.owner.address.zip,
                    adicionalInfo: ret.request.owner.address.adicionalInfo,
                    reference: ret.request.owner.address.reference,
                    location: {
                        lat: ret.request.owner.address.location.lat,
                        lon: ret.request.owner.address.location.lon,
                    },
                },
                createdAt: new Date(ret.request.owner.createdAt + "Z"),
                deletedAt: ret.request.owner.deletedAt === null || ret.request.owner.deletedAt === undefined ? null : new Date(ret.request.owner.deletedAt + "Z"),
                blockedUntil: ret.request.owner.blockedUntil === null || ret.request.owner.blockedUntil === undefined ? null : new Date(ret.request.owner.blockedUntil + "Z"),
                basePopularity: ret.request.owner.basePopularity || 0,
                documentsVerified: !!ret.request.owner.documentsVerified,
            },
            requester: {
                id: ret.request.requester.id,
                gender: ret.request.requester.gender === null || ret.request.requester.gender === undefined ? null : ret.request.requester.gender,
                birthday: ret.request.requester.birthday === null || ret.request.requester.birthday === undefined ? null : new Date(parseInt(ret.request.requester.birthday.split("-")[0], 10), parseInt(ret.request.requester.birthday.split("-")[1], 10) - 1, parseInt(ret.request.requester.birthday.split("-")[2], 10)),
                type: ret.request.requester.type,
                name: ret.request.requester.name,
                nick: ret.request.requester.nick,
                cpf: ret.request.requester.cpf,
                email: ret.request.requester.email,
                phone: {
                    ddd: ret.request.requester.phone.ddd,
                    ddi: ret.request.requester.phone.ddi,
                    number: ret.request.requester.phone.number,
                },
                avatar: ret.request.requester.avatar === null || ret.request.requester.avatar === undefined ? null : {
                    width: ret.request.requester.avatar.width || 0,
                    height: ret.request.requester.avatar.height || 0,
                    url: ret.request.requester.avatar.url,
                },
                address: ret.request.requester.address === null || ret.request.requester.address === undefined ? null : {
                    street: ret.request.requester.address.street,
                    neighborhood: ret.request.requester.address.neighborhood,
                    city: ret.request.requester.address.city,
                    uf: ret.request.requester.address.uf,
                    country: ret.request.requester.address.country,
                    number: ret.request.requester.address.number,
                    zip: ret.request.requester.address.zip,
                    adicionalInfo: ret.request.requester.address.adicionalInfo,
                    reference: ret.request.requester.address.reference,
                    location: {
                        lat: ret.request.requester.address.location.lat,
                        lon: ret.request.requester.address.location.lon,
                    },
                },
                createdAt: new Date(ret.request.requester.createdAt + "Z"),
                deletedAt: ret.request.requester.deletedAt === null || ret.request.requester.deletedAt === undefined ? null : new Date(ret.request.requester.deletedAt + "Z"),
                blockedUntil: ret.request.requester.blockedUntil === null || ret.request.requester.blockedUntil === undefined ? null : new Date(ret.request.requester.blockedUntil + "Z"),
                basePopularity: ret.request.requester.basePopularity || 0,
                documentsVerified: !!ret.request.requester.documentsVerified,
            },
            item: {
                thumbImage: {
                    width: ret.request.item.thumbImage.width || 0,
                    height: ret.request.item.thumbImage.height || 0,
                    url: ret.request.item.thumbImage.url,
                },
                calendar: ret.request.item.calendar.map((e: any) => ({
                    start: new Date(parseInt(e.start.split("-")[0], 10), parseInt(e.start.split("-")[1], 10) - 1, parseInt(e.start.split("-")[2], 10)),
                    end: new Date(parseInt(e.end.split("-")[0], 10), parseInt(e.end.split("-")[1], 10) - 1, parseInt(e.end.split("-")[2], 10)),
                })),
                adjustment: !!ret.request.item.adjustment,
                category: ret.request.item.category,
                color: ret.request.item.color,
                details: ret.request.item.details,
                gender: ret.request.item.gender,
                images: ret.request.item.images.map((e: any) => ({
                    width: e.width || 0,
                    height: e.height || 0,
                    url: e.url,
                })),
                brand: ret.request.item.brand === null || ret.request.item.brand === undefined ? null : ret.request.item.brand,
                ocasions: ret.request.item.ocasions.map((e: any) => e),
                size: ret.request.item.size,
                salePrice: ret.request.item.salePrice === null || ret.request.item.salePrice === undefined ? null : ret.request.item.salePrice || 0,
                rentPrice: ret.request.item.rentPrice === null || ret.request.item.rentPrice === undefined ? null : ret.request.item.rentPrice || 0,
                originalPrice: ret.request.item.originalPrice || 0,
            },
            transport: ret.request.transport === null || ret.request.transport === undefined ? null : {
                requester: {
                    street: ret.request.transport.requester.street,
                    neighborhood: ret.request.transport.requester.neighborhood,
                    city: ret.request.transport.requester.city,
                    uf: ret.request.transport.requester.uf,
                    country: ret.request.transport.requester.country,
                    number: ret.request.transport.requester.number,
                    zip: ret.request.transport.requester.zip,
                    adicionalInfo: ret.request.transport.requester.adicionalInfo,
                    reference: ret.request.transport.requester.reference,
                    location: {
                        lat: ret.request.transport.requester.location.lat,
                        lon: ret.request.transport.requester.location.lon,
                    },
                },
                owner: {
                    street: ret.request.transport.owner.street,
                    neighborhood: ret.request.transport.owner.neighborhood,
                    city: ret.request.transport.owner.city,
                    uf: ret.request.transport.owner.uf,
                    country: ret.request.transport.owner.country,
                    number: ret.request.transport.owner.number,
                    zip: ret.request.transport.owner.zip,
                    adicionalInfo: ret.request.transport.owner.adicionalInfo,
                    reference: ret.request.transport.owner.reference,
                    location: {
                        lat: ret.request.transport.owner.location.lat,
                        lon: ret.request.transport.owner.location.lon,
                    },
                },
                price: ret.request.transport.price || 0,
            },
            period: {
                start: new Date(parseInt(ret.request.period.start.split("-")[0], 10), parseInt(ret.request.period.start.split("-")[1], 10) - 1, parseInt(ret.request.period.start.split("-")[2], 10)),
                end: new Date(parseInt(ret.request.period.end.split("-")[0], 10), parseInt(ret.request.period.end.split("-")[1], 10) - 1, parseInt(ret.request.period.end.split("-")[2], 10)),
            },
            rentPrice: ret.request.rentPrice || 0,
            originalPrice: ret.request.originalPrice || 0,
            statusList: ret.request.statusList.map((e: any) => ({
                status: e.status,
                changeDate: new Date(parseInt(e.changeDate.split("-")[0], 10), parseInt(e.changeDate.split("-")[1], 10) - 1, parseInt(e.changeDate.split("-")[2], 10)),
                actionBy: e.actionBy === null || e.actionBy === undefined ? null : e.actionBy,
                pending: !!e.pending,
            })),
            currentStatus: {
                status: ret.request.currentStatus.status,
                changeDate: new Date(parseInt(ret.request.currentStatus.changeDate.split("-")[0], 10), parseInt(ret.request.currentStatus.changeDate.split("-")[1], 10) - 1, parseInt(ret.request.currentStatus.changeDate.split("-")[2], 10)),
                actionBy: ret.request.currentStatus.actionBy === null || ret.request.currentStatus.actionBy === undefined ? null : ret.request.currentStatus.actionBy,
                pending: !!ret.request.currentStatus.pending,
            },
            payment: ret.request.payment === null || ret.request.payment === undefined ? null : {
                transactionId: ret.request.payment.transactionId || 0,
                lastDigits: ret.request.payment.lastDigits,
                brand: ret.request.payment.brand,
                paidAmount: ret.request.payment.paidAmount || 0,
                status: ret.request.payment.status,
                locSplit: {
                    id: ret.request.payment.locSplit.id,
                    recipientId: ret.request.payment.locSplit.recipientId,
                    amount: ret.request.payment.locSplit.amount || 0,
                },
                ownerSplit: {
                    id: ret.request.payment.ownerSplit.id,
                    recipientId: ret.request.payment.ownerSplit.recipientId,
                    amount: ret.request.payment.ownerSplit.amount || 0,
                },
            },
            promotion: {
                card: ret.request.promotion.card === null || ret.request.promotion.card === undefined ? null : {
                    transactionId: ret.request.promotion.card.transactionId || 0,
                    lastDigits: ret.request.promotion.card.lastDigits,
                    brand: ret.request.promotion.card.brand,
                    paidAmount: ret.request.promotion.card.paidAmount || 0,
                    status: ret.request.promotion.card.status,
                    locSplit: {
                        id: ret.request.promotion.card.locSplit.id,
                        recipientId: ret.request.promotion.card.locSplit.recipientId,
                        amount: ret.request.promotion.card.locSplit.amount || 0,
                    },
                    ownerSplit: {
                        id: ret.request.promotion.card.ownerSplit.id,
                        recipientId: ret.request.promotion.card.ownerSplit.recipientId,
                        amount: ret.request.promotion.card.ownerSplit.amount || 0,
                    },
                },
                type: ret.request.promotion.type === null || ret.request.promotion.type === undefined ? null : ret.request.promotion.type,
                total: ret.request.promotion.total || 0,
                id: ret.request.promotion.id === null || ret.request.promotion.id === undefined ? null : ret.request.promotion.id,
            },
            nfe: {
                id: ret.request.nfe.id === null || ret.request.nfe.id === undefined ? null : ret.request.nfe.id,
                number: ret.request.nfe.number === null || ret.request.nfe.number === undefined ? null : ret.request.nfe.number || 0,
                emitedAt: ret.request.nfe.emitedAt === null || ret.request.nfe.emitedAt === undefined ? null : new Date(ret.request.nfe.emitedAt + "Z"),
                canceledAt: ret.request.nfe.canceledAt === null || ret.request.nfe.canceledAt === undefined ? null : new Date(ret.request.nfe.canceledAt + "Z"),
                status: ret.request.nfe.status === null || ret.request.nfe.status === undefined ? null : ret.request.nfe.status,
            },
            createdAt: new Date(ret.request.createdAt + "Z"),
        },
        createdAt: new Date(ret.createdAt + "Z"),
        reason: ret.reason,
        amount: ret.amount || 0,
    };
}

export async function createFine(fine: CreateFine, progress?: (progress: number) => void): Promise<Fine> {
    const args = {
        fine: {
            payedById: fine.payedById === null || fine.payedById === undefined ? null : fine.payedById,
            payedToId: fine.payedToId === null || fine.payedToId === undefined ? null : fine.payedToId,
            reason: fine.reason,
            amount: fine.amount || 0,
        },
    };
    const ret = await makeRequest({name: "createFine", args, progress});
    return {
        id: ret.id,
        transactionId: ret.transactionId || 0,
        payedBy: ret.payedBy === null || ret.payedBy === undefined ? null : {
            id: ret.payedBy.id,
            gender: ret.payedBy.gender === null || ret.payedBy.gender === undefined ? null : ret.payedBy.gender,
            birthday: ret.payedBy.birthday === null || ret.payedBy.birthday === undefined ? null : new Date(parseInt(ret.payedBy.birthday.split("-")[0], 10), parseInt(ret.payedBy.birthday.split("-")[1], 10) - 1, parseInt(ret.payedBy.birthday.split("-")[2], 10)),
            type: ret.payedBy.type,
            name: ret.payedBy.name,
            nick: ret.payedBy.nick,
            cpf: ret.payedBy.cpf,
            email: ret.payedBy.email,
            phone: {
                ddd: ret.payedBy.phone.ddd,
                ddi: ret.payedBy.phone.ddi,
                number: ret.payedBy.phone.number,
            },
            avatar: ret.payedBy.avatar === null || ret.payedBy.avatar === undefined ? null : {
                width: ret.payedBy.avatar.width || 0,
                height: ret.payedBy.avatar.height || 0,
                url: ret.payedBy.avatar.url,
            },
            address: ret.payedBy.address === null || ret.payedBy.address === undefined ? null : {
                street: ret.payedBy.address.street,
                neighborhood: ret.payedBy.address.neighborhood,
                city: ret.payedBy.address.city,
                uf: ret.payedBy.address.uf,
                country: ret.payedBy.address.country,
                number: ret.payedBy.address.number,
                zip: ret.payedBy.address.zip,
                adicionalInfo: ret.payedBy.address.adicionalInfo,
                reference: ret.payedBy.address.reference,
                location: {
                    lat: ret.payedBy.address.location.lat,
                    lon: ret.payedBy.address.location.lon,
                },
            },
            createdAt: new Date(ret.payedBy.createdAt + "Z"),
            deletedAt: ret.payedBy.deletedAt === null || ret.payedBy.deletedAt === undefined ? null : new Date(ret.payedBy.deletedAt + "Z"),
            blockedUntil: ret.payedBy.blockedUntil === null || ret.payedBy.blockedUntil === undefined ? null : new Date(ret.payedBy.blockedUntil + "Z"),
            basePopularity: ret.payedBy.basePopularity || 0,
            documentsVerified: !!ret.payedBy.documentsVerified,
        },
        payedTo: ret.payedTo === null || ret.payedTo === undefined ? null : {
            id: ret.payedTo.id,
            gender: ret.payedTo.gender === null || ret.payedTo.gender === undefined ? null : ret.payedTo.gender,
            birthday: ret.payedTo.birthday === null || ret.payedTo.birthday === undefined ? null : new Date(parseInt(ret.payedTo.birthday.split("-")[0], 10), parseInt(ret.payedTo.birthday.split("-")[1], 10) - 1, parseInt(ret.payedTo.birthday.split("-")[2], 10)),
            type: ret.payedTo.type,
            name: ret.payedTo.name,
            nick: ret.payedTo.nick,
            cpf: ret.payedTo.cpf,
            email: ret.payedTo.email,
            phone: {
                ddd: ret.payedTo.phone.ddd,
                ddi: ret.payedTo.phone.ddi,
                number: ret.payedTo.phone.number,
            },
            avatar: ret.payedTo.avatar === null || ret.payedTo.avatar === undefined ? null : {
                width: ret.payedTo.avatar.width || 0,
                height: ret.payedTo.avatar.height || 0,
                url: ret.payedTo.avatar.url,
            },
            address: ret.payedTo.address === null || ret.payedTo.address === undefined ? null : {
                street: ret.payedTo.address.street,
                neighborhood: ret.payedTo.address.neighborhood,
                city: ret.payedTo.address.city,
                uf: ret.payedTo.address.uf,
                country: ret.payedTo.address.country,
                number: ret.payedTo.address.number,
                zip: ret.payedTo.address.zip,
                adicionalInfo: ret.payedTo.address.adicionalInfo,
                reference: ret.payedTo.address.reference,
                location: {
                    lat: ret.payedTo.address.location.lat,
                    lon: ret.payedTo.address.location.lon,
                },
            },
            createdAt: new Date(ret.payedTo.createdAt + "Z"),
            deletedAt: ret.payedTo.deletedAt === null || ret.payedTo.deletedAt === undefined ? null : new Date(ret.payedTo.deletedAt + "Z"),
            blockedUntil: ret.payedTo.blockedUntil === null || ret.payedTo.blockedUntil === undefined ? null : new Date(ret.payedTo.blockedUntil + "Z"),
            basePopularity: ret.payedTo.basePopularity || 0,
            documentsVerified: !!ret.payedTo.documentsVerified,
        },
        request: ret.request === null || ret.request === undefined ? null : {
            id: ret.request.id,
            numId: ret.request.numId,
            owner: {
                id: ret.request.owner.id,
                gender: ret.request.owner.gender === null || ret.request.owner.gender === undefined ? null : ret.request.owner.gender,
                birthday: ret.request.owner.birthday === null || ret.request.owner.birthday === undefined ? null : new Date(parseInt(ret.request.owner.birthday.split("-")[0], 10), parseInt(ret.request.owner.birthday.split("-")[1], 10) - 1, parseInt(ret.request.owner.birthday.split("-")[2], 10)),
                type: ret.request.owner.type,
                name: ret.request.owner.name,
                nick: ret.request.owner.nick,
                cpf: ret.request.owner.cpf,
                email: ret.request.owner.email,
                phone: {
                    ddd: ret.request.owner.phone.ddd,
                    ddi: ret.request.owner.phone.ddi,
                    number: ret.request.owner.phone.number,
                },
                avatar: ret.request.owner.avatar === null || ret.request.owner.avatar === undefined ? null : {
                    width: ret.request.owner.avatar.width || 0,
                    height: ret.request.owner.avatar.height || 0,
                    url: ret.request.owner.avatar.url,
                },
                address: ret.request.owner.address === null || ret.request.owner.address === undefined ? null : {
                    street: ret.request.owner.address.street,
                    neighborhood: ret.request.owner.address.neighborhood,
                    city: ret.request.owner.address.city,
                    uf: ret.request.owner.address.uf,
                    country: ret.request.owner.address.country,
                    number: ret.request.owner.address.number,
                    zip: ret.request.owner.address.zip,
                    adicionalInfo: ret.request.owner.address.adicionalInfo,
                    reference: ret.request.owner.address.reference,
                    location: {
                        lat: ret.request.owner.address.location.lat,
                        lon: ret.request.owner.address.location.lon,
                    },
                },
                createdAt: new Date(ret.request.owner.createdAt + "Z"),
                deletedAt: ret.request.owner.deletedAt === null || ret.request.owner.deletedAt === undefined ? null : new Date(ret.request.owner.deletedAt + "Z"),
                blockedUntil: ret.request.owner.blockedUntil === null || ret.request.owner.blockedUntil === undefined ? null : new Date(ret.request.owner.blockedUntil + "Z"),
                basePopularity: ret.request.owner.basePopularity || 0,
                documentsVerified: !!ret.request.owner.documentsVerified,
            },
            requester: {
                id: ret.request.requester.id,
                gender: ret.request.requester.gender === null || ret.request.requester.gender === undefined ? null : ret.request.requester.gender,
                birthday: ret.request.requester.birthday === null || ret.request.requester.birthday === undefined ? null : new Date(parseInt(ret.request.requester.birthday.split("-")[0], 10), parseInt(ret.request.requester.birthday.split("-")[1], 10) - 1, parseInt(ret.request.requester.birthday.split("-")[2], 10)),
                type: ret.request.requester.type,
                name: ret.request.requester.name,
                nick: ret.request.requester.nick,
                cpf: ret.request.requester.cpf,
                email: ret.request.requester.email,
                phone: {
                    ddd: ret.request.requester.phone.ddd,
                    ddi: ret.request.requester.phone.ddi,
                    number: ret.request.requester.phone.number,
                },
                avatar: ret.request.requester.avatar === null || ret.request.requester.avatar === undefined ? null : {
                    width: ret.request.requester.avatar.width || 0,
                    height: ret.request.requester.avatar.height || 0,
                    url: ret.request.requester.avatar.url,
                },
                address: ret.request.requester.address === null || ret.request.requester.address === undefined ? null : {
                    street: ret.request.requester.address.street,
                    neighborhood: ret.request.requester.address.neighborhood,
                    city: ret.request.requester.address.city,
                    uf: ret.request.requester.address.uf,
                    country: ret.request.requester.address.country,
                    number: ret.request.requester.address.number,
                    zip: ret.request.requester.address.zip,
                    adicionalInfo: ret.request.requester.address.adicionalInfo,
                    reference: ret.request.requester.address.reference,
                    location: {
                        lat: ret.request.requester.address.location.lat,
                        lon: ret.request.requester.address.location.lon,
                    },
                },
                createdAt: new Date(ret.request.requester.createdAt + "Z"),
                deletedAt: ret.request.requester.deletedAt === null || ret.request.requester.deletedAt === undefined ? null : new Date(ret.request.requester.deletedAt + "Z"),
                blockedUntil: ret.request.requester.blockedUntil === null || ret.request.requester.blockedUntil === undefined ? null : new Date(ret.request.requester.blockedUntil + "Z"),
                basePopularity: ret.request.requester.basePopularity || 0,
                documentsVerified: !!ret.request.requester.documentsVerified,
            },
            item: {
                thumbImage: {
                    width: ret.request.item.thumbImage.width || 0,
                    height: ret.request.item.thumbImage.height || 0,
                    url: ret.request.item.thumbImage.url,
                },
                calendar: ret.request.item.calendar.map((e: any) => ({
                    start: new Date(parseInt(e.start.split("-")[0], 10), parseInt(e.start.split("-")[1], 10) - 1, parseInt(e.start.split("-")[2], 10)),
                    end: new Date(parseInt(e.end.split("-")[0], 10), parseInt(e.end.split("-")[1], 10) - 1, parseInt(e.end.split("-")[2], 10)),
                })),
                adjustment: !!ret.request.item.adjustment,
                category: ret.request.item.category,
                color: ret.request.item.color,
                details: ret.request.item.details,
                gender: ret.request.item.gender,
                images: ret.request.item.images.map((e: any) => ({
                    width: e.width || 0,
                    height: e.height || 0,
                    url: e.url,
                })),
                brand: ret.request.item.brand === null || ret.request.item.brand === undefined ? null : ret.request.item.brand,
                ocasions: ret.request.item.ocasions.map((e: any) => e),
                size: ret.request.item.size,
                salePrice: ret.request.item.salePrice === null || ret.request.item.salePrice === undefined ? null : ret.request.item.salePrice || 0,
                rentPrice: ret.request.item.rentPrice === null || ret.request.item.rentPrice === undefined ? null : ret.request.item.rentPrice || 0,
                originalPrice: ret.request.item.originalPrice || 0,
            },
            transport: ret.request.transport === null || ret.request.transport === undefined ? null : {
                requester: {
                    street: ret.request.transport.requester.street,
                    neighborhood: ret.request.transport.requester.neighborhood,
                    city: ret.request.transport.requester.city,
                    uf: ret.request.transport.requester.uf,
                    country: ret.request.transport.requester.country,
                    number: ret.request.transport.requester.number,
                    zip: ret.request.transport.requester.zip,
                    adicionalInfo: ret.request.transport.requester.adicionalInfo,
                    reference: ret.request.transport.requester.reference,
                    location: {
                        lat: ret.request.transport.requester.location.lat,
                        lon: ret.request.transport.requester.location.lon,
                    },
                },
                owner: {
                    street: ret.request.transport.owner.street,
                    neighborhood: ret.request.transport.owner.neighborhood,
                    city: ret.request.transport.owner.city,
                    uf: ret.request.transport.owner.uf,
                    country: ret.request.transport.owner.country,
                    number: ret.request.transport.owner.number,
                    zip: ret.request.transport.owner.zip,
                    adicionalInfo: ret.request.transport.owner.adicionalInfo,
                    reference: ret.request.transport.owner.reference,
                    location: {
                        lat: ret.request.transport.owner.location.lat,
                        lon: ret.request.transport.owner.location.lon,
                    },
                },
                price: ret.request.transport.price || 0,
            },
            period: {
                start: new Date(parseInt(ret.request.period.start.split("-")[0], 10), parseInt(ret.request.period.start.split("-")[1], 10) - 1, parseInt(ret.request.period.start.split("-")[2], 10)),
                end: new Date(parseInt(ret.request.period.end.split("-")[0], 10), parseInt(ret.request.period.end.split("-")[1], 10) - 1, parseInt(ret.request.period.end.split("-")[2], 10)),
            },
            rentPrice: ret.request.rentPrice || 0,
            originalPrice: ret.request.originalPrice || 0,
            statusList: ret.request.statusList.map((e: any) => ({
                status: e.status,
                changeDate: new Date(parseInt(e.changeDate.split("-")[0], 10), parseInt(e.changeDate.split("-")[1], 10) - 1, parseInt(e.changeDate.split("-")[2], 10)),
                actionBy: e.actionBy === null || e.actionBy === undefined ? null : e.actionBy,
                pending: !!e.pending,
            })),
            currentStatus: {
                status: ret.request.currentStatus.status,
                changeDate: new Date(parseInt(ret.request.currentStatus.changeDate.split("-")[0], 10), parseInt(ret.request.currentStatus.changeDate.split("-")[1], 10) - 1, parseInt(ret.request.currentStatus.changeDate.split("-")[2], 10)),
                actionBy: ret.request.currentStatus.actionBy === null || ret.request.currentStatus.actionBy === undefined ? null : ret.request.currentStatus.actionBy,
                pending: !!ret.request.currentStatus.pending,
            },
            payment: ret.request.payment === null || ret.request.payment === undefined ? null : {
                transactionId: ret.request.payment.transactionId || 0,
                lastDigits: ret.request.payment.lastDigits,
                brand: ret.request.payment.brand,
                paidAmount: ret.request.payment.paidAmount || 0,
                status: ret.request.payment.status,
                locSplit: {
                    id: ret.request.payment.locSplit.id,
                    recipientId: ret.request.payment.locSplit.recipientId,
                    amount: ret.request.payment.locSplit.amount || 0,
                },
                ownerSplit: {
                    id: ret.request.payment.ownerSplit.id,
                    recipientId: ret.request.payment.ownerSplit.recipientId,
                    amount: ret.request.payment.ownerSplit.amount || 0,
                },
            },
            promotion: {
                card: ret.request.promotion.card === null || ret.request.promotion.card === undefined ? null : {
                    transactionId: ret.request.promotion.card.transactionId || 0,
                    lastDigits: ret.request.promotion.card.lastDigits,
                    brand: ret.request.promotion.card.brand,
                    paidAmount: ret.request.promotion.card.paidAmount || 0,
                    status: ret.request.promotion.card.status,
                    locSplit: {
                        id: ret.request.promotion.card.locSplit.id,
                        recipientId: ret.request.promotion.card.locSplit.recipientId,
                        amount: ret.request.promotion.card.locSplit.amount || 0,
                    },
                    ownerSplit: {
                        id: ret.request.promotion.card.ownerSplit.id,
                        recipientId: ret.request.promotion.card.ownerSplit.recipientId,
                        amount: ret.request.promotion.card.ownerSplit.amount || 0,
                    },
                },
                type: ret.request.promotion.type === null || ret.request.promotion.type === undefined ? null : ret.request.promotion.type,
                total: ret.request.promotion.total || 0,
                id: ret.request.promotion.id === null || ret.request.promotion.id === undefined ? null : ret.request.promotion.id,
            },
            nfe: {
                id: ret.request.nfe.id === null || ret.request.nfe.id === undefined ? null : ret.request.nfe.id,
                number: ret.request.nfe.number === null || ret.request.nfe.number === undefined ? null : ret.request.nfe.number || 0,
                emitedAt: ret.request.nfe.emitedAt === null || ret.request.nfe.emitedAt === undefined ? null : new Date(ret.request.nfe.emitedAt + "Z"),
                canceledAt: ret.request.nfe.canceledAt === null || ret.request.nfe.canceledAt === undefined ? null : new Date(ret.request.nfe.canceledAt + "Z"),
                status: ret.request.nfe.status === null || ret.request.nfe.status === undefined ? null : ret.request.nfe.status,
            },
            createdAt: new Date(ret.request.createdAt + "Z"),
        },
        createdAt: new Date(ret.createdAt + "Z"),
        reason: ret.reason,
        amount: ret.amount || 0,
    };
}

export async function sendMqttMessage(topic: string, message: string, progress?: (progress: number) => void): Promise<void> {
    const args = {
        topic: topic,
        message: message,
    };
    await makeRequest({name: "sendMqttMessage", args, progress});
    return undefined;
}

export async function sendResetPasswordEmailAdminUser(email: string, progress?: (progress: number) => void): Promise<void> {
    const args = {
        email: email,
    };
    await makeRequest({name: "sendResetPasswordEmailAdminUser", args, progress});
    return undefined;
}

export async function getRents(page: number | null, filter: RentsFilter | null, progress?: (progress: number) => void): Promise<Request[]> {
    const args = {
        page: page === null || page === undefined ? null : page || 0,
        filter: filter === null || filter === undefined ? null : {
            startRentDate: filter.startRentDate === null || filter.startRentDate === undefined ? null : {
                startDate: filter.startRentDate.startDate === null || filter.startRentDate.startDate === undefined ? null : typeof(filter.startRentDate.startDate) === "string" ? new Date(new Date(filter.startRentDate.startDate).getTime() - new Date(filter.startRentDate.startDate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(filter.startRentDate.startDate.getTime() - filter.startRentDate.startDate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
                endDate: filter.startRentDate.endDate === null || filter.startRentDate.endDate === undefined ? null : typeof(filter.startRentDate.endDate) === "string" ? new Date(new Date(filter.startRentDate.endDate).getTime() - new Date(filter.startRentDate.endDate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(filter.startRentDate.endDate.getTime() - filter.startRentDate.endDate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
            },
            endRentDate: filter.endRentDate === null || filter.endRentDate === undefined ? null : {
                startDate: filter.endRentDate.startDate === null || filter.endRentDate.startDate === undefined ? null : typeof(filter.endRentDate.startDate) === "string" ? new Date(new Date(filter.endRentDate.startDate).getTime() - new Date(filter.endRentDate.startDate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(filter.endRentDate.startDate.getTime() - filter.endRentDate.startDate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
                endDate: filter.endRentDate.endDate === null || filter.endRentDate.endDate === undefined ? null : typeof(filter.endRentDate.endDate) === "string" ? new Date(new Date(filter.endRentDate.endDate).getTime() - new Date(filter.endRentDate.endDate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(filter.endRentDate.endDate.getTime() - filter.endRentDate.endDate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
            },
            createdAtRentDate: filter.createdAtRentDate === null || filter.createdAtRentDate === undefined ? null : {
                startDate: filter.createdAtRentDate.startDate === null || filter.createdAtRentDate.startDate === undefined ? null : typeof(filter.createdAtRentDate.startDate) === "string" ? new Date(new Date(filter.createdAtRentDate.startDate).getTime() - new Date(filter.createdAtRentDate.startDate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(filter.createdAtRentDate.startDate.getTime() - filter.createdAtRentDate.startDate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
                endDate: filter.createdAtRentDate.endDate === null || filter.createdAtRentDate.endDate === undefined ? null : typeof(filter.createdAtRentDate.endDate) === "string" ? new Date(new Date(filter.createdAtRentDate.endDate).getTime() - new Date(filter.createdAtRentDate.endDate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(filter.createdAtRentDate.endDate.getTime() - filter.createdAtRentDate.endDate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
            },
            status: filter.status.map(e => e),
            orderBy: filter.orderBy === null || filter.orderBy === undefined ? null : {
                field: filter.orderBy.field,
                order: filter.orderBy.order,
            },
        },
    };
    const ret = await makeRequest({name: "getRents", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        numId: e.numId,
        owner: {
            id: e.owner.id,
            gender: e.owner.gender === null || e.owner.gender === undefined ? null : e.owner.gender,
            birthday: e.owner.birthday === null || e.owner.birthday === undefined ? null : new Date(parseInt(e.owner.birthday.split("-")[0], 10), parseInt(e.owner.birthday.split("-")[1], 10) - 1, parseInt(e.owner.birthday.split("-")[2], 10)),
            type: e.owner.type,
            name: e.owner.name,
            nick: e.owner.nick,
            cpf: e.owner.cpf,
            email: e.owner.email,
            phone: {
                ddd: e.owner.phone.ddd,
                ddi: e.owner.phone.ddi,
                number: e.owner.phone.number,
            },
            avatar: e.owner.avatar === null || e.owner.avatar === undefined ? null : {
                width: e.owner.avatar.width || 0,
                height: e.owner.avatar.height || 0,
                url: e.owner.avatar.url,
            },
            address: e.owner.address === null || e.owner.address === undefined ? null : {
                street: e.owner.address.street,
                neighborhood: e.owner.address.neighborhood,
                city: e.owner.address.city,
                uf: e.owner.address.uf,
                country: e.owner.address.country,
                number: e.owner.address.number,
                zip: e.owner.address.zip,
                adicionalInfo: e.owner.address.adicionalInfo,
                reference: e.owner.address.reference,
                location: {
                    lat: e.owner.address.location.lat,
                    lon: e.owner.address.location.lon,
                },
            },
            createdAt: new Date(e.owner.createdAt + "Z"),
            deletedAt: e.owner.deletedAt === null || e.owner.deletedAt === undefined ? null : new Date(e.owner.deletedAt + "Z"),
            blockedUntil: e.owner.blockedUntil === null || e.owner.blockedUntil === undefined ? null : new Date(e.owner.blockedUntil + "Z"),
            basePopularity: e.owner.basePopularity || 0,
            documentsVerified: !!e.owner.documentsVerified,
        },
        requester: {
            id: e.requester.id,
            gender: e.requester.gender === null || e.requester.gender === undefined ? null : e.requester.gender,
            birthday: e.requester.birthday === null || e.requester.birthday === undefined ? null : new Date(parseInt(e.requester.birthday.split("-")[0], 10), parseInt(e.requester.birthday.split("-")[1], 10) - 1, parseInt(e.requester.birthday.split("-")[2], 10)),
            type: e.requester.type,
            name: e.requester.name,
            nick: e.requester.nick,
            cpf: e.requester.cpf,
            email: e.requester.email,
            phone: {
                ddd: e.requester.phone.ddd,
                ddi: e.requester.phone.ddi,
                number: e.requester.phone.number,
            },
            avatar: e.requester.avatar === null || e.requester.avatar === undefined ? null : {
                width: e.requester.avatar.width || 0,
                height: e.requester.avatar.height || 0,
                url: e.requester.avatar.url,
            },
            address: e.requester.address === null || e.requester.address === undefined ? null : {
                street: e.requester.address.street,
                neighborhood: e.requester.address.neighborhood,
                city: e.requester.address.city,
                uf: e.requester.address.uf,
                country: e.requester.address.country,
                number: e.requester.address.number,
                zip: e.requester.address.zip,
                adicionalInfo: e.requester.address.adicionalInfo,
                reference: e.requester.address.reference,
                location: {
                    lat: e.requester.address.location.lat,
                    lon: e.requester.address.location.lon,
                },
            },
            createdAt: new Date(e.requester.createdAt + "Z"),
            deletedAt: e.requester.deletedAt === null || e.requester.deletedAt === undefined ? null : new Date(e.requester.deletedAt + "Z"),
            blockedUntil: e.requester.blockedUntil === null || e.requester.blockedUntil === undefined ? null : new Date(e.requester.blockedUntil + "Z"),
            basePopularity: e.requester.basePopularity || 0,
            documentsVerified: !!e.requester.documentsVerified,
        },
        item: {
            thumbImage: {
                width: e.item.thumbImage.width || 0,
                height: e.item.thumbImage.height || 0,
                url: e.item.thumbImage.url,
            },
            calendar: e.item.calendar.map((e: any) => ({
                start: new Date(parseInt(e.start.split("-")[0], 10), parseInt(e.start.split("-")[1], 10) - 1, parseInt(e.start.split("-")[2], 10)),
                end: new Date(parseInt(e.end.split("-")[0], 10), parseInt(e.end.split("-")[1], 10) - 1, parseInt(e.end.split("-")[2], 10)),
            })),
            adjustment: !!e.item.adjustment,
            category: e.item.category,
            color: e.item.color,
            details: e.item.details,
            gender: e.item.gender,
            images: e.item.images.map((e: any) => ({
                width: e.width || 0,
                height: e.height || 0,
                url: e.url,
            })),
            brand: e.item.brand === null || e.item.brand === undefined ? null : e.item.brand,
            ocasions: e.item.ocasions.map((e: any) => e),
            size: e.item.size,
            salePrice: e.item.salePrice === null || e.item.salePrice === undefined ? null : e.item.salePrice || 0,
            rentPrice: e.item.rentPrice === null || e.item.rentPrice === undefined ? null : e.item.rentPrice || 0,
            originalPrice: e.item.originalPrice || 0,
        },
        transport: e.transport === null || e.transport === undefined ? null : {
            requester: {
                street: e.transport.requester.street,
                neighborhood: e.transport.requester.neighborhood,
                city: e.transport.requester.city,
                uf: e.transport.requester.uf,
                country: e.transport.requester.country,
                number: e.transport.requester.number,
                zip: e.transport.requester.zip,
                adicionalInfo: e.transport.requester.adicionalInfo,
                reference: e.transport.requester.reference,
                location: {
                    lat: e.transport.requester.location.lat,
                    lon: e.transport.requester.location.lon,
                },
            },
            owner: {
                street: e.transport.owner.street,
                neighborhood: e.transport.owner.neighborhood,
                city: e.transport.owner.city,
                uf: e.transport.owner.uf,
                country: e.transport.owner.country,
                number: e.transport.owner.number,
                zip: e.transport.owner.zip,
                adicionalInfo: e.transport.owner.adicionalInfo,
                reference: e.transport.owner.reference,
                location: {
                    lat: e.transport.owner.location.lat,
                    lon: e.transport.owner.location.lon,
                },
            },
            price: e.transport.price || 0,
        },
        period: {
            start: new Date(parseInt(e.period.start.split("-")[0], 10), parseInt(e.period.start.split("-")[1], 10) - 1, parseInt(e.period.start.split("-")[2], 10)),
            end: new Date(parseInt(e.period.end.split("-")[0], 10), parseInt(e.period.end.split("-")[1], 10) - 1, parseInt(e.period.end.split("-")[2], 10)),
        },
        rentPrice: e.rentPrice || 0,
        originalPrice: e.originalPrice || 0,
        statusList: e.statusList.map((e: any) => ({
            status: e.status,
            changeDate: new Date(parseInt(e.changeDate.split("-")[0], 10), parseInt(e.changeDate.split("-")[1], 10) - 1, parseInt(e.changeDate.split("-")[2], 10)),
            actionBy: e.actionBy === null || e.actionBy === undefined ? null : e.actionBy,
            pending: !!e.pending,
        })),
        currentStatus: {
            status: e.currentStatus.status,
            changeDate: new Date(parseInt(e.currentStatus.changeDate.split("-")[0], 10), parseInt(e.currentStatus.changeDate.split("-")[1], 10) - 1, parseInt(e.currentStatus.changeDate.split("-")[2], 10)),
            actionBy: e.currentStatus.actionBy === null || e.currentStatus.actionBy === undefined ? null : e.currentStatus.actionBy,
            pending: !!e.currentStatus.pending,
        },
        payment: e.payment === null || e.payment === undefined ? null : {
            transactionId: e.payment.transactionId || 0,
            lastDigits: e.payment.lastDigits,
            brand: e.payment.brand,
            paidAmount: e.payment.paidAmount || 0,
            status: e.payment.status,
            locSplit: {
                id: e.payment.locSplit.id,
                recipientId: e.payment.locSplit.recipientId,
                amount: e.payment.locSplit.amount || 0,
            },
            ownerSplit: {
                id: e.payment.ownerSplit.id,
                recipientId: e.payment.ownerSplit.recipientId,
                amount: e.payment.ownerSplit.amount || 0,
            },
        },
        promotion: {
            card: e.promotion.card === null || e.promotion.card === undefined ? null : {
                transactionId: e.promotion.card.transactionId || 0,
                lastDigits: e.promotion.card.lastDigits,
                brand: e.promotion.card.brand,
                paidAmount: e.promotion.card.paidAmount || 0,
                status: e.promotion.card.status,
                locSplit: {
                    id: e.promotion.card.locSplit.id,
                    recipientId: e.promotion.card.locSplit.recipientId,
                    amount: e.promotion.card.locSplit.amount || 0,
                },
                ownerSplit: {
                    id: e.promotion.card.ownerSplit.id,
                    recipientId: e.promotion.card.ownerSplit.recipientId,
                    amount: e.promotion.card.ownerSplit.amount || 0,
                },
            },
            type: e.promotion.type === null || e.promotion.type === undefined ? null : e.promotion.type,
            total: e.promotion.total || 0,
            id: e.promotion.id === null || e.promotion.id === undefined ? null : e.promotion.id,
        },
        nfe: {
            id: e.nfe.id === null || e.nfe.id === undefined ? null : e.nfe.id,
            number: e.nfe.number === null || e.nfe.number === undefined ? null : e.nfe.number || 0,
            emitedAt: e.nfe.emitedAt === null || e.nfe.emitedAt === undefined ? null : new Date(e.nfe.emitedAt + "Z"),
            canceledAt: e.nfe.canceledAt === null || e.nfe.canceledAt === undefined ? null : new Date(e.nfe.canceledAt + "Z"),
            status: e.nfe.status === null || e.nfe.status === undefined ? null : e.nfe.status,
        },
        createdAt: new Date(e.createdAt + "Z"),
    }));
}

export async function getRentsReportForAdminUser(filter: RentsFilter | null, progress?: (progress: number) => void): Promise<string> {
    const args = {
        filter: filter === null || filter === undefined ? null : {
            startRentDate: filter.startRentDate === null || filter.startRentDate === undefined ? null : {
                startDate: filter.startRentDate.startDate === null || filter.startRentDate.startDate === undefined ? null : typeof(filter.startRentDate.startDate) === "string" ? new Date(new Date(filter.startRentDate.startDate).getTime() - new Date(filter.startRentDate.startDate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(filter.startRentDate.startDate.getTime() - filter.startRentDate.startDate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
                endDate: filter.startRentDate.endDate === null || filter.startRentDate.endDate === undefined ? null : typeof(filter.startRentDate.endDate) === "string" ? new Date(new Date(filter.startRentDate.endDate).getTime() - new Date(filter.startRentDate.endDate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(filter.startRentDate.endDate.getTime() - filter.startRentDate.endDate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
            },
            endRentDate: filter.endRentDate === null || filter.endRentDate === undefined ? null : {
                startDate: filter.endRentDate.startDate === null || filter.endRentDate.startDate === undefined ? null : typeof(filter.endRentDate.startDate) === "string" ? new Date(new Date(filter.endRentDate.startDate).getTime() - new Date(filter.endRentDate.startDate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(filter.endRentDate.startDate.getTime() - filter.endRentDate.startDate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
                endDate: filter.endRentDate.endDate === null || filter.endRentDate.endDate === undefined ? null : typeof(filter.endRentDate.endDate) === "string" ? new Date(new Date(filter.endRentDate.endDate).getTime() - new Date(filter.endRentDate.endDate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(filter.endRentDate.endDate.getTime() - filter.endRentDate.endDate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
            },
            createdAtRentDate: filter.createdAtRentDate === null || filter.createdAtRentDate === undefined ? null : {
                startDate: filter.createdAtRentDate.startDate === null || filter.createdAtRentDate.startDate === undefined ? null : typeof(filter.createdAtRentDate.startDate) === "string" ? new Date(new Date(filter.createdAtRentDate.startDate).getTime() - new Date(filter.createdAtRentDate.startDate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(filter.createdAtRentDate.startDate.getTime() - filter.createdAtRentDate.startDate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
                endDate: filter.createdAtRentDate.endDate === null || filter.createdAtRentDate.endDate === undefined ? null : typeof(filter.createdAtRentDate.endDate) === "string" ? new Date(new Date(filter.createdAtRentDate.endDate).getTime() - new Date(filter.createdAtRentDate.endDate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(filter.createdAtRentDate.endDate.getTime() - filter.createdAtRentDate.endDate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
            },
            status: filter.status.map(e => e),
            orderBy: filter.orderBy === null || filter.orderBy === undefined ? null : {
                field: filter.orderBy.field,
                order: filter.orderBy.order,
            },
        },
    };
    const ret = await makeRequest({name: "getRentsReportForAdminUser", args, progress});
    return ret;
}

export async function getRentsStats(filter: RentsFilter | null, progress?: (progress: number) => void): Promise<TableStats> {
    const args = {
        filter: filter === null || filter === undefined ? null : {
            startRentDate: filter.startRentDate === null || filter.startRentDate === undefined ? null : {
                startDate: filter.startRentDate.startDate === null || filter.startRentDate.startDate === undefined ? null : typeof(filter.startRentDate.startDate) === "string" ? new Date(new Date(filter.startRentDate.startDate).getTime() - new Date(filter.startRentDate.startDate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(filter.startRentDate.startDate.getTime() - filter.startRentDate.startDate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
                endDate: filter.startRentDate.endDate === null || filter.startRentDate.endDate === undefined ? null : typeof(filter.startRentDate.endDate) === "string" ? new Date(new Date(filter.startRentDate.endDate).getTime() - new Date(filter.startRentDate.endDate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(filter.startRentDate.endDate.getTime() - filter.startRentDate.endDate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
            },
            endRentDate: filter.endRentDate === null || filter.endRentDate === undefined ? null : {
                startDate: filter.endRentDate.startDate === null || filter.endRentDate.startDate === undefined ? null : typeof(filter.endRentDate.startDate) === "string" ? new Date(new Date(filter.endRentDate.startDate).getTime() - new Date(filter.endRentDate.startDate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(filter.endRentDate.startDate.getTime() - filter.endRentDate.startDate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
                endDate: filter.endRentDate.endDate === null || filter.endRentDate.endDate === undefined ? null : typeof(filter.endRentDate.endDate) === "string" ? new Date(new Date(filter.endRentDate.endDate).getTime() - new Date(filter.endRentDate.endDate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(filter.endRentDate.endDate.getTime() - filter.endRentDate.endDate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
            },
            createdAtRentDate: filter.createdAtRentDate === null || filter.createdAtRentDate === undefined ? null : {
                startDate: filter.createdAtRentDate.startDate === null || filter.createdAtRentDate.startDate === undefined ? null : typeof(filter.createdAtRentDate.startDate) === "string" ? new Date(new Date(filter.createdAtRentDate.startDate).getTime() - new Date(filter.createdAtRentDate.startDate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(filter.createdAtRentDate.startDate.getTime() - filter.createdAtRentDate.startDate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
                endDate: filter.createdAtRentDate.endDate === null || filter.createdAtRentDate.endDate === undefined ? null : typeof(filter.createdAtRentDate.endDate) === "string" ? new Date(new Date(filter.createdAtRentDate.endDate).getTime() - new Date(filter.createdAtRentDate.endDate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(filter.createdAtRentDate.endDate.getTime() - filter.createdAtRentDate.endDate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
            },
            status: filter.status.map(e => e),
            orderBy: filter.orderBy === null || filter.orderBy === undefined ? null : {
                field: filter.orderBy.field,
                order: filter.orderBy.order,
            },
        },
    };
    const ret = await makeRequest({name: "getRentsStats", args, progress});
    return {
        totalAmount: ret.totalAmount || 0,
        averageTicked: ret.averageTicked || 0,
        totalPrice: ret.totalPrice || 0,
    };
}

export async function getCommentsFeedPostForId(postID: string, pageOffset: number, progress?: (progress: number) => void): Promise<PostComment[]> {
    const args = {
        postID: postID,
        pageOffset: pageOffset || 0,
    };
    const ret = await makeRequest({name: "getCommentsFeedPostForId", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        text: e.text,
        userInfo: {
            id: e.userInfo.id,
            avatar: e.userInfo.avatar === null || e.userInfo.avatar === undefined ? null : {
                width: e.userInfo.avatar.width || 0,
                height: e.userInfo.avatar.height || 0,
                url: e.userInfo.avatar.url,
            },
            name: e.userInfo.name,
            nick: e.userInfo.nick,
            rate: e.userInfo.rate,
            height: e.userInfo.height === null || e.userInfo.height === undefined ? null : e.userInfo.height || 0,
            deleted: !!e.userInfo.deleted,
            type: e.userInfo.type,
            isLockerLiked: !!e.userInfo.isLockerLiked,
            isFollowing: !!e.userInfo.isFollowing,
            ownerName: e.userInfo.ownerName === null || e.userInfo.ownerName === undefined ? null : e.userInfo.ownerName,
        },
        postId: e.postId,
        createdAt: new Date(e.createdAt + "Z"),
    }));
}

export async function getCommentForId(commentId: string, progress?: (progress: number) => void): Promise<PostComment> {
    const args = {
        commentId: commentId,
    };
    const ret = await makeRequest({name: "getCommentForId", args, progress});
    return {
        id: ret.id,
        text: ret.text,
        userInfo: {
            id: ret.userInfo.id,
            avatar: ret.userInfo.avatar === null || ret.userInfo.avatar === undefined ? null : {
                width: ret.userInfo.avatar.width || 0,
                height: ret.userInfo.avatar.height || 0,
                url: ret.userInfo.avatar.url,
            },
            name: ret.userInfo.name,
            nick: ret.userInfo.nick,
            rate: ret.userInfo.rate,
            height: ret.userInfo.height === null || ret.userInfo.height === undefined ? null : ret.userInfo.height || 0,
            deleted: !!ret.userInfo.deleted,
            type: ret.userInfo.type,
            isLockerLiked: !!ret.userInfo.isLockerLiked,
            isFollowing: !!ret.userInfo.isFollowing,
            ownerName: ret.userInfo.ownerName === null || ret.userInfo.ownerName === undefined ? null : ret.userInfo.ownerName,
        },
        postId: ret.postId,
        createdAt: new Date(ret.createdAt + "Z"),
    };
}

export async function deleteCommentPost(commentPostId: string, progress?: (progress: number) => void): Promise<void> {
    const args = {
        commentPostId: commentPostId,
    };
    await makeRequest({name: "deleteCommentPost", args, progress});
    return undefined;
}

export async function getPromotion(promotionId: string, progress?: (progress: number) => void): Promise<Promotion> {
    const args = {
        promotionId: promotionId,
    };
    const ret = await makeRequest({name: "getPromotion", args, progress});
    return {
        uses: ret.uses || 0,
        createdAt: new Date(ret.createdAt + "Z"),
        deletedAt: ret.deletedAt === null || ret.deletedAt === undefined ? null : new Date(ret.deletedAt + "Z"),
        cityIds: ret.cityIds === null || ret.cityIds === undefined ? null : ret.cityIds.map((e: any) => e),
        id: ret.id,
        value: ret.value === null || ret.value === undefined ? null : ret.value || 0,
        percentage: ret.percentage === null || ret.percentage === undefined ? null : ret.percentage || 0,
        type: ret.type,
        usageType: ret.usageType,
        maxUsesPerUser: ret.maxUsesPerUser === null || ret.maxUsesPerUser === undefined ? null : ret.maxUsesPerUser || 0,
        maxUses: ret.maxUses || 0,
        expirationDate: new Date(ret.expirationDate + "Z"),
        userIds: ret.userIds === null || ret.userIds === undefined ? null : ret.userIds.map((e: any) => e),
    };
}

export async function getPromotions(page: number | null, filter: PromotionFilter | null, progress?: (progress: number) => void): Promise<Promotion[]> {
    const args = {
        page: page === null || page === undefined ? null : page || 0,
        filter: filter === null || filter === undefined ? null : {
            id: filter.id === null || filter.id === undefined ? null : filter.id,
            expirationDate: filter.expirationDate === null || filter.expirationDate === undefined ? null : {
                startDate: filter.expirationDate.startDate === null || filter.expirationDate.startDate === undefined ? null : typeof(filter.expirationDate.startDate) === "string" ? new Date(new Date(filter.expirationDate.startDate).getTime() - new Date(filter.expirationDate.startDate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(filter.expirationDate.startDate.getTime() - filter.expirationDate.startDate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
                endDate: filter.expirationDate.endDate === null || filter.expirationDate.endDate === undefined ? null : typeof(filter.expirationDate.endDate) === "string" ? new Date(new Date(filter.expirationDate.endDate).getTime() - new Date(filter.expirationDate.endDate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(filter.expirationDate.endDate.getTime() - filter.expirationDate.endDate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
            },
            createdAtDate: filter.createdAtDate === null || filter.createdAtDate === undefined ? null : {
                startDate: filter.createdAtDate.startDate === null || filter.createdAtDate.startDate === undefined ? null : typeof(filter.createdAtDate.startDate) === "string" ? new Date(new Date(filter.createdAtDate.startDate).getTime() - new Date(filter.createdAtDate.startDate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(filter.createdAtDate.startDate.getTime() - filter.createdAtDate.startDate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
                endDate: filter.createdAtDate.endDate === null || filter.createdAtDate.endDate === undefined ? null : typeof(filter.createdAtDate.endDate) === "string" ? new Date(new Date(filter.createdAtDate.endDate).getTime() - new Date(filter.createdAtDate.endDate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(filter.createdAtDate.endDate.getTime() - filter.createdAtDate.endDate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
            },
            orderBy: filter.orderBy === null || filter.orderBy === undefined ? null : {
                field: filter.orderBy.field,
                order: filter.orderBy.order,
            },
        },
    };
    const ret = await makeRequest({name: "getPromotions", args, progress});
    return ret.map((e: any) => ({
        uses: e.uses || 0,
        createdAt: new Date(e.createdAt + "Z"),
        deletedAt: e.deletedAt === null || e.deletedAt === undefined ? null : new Date(e.deletedAt + "Z"),
        cityIds: e.cityIds === null || e.cityIds === undefined ? null : e.cityIds.map((e: any) => e),
        id: e.id,
        value: e.value === null || e.value === undefined ? null : e.value || 0,
        percentage: e.percentage === null || e.percentage === undefined ? null : e.percentage || 0,
        type: e.type,
        usageType: e.usageType,
        maxUsesPerUser: e.maxUsesPerUser === null || e.maxUsesPerUser === undefined ? null : e.maxUsesPerUser || 0,
        maxUses: e.maxUses || 0,
        expirationDate: new Date(e.expirationDate + "Z"),
        userIds: e.userIds === null || e.userIds === undefined ? null : e.userIds.map((e: any) => e),
    }));
}

export async function createOrEditPromotion(promotionDetails: PromotionDetails, progress?: (progress: number) => void): Promise<Promotion> {
    const args = {
        promotionDetails: {
            id: promotionDetails.id,
            value: promotionDetails.value === null || promotionDetails.value === undefined ? null : promotionDetails.value || 0,
            percentage: promotionDetails.percentage === null || promotionDetails.percentage === undefined ? null : promotionDetails.percentage || 0,
            type: promotionDetails.type,
            usageType: promotionDetails.usageType,
            maxUsesPerUser: promotionDetails.maxUsesPerUser === null || promotionDetails.maxUsesPerUser === undefined ? null : promotionDetails.maxUsesPerUser || 0,
            maxUses: promotionDetails.maxUses || 0,
            expirationDate: (typeof promotionDetails.expirationDate === "string" && (promotionDetails.expirationDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (promotionDetails.expirationDate as any).replace("Z", "") : promotionDetails.expirationDate.toISOString().replace("Z", "")),
            userIds: promotionDetails.userIds === null || promotionDetails.userIds === undefined ? null : promotionDetails.userIds.map(e => e),
        },
    };
    const ret = await makeRequest({name: "createOrEditPromotion", args, progress});
    return {
        uses: ret.uses || 0,
        createdAt: new Date(ret.createdAt + "Z"),
        deletedAt: ret.deletedAt === null || ret.deletedAt === undefined ? null : new Date(ret.deletedAt + "Z"),
        cityIds: ret.cityIds === null || ret.cityIds === undefined ? null : ret.cityIds.map((e: any) => e),
        id: ret.id,
        value: ret.value === null || ret.value === undefined ? null : ret.value || 0,
        percentage: ret.percentage === null || ret.percentage === undefined ? null : ret.percentage || 0,
        type: ret.type,
        usageType: ret.usageType,
        maxUsesPerUser: ret.maxUsesPerUser === null || ret.maxUsesPerUser === undefined ? null : ret.maxUsesPerUser || 0,
        maxUses: ret.maxUses || 0,
        expirationDate: new Date(ret.expirationDate + "Z"),
        userIds: ret.userIds === null || ret.userIds === undefined ? null : ret.userIds.map((e: any) => e),
    };
}

export async function getReport(startDate: Date | null, endDate: Date | null, progress?: (progress: number) => void): Promise<Report> {
    const args = {
        startDate: startDate === null || startDate === undefined ? null : typeof(startDate) === "string" ? new Date(new Date(startDate).getTime() - new Date(startDate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(startDate.getTime() - startDate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
        endDate: endDate === null || endDate === undefined ? null : typeof(endDate) === "string" ? new Date(new Date(endDate).getTime() - new Date(endDate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(endDate.getTime() - endDate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
    };
    const ret = await makeRequest({name: "getReport", args, progress});
    return {
        numberOfUser: ret.numberOfUser || 0,
        numberOfPieces: ret.numberOfPieces || 0,
        piecesTotalSum: ret.piecesTotalSum || 0,
        averageTicket: ret.averageTicket || 0,
        averageSalesTicket: ret.averageSalesTicket || 0,
        totalOfRents: ret.totalOfRents || 0,
        totalOfSales: ret.totalOfSales || 0,
    };
}

export async function getUsersPerDate(startDate: Date | null, endDate: Date | null, progress?: (progress: number) => void): Promise<NumberDateReport[]> {
    const args = {
        startDate: startDate === null || startDate === undefined ? null : typeof(startDate) === "string" ? new Date(new Date(startDate).getTime() - new Date(startDate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(startDate.getTime() - startDate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
        endDate: endDate === null || endDate === undefined ? null : typeof(endDate) === "string" ? new Date(new Date(endDate).getTime() - new Date(endDate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(endDate.getTime() - endDate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
    };
    const ret = await makeRequest({name: "getUsersPerDate", args, progress});
    return ret.map((e: any) => ({
        date: new Date(parseInt(e.date.split("-")[0], 10), parseInt(e.date.split("-")[1], 10) - 1, parseInt(e.date.split("-")[2], 10)),
        number: e.number || 0,
    }));
}

export async function getUsersPerDateHour(startDate: Date | null, endDate: Date | null, progress?: (progress: number) => void): Promise<NumberDateHourReport[]> {
    const args = {
        startDate: startDate === null || startDate === undefined ? null : typeof(startDate) === "string" ? new Date(new Date(startDate).getTime() - new Date(startDate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(startDate.getTime() - startDate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
        endDate: endDate === null || endDate === undefined ? null : typeof(endDate) === "string" ? new Date(new Date(endDate).getTime() - new Date(endDate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(endDate.getTime() - endDate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
    };
    const ret = await makeRequest({name: "getUsersPerDateHour", args, progress});
    return ret.map((e: any) => ({
        dateHour: new Date(e.dateHour + "Z"),
        number: e.number || 0,
    }));
}

export async function getUsersPerHour(startDate: Date | null, endDate: Date | null, progress?: (progress: number) => void): Promise<NumberHourReport[]> {
    const args = {
        startDate: startDate === null || startDate === undefined ? null : typeof(startDate) === "string" ? new Date(new Date(startDate).getTime() - new Date(startDate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(startDate.getTime() - startDate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
        endDate: endDate === null || endDate === undefined ? null : typeof(endDate) === "string" ? new Date(new Date(endDate).getTime() - new Date(endDate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(endDate.getTime() - endDate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
    };
    const ret = await makeRequest({name: "getUsersPerHour", args, progress});
    return ret.map((e: any) => ({
        hour: e.hour || 0,
        number: e.number || 0,
    }));
}

export async function getItemsPerDate(startDate: Date | null, endDate: Date | null, progress?: (progress: number) => void): Promise<NumberDateReport[]> {
    const args = {
        startDate: startDate === null || startDate === undefined ? null : typeof(startDate) === "string" ? new Date(new Date(startDate).getTime() - new Date(startDate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(startDate.getTime() - startDate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
        endDate: endDate === null || endDate === undefined ? null : typeof(endDate) === "string" ? new Date(new Date(endDate).getTime() - new Date(endDate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(endDate.getTime() - endDate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
    };
    const ret = await makeRequest({name: "getItemsPerDate", args, progress});
    return ret.map((e: any) => ({
        date: new Date(parseInt(e.date.split("-")[0], 10), parseInt(e.date.split("-")[1], 10) - 1, parseInt(e.date.split("-")[2], 10)),
        number: e.number || 0,
    }));
}

export async function getItemsPerDateHour(startDate: Date | null, endDate: Date | null, progress?: (progress: number) => void): Promise<NumberDateHourReport[]> {
    const args = {
        startDate: startDate === null || startDate === undefined ? null : typeof(startDate) === "string" ? new Date(new Date(startDate).getTime() - new Date(startDate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(startDate.getTime() - startDate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
        endDate: endDate === null || endDate === undefined ? null : typeof(endDate) === "string" ? new Date(new Date(endDate).getTime() - new Date(endDate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(endDate.getTime() - endDate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
    };
    const ret = await makeRequest({name: "getItemsPerDateHour", args, progress});
    return ret.map((e: any) => ({
        dateHour: new Date(e.dateHour + "Z"),
        number: e.number || 0,
    }));
}

export async function getItemsPerHour(startDate: Date | null, endDate: Date | null, progress?: (progress: number) => void): Promise<NumberHourReport[]> {
    const args = {
        startDate: startDate === null || startDate === undefined ? null : typeof(startDate) === "string" ? new Date(new Date(startDate).getTime() - new Date(startDate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(startDate.getTime() - startDate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
        endDate: endDate === null || endDate === undefined ? null : typeof(endDate) === "string" ? new Date(new Date(endDate).getTime() - new Date(endDate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(endDate.getTime() - endDate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
    };
    const ret = await makeRequest({name: "getItemsPerHour", args, progress});
    return ret.map((e: any) => ({
        hour: e.hour || 0,
        number: e.number || 0,
    }));
}

export async function getItemsPerCategory(startDate: Date | null, endDate: Date | null, progress?: (progress: number) => void): Promise<ItemCategoryReport[]> {
    const args = {
        startDate: startDate === null || startDate === undefined ? null : typeof(startDate) === "string" ? new Date(new Date(startDate).getTime() - new Date(startDate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(startDate.getTime() - startDate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
        endDate: endDate === null || endDate === undefined ? null : typeof(endDate) === "string" ? new Date(new Date(endDate).getTime() - new Date(endDate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(endDate.getTime() - endDate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
    };
    const ret = await makeRequest({name: "getItemsPerCategory", args, progress});
    return ret.map((e: any) => ({
        category: e.category,
        number: e.number || 0,
        originalPrice: e.originalPrice || 0,
        rentPrice: e.rentPrice || 0,
    }));
}

export async function getItemsPerColor(startDate: Date | null, endDate: Date | null, progress?: (progress: number) => void): Promise<NumberColorReport[]> {
    const args = {
        startDate: startDate === null || startDate === undefined ? null : typeof(startDate) === "string" ? new Date(new Date(startDate).getTime() - new Date(startDate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(startDate.getTime() - startDate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
        endDate: endDate === null || endDate === undefined ? null : typeof(endDate) === "string" ? new Date(new Date(endDate).getTime() - new Date(endDate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(endDate.getTime() - endDate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
    };
    const ret = await makeRequest({name: "getItemsPerColor", args, progress});
    return ret.map((e: any) => ({
        color: e.color,
        number: e.number || 0,
    }));
}

export async function getItemsPerDepartment(startDate: Date | null, endDate: Date | null, progress?: (progress: number) => void): Promise<NumberDepartmentReport[]> {
    const args = {
        startDate: startDate === null || startDate === undefined ? null : typeof(startDate) === "string" ? new Date(new Date(startDate).getTime() - new Date(startDate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(startDate.getTime() - startDate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
        endDate: endDate === null || endDate === undefined ? null : typeof(endDate) === "string" ? new Date(new Date(endDate).getTime() - new Date(endDate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(endDate.getTime() - endDate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
    };
    const ret = await makeRequest({name: "getItemsPerDepartment", args, progress});
    return ret.map((e: any) => ({
        department: e.department,
        number: e.number || 0,
    }));
}

export async function getItemsPerSize(startDate: Date | null, endDate: Date | null, progress?: (progress: number) => void): Promise<NumberSizeReport[]> {
    const args = {
        startDate: startDate === null || startDate === undefined ? null : typeof(startDate) === "string" ? new Date(new Date(startDate).getTime() - new Date(startDate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(startDate.getTime() - startDate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
        endDate: endDate === null || endDate === undefined ? null : typeof(endDate) === "string" ? new Date(new Date(endDate).getTime() - new Date(endDate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(endDate.getTime() - endDate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
    };
    const ret = await makeRequest({name: "getItemsPerSize", args, progress});
    return ret.map((e: any) => ({
        size: e.size,
        number: e.number || 0,
    }));
}

export async function getItemsPerBrand(startDate: Date | null, endDate: Date | null, progress?: (progress: number) => void): Promise<NumberLabelReport[]> {
    const args = {
        startDate: startDate === null || startDate === undefined ? null : typeof(startDate) === "string" ? new Date(new Date(startDate).getTime() - new Date(startDate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(startDate.getTime() - startDate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
        endDate: endDate === null || endDate === undefined ? null : typeof(endDate) === "string" ? new Date(new Date(endDate).getTime() - new Date(endDate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(endDate.getTime() - endDate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
    };
    const ret = await makeRequest({name: "getItemsPerBrand", args, progress});
    return ret.map((e: any) => ({
        label: e.label,
        number: e.number || 0,
    }));
}

export async function getRentsPerDate(startDate: Date | null, endDate: Date | null, progress?: (progress: number) => void): Promise<NumberDateReport[]> {
    const args = {
        startDate: startDate === null || startDate === undefined ? null : typeof(startDate) === "string" ? new Date(new Date(startDate).getTime() - new Date(startDate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(startDate.getTime() - startDate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
        endDate: endDate === null || endDate === undefined ? null : typeof(endDate) === "string" ? new Date(new Date(endDate).getTime() - new Date(endDate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(endDate.getTime() - endDate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
    };
    const ret = await makeRequest({name: "getRentsPerDate", args, progress});
    return ret.map((e: any) => ({
        date: new Date(parseInt(e.date.split("-")[0], 10), parseInt(e.date.split("-")[1], 10) - 1, parseInt(e.date.split("-")[2], 10)),
        number: e.number || 0,
    }));
}

export async function getRentsPerDateHour(startDate: Date | null, endDate: Date | null, progress?: (progress: number) => void): Promise<NumberDateHourReport[]> {
    const args = {
        startDate: startDate === null || startDate === undefined ? null : typeof(startDate) === "string" ? new Date(new Date(startDate).getTime() - new Date(startDate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(startDate.getTime() - startDate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
        endDate: endDate === null || endDate === undefined ? null : typeof(endDate) === "string" ? new Date(new Date(endDate).getTime() - new Date(endDate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(endDate.getTime() - endDate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
    };
    const ret = await makeRequest({name: "getRentsPerDateHour", args, progress});
    return ret.map((e: any) => ({
        dateHour: new Date(e.dateHour + "Z"),
        number: e.number || 0,
    }));
}

export async function getRentsPerHour(startDate: Date | null, endDate: Date | null, progress?: (progress: number) => void): Promise<NumberHourReport[]> {
    const args = {
        startDate: startDate === null || startDate === undefined ? null : typeof(startDate) === "string" ? new Date(new Date(startDate).getTime() - new Date(startDate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(startDate.getTime() - startDate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
        endDate: endDate === null || endDate === undefined ? null : typeof(endDate) === "string" ? new Date(new Date(endDate).getTime() - new Date(endDate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(endDate.getTime() - endDate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
    };
    const ret = await makeRequest({name: "getRentsPerHour", args, progress});
    return ret.map((e: any) => ({
        hour: e.hour || 0,
        number: e.number || 0,
    }));
}

export async function getSalesPerDate(startDate: Date | null, endDate: Date | null, progress?: (progress: number) => void): Promise<NumberDateReport[]> {
    const args = {
        startDate: startDate === null || startDate === undefined ? null : typeof(startDate) === "string" ? new Date(new Date(startDate).getTime() - new Date(startDate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(startDate.getTime() - startDate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
        endDate: endDate === null || endDate === undefined ? null : typeof(endDate) === "string" ? new Date(new Date(endDate).getTime() - new Date(endDate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(endDate.getTime() - endDate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
    };
    const ret = await makeRequest({name: "getSalesPerDate", args, progress});
    return ret.map((e: any) => ({
        date: new Date(parseInt(e.date.split("-")[0], 10), parseInt(e.date.split("-")[1], 10) - 1, parseInt(e.date.split("-")[2], 10)),
        number: e.number || 0,
    }));
}

export async function getSalesPerDateHour(startDate: Date | null, endDate: Date | null, progress?: (progress: number) => void): Promise<NumberDateHourReport[]> {
    const args = {
        startDate: startDate === null || startDate === undefined ? null : typeof(startDate) === "string" ? new Date(new Date(startDate).getTime() - new Date(startDate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(startDate.getTime() - startDate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
        endDate: endDate === null || endDate === undefined ? null : typeof(endDate) === "string" ? new Date(new Date(endDate).getTime() - new Date(endDate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(endDate.getTime() - endDate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
    };
    const ret = await makeRequest({name: "getSalesPerDateHour", args, progress});
    return ret.map((e: any) => ({
        dateHour: new Date(e.dateHour + "Z"),
        number: e.number || 0,
    }));
}

export async function getSalesPerHour(startDate: Date | null, endDate: Date | null, progress?: (progress: number) => void): Promise<NumberHourReport[]> {
    const args = {
        startDate: startDate === null || startDate === undefined ? null : typeof(startDate) === "string" ? new Date(new Date(startDate).getTime() - new Date(startDate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(startDate.getTime() - startDate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
        endDate: endDate === null || endDate === undefined ? null : typeof(endDate) === "string" ? new Date(new Date(endDate).getTime() - new Date(endDate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(endDate.getTime() - endDate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
    };
    const ret = await makeRequest({name: "getSalesPerHour", args, progress});
    return ret.map((e: any) => ({
        hour: e.hour || 0,
        number: e.number || 0,
    }));
}

export async function getSale(saleId: string, progress?: (progress: number) => void): Promise<Sale> {
    const args = {
        saleId: saleId,
    };
    const ret = await makeRequest({name: "getSale", args, progress});
    return {
        id: ret.id,
        simpleId: ret.simpleId,
        price: ret.price || 0,
        item: {
            id: ret.item.id,
            owner: {
                id: ret.item.owner.id,
                gender: ret.item.owner.gender === null || ret.item.owner.gender === undefined ? null : ret.item.owner.gender,
                birthday: ret.item.owner.birthday === null || ret.item.owner.birthday === undefined ? null : new Date(parseInt(ret.item.owner.birthday.split("-")[0], 10), parseInt(ret.item.owner.birthday.split("-")[1], 10) - 1, parseInt(ret.item.owner.birthday.split("-")[2], 10)),
                type: ret.item.owner.type,
                name: ret.item.owner.name,
                nick: ret.item.owner.nick,
                cpf: ret.item.owner.cpf,
                email: ret.item.owner.email,
                phone: {
                    ddd: ret.item.owner.phone.ddd,
                    ddi: ret.item.owner.phone.ddi,
                    number: ret.item.owner.phone.number,
                },
                avatar: ret.item.owner.avatar === null || ret.item.owner.avatar === undefined ? null : {
                    width: ret.item.owner.avatar.width || 0,
                    height: ret.item.owner.avatar.height || 0,
                    url: ret.item.owner.avatar.url,
                },
                address: ret.item.owner.address === null || ret.item.owner.address === undefined ? null : {
                    street: ret.item.owner.address.street,
                    neighborhood: ret.item.owner.address.neighborhood,
                    city: ret.item.owner.address.city,
                    uf: ret.item.owner.address.uf,
                    country: ret.item.owner.address.country,
                    number: ret.item.owner.address.number,
                    zip: ret.item.owner.address.zip,
                    adicionalInfo: ret.item.owner.address.adicionalInfo,
                    reference: ret.item.owner.address.reference,
                    location: {
                        lat: ret.item.owner.address.location.lat,
                        lon: ret.item.owner.address.location.lon,
                    },
                },
                createdAt: new Date(ret.item.owner.createdAt + "Z"),
                deletedAt: ret.item.owner.deletedAt === null || ret.item.owner.deletedAt === undefined ? null : new Date(ret.item.owner.deletedAt + "Z"),
                blockedUntil: ret.item.owner.blockedUntil === null || ret.item.owner.blockedUntil === undefined ? null : new Date(ret.item.owner.blockedUntil + "Z"),
                basePopularity: ret.item.owner.basePopularity || 0,
                documentsVerified: !!ret.item.owner.documentsVerified,
            },
            calendar: ret.item.calendar.map((e: any) => ({
                start: new Date(parseInt(e.start.split("-")[0], 10), parseInt(e.start.split("-")[1], 10) - 1, parseInt(e.start.split("-")[2], 10)),
                end: new Date(parseInt(e.end.split("-")[0], 10), parseInt(e.end.split("-")[1], 10) - 1, parseInt(e.end.split("-")[2], 10)),
            })),
            createdAt: new Date(ret.item.createdAt + "Z"),
            department: ret.item.department,
            adjustment: !!ret.item.adjustment,
            category: ret.item.category === null || ret.item.category === undefined ? null : ret.item.category,
            color: ret.item.color === null || ret.item.color === undefined ? null : ret.item.color,
            details: ret.item.details,
            gender: ret.item.gender,
            thumbImage: {
                width: ret.item.thumbImage.width || 0,
                height: ret.item.thumbImage.height || 0,
                url: ret.item.thumbImage.url,
            },
            images: ret.item.images.map((e: any) => ({
                width: e.width || 0,
                height: e.height || 0,
                url: e.url,
            })),
            brand: ret.item.brand === null || ret.item.brand === undefined ? null : ret.item.brand,
            ocasions: ret.item.ocasions.map((e: any) => e === null || e === undefined ? null : e),
            size: ret.item.size === null || ret.item.size === undefined ? null : ret.item.size,
            rentPrice: ret.item.rentPrice === null || ret.item.rentPrice === undefined ? null : ret.item.rentPrice || 0,
            salePrice: ret.item.salePrice === null || ret.item.salePrice === undefined ? null : ret.item.salePrice || 0,
            originalPrice: ret.item.originalPrice || 0,
        },
        seller: {
            id: ret.seller.id,
            gender: ret.seller.gender === null || ret.seller.gender === undefined ? null : ret.seller.gender,
            birthday: ret.seller.birthday === null || ret.seller.birthday === undefined ? null : new Date(parseInt(ret.seller.birthday.split("-")[0], 10), parseInt(ret.seller.birthday.split("-")[1], 10) - 1, parseInt(ret.seller.birthday.split("-")[2], 10)),
            type: ret.seller.type,
            name: ret.seller.name,
            nick: ret.seller.nick,
            cpf: ret.seller.cpf,
            email: ret.seller.email,
            phone: {
                ddd: ret.seller.phone.ddd,
                ddi: ret.seller.phone.ddi,
                number: ret.seller.phone.number,
            },
            avatar: ret.seller.avatar === null || ret.seller.avatar === undefined ? null : {
                width: ret.seller.avatar.width || 0,
                height: ret.seller.avatar.height || 0,
                url: ret.seller.avatar.url,
            },
            address: ret.seller.address === null || ret.seller.address === undefined ? null : {
                street: ret.seller.address.street,
                neighborhood: ret.seller.address.neighborhood,
                city: ret.seller.address.city,
                uf: ret.seller.address.uf,
                country: ret.seller.address.country,
                number: ret.seller.address.number,
                zip: ret.seller.address.zip,
                adicionalInfo: ret.seller.address.adicionalInfo,
                reference: ret.seller.address.reference,
                location: {
                    lat: ret.seller.address.location.lat,
                    lon: ret.seller.address.location.lon,
                },
            },
            createdAt: new Date(ret.seller.createdAt + "Z"),
            deletedAt: ret.seller.deletedAt === null || ret.seller.deletedAt === undefined ? null : new Date(ret.seller.deletedAt + "Z"),
            blockedUntil: ret.seller.blockedUntil === null || ret.seller.blockedUntil === undefined ? null : new Date(ret.seller.blockedUntil + "Z"),
            basePopularity: ret.seller.basePopularity || 0,
            documentsVerified: !!ret.seller.documentsVerified,
        },
        buyer: {
            id: ret.buyer.id,
            gender: ret.buyer.gender === null || ret.buyer.gender === undefined ? null : ret.buyer.gender,
            birthday: ret.buyer.birthday === null || ret.buyer.birthday === undefined ? null : new Date(parseInt(ret.buyer.birthday.split("-")[0], 10), parseInt(ret.buyer.birthday.split("-")[1], 10) - 1, parseInt(ret.buyer.birthday.split("-")[2], 10)),
            type: ret.buyer.type,
            name: ret.buyer.name,
            nick: ret.buyer.nick,
            cpf: ret.buyer.cpf,
            email: ret.buyer.email,
            phone: {
                ddd: ret.buyer.phone.ddd,
                ddi: ret.buyer.phone.ddi,
                number: ret.buyer.phone.number,
            },
            avatar: ret.buyer.avatar === null || ret.buyer.avatar === undefined ? null : {
                width: ret.buyer.avatar.width || 0,
                height: ret.buyer.avatar.height || 0,
                url: ret.buyer.avatar.url,
            },
            address: ret.buyer.address === null || ret.buyer.address === undefined ? null : {
                street: ret.buyer.address.street,
                neighborhood: ret.buyer.address.neighborhood,
                city: ret.buyer.address.city,
                uf: ret.buyer.address.uf,
                country: ret.buyer.address.country,
                number: ret.buyer.address.number,
                zip: ret.buyer.address.zip,
                adicionalInfo: ret.buyer.address.adicionalInfo,
                reference: ret.buyer.address.reference,
                location: {
                    lat: ret.buyer.address.location.lat,
                    lon: ret.buyer.address.location.lon,
                },
            },
            createdAt: new Date(ret.buyer.createdAt + "Z"),
            deletedAt: ret.buyer.deletedAt === null || ret.buyer.deletedAt === undefined ? null : new Date(ret.buyer.deletedAt + "Z"),
            blockedUntil: ret.buyer.blockedUntil === null || ret.buyer.blockedUntil === undefined ? null : new Date(ret.buyer.blockedUntil + "Z"),
            basePopularity: ret.buyer.basePopularity || 0,
            documentsVerified: !!ret.buyer.documentsVerified,
        },
        currentStatus: {
            status: ret.currentStatus.status,
            actionBy: ret.currentStatus.actionBy === null || ret.currentStatus.actionBy === undefined ? null : ret.currentStatus.actionBy,
            createdAt: new Date(ret.currentStatus.createdAt + "Z"),
        },
        statusList: ret.statusList.map((e: any) => ({
            status: e.status,
            actionBy: e.actionBy === null || e.actionBy === undefined ? null : e.actionBy,
            createdAt: new Date(e.createdAt + "Z"),
        })),
        promotion: ret.promotion === null || ret.promotion === undefined ? null : {
            promotionId: ret.promotion.promotionId,
            type: ret.promotion.type,
            total: ret.promotion.total || 0,
        },
        createdAt: new Date(ret.createdAt + "Z"),
        editedAt: new Date(ret.editedAt + "Z"),
    };
}

export async function getSales(pageOffset: number | null, filter: SalesFilter | null, progress?: (progress: number) => void): Promise<Sale[]> {
    const args = {
        pageOffset: pageOffset === null || pageOffset === undefined ? null : pageOffset || 0,
        filter: filter === null || filter === undefined ? null : {
            createdAtSaleDate: filter.createdAtSaleDate === null || filter.createdAtSaleDate === undefined ? null : filter.createdAtSaleDate === null || filter.createdAtSaleDate === undefined ? null : {
                startDate: filter.createdAtSaleDate.startDate === null || filter.createdAtSaleDate.startDate === undefined ? null : typeof(filter.createdAtSaleDate.startDate) === "string" ? new Date(new Date(filter.createdAtSaleDate.startDate).getTime() - new Date(filter.createdAtSaleDate.startDate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(filter.createdAtSaleDate.startDate.getTime() - filter.createdAtSaleDate.startDate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
                endDate: filter.createdAtSaleDate.endDate === null || filter.createdAtSaleDate.endDate === undefined ? null : typeof(filter.createdAtSaleDate.endDate) === "string" ? new Date(new Date(filter.createdAtSaleDate.endDate).getTime() - new Date(filter.createdAtSaleDate.endDate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(filter.createdAtSaleDate.endDate.getTime() - filter.createdAtSaleDate.endDate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
            },
            orderBy: filter.orderBy === null || filter.orderBy === undefined ? null : {
                field: filter.orderBy.field,
                order: filter.orderBy.order,
            },
            status: filter.status.map(e => e),
        },
    };
    const ret = await makeRequest({name: "getSales", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        simpleId: e.simpleId,
        price: e.price || 0,
        item: {
            id: e.item.id,
            owner: {
                id: e.item.owner.id,
                gender: e.item.owner.gender === null || e.item.owner.gender === undefined ? null : e.item.owner.gender,
                birthday: e.item.owner.birthday === null || e.item.owner.birthday === undefined ? null : new Date(parseInt(e.item.owner.birthday.split("-")[0], 10), parseInt(e.item.owner.birthday.split("-")[1], 10) - 1, parseInt(e.item.owner.birthday.split("-")[2], 10)),
                type: e.item.owner.type,
                name: e.item.owner.name,
                nick: e.item.owner.nick,
                cpf: e.item.owner.cpf,
                email: e.item.owner.email,
                phone: {
                    ddd: e.item.owner.phone.ddd,
                    ddi: e.item.owner.phone.ddi,
                    number: e.item.owner.phone.number,
                },
                avatar: e.item.owner.avatar === null || e.item.owner.avatar === undefined ? null : {
                    width: e.item.owner.avatar.width || 0,
                    height: e.item.owner.avatar.height || 0,
                    url: e.item.owner.avatar.url,
                },
                address: e.item.owner.address === null || e.item.owner.address === undefined ? null : {
                    street: e.item.owner.address.street,
                    neighborhood: e.item.owner.address.neighborhood,
                    city: e.item.owner.address.city,
                    uf: e.item.owner.address.uf,
                    country: e.item.owner.address.country,
                    number: e.item.owner.address.number,
                    zip: e.item.owner.address.zip,
                    adicionalInfo: e.item.owner.address.adicionalInfo,
                    reference: e.item.owner.address.reference,
                    location: {
                        lat: e.item.owner.address.location.lat,
                        lon: e.item.owner.address.location.lon,
                    },
                },
                createdAt: new Date(e.item.owner.createdAt + "Z"),
                deletedAt: e.item.owner.deletedAt === null || e.item.owner.deletedAt === undefined ? null : new Date(e.item.owner.deletedAt + "Z"),
                blockedUntil: e.item.owner.blockedUntil === null || e.item.owner.blockedUntil === undefined ? null : new Date(e.item.owner.blockedUntil + "Z"),
                basePopularity: e.item.owner.basePopularity || 0,
                documentsVerified: !!e.item.owner.documentsVerified,
            },
            calendar: e.item.calendar.map((e: any) => ({
                start: new Date(parseInt(e.start.split("-")[0], 10), parseInt(e.start.split("-")[1], 10) - 1, parseInt(e.start.split("-")[2], 10)),
                end: new Date(parseInt(e.end.split("-")[0], 10), parseInt(e.end.split("-")[1], 10) - 1, parseInt(e.end.split("-")[2], 10)),
            })),
            createdAt: new Date(e.item.createdAt + "Z"),
            department: e.item.department,
            adjustment: !!e.item.adjustment,
            category: e.item.category === null || e.item.category === undefined ? null : e.item.category,
            color: e.item.color === null || e.item.color === undefined ? null : e.item.color,
            details: e.item.details,
            gender: e.item.gender,
            thumbImage: {
                width: e.item.thumbImage.width || 0,
                height: e.item.thumbImage.height || 0,
                url: e.item.thumbImage.url,
            },
            images: e.item.images.map((e: any) => ({
                width: e.width || 0,
                height: e.height || 0,
                url: e.url,
            })),
            brand: e.item.brand === null || e.item.brand === undefined ? null : e.item.brand,
            ocasions: e.item.ocasions.map((e: any) => e === null || e === undefined ? null : e),
            size: e.item.size === null || e.item.size === undefined ? null : e.item.size,
            rentPrice: e.item.rentPrice === null || e.item.rentPrice === undefined ? null : e.item.rentPrice || 0,
            salePrice: e.item.salePrice === null || e.item.salePrice === undefined ? null : e.item.salePrice || 0,
            originalPrice: e.item.originalPrice || 0,
        },
        seller: {
            id: e.seller.id,
            gender: e.seller.gender === null || e.seller.gender === undefined ? null : e.seller.gender,
            birthday: e.seller.birthday === null || e.seller.birthday === undefined ? null : new Date(parseInt(e.seller.birthday.split("-")[0], 10), parseInt(e.seller.birthday.split("-")[1], 10) - 1, parseInt(e.seller.birthday.split("-")[2], 10)),
            type: e.seller.type,
            name: e.seller.name,
            nick: e.seller.nick,
            cpf: e.seller.cpf,
            email: e.seller.email,
            phone: {
                ddd: e.seller.phone.ddd,
                ddi: e.seller.phone.ddi,
                number: e.seller.phone.number,
            },
            avatar: e.seller.avatar === null || e.seller.avatar === undefined ? null : {
                width: e.seller.avatar.width || 0,
                height: e.seller.avatar.height || 0,
                url: e.seller.avatar.url,
            },
            address: e.seller.address === null || e.seller.address === undefined ? null : {
                street: e.seller.address.street,
                neighborhood: e.seller.address.neighborhood,
                city: e.seller.address.city,
                uf: e.seller.address.uf,
                country: e.seller.address.country,
                number: e.seller.address.number,
                zip: e.seller.address.zip,
                adicionalInfo: e.seller.address.adicionalInfo,
                reference: e.seller.address.reference,
                location: {
                    lat: e.seller.address.location.lat,
                    lon: e.seller.address.location.lon,
                },
            },
            createdAt: new Date(e.seller.createdAt + "Z"),
            deletedAt: e.seller.deletedAt === null || e.seller.deletedAt === undefined ? null : new Date(e.seller.deletedAt + "Z"),
            blockedUntil: e.seller.blockedUntil === null || e.seller.blockedUntil === undefined ? null : new Date(e.seller.blockedUntil + "Z"),
            basePopularity: e.seller.basePopularity || 0,
            documentsVerified: !!e.seller.documentsVerified,
        },
        buyer: {
            id: e.buyer.id,
            gender: e.buyer.gender === null || e.buyer.gender === undefined ? null : e.buyer.gender,
            birthday: e.buyer.birthday === null || e.buyer.birthday === undefined ? null : new Date(parseInt(e.buyer.birthday.split("-")[0], 10), parseInt(e.buyer.birthday.split("-")[1], 10) - 1, parseInt(e.buyer.birthday.split("-")[2], 10)),
            type: e.buyer.type,
            name: e.buyer.name,
            nick: e.buyer.nick,
            cpf: e.buyer.cpf,
            email: e.buyer.email,
            phone: {
                ddd: e.buyer.phone.ddd,
                ddi: e.buyer.phone.ddi,
                number: e.buyer.phone.number,
            },
            avatar: e.buyer.avatar === null || e.buyer.avatar === undefined ? null : {
                width: e.buyer.avatar.width || 0,
                height: e.buyer.avatar.height || 0,
                url: e.buyer.avatar.url,
            },
            address: e.buyer.address === null || e.buyer.address === undefined ? null : {
                street: e.buyer.address.street,
                neighborhood: e.buyer.address.neighborhood,
                city: e.buyer.address.city,
                uf: e.buyer.address.uf,
                country: e.buyer.address.country,
                number: e.buyer.address.number,
                zip: e.buyer.address.zip,
                adicionalInfo: e.buyer.address.adicionalInfo,
                reference: e.buyer.address.reference,
                location: {
                    lat: e.buyer.address.location.lat,
                    lon: e.buyer.address.location.lon,
                },
            },
            createdAt: new Date(e.buyer.createdAt + "Z"),
            deletedAt: e.buyer.deletedAt === null || e.buyer.deletedAt === undefined ? null : new Date(e.buyer.deletedAt + "Z"),
            blockedUntil: e.buyer.blockedUntil === null || e.buyer.blockedUntil === undefined ? null : new Date(e.buyer.blockedUntil + "Z"),
            basePopularity: e.buyer.basePopularity || 0,
            documentsVerified: !!e.buyer.documentsVerified,
        },
        currentStatus: {
            status: e.currentStatus.status,
            actionBy: e.currentStatus.actionBy === null || e.currentStatus.actionBy === undefined ? null : e.currentStatus.actionBy,
            createdAt: new Date(e.currentStatus.createdAt + "Z"),
        },
        statusList: e.statusList.map((e: any) => ({
            status: e.status,
            actionBy: e.actionBy === null || e.actionBy === undefined ? null : e.actionBy,
            createdAt: new Date(e.createdAt + "Z"),
        })),
        promotion: e.promotion === null || e.promotion === undefined ? null : {
            promotionId: e.promotion.promotionId,
            type: e.promotion.type,
            total: e.promotion.total || 0,
        },
        createdAt: new Date(e.createdAt + "Z"),
        editedAt: new Date(e.editedAt + "Z"),
    }));
}

export async function getSalesStats(filter: SalesFilter | null, progress?: (progress: number) => void): Promise<TableStats> {
    const args = {
        filter: filter === null || filter === undefined ? null : {
            createdAtSaleDate: filter.createdAtSaleDate === null || filter.createdAtSaleDate === undefined ? null : filter.createdAtSaleDate === null || filter.createdAtSaleDate === undefined ? null : {
                startDate: filter.createdAtSaleDate.startDate === null || filter.createdAtSaleDate.startDate === undefined ? null : typeof(filter.createdAtSaleDate.startDate) === "string" ? new Date(new Date(filter.createdAtSaleDate.startDate).getTime() - new Date(filter.createdAtSaleDate.startDate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(filter.createdAtSaleDate.startDate.getTime() - filter.createdAtSaleDate.startDate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
                endDate: filter.createdAtSaleDate.endDate === null || filter.createdAtSaleDate.endDate === undefined ? null : typeof(filter.createdAtSaleDate.endDate) === "string" ? new Date(new Date(filter.createdAtSaleDate.endDate).getTime() - new Date(filter.createdAtSaleDate.endDate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(filter.createdAtSaleDate.endDate.getTime() - filter.createdAtSaleDate.endDate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
            },
            orderBy: filter.orderBy === null || filter.orderBy === undefined ? null : {
                field: filter.orderBy.field,
                order: filter.orderBy.order,
            },
            status: filter.status.map(e => e),
        },
    };
    const ret = await makeRequest({name: "getSalesStats", args, progress});
    return {
        totalAmount: ret.totalAmount || 0,
        averageTicked: ret.averageTicked || 0,
        totalPrice: ret.totalPrice || 0,
    };
}

export async function getSalesReportForAdmin(filter: SalesFilter | null, progress?: (progress: number) => void): Promise<string> {
    const args = {
        filter: filter === null || filter === undefined ? null : {
            createdAtSaleDate: filter.createdAtSaleDate === null || filter.createdAtSaleDate === undefined ? null : filter.createdAtSaleDate === null || filter.createdAtSaleDate === undefined ? null : {
                startDate: filter.createdAtSaleDate.startDate === null || filter.createdAtSaleDate.startDate === undefined ? null : typeof(filter.createdAtSaleDate.startDate) === "string" ? new Date(new Date(filter.createdAtSaleDate.startDate).getTime() - new Date(filter.createdAtSaleDate.startDate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(filter.createdAtSaleDate.startDate.getTime() - filter.createdAtSaleDate.startDate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
                endDate: filter.createdAtSaleDate.endDate === null || filter.createdAtSaleDate.endDate === undefined ? null : typeof(filter.createdAtSaleDate.endDate) === "string" ? new Date(new Date(filter.createdAtSaleDate.endDate).getTime() - new Date(filter.createdAtSaleDate.endDate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(filter.createdAtSaleDate.endDate.getTime() - filter.createdAtSaleDate.endDate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
            },
            orderBy: filter.orderBy === null || filter.orderBy === undefined ? null : {
                field: filter.orderBy.field,
                order: filter.orderBy.order,
            },
            status: filter.status.map(e => e),
        },
    };
    const ret = await makeRequest({name: "getSalesReportForAdmin", args, progress});
    return ret;
}

export async function ping(progress?: (progress: number) => void): Promise<string> {
    const ret = await makeRequest({name: "ping", args: {}, progress});
    return ret;
}

export async function setPushToken(token: string, progress?: (progress: number) => void): Promise<void> {
    const args = {
        token: token,
    };
    await makeRequest({name: "setPushToken", args, progress});
    return undefined;
}

//////////////////////////////////////////////////////

let fallbackDeviceId: string | null = null;

function setDeviceId(deviceId: string): void {
    fallbackDeviceId = deviceId;
    try {
        localStorage.setItem("deviceId", deviceId);
    } catch (e) {}
}

function getDeviceId(): string | null {
    try {
        return localStorage.getItem("deviceId") || fallbackDeviceId;
    } catch (e) {}

    return fallbackDeviceId;
}

async function device(): Promise<any> {
    const parser = new UAParser();
    parser.setUA(navigator.userAgent);
    const agent = parser.getResult();
    const me = document.currentScript as HTMLScriptElement;
    const device: any = {
            type: "web",
            platform: {
                browser: agent.browser.name,
                browserVersion: agent.browser.version,
                os: agent.os.name,
                osVersion: agent.os.version,
            },
            screen: {
                width: screen.width,
                height: screen.height,
            },
            version: me ? me.src : "",
            language: navigator.language,
    };

    const deviceId = getDeviceId();
    if (deviceId)
        device.id = deviceId;

    return device;
}

function randomBytesHex(len: number): string {
    let hex = "";
    for (let i = 0; i < 2 * len; ++i) {
        hex += "0123456789abcdef"[Math.floor(Math.random() * 16)];
    }

    return hex;
}

export interface ListenerTypes {
    fail: (e: Error, name: string, args: any) => void;
    fatal: (e: Error, name: string, args: any) => void;
    success: (res: string, name: string, args: any) => void;
}

// tslint:disable-next-line: ban-types
type HookArray = Function[];
export type Listenables = keyof ListenerTypes;
export type ListenersDict = { [k in Listenables]: Array<ListenerTypes[k]> };

const listenersDict: ListenersDict = {
    fail: [],
    fatal: [],
    success: [],
};

export function addEventListener(listenable: Listenables, hook: ListenerTypes[typeof listenable]): void {
    const listeners: HookArray = listenersDict[listenable];
    listeners.push(hook);
}

export function removeEventListener(listenable: Listenables, hook: ListenerTypes[typeof listenable]): void {
    const listeners: HookArray = listenersDict[listenable];
    listenersDict[listenable] = listeners.filter((h) => h !== hook) as any;
}

async function makeRequest({name, args, progress}: {name: string, args: any, progress?: (progress: number) => void}): Promise<any> {
    const deviceData = await device();
    return new Promise<any>((resolve, reject) => {
        const req = new XMLHttpRequest();
        req.open(
            "POST",
            `${baseUrl.startsWith("http") || baseUrl.startsWith("localhost") ?
                "" :
                "https://"
            }${baseUrl}/${name}`,
        );

        const body = {
            id: randomBytesHex(8),
            device: deviceData,
            name: name,
            args: args,
        };

        function roughSizeOfObject(object: any): number {
            const objectList: any = [];
            const stack: any = [ object ];
            let bytes = 0;

            while (stack.length) {
                const value = stack.pop();
                if (typeof value === "boolean") {
                    bytes += 4;
                } else if (typeof value === "string") {
                    bytes += value.length * 2;
                } else if (typeof value === "number") {
                    bytes += 8;
                } else if (
                    typeof value === "object"
                    && objectList.indexOf(value) === -1
                ) {
                    objectList.push(value);
                    for (const i in value) {
                        stack.push(value[i]);
                    }
                }
            }

            return bytes;
        }

        req.upload.onprogress = (event: ProgressEvent) => {
            if (event.lengthComputable && progress) {
                progress(Math.ceil(((event.loaded) / event.total) * 100));
            }
        };

        req.onreadystatechange = () => {
            if (req.readyState !== 4) return;
            try {
                const response = JSON.parse(req.responseText);

                try {
                    setDeviceId(response.deviceId);

                    if (response.ok) {
                        resolve(response.result);
                        listenersDict["success"].forEach((hook) => hook(response.result, name, args));
                    } else {
                        const error = typeof response.error === "object" ?
                            response.error :
                            { type: "Fatal", message: response.toString() };

                        reject(error);

                        listenersDict["fail"].forEach((hook) => hook(error, name, args));
                    }
                } catch (e) {
                    console.error(e);
                    reject({type: "Fatal", message: `[${name}] ${e.toString()}`});

                    listenersDict["fatal"].forEach((hook) => hook(e, name, args));
                }
            } catch (e) {
                console.error(e);
                reject({ type: "BadFormattedResponse", message: `Response couldn't be parsed as JSON (${req.responseText}):\n${e.toString()}` });
                listenersDict["fatal"].forEach((hook) => hook(e, name, args));
            }
        };

        req.send(JSON.stringify(body));
    });
}
